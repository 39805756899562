import React from 'react'
import { CircularProgress } from 'shared-components/material/core'
import ExhibitSetDataGrid from './ExhibiSetDataGrid/ExhibitSetDataGrid'
import { gql, useQuery } from '@apollo/client'
import { CenteredContent } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'

export const GET_EX_SETS= gql`
    query ExSetsForDispute($disputeId: Int) {
        exSetsForDispute(disputeId: $disputeId) {
            exSetName
            exSetCreatedAt
            exSetPrefix
            exSetStart
            exSetEnd
            exSetId
            exSetStatus
            jobs {
                jobStatus
            }
        }
    }
`

export default function ExhibitGenie({ disputeId } : { disputeId: number}) {
    const { 
        data: exSetData, 
        loading: exSetLoading, 
        error: exSetError, 
        refetch } = useQuery(GET_EX_SETS, { variables: {disputeId}, fetchPolicy: 'network-only'})

    if (exSetLoading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }

    return (
        <>
            <ExhibitSetDataGrid rows={exSetData.exSetsForDispute} refetch={refetch} />
            <ErrorModal error={(exSetError && exSetError.message) || ''} />
        </>
    )
}
