import { useUploadContext } from 'App/App'
import { Step, Steps } from 'intro.js-react'
import React, { memo, useState } from 'react'

type TourProps = {
    tourSteps: Step[]
}

function Tour({ tourSteps } : TourProps) {
    const { user } = useUploadContext()
    const [ stepsEnabled, setStepsEnabled ] = useState(user.userTourActive || false)
    return (
        <>
            <Steps
                enabled={stepsEnabled}
                steps={tourSteps}
                initialStep={0}
                onExit={() => console.log('Tour Exited')}
                onComplete={() => setStepsEnabled(false)}
                options={{ 
                    showBullets: false, 
                    exitOnOverlayClick: false, 
                    doneLabel: "Done",
                    nextLabel: "Next",
                    hideNext: false,
                    disableInteraction: false
                }}
            />
        </>
    )
}

export default memo(Tour)
