import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { DetailWrapper, CenteredContent } from 'shared-components/layout'
import { CircularProgress, Grid, Typography } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

import EmailsDataGrid from './EmailsDataGrid'

const GET_EADS = gql`
  query eads($jobId: Int) {
      eads(jobId: $jobId) {
        id: eadId
        eadDisputeId
        eadEmail
        eadName
        eadDomainId
        eadEmailRole
        count
        domainDomain
    }
  }
`

export default function ClassifyEmails() {
  const { jobId } = useParams()

  const id = jobId ? parseInt(jobId) : 0

  const { loading, error, data } = useQuery(GET_EADS, {
    fetchPolicy: 'network-only',
    variables: { jobId: id },
  });

  if (loading) {
      return (
          <CenteredContent>
              <CircularProgress sx={{ color: 'grey.50' }} />
          </CenteredContent>
      )
  }

  return (
    <DetailWrapper>
        <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
          Classify Emails
        </Typography>
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <EmailsDataGrid eads={data.eads} />
            </Grid>
        </Grid>
        <ErrorModal error={error ? error.message : ''} />
    </DetailWrapper>
  )
}
