import React from 'react'
import { Typography, DialogContent, DialogActions, Box, Button, CircularProgress } from 'shared-components/material/core'
import { CenteredContent, CenteredForm } from 'shared-components/layout'
import ErrorModal from '../ErrorModal'
import { gql, useQuery } from '@apollo/client'

export const GET_USER = gql`
    query User($userId: Int) {
        user(userId: $userId) {
            userAccountSetupComplete
        }
    }
`

export default function Success() {
  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, { fetchPolicy: 'network-only'})
  if (userLoading) {
    return (
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    )
}
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
          <CenteredForm>
          <Typography variant="body1" component="p">
              You&apos;ve successfully paid for your subscription! You can now use Discovery Genie with your new subscription plan.
          </Typography>
        </CenteredForm>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pt: 2,
            width: '100%'
        }}>
          <Button 
            href={userData.user.userAccountSetupComplete ? `/app/cases` : '/account-setup'}
            variant="contained"
          >
              Done
          </Button>
        </Box>
      </DialogActions>
      <ErrorModal error={(userError && userError.message) || '' } />
    </>
  )
}
