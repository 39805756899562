import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dispute, Client } from 'generated/graphql'
import validator from 'validator'

import { Typography, Link } from 'shared-components/material/core'


function DisputeMeta({ dispute, client } : { dispute: Dispute, client: Client }) {
  const navigate = useNavigate()
  return (
    <>
      <Typography variant="body1" gutterBottom>
        <strong>Client Name: </strong>
        <Link component="button" variant="body1" onClick={() => navigate(`/app/client/${client.clientId}`)}>{validator.unescape(client.clientName as string)}</Link> <br />
        <strong>Matter:</strong> {validator.unescape(dispute.disputeMatter as string)} <br />
      </Typography>
    </>
  )
}

export default memo(DisputeMeta)
