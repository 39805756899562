import { getS3ArchiveUrl } from 'api/jobs'
import { Job } from 'generated/graphql'
import moment from 'moment'
import React from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useError } from 'shared-components/hooks'
import { Typography, Button, Box, Divider } from 'shared-components/material/core'
import { RefreshIcon } from 'shared-components/material/icons'
import { ErrorModal } from 'shared-components/modals'

type ActionButtonProps = { 
    job: Job,
    refetchJob: () => void
}

export default function ActionButton({ job, refetchJob }: ActionButtonProps) {
    const navigate = useNavigate()
    const [error, href, handleError, resetError ] = useError()
    const handleGetDownload = async () => {
        const { jobId } = job
        const payload = await getS3ArchiveUrl(jobId)
          .catch(e => handleError(e))
    
        if (payload.success) {
          window.open(payload.data.signedGetUrl)
          refetchJob()
        } else {
            handleError(payload.err, payload.href)
        }
    }
    return (
        <>
            <Box sx={{ display: 'flex', mt: 1}}>
            {job.statusString && job.statusString.message === 'FINISHING' ? (
                <>
                <Button
                    variant="contained"
                    disabled
                    >
                    Finishing
                </Button>
                <Button
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={(e) => {
                        e.stopPropagation()
                        window.location.reload()
                    }}
                >
                    <RefreshIcon />
                </Button>
                </>
            ): null}

            {job.statusString && job.statusString.message === 'PROCESSING' ? (
            <>
                <Button
                    variant="contained"
                    disabled
                >
                    Processing
                </Button>
                <Button
                variant="contained"
                sx={{ ml: 1 }}
                onClick={(e) => {
                    e.stopPropagation()
                    window.location.reload()
                }}
                >
                <RefreshIcon />
                </Button>
            </>
            ): null}

            {job.statusString && job.statusString.message === 'CLASSIFY EMAILS' ? (
                <Button
                    variant="contained"
                    onClick={() => navigate(`/app/document-set/${job.jobId}/classify-emails`)}
                    color="secondary"
                >
                    Classify
                </Button>
            ): null}

            {job.statusString && job.statusString.message === 'REVIEW' ? (
                <Button
                    variant="contained"
                    onClick={() => navigate(`/app/document-Set/${job.jobId}/document-review`)}
                    color="secondary"
                >
                    Review
                </Button>
            ): null}

            {job.statusString && job.statusString.message === 'REVIEW PST' ? (
                <Button
                    variant="contained"
                    onClick={() => navigate({
                    pathname: `/app/document-set/${job.jobId}`,
                    search: createSearchParams({
                        tab: '2'
                    }).toString()
                })}
                    color="secondary"
                >
                    Review PST
                </Button>
            ): null}

            {job.statusString && job.statusString.message === 'REQUEST SENT' ? (
                <Button
                    variant="contained"
                    disabled
                >
                    Request Sent
                </Button>
            ): null}

            {job.statusString && job.statusString.message === 'EXCEPTION' ? (
                <Button
                    variant="contained"
                    disabled
                >
                    EXCEPTION
                </Button>
            ): null}
        </Box>

        {job.statusString && job.statusString.message === 'DOWNLOAD' ? (
            <>
            <Divider />
            {job.jobExpiresTimestamp ? (
                <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                <strong>Output File Expires:</strong> {moment(job.jobExpiresTimestamp).format('MM/DD/YYYY')} <br />
                </Typography>
            ) : null}
            <Box sx={{ display: 'flex', justifyContent: 'center', m: 3}}>
                <Button
                    variant="contained"
                    onClick={handleGetDownload}
                    color="secondary"
                >
                    Download Output File
                </Button>
                <Button
                    variant="contained"
                    onClick={() => navigate(`/app/document-Set/${job.jobId}/document-review`)}
                    color="primary"
                    sx={{ ml: 1 }}
                >
                    Review
                </Button>
            </Box>
            </>
        ): null}

        {job.statusString && job.statusString.message === 'EXPIRED' ? (
            <>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'center', m: 3}}>
                <Button
                    variant="contained"
                    disabled
                >
                    Output File Expired
                </Button>
            </Box>
            </>
        ): null}
        <ErrorModal error={error} href={href} resetError={resetError} />
        </>
    )
}
