import React from 'react'
import { Box, styled, sx } from 'shared-components/material/core'
import BullsEye from './BullsEye'

const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

type UploadProps = {
    exSetId: number,
    refetch: () => void,
}

export default function Upload({ exSetId, refetch } : UploadProps) {
    return (
        <UploadContainer>
            <BullsEye
                refetch={refetch}
                exSetId={exSetId}
            />
        </UploadContainer>
    )
}
