import React, { Dispatch, SetStateAction, useState } from 'react'
import { CircularProgress, Grid } from 'shared-components/material/core'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'
import { CenteredContent } from 'shared-components/layout'
import { SearchResultItem } from '../CaseSearch'
import { searchDispute } from 'api/disputes'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

type DocumentSearchProps = {
  setSelectedItems: Dispatch<SetStateAction<SearchResultItem[]>>,
  selectedItems: SearchResultItem[],
  handleReview: () => void
}

export default function DocumentSearch({ 
  setSelectedItems,
  selectedItems,
  handleReview,
} : DocumentSearchProps) {
  const [error, href, handleError, resetError] = useError()
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true)

  const handleSearchDispute = async (
    disputeId: number, 
    query: string, 
    searchType: string, 
    jobStatuses: string[]
  ) => {
    setLoading(true)
    const payload = await searchDispute(disputeId, query, searchType, jobStatuses )
        .catch(e => handleError(e))

    if (payload.success) {
        setSearchResults(payload.data.searchResults)
        setLoading(false)
    } else {
        setLoading(false)
        handleError(payload.err, payload.href)
    }
  }
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item md={4}>
          <SearchForm handleSearchDispute={handleSearchDispute} setSearchTerm={setSearchTerm} />
        </Grid>
        <Grid item md={8}>
          {loading ? (
            <CenteredContent>
              <CircularProgress />
            </CenteredContent>
          ) : (
            <SearchResults 
              searchResults={searchResults} 
              setSelectedItems={setSelectedItems} 
              selectedItems={selectedItems} 
              handleReview={handleReview}
              searchTerm={searchTerm}
            />
          )}
        </Grid>
        <ErrorModal
                error={error || ''} 
                href={href} 
                resetError={resetError} 
            />
    </Grid>
  )
}
