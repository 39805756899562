import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { BatesRec } from 'generated/graphql'

import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from 'shared-components/material/core'

export default function BatesRecsPanel({ batesRecs }: { batesRecs: BatesRec[] }) {
    const navigate = useNavigate()
    const handleRowClick = (batesRecId: number) => navigate(`/app/bates/${batesRecId}`)
    return (
        <TableContainer component={Paper}>
            <Table aria-label="bates records table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Prefix</TableCell>
                        <TableCell align="right">Next Bates</TableCell>
                        <TableCell align="right">Next Priv</TableCell>
                        <TableCell align="right">Next Irrel</TableCell>
                        <TableCell align="right">Date Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {batesRecs.map((batesRec) => (
                        <TableRow 
                            style={{ cursor: 'pointer' }}
                            key={batesRec.batesRecId}
                            onClick={() => handleRowClick(batesRec.batesRecId)}
                        >
                            <TableCell component="th" scope="row">{batesRec.batesRecPrefix}</TableCell>
                            <TableCell align="right">{batesRec.batesRecNextBatesStr}</TableCell>
                            <TableCell align="right">{batesRec.batesRecNextPrivStr}</TableCell>
                            <TableCell align="right">{batesRec.batesRecNextIrrelStr}</TableCell>
                            <TableCell align="right">
                                {moment(batesRec.batesRecCreatedAt).format('MM/DD/YY')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
