import React, { Dispatch, SetStateAction } from 'react';
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CenteredForm } from 'shared-components/layout'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import { TextInput } from 'shared-components/inputs'
import { gql, useMutation } from '@apollo/client'
import ErrorModal from 'shared-components/modals/ErrorModal'

export const ADD_BATES = gql`
  mutation Mutation($batesRecPrefix: String!, $batesRecNextBatesStr: String!, $batesRecNextPrivStr: String!, $batesRecDisputeId: Int) {
  addBatesRec(batesRecPrefix: $batesRecPrefix, batesRecNextBatesStr: $batesRecNextBatesStr, batesRecNextPrivStr: $batesRecNextPrivStr, batesRecDisputeId: $batesRecDisputeId) {
    batesRecId
  }
}
`

const schema = yup.object({
    batesRecPrefix: yup.string().required('Bates prefix required'),
    batesRecNextBatesStr: yup.string().required('Next Bates required'),
    batesRecNextPrivStr: yup.string().required('Next priv required'),
  }).required()

type FormData = {
    batesRecPrefix: string;
    batesRecNextBatesStr: string;
    batesRecNextPrivStr: string;
}

type AddBatesModalProps = {
    openAddBates: boolean,
    setOpenAddBates: Dispatch<SetStateAction<boolean>>,
    refetch: () => void,
    batesRecDisputeId: number,
}

export default function AddBatesModal({ openAddBates, setOpenAddBates, refetch, batesRecDisputeId }: AddBatesModalProps) {
    const [addBates, { error }] = useMutation(ADD_BATES)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
          batesRecPrefix: '',
          batesRecNextBatesStr: '',
          batesRecNextPrivStr: '',
        },
        resolver: yupResolver(schema),
    });

    const { handleSubmit, formState: { errors } } = methods

    const onSubmit = async (data: FormData) => {
        const variables = Object.assign(data, { batesRecDisputeId })
        await addBates({ variables })
        refetch()
        setOpenAddBates(false)
    }

    return (
        <FormProvider {...methods}>
            <Dialog open={openAddBates} onClose={() => setOpenAddBates(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Add Bates Record</DialogTitle>
                    <DialogContent>
                        <CenteredForm>
                            <TextInput
                                name="batesRecPrefix"
                                label="Bates Prefix"
                                required
                                error={errors.batesRecPrefix !== undefined ? true : false}
                                errorMessage={errors.batesRecPrefix ? errors.batesRecPrefix.message : undefined}
                                autoFocus
                            />
                            <TextInput
                                name="batesRecNextBatesStr"
                                label="Next Bates"
                                required
                                error={errors.batesRecNextBatesStr !== undefined ? true : false}
                                errorMessage={errors.batesRecNextBatesStr ? errors.batesRecNextBatesStr.message : undefined}
                            />
                            <TextInput
                                name="batesRecNextPrivStr"
                                label="Next Priv"
                                required
                                error={errors.batesRecNextPrivStr !== undefined ? true : false}
                                errorMessage={errors.batesRecNextPrivStr ? errors.batesRecNextPrivStr.message : undefined}
                            />
                        </CenteredForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAddBates(false)}>Cancel</Button>
                        <Button type="submit" variant="contained">Add Bates</Button>
                    </DialogActions>
                    </form>
            </Dialog>
            <ErrorModal error={error ? error.message : ''} />
        </FormProvider>
    );
}

