import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import Review from './Review'
import Success from './Success'
import Options from './Options'
import Payment from './Payment'
import ErrorModal from '../ErrorModal'
import { calcExSetCost } from 'api/exSets'

const GET_DATA = gql`
    query ExSet($exSetId: Int) {
        exSet(exSetId: $exSetId) {
            exSetId
            exSetDisputeId
            exSetOwner
            exSetName
            exSetNotes
            exSetPrefix
            exSetStart
            exSetEnd
            exSetCreatedAt
            exSetSequencingType
            exSetStatus
            exSetStmpCost
            exSetStmpCostPer
            exSetStmpCount
            exSetStmpPages
            exSetSuppCost
            exSetSuppCostPer
            exSetSuppCount
            exSetSuppPages
            exSetCostActual
            exSetCostOverride
            exSetCost
        }
    }
`

type PayModalProps = { 
    open: boolean,
    togglePayModal: () => void,
    exSetId: number,
}

const steps = ['Options', 'Review', 'Payment', 'Success']

export default function ExhibitGeniePayModal({
    open,
    togglePayModal,
    exSetId,
}: PayModalProps) {

    const { error: dataError, loading: dataLoading, data, refetch: refetchData } = useQuery(GET_DATA, {
        fetchPolicy: 'network-only',
        variables: { exSetId },
    })

    const theme = useTheme()
    const [error, href, handleError, resetError ] = useError()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [activeStep, setActiveStep] = useState(0)
    const [payMethods, setPayMethods] = useState([])
    const [loading, setLoading] = useState(false)

    const getExSetCost = async () => {
        setLoading(true)
        const payload = await calcExSetCost(exSetId)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setPayMethods(payload.data.payMethods)
            setLoading(false)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

    if (dataLoading || loading) {
        return (
            <Dialog open={open} onClose={() => togglePayModal()} fullScreen={fullScreen}>
                <DialogTitle>Produce Exhibit Set</DialogTitle>
                <DialogContent sx={{ textAlign: 'center'}}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={() => togglePayModal()} fullWidth maxWidth={'md'} fullScreen={fullScreen}>
                <DialogTitle>Produce Exhibit Set</DialogTitle>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {}
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                {activeStep === 0 && 
                    <Options
                        exSet={data.exSet}
                        togglePayModal={togglePayModal}
                        getExSetCost={getExSetCost}
                        handleNext={handleNext}
                        refetchData={refetchData}
                    />
                }
                {activeStep === 1 &&
                    <Review
                        exSet={data.exSet}
                        handleBack={handleBack}
                        activeStep={activeStep}
                        handleNext={handleNext}
                    />
                }
                {activeStep === 2 &&
                    <Payment
                        exSet={data.exSet}
                        payMethods={payMethods}
                        handleNext={handleNext}
                        activeStep={activeStep}
                        handleBack={handleBack}
                    />
                }
                {activeStep === 3 && 
                    <Success disputeId={data.exSet.exSetDisputeId} exSetId={data.exSet.exSetId} />
                }
                <ErrorModal 
                    error={dataError ? dataError.message : error} 
                    href={href} 
                    resetError={resetError}
                />
        </Dialog>
    )
}
