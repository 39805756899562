import React, { memo } from 'react'
import { Typography, Link } from 'shared-components/material/core'
import { Dispute, Client } from 'generated/graphql'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'

type DisputeMetaProps = {
  dispute: Dispute,
  client: Client,
}

function DisputeMeta({ dispute, client } : DisputeMetaProps) {
  const navigate = useNavigate()
  return (
    <>
      <Typography variant="body1" gutterBottom>
        <strong>Case Name: </strong>
        <Link 
          component="button" 
          variant="body1" 
          onClick={() => navigate(`/app/cases/${dispute.disputeId}`)}
        >
          {dispute.disputeName && validator.unescape(dispute.disputeName)}
        </Link> <br />
        <strong>Client Name: </strong>
        <Link 
          component="button" 
          variant="body1" 
          onClick={() => navigate(`/app/client/${client.clientId}`)}
        >
          {client.clientName}
        </Link> <br />
        
        <strong>Matter:</strong> {dispute.disputeMatter} <br />
      </Typography>
    </>
  )
}

export default memo(DisputeMeta)
