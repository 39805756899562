const { REACT_APP_API_URL } = process.env

export async function getSSOLink() {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/sso-link`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getClioAccessToken(clioAuthCode: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/request-access-token/${clioAuthCode}`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function postSSORedirect(clioAuthCode: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/sso-redirect`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ clioAuthCode }),
  })

  return res.json()
}

export async function completeClioIntegration({activityDescriptionName, activityDescriptionRate}: {activityDescriptionName: string, activityDescriptionRate: string}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/complete-integration`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ activityDescriptionName, activityDescriptionRate }),
  })

  return res.json()
}

export async function removeClioIntegration() {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/remove-integration`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function patchStartTimer(activityId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/activities/${activityId}/start-timer`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function postClioActivity(disputeClioMatterId: number | null) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/activities`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ disputeClioMatterId }),
  })

  return res.json()
}

type TimeEntry = {
  activityDescriptionId: number,
  quantity: number,
  description: string,
  date: Date,
  rate: number,
  nonBillable: boolean,
}

export async function patchClioActivity(
  activityId: number | null,
  timeEntry: TimeEntry
  ) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/activities/${activityId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify(timeEntry),
  })

  return res.json()
}

export async function deleteClioTimer(activityId: number | null) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/activities/${activityId}/stop-timer`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getClioTimerAndDisputes() {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/disputes`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getOrCreateDispute(clioMatterId: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/dispute`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ clioMatterId }),
  })

  return res.json()
}

export async function getClioMattersSearch(searchQuery: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/matters/search`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ searchQuery }),
  })

  return res.json()
}

export async function getMatterActivities(matterId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/clio/matters/${matterId}/activities`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}