import React from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'

import { CenteredForm } from 'shared-components/layout'
import { Button, LoadingButton,Snackbar, MuiAlert, AlertProps, Box, Typography } from 'shared-components/material/core'
import { PasswordInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

export const EDIT_USER = gql`
    mutation editUser(
        $userId: Int!,
        $userPassword: String!,
    ) {
        updateUser(
            userId: $userId, 
            userPassword: $userPassword, 
        ) {
            userId
        }
    }
`

const schema = yup.object({
    userPassword: yup
        .string()
        .required()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must contain 8 Ccharacters, one uppercase, one lowercase, one number and one special character"
            ),
    confirmPassword: yup
        .string()
        .when("password", {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("password")],
                "Passwords must match"
            ),
        })
        .required("Confirm Password Required"),
  }).required()

type FormData = {
    userPassword: string,
    confirmPassword?: string,
}

function SetPassword({ userId, handleNext, handleBack }: { userId: number, handleNext: () => void, handleBack: () => void }) {
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
    })

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            userPassword: '',
            confirmPassword: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors }} = methods

    const onSubmit = async (formData: FormData) => {
        const variables = { ...formData, userId}
        delete variables.confirmPassword
        editUser({ variables })
        handleNext()
    }

    const handleCloseSnackbar = () => setSnackbar(null)

    return (
        <FormProvider {...methods} >
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <CenteredForm align="center">
                <Typography variant="h2" component="h2" textAlign='left'>
                    Set Password
                </Typography>
                <PasswordInput
                    name={"userPassword"}
                    label={"Password"}
                    error={errors.userPassword != undefined ? true : false }
                    errorMessage={errors.userPassword ? errors.userPassword.message : undefined}
                />
                <PasswordInput
                    name={"confirmPassword"}
                    label={"Confirm Password"}
                    error={errors.confirmPassword != undefined ? true : false }
                    errorMessage={errors.confirmPassword ? errors.confirmPassword.message : undefined}
                />
                </CenteredForm>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleBack} variant="contained">
                        Back
                    </Button>
                    {editUserLoading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ): (
                        <Button onClick={handleSubmit(onSubmit)} variant="contained">
                            Next
                        </Button>
                    )}
                </Box>
            </Box>
            <ErrorModal 
                error={(editUserError && editUserError.message) || ''}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FormProvider>
    )
}

export default SetPassword
