import React, { useState } from 'react'
import { Typography, DialogContent, DialogActions, Box, Button, LoadingButton } from 'shared-components/material/core'
import { CenteredForm } from 'shared-components/layout'
import { acceptEstimatedCategories } from 'api/jobs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import { useError } from 'shared-components/hooks'

type UncategorizedProps = { 
  jobId: number,
  togglePayModal: () => void,
  handleNext: () => void,
  getJobCost: () => void,
  getJobItemsFn?: () => void
}

export default function Uncategorized({ jobId, togglePayModal, handleNext, getJobCost, getJobItemsFn } : UncategorizedProps) {
  const [loading, setLoading] = useState(false)
  const [error, href, handleError, resetError ] = useError()
  const handleAcceptEstimatedCategories = async () => {
    setLoading(true)
    const payload = await acceptEstimatedCategories(jobId)
      .catch(e => handleError(e))

    if (payload.success) {
      if (getJobItemsFn) {
        await getJobItemsFn()
      }
      await getJobCost()
      
      setLoading(false)
      return handleNext()
    }
    setLoading(false)
    return handleError(payload.err, payload.href)
  }
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
        <CenteredForm>
          <Typography variant="body1" component="p" >
            You have uncategorized documents. Would you like to accept
            the predicted categories and finish this job?
          </Typography>
          <Typography variant="body1" component="p" >
              Records without predictions will be returned as &quot;produced.&quot;
          </Typography>
        </CenteredForm>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pt: 2,
            width: '100%'
        }}>
          <Button onClick={togglePayModal} color="inherit">Cancel</Button>

          {loading ? (
            <LoadingButton loading variant="outlined">
              accept
            </LoadingButton>
          ) : (
            <Button 
            variant="contained"
            onClick={handleAcceptEstimatedCategories}
          >
              Accept
          </Button>
          )}
          
        </Box>
      </DialogActions>
      <ErrorModal error={error} href={href} resetError={resetError} />
    </>
  )
}
