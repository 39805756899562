import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DataGridPremium, GridColumnMenu, GridToolbar } from '@mui/x-data-grid-premium'
import { getInvoices } from 'api/accounts'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { Box } from 'shared-components/material/core'
import { getColumns } from './columns'
import { postS3DownloadUrl } from 'api/jobs'

export default function Invoices() {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 0,
      });
    const [error, href, handleError, resetError] = useError()
    const [invoices, setInvoices] = useState([])

    const totalItems = invoices.length

    useEffect(() => {
        const getInvoicesFn = async () => {
            const payload = await getInvoices()
                .catch((e) => handleError(e))
            if (payload.success) {
                setInvoices(payload.data.jobs)
            } else {
                handleError(payload.err, payload.href)
            }
        }
        getInvoicesFn()
    }, [])

    const handleGetSignedUrl = useCallback(async (jobId: number) => {
        const payload = await postS3DownloadUrl(jobId, 'invoice', null, null)
          .catch(e => handleError(e))
    
        if (payload.success) {
            window.open(payload.data.signedGetUrl)
        } else {
            handleError(payload.err, payload.href)
        }
        
    }, [])

    const columns = useMemo(() => getColumns(handleGetSignedUrl), [])

    return (
        <Box sx={{height: 'calc(100vh - 260Px)', width: '100%' }}>
            <DataGridPremium 
                rows={invoices} 
                columns={columns} 
                getRowId={(row) => row.jobId}
                slots={{ columnMenu: GridColumnMenu, toolbar: GridToolbar }}
                pageSizeOptions={[25, 50, 100, totalItems]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <ErrorModal error={error || ''} href={href} resetError={resetError} />
        </Box>
    );
}
