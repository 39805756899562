import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { 
    Typography,
    Box, 
    CircularProgress, 
    styled, 
    sx, 
    Divider, 
    Button 
} from 'shared-components/material/core'
import { AlbumIcon } from 'shared-components/material/icons'
import "intro.js/introjs.css"
import { useUploadContext } from 'App/App'
import Tour from 'shared-components/Tour/Tour'
import { User } from 'generated/graphql'


const ProgressContainer = styled(Box)((
    sx({
        position: 'relative', 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        alignItems: 'center', 
        justifyContent: 'center'
    })
))

const ProgressNumber = styled(Box)((
    sx({
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    })
))

const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

const BullsEyeIcon = styled(AlbumIcon)((
    sx({
        fontSize: '15em',
        cursor: 'pointer',
        margin: '0 auto'
    })
))

type BullsEyeProps = {
    jobId: number, 
    disputeId: number,
    allConfidential: boolean, 
    category: string,
    handleBack: () => void,
    refetchJob: () => void,
    user: User
}

const tourSteps = [
    {
        element: ".introInstructions",
        intro: "Discovery Genie works best with native files. Emails should be exported from your client’s email program into a .pst or an .mbox. Folders (with included subfolders)   of   electronic   records   should   first   be   compressed   into   a   .zip   file. Discovery Genie’s tutorial and support topics show you exactly how to do it—and if you have any trouble, our support team can help."
      },
  ]

export default function BullsEye({ allConfidential, category, jobId, disputeId, handleBack, refetchJob }: BullsEyeProps) {
    const { getUploadData, fileSize, uploadValue, uploading, uploadJobId } = useUploadContext()

    useEffect(() => {
        refetchJob()
    }, [uploading])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        return getUploadData(acceptedFiles, jobId, disputeId, allConfidential, category)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    if (uploading && uploadJobId === jobId) {
        return (
            <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Uploading File
                </Typography>
                <ProgressContainer>
                    <CircularProgress 
                        variant={uploadValue ? 'determinate' : 'indeterminate'}
                        value={(100 * uploadValue) / fileSize} size={100} 
                    />
                    <ProgressNumber>
                        <Typography
                            variant="h2"
                            component="div"
                            color="text.secondary"
                            sx={{ textAlign: 'center' }}
                        >
                            {`${Math.round((100 * uploadValue) / fileSize)}%`}
                        </Typography>
                    </ProgressNumber>
                </ProgressContainer>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
                    Uploading, converting to pdf, and preparing documents for review.
                </Typography>
            </>
        )
    }

    if (uploading && uploadJobId !== jobId) {
        return (
            <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Upload in Progress
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
                    Another file is being uploaded. Please wait for that file to finish uploading.
                </Typography>
            </>
        )
    }

    return (
        <Box className='introInstructions'>
            <Divider />
            <Typography variant="h5" component="h2" sx={{ mt: 2}}>
                Upload File
            </Typography>
            <UploadContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div {...getRootProps()} style={{ textAlign: 'center' }}>
                    <input {...getInputProps()} />
                        <BullsEyeIcon color="primary" />
                        <p>
                            Drag a .pst, .mbox or .zip file onto bullseye or click to select upload file
                            <br />
                            (3GB max file size)
                        </p>
                    </div>
                </Box>
                
            </ UploadContainer>
            <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pt: 2,
                        width: '100%'
                    }}>
                    <Button
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
            </Box>
            <Tour tourSteps={tourSteps} />
        </Box>
    )
}
