import React from 'react'
import moment from 'moment'
import validator from 'validator'
import {
    GridValueFormatterParams,
    GridRowModel,
    GridActionsCellItem,
    GridRowId,
    GridRenderCellParams,
    GridCellParams,
    GridRenderEditCellParams,
    getGridStringOperators,
    GridValidRowModel,
} from '@mui/x-data-grid-premium'

import { Tooltip } from 'shared-components/material/core'
import {
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon,
    IndeterminateCheckBoxIcon,
    AttachFileIcon,
    EditIcon
} from 'shared-components/material/icons'
import { codeToColor, catEstimatedToTooltip, removePath } from 'shared-components/utils'
import { CustomFlagComponent, FlagInputValue, PredictedInputValue, SelectedCategoryInputValue, customStringSortComparitor, getCategorized, getSortAlpha, getSortChrono, getValue, renderFlag } from './columnUtils'

type GetColumnsProps = {
    id: GridRowId, 
    row: GridRowModel, 
    col: string, 
    update: boolean
}

export const getEmlColumns = 
    (handleCheck : ({id, row, col, update}: GetColumnsProps) => 
    void, handleHoldCheck: (id: GridRowId, row: GridRowModel, col: string, update: boolean) => 
    void, jobStatus: number) => [
        {
            field: 'notes',
            renderHeader: () => <span>Notes <EditIcon sx={{ fontSize: '18px'}} /></span>,
            editable: true,
            width: 250,
            headerClassName: 'introNotes',
            renderCell: (params: GridRenderCellParams) => params.row.notes ? validator.unescape(params.row.notes) : '',
        },
        {
            field: 'outputName',
            headerName: 'Bates',
            editable: false,
            width: 150,
            sortComparator: customStringSortComparitor,
        },
        {
            field: 'sortAlpha', 
            headerName: 'Sort Output Alpha', 
            editable: false, 
            width: 150,
            valueGetter: getSortAlpha,
            sortComparator: customStringSortComparitor
        },
        {
            field: 'sortChrono',
            headerName: 'Sort Output Chrono',
            editable: false,
            width: 150,
            valueGetter: getSortChrono,
        },
        {
            field: 'folder', 
            renderHeader: () => <span>Folder <EditIcon sx={{ fontSize: '18px'}} /></span>,
            width: 70, 
            editable: true,
            sortComparator: customStringSortComparitor,
            renderCell: (params: GridRenderCellParams) => params.row.folder ? validator.unescape(params.row.folder) : '',
        },
        {
            field: 'subject', 
            headerName: 'Subject/Attachment', 
            width: 250, 
            editable: true,
            valueGetter: getValue,
            sortComparator: customStringSortComparitor,
            headerClassName: 'introFileName',
            cellClassName: (params: GridCellParams) => {
                if (params.row.attId) {
                    return 'custom-indent'
                }
                return ''
            },
            renderHeader: () => <span>Subject/Attachment <EditIcon sx={{ fontSize: '18px'}} /></span>,
            renderCell: (params: GridValidRowModel) => {
                if (params.rowNode.isAutoGenerated === true) {
                    return "PARENT EMAIL MISSING"
                }
                if (params.row.attId){
                    return (
                        <><AttachFileIcon fontSize="small" /> {params.value}</>
                    )
                }
                if (params.row.docId){
                    return null
                }
            }
        },
        {
            field: 'filename', 
            renderHeader: () => <span>File Name <EditIcon sx={{ fontSize: '18px'}} /></span>,
            width: 250, 
            editable: true,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = removePath(params.value)
                return valueFormatted
            },
            renderCell: (params: GridValidRowModel) => {
                if (params.row.attId){
                    return null
                }
            },
            sortComparator: customStringSortComparitor,
            headerClassName: 'introFileName',
        },
        {
            field: 'date', 
            renderHeader: () => <span>Date Sent <EditIcon sx={{ fontSize: '18px'}} /></span>,
            width: 170,
            editable: true,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                // if (params.rowNode.type !== 'group') {
                //     return undefined
                // }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'ogSubject',
            headerName: 'OG Subject',
            width: 250,
            sortComparator: customStringSortComparitor
        },
        {
            field: 'ogFolder',
            headerName: 'OG Folder',
            width: 250,
            sortComparator: customStringSortComparitor
        },
        {
            field: 'ogFilename',
            headerName: 'OG Filename',
            width: 250,
            sortComparator: customStringSortComparitor
        },
        {
            field: 'ogDate', 
            headerName: 'OG Date Sent',
            width: 170,
            type: 'date',
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return null
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'from', 
            headerName: 'From',
            width: 250,
        },
        {
            field: 'to',
            headerName: 'To',
            width: 200,
        },
        {
            field: 'cc', 
            headerName: 'CC',
            width: 200,
        },
        {
            field: 'bcc',
            headerName: 'BCC',
            width: 200,
        },
        {
            field: 'legalResearch',
            renderHeader: () => <span>Research <EditIcon sx={{ fontSize: '18px'}} /></span>,
            editable: true,
            width: 250,
            renderCell: (params: GridRenderCellParams) => params.row.legalResearch ? validator.unescape(params.row.legalResearch) : '',
        },
        {
            field: 'flag',
            renderHeader: () => <span>Flag <EditIcon sx={{ fontSize: '18px'}} /></span>,
            renderCell: renderFlag,
            renderEditCell: (params: GridRenderEditCellParams) => <CustomFlagComponent {...params} />,
            editable: true,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === 'equals')
                .map((operator => ({
                    ...operator,
                    InputComponent: operator.InputComponent
                        ? FlagInputValue
                        : undefined,
                }))),
        },
        // {
        //     field: 'exSet',
        //     renderHeader: () => <span>Exhibit Set <EditIcon sx={{ fontSize: '18px'}} /></span>,
        //     type: 'singleSelect',
        //     getOptionValue: (value: ExSet) => value.exSetId,
        //     getOptionLabel: (value: ExSet) => value.exSetName,
        //     valueOptions: exSets, 
        //     editable: true,
        // },
        { field: 'reviewInitials', headerName: 'Reviewed By', width: 100 },
        {
            field: 'reviewTimestamp', 
            headerName: 'Reviewed On',
            width: 170,
            type: 'date',
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return null
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'categorized',
            headerName: 'Reviewed',
            type:"boolean",
            width: 250,
            valueGetter: getCategorized
        },
        {
            field: 'converted',
            headerName: 'Converted',
            type:"boolean",
            hideable: false,
            filterable: true
        },
        { 
            field:'catEstimatedStr', 
            headerName: 'Predicted Category',
            hideable: true,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === 'equals')
                .map((operator => ({
                    ...operator,
                    InputComponent: operator.InputComponent
                        ? PredictedInputValue
                        : undefined,
                }))),
        },
        { 
            field:'catFinalStr', 
            headerName: 'Selected Category',
            hideable: true,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === 'equals')
                .map((operator => ({
                    ...operator,
                    InputComponent: operator.InputComponent
                        ? SelectedCategoryInputValue
                        : undefined,
                }))),
        },
        {
            field: 'priv',
            type: 'actions',
            headerName: 'priv',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            cellClassName: 'actions',
            hideable: false,
            filterable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if ((
                    row.catEstimated === 4 || 
                    row.catEstimated === 5) && !row.catFinal && jobStatus !== 70)
                {
                    return [
                        <GridActionsCellItem
                            icon={
                                <Tooltip title={catEstimatedToTooltip(row.catEstimated) || ''}>
                                    <IndeterminateCheckBoxIcon
                                        sx={{ fill: codeToColor(row.catEstimated) }}
                                    />
                                </Tooltip>
                            }
                            label="Priv"
                            onClick={() => handleCheck({id, row, col: 'priv', update: true})}
                            key={id}
                            disabled={jobStatus === 70}
                        /> 
                    ]
                }
                if (row.catFinal === 1) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'}/>}
                            label="Priv"
                            onClick={() => handleCheck({id, row, col: 'priv', update: false})}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ] 
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Priv"
                        onClick={() => handleCheck({id, row, col:'priv', update: true})}
                        key={id}
                        disabled={jobStatus === 70}
                    />
                ]
            },
        },
        {
            field: 'prod',
            type: 'actions',
            headerName: 'prod',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            cellClassName: 'actions',
            hideable: false,
            filterable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if ((
                    row.catEstimated === 1 ||
                    row.catEstimated === 2 ||
                    row.catEstimated === 3) && !row.catFinal && jobStatus !== 70
                ){
                    return [
                        <GridActionsCellItem
                            icon={
                                <Tooltip title={catEstimatedToTooltip(row.catEstimated) || ''}>
                                    <IndeterminateCheckBoxIcon sx={{ fill: codeToColor(row.catEstimated) }} />
                                </Tooltip>
                            }
                            label="Prod"
                            onClick={() => handleCheck({id, row, col:'prod', update: true})}
                            key={id}
                            disabled={jobStatus === 70}
                        /> 
                    ]
                }
                if (row.catFinal === 2) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Prod"
                            onClick={() => handleCheck({id, row, col:'prod', update: false})}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ] 
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Prod"
                        onClick={() => handleCheck({id, row, col:'prod', update: true})}
                        key={id}
                        disabled={jobStatus === 70}
                    />
                ]
            },
        },
        {field: 'isKey', headerName: 'key', type: 'boolean', filterable: true, hideable: false},
        {
            field: 'key',
            type: 'actions',
            headerName: 'key',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            filterable: false,
            hideable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if (row.isKey) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Key"
                            onClick={() => handleCheck({id, row, col: 'isKey', update: false})}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Key"
                        onClick={() => handleCheck({id, row, col: 'isKey', update: true})}
                        key={id}
                        disabled={row.catFinal !== 2 || jobStatus === 70}
                    />
                ]
            },
        },
        {
            field: 'confidential',
            headerName: 'stmp',
            type: 'boolean',
            filterable: true,
            hideable: false
        },
        {
            field: 'stmp',
            type: 'actions',
            headerName: 'stmp',
            width: 53,
            minWidth: 53,
            maxWidth: 53,
            filterable: false,
            hideable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if (row.confidential) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Stamp"
                            onClick={() => handleCheck({id, row, col: 'confidential', update: false})}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Stamp"
                        onClick={() => handleCheck({id, row, col:'confidential', update: true})}
                        key={id}
                        disabled={jobStatus === 70}
                    />
                ]
            },
        },
        {
            field: 'irrl',
            type: 'actions',
            headerName: 'irrl',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            cellClassName: 'actions',
            hideable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if (row.catFinal === 4) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Irrl"
                            onClick={() => handleCheck({id, row, col: 'irrl', update: false})}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Irrl"
                        onClick={() => handleCheck({id, row, col:'irrl', update:true})}
                        key={id}
                        disabled={jobStatus === 70}
                    />
                ]
            },
        },
        {field: 'isHold', headerName: 'hold', type: 'boolean', filterable: true, hideable: false},
        {
            field: 'hold',
            type: 'actions',
            headerName: 'hold',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            hideable: true,
            filterable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType || row.attId !== null) {
                    return []
                }
                if (row.isHold) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Hold"
                            onClick={() => handleHoldCheck(id, row, 'isHold', false)}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Hold"
                        onClick={() => handleHoldCheck(id, row, 'isHold', true)}
                        key={id}
                        disabled={jobStatus === 70}
                    />
                ]
            },
        },
        {field: 'redact', headerName: 'rdct', type: 'boolean', filterable: true, hideable: false},
        {
            field: 'rdct',
            type: 'actions',
            headerName: 'rdct',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            filterable: false,
            hideable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if (row.redact) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Redact"
                            onClick={() => handleCheck({id, row, col:'redact', update: false})}
                            key={id}
                            disabled={jobStatus === 70}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Redact"
                        onClick={() => handleCheck({id, row, col:'redact', update:true})}
                        key={id}
                        disabled={row.catFinal !== 2 || jobStatus === 70}
                    />
                ]
            },
        },
        {field: 'native', headerName: 'natv', type: 'boolean', filterable: true, hideable: false },
        {
            field: 'natv',
            type: 'actions',
            headerName: 'natv',
            width: 51,
            minWidth: 51,
            maxWidth: 51,
            filterable: false,
            hideable: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (!row.itemType) {
                    return []
                }
                if (row.native) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color={jobStatus === 70 ? 'inherit' : 'primary'} />}
                            label="Native"
                            onClick={() => handleCheck({id, row, col:'native', update: false})}
                            key={id}
                            disabled={!row.converted || jobStatus === 70}
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Native"
                        onClick={() => handleCheck({id, row, col:'native', update:true})}
                        key={id}
                        disabled={row.emlId !== null || !row.nativeDownloadable || jobStatus === 70}
                    />
                ]
            },
        },
    ]