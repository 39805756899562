import React, { ReactElement } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { FormControl, FormControlLabel, FormHelperText, Checkbox, Tooltip } from "shared-components/material/core"
import { SxProps, Theme } from '@mui/material/styles';

type CheckboxProps = {
  name: string,
  label: string | ReactElement,
  required?: boolean,
  error?: boolean,
  errorMessage?: string,
  sx?: SxProps<Theme>,
  tooltip?: string,
  disabled?: boolean
}

function CheckboxInput({ name, label, required=false, error=false, errorMessage, sx, tooltip, disabled=false }: CheckboxProps) {
  const { control } = useFormContext()
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Tooltip 
              title={tooltip ? tooltip : ''}
          >
            <FormControl error={error} required={required}>
              <FormControlLabel
                sx={sx}
                control={
                  <Checkbox 
                    name={name}
                    onChange={onChange}
                    checked={value} 
                    disabled={disabled}
                  />
                }
              label={label}
              />
              <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
          </Tooltip>
      )}
      />
    </>
  )
}

export default CheckboxInput