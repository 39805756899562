import React from 'react'
import { Typography, DialogContent, DialogActions, Box, Button } from 'shared-components/material/core'
import { CenteredForm } from 'shared-components/layout'

export default function Success({ disputeId, exSetId } : {disputeId: number, exSetId: number}) {
  return (
    <>
      <DialogContent 
        sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}
      >
          <CenteredForm>
          <Typography variant="body1" component="p">
              We are preparing your exhibits, we will send you an Email 
              notification when they are ready for download.

              Please note, you can only finish one exhibit set at a time.
          </Typography>
        </CenteredForm>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pt: 2,
            width: '100%'
        }}>
          <Button 
            href={`/app/cases/${disputeId}/exhibit-set/${exSetId}`}
            variant="contained"
          >
              Done
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
