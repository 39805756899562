import React, { useEffect, useState } from 'react'
import { CenteredForm } from 'shared-components/layout'
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import ErrorModal from './ErrorModal'
import { getMatterActivities } from 'api/clio'
import { Dispute } from 'generated/graphql'

type ClioActivityModalProps = {
    openActivityModal: boolean,
    closeActivityModal: () => void,
    sendActivityAndDispute: (activity: number | null, dispute: number, time: number) => void,
    disputes: Dispute[],
}

type Activity = {
    id: number,
    date: string,
    quantity_in_hours: number,
}

export default function ClioActivityModal({ sendActivityAndDispute, openActivityModal, closeActivityModal, disputes }: ClioActivityModalProps) {
    const [matters, setMatters] = useState<readonly Dispute[]>([])
    const [activities, setActivities] = useState<readonly Activity[]>([])
    const [selectedActivity, setSelectedActivity] = useState<number | null>(null)
    const [selectedDispute, setSelectedDispute] = useState(0)
    const [time, setTime] = useState(0)
    const [error, href, handleError, resetError] = useError()
    const loading = matters.length === 0

    useEffect(() => {
        if (disputes) {
            setMatters(disputes)
        }
    }, [disputes])

    const handleGetActivities = async (event: React.SyntheticEvent, value: number ) => {
        const payload = await getMatterActivities(value)
            .catch(e => handleError(e))
        if (payload.success) {
            setActivities(payload.data.activities)
            setSelectedDispute(value)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    return (
        <>
            <Dialog open={openActivityModal} onClose={() => closeActivityModal()}>
                <DialogTitle>Select Activity</DialogTitle>
                <DialogContent>
                    <CenteredForm>
                        <Autocomplete
                            id="clio-matters"
                            sx={{ width: 300 }}
                            isOptionEqualToValue={(matters, value) => matters.disputeName === value.disputeName}
                            getOptionLabel={(matters) => matters.disputeName as string}
                            options={matters}
                            onChange={(event, value ) => value && handleGetActivities(event, value.disputeClioMatterId)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Clio Matter"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="activity-select">Select Activity</InputLabel>
                            <Select
                                labelId="activity-select"
                                id="activity selector"
                                value={selectedActivity}
                                label="Age"
                            >
                                <MenuItem
                                    value={0}
                                    onClick={() => { 
                                        setSelectedActivity(0)
                                        setTime(0)
                                    }}
                                >
                                    New Activity
                                </MenuItem>
                                {activities && (
                                    activities.map((a) => {
                                        const aMilli = a.quantity_in_hours * 60 * 60 * 1000
                                        const aSeconds = (`0${(Math.floor(aMilli / 1000) % 60)}`).slice(-2)
                                        const aMinutes = (`0${(Math.floor(aMilli / 60000) % 60)}`).slice(-2)
                                        const aHours = (`0${Math.floor(aMilli / 3600000)}`).slice(-2)
                                        return (
                                            <MenuItem 
                                                key={a.id}
                                                value={a.id}
                                                onClick={() => {
                                                    setSelectedActivity(a.id)
                                                    setTime(aMilli)
                                                }}
                                            >
                                                {a.date} - {aHours}:{aMinutes}:{aSeconds}
                                            </MenuItem>)
                                    }
                                ))}
                            </Select>
                        </FormControl>
                    </CenteredForm>
                </DialogContent>
                <DialogActions>
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pt: 2,
                        width: '100%'
                    }}>
                        <Button onClick={closeActivityModal}>Cancel</Button>
                        <Button 
                            onClick={() => {
                                sendActivityAndDispute(selectedActivity, selectedDispute, time)
                                closeActivityModal()
                            }} 
                            variant="contained"
                            disabled={selectedActivity === null || !selectedDispute}
                        >
                            Select Activity
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </>
    )
}
