import React, { ReactElement, ReactNode } from 'react'
import { Box, styled, sx } from 'shared-components/material/core'

const Wrapper = styled('div')({
    display: 'flex',
    width: '100%',
    maxHeight: '100vh',
    overflow: 'hidden',
})

const ColumnLeft = styled('div')(()=> (
    sx({
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        height: '100vh',
        backgroundColor: '#C8CFE4',
    })
))

const VideoColumnLeft = styled('div')(()=> (
    sx({
        position: 'relative',
        width: '50%',
        height: '100vh',
        backgroundColor: '#d5daea',
        overflow: 'auto'
    })
))

const ColumnRight = styled('div')((
    sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: { xs: '100%', md: '50%' },
        overflow: 'auto',
        pt: '3rem',
        pb: '3rem',
    })
))

function SplitScreen ({ children, svg, video, copy }: { children: ReactNode, svg?: ReactNode, video?: ReactElement, copy?: ReactElement }) { 
    return (
        <Wrapper>
            {svg ? (
                <ColumnLeft>
                    {svg}
                </ColumnLeft>
            ) : (
                <VideoColumnLeft>
                    <Box sx={{ maxWidth: '500px', margin: '15% auto 40px auto'}}>
                        {video}
                    </Box>
                    {copy}
                </VideoColumnLeft>
            )}
            
            <ColumnRight>{children}</ColumnRight>
        </Wrapper>
    )
}

export default SplitScreen