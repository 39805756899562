import React, { Dispatch, SetStateAction, useState } from 'react'
import { CircularProgress, Grid } from 'shared-components/material/core'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'
import { CenteredContent } from 'shared-components/layout'
import { SearchResultItem } from '../DocumentReview'
import { useError } from 'shared-components/hooks'
import { searchJob } from 'api/jobs'
import { ErrorModal } from 'shared-components/modals'
import { useParams } from 'react-router-dom'

type DocumentSearchProps = {
  setSelectedItems: Dispatch<SetStateAction<SearchResultItem[]>>,
  selectedItems: SearchResultItem[],
  handleReview: () => void
}

export default function DocumentSearch({ 
  setSelectedItems,
  selectedItems,
  handleReview,
} : DocumentSearchProps) {
  const [error, href, handleError, resetError] = useError()
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  const { jobId } = useParams()
  const id = jobId ? parseInt(jobId) : 0

  const handleSearchJob = async (
    query: string, 
    searchType: string, 
  ) => {
    setLoading(true)
    const payload = await searchJob(id, query, searchType)
        .catch(e => handleError(e))
    if (payload.success) {
        setSearchResults(payload.data.searchResults)
        setLoading(false)
    } else {
        setLoading(false)
        handleError(payload.err, payload.href)
    }
  }
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item md={4}>
          <SearchForm handleSearchJob={handleSearchJob} />
        </Grid>
        <Grid item md={8}>
          {loading ? (
            <CenteredContent>
              <CircularProgress />
            </CenteredContent>
          ) : (
            <SearchResults 
              searchResults={searchResults} 
              setSelectedItems={setSelectedItems} 
              selectedItems={selectedItems} 
              handleReview={handleReview}
            />
          )}
        </Grid>
        <ErrorModal 
                error={error || ''}
                href={href}
                resetError={resetError}
            />
    </Grid>
  )
}
