import React, { Dispatch, SetStateAction, useEffect, useState }  from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from 'shared-components/material/core'
import ErrorModal from './ErrorModal'
import { getOpposingCounselEmailBody } from 'api/disputes'
import { useError } from 'shared-components/hooks'
import { gql, useMutation } from '@apollo/client'

export const EDIT_DISPUTE = gql`
  mutation UpdateDispute($disputeId: Int!, $disputeOpposingCounselEmailSent: Boolean) {
  updateDispute(disputeId: $disputeId, disputeOpposingCounselEmailSent: $disputeOpposingCounselEmailSent) {
    disputeId
  }
}
`

type DiscoveryProtocolModalProps = {
    openDiscoveryProtocolModal: boolean,
    setOpenDiscoveryProtocolModal: Dispatch<SetStateAction<boolean>>,
    disputeId: number,
    refetchCase: () => void,
}

export default function DiscoveryProtocolModal({ openDiscoveryProtocolModal, setOpenDiscoveryProtocolModal, disputeId, refetchCase }: DiscoveryProtocolModalProps) {
    const [editDispute, { error: editDisputeError, loading: editDisputeLoading }] = useMutation(EDIT_DISPUTE)
    const [ error, href, handleError, resetError ] = useError()
    const [ loading, setLoading ] = useState(false)
    const [ defaultBody, setDefaultBody] = useState('')

    const markComplete = async () => {
        const variables = { disputeId, disputeOpposingCounselEmailSent: true }
        await editDispute({ variables })
        refetchCase()
        setOpenDiscoveryProtocolModal(false)
    }

    const getDiscoveryProtocolEmailBodyFn = async () => {
        setLoading(true)
        const payload = await getOpposingCounselEmailBody(disputeId)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setLoading(false)
            setDefaultBody(payload.data.emailBody)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    useEffect(() => {
        getDiscoveryProtocolEmailBodyFn()
    }, [])

    if (loading || editDisputeLoading) {
        <CircularProgress />
    }

    return (
        <Dialog open={openDiscoveryProtocolModal} onClose={() => setOpenDiscoveryProtocolModal(false)}>
            <DialogTitle>Discovery Protocol Email</DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="p" gutterBottom>
                    Copy the email template below and paste into your email client to send to opposing counsel. Once sent, click the &ldquo;mark complete&rdquo; button to check this item off your To Do list.
                </Typography>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {navigator.clipboard.writeText(`${defaultBody}`)}}
                    >
                    Copy
                </Button>
                <Box sx={{ maxWidth: '700px', backgroundColor: '#f2f3f8', p:2, mt: 2 }}>
                    {defaultBody.split('\n').map((str, index) => <p key={index}>{str}</p>)}
                </Box>
                <ErrorModal 
                    error={error || (editDisputeError && editDisputeError.message) || ''} 
                    href={href} 
                    resetError={resetError}
                />
            </DialogContent>
            <DialogActions>
            <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pt: 2,
                    width: '100%'
                }}>
                    <Button
                        onClick={() => setOpenDiscoveryProtocolModal(false)}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Button 
                        onClick={markComplete}
                        variant="contained"
                    >
                        Mark Complete
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
