import React from 'react'

export default function ExhibitGenieGettingStarted() {
  return (
    <>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe 
                src="https://player.vimeo.com/video/995412751?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{ position:'absolute', top:0, left:0, width:'100%', height:'100%' }}
                title="Getting Started with Exhibit Genie" />
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  )
}
