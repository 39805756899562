import React, { ReactNode } from 'react'
import { Box } from 'shared-components/material/core'

function CenteredContent({ children }: { children: ReactNode[] | ReactNode }) {
    return (
        <Box
            component="div"
            sx={{
                m: 0,
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                minHeight: 'calc(100vh - 128px)',
                width: '100%',
                overflow: 'auto',
            }}
        >
            {children}
        </Box>
    )
}

export default CenteredContent