import React, { useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { 
    Typography, 
    Box, 
    styled, 
    sx, 
    Divider, 
    Button, 
    AccordionDetails, 
} from 'shared-components/material/core'
import { CenteredForm } from 'shared-components/layout'
import { CheckboxInput, RadioInput, TextInput } from 'shared-components/inputs'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { ExpandMore } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import "intro.js/introjs.css"
import Tour from 'shared-components/Tour/Tour'
import { User } from 'generated/graphql'

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    border: 'none',
    '&:before': {
      display: 'none',
    },
    width: '100%',
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

const ProcessingOptionsContainer = styled(Box)((
    sx({
        display: 'flex', 
        flexDirection: 'column', 
        width: '40%'
    })
))

const schema = yup.object({
    source: yup.string().required(),
    category: yup.number().required(),
    allConfidential: yup.bool(),
    clientEmail: yup
        .string()
        .email("please enter a valid email")
        .when('source', {is: '1', then: yup.string().required('Client E-Mail is required')}),
    jobClientInstructions: yup
        .string()
        .when('source', {is: '1', then: yup.string().required('Client E-Mail is required')}),
}).required()

const sourceOptions = [
    { label: 'I will upload', value: '0' },
    { label: 'Client will upload', value: '1' },
]

const category = [
    { label: 'I will review', value: '0' },
    { label: 'Mark all privileged', value: '1' },
    { label: 'Mark all produced', value: '2' },
]

type FormData = {
    source: string,
    category: string,
    allConfidential: boolean,
    clientEmail: string,
    jobClientInstructions: string, 
}

type SetupProps = {
    clientEmail: string,
    jobClientInstructions: string,
    passSetupData: (data: FormData) => void,
    user: User
}

type LocationState = {
    clientEmail: string
  }

  const tourSteps = [
    {
        element: ".sourceOptions",
        intro: 'Select "I will upload" and click the "next" button to upload a file. Select "Client will upload" to send an email to your client with instructions on how to upload a file.'
    },
]

export default function Source({ clientEmail, jobClientInstructions, passSetupData }: SetupProps) {

    const location = useLocation()
    const state = location.state as LocationState
    let clioClientEmail: string

    if (location.state) {
        clioClientEmail = state.clientEmail 
    }

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            source: '0',
            category: '0',
            allConfidential: false,
            clientEmail: '',
            jobClientInstructions: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, setValue, formState: { errors } } = methods

    const radioValue = watch('source')

    useEffect(() => {
        if (clioClientEmail) {
            setValue('clientEmail', clioClientEmail)
            setValue('source', '1')
        }
    }, [])

    useEffect(() => {
        if (clientEmail || jobClientInstructions) {
            setValue('clientEmail', clientEmail)
            setValue('jobClientInstructions', jobClientInstructions)
        }
    }, [clientEmail, jobClientInstructions])

    const onSubmit = (data: FormData) => {
        passSetupData(data)
    }

    const source = watch('source')

    return (
        <FormProvider {...methods}>
                <ProcessingOptionsContainer className="sourceOptions">
                    <Divider />
                    <Typography variant="h5" component="h2" sx={{ mt: 2}}>
                        Setup
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CenteredForm>
                            <RadioInput
                                name="source"
                                label="Source"
                                required
                                error={errors.source !== undefined ? true : false}
                                errorMessage={errors.source ? errors.source.message : undefined}
                                options={sourceOptions}
                                value={radioValue}
                                defaultValue={sourceOptions[0].value}
                            />
                        </CenteredForm>
                    </Box>
                    {source === '0' ? (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="advanced-options"
                                id="advanced-options"
                            >
                                <Typography>Advanced Options</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingTop: '1em' }}>
                                <CenteredForm>
                                    <RadioInput
                                        name="category"
                                        label="Processing Options"
                                        required
                                        error={errors.category !== undefined ? true : false}
                                        errorMessage={errors.category 
                                            ? errors.category.message 
                                            : undefined
                                        }
                                        options={category}
                                        defaultValue={category[0].value}
                                    />
                                    <CheckboxInput 
                                        name={"allConfidential"}
                                        label="Mark all confidential"
                                        required
                                        error={errors.allConfidential != undefined ? true : false }
                                        errorMessage={errors.allConfidential 
                                            ? errors.allConfidential.message 
                                            : undefined}
                                        sx={{ 
                                            ml: -2.5,
                                            pl: -2.5, 
                                            mt: -3, 
                                            pt: -3, 
                                            '& .MuiTypography-root': { ml: '-2px' }
                                        }}
                                    />
                                </CenteredForm>
                            </AccordionDetails>
                        </Accordion>
                    ): null}
                    {source === '1' ? (
                        <>
                            <TextInput
                                name="clientEmail"
                                label="Client E-Mail"
                                required
                                error={errors.clientEmail !== undefined ? true : false }
                                errorMessage={errors.clientEmail ? errors.clientEmail.message : undefined}
                                sx={{ mb: 1 }}
                            />
                            <TextInput
                                name="jobClientInstructions"
                                label="Client Instructions"
                                required
                                error={errors.jobClientInstructions !== undefined ? true : false }
                                errorMessage={errors.jobClientInstructions 
                                    ? errors.jobClientInstructions.message 
                                    : undefined
                                }
                                multiline
                                rows={10}
                            />
                        </>
                    ): null}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        pt: 2,
                        width: '100%'
                    }}>
                        <Button 
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </Box>
                </ProcessingOptionsContainer>
                <Tour tourSteps={tourSteps} />
        </FormProvider>
    )
}
