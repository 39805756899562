import React from 'react'
import { currencyFormat } from 'shared-components/utils'
import { Job } from 'generated/graphql'
import { DialogContent, DialogActions, styled, Button, Box } from 'shared-components/material/core'

const CostTable = styled('table')`
    width: 100%;
    max-width: 600px;
    margin: 2em auto 1.5em auto;
    padding: 1em;
    border: 0;
    border-collapse: collapse;
`

const CostThead = styled('thead')`
    border-bottom: .5px solid #e0e0e0;
`

const CostTd = styled('td')`
    text-align: right;
    color: #999;
    padding: 2px 4px;
    border: 0;
`

const CostTh = styled('th')`
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #333;
    padding: 2px 4px;
    border: 0;
    text-align: left;
`

type ReviewProps = { 
  job: Job,
  handleBack: () => void,
  activeStep: number,
  handleNext: () => void,
  firstFinish: boolean
}

export default function Review({ job, handleBack, handleNext, activeStep, firstFinish } : ReviewProps) {
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
        <CostTable>
          <CostThead>
            <tr>
              <CostTh>Category</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Docs</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Cost</CostTh>
            </tr>
          </CostThead>
          <tbody>
            {job.jobPrivEmlsNum && job.jobPrivEmlsNum > 0
              ? <tr>
                <CostTh>Privileged Emails</CostTh>
                <CostTd>{job.jobPrivEmlsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobPrivEmlsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobPrivAttsNum && job.jobPrivAttsNum > 0
              ? <tr>
                <CostTh>Privileged Attachments</CostTh>
                <CostTd>{job.jobPrivAttsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobPrivAttsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobPrivDocsNum && job.jobPrivDocsNum > 0
              ? <tr>
                <CostTh>Privileged Documents</CostTh>
                <CostTd>{job.jobPrivDocsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobPrivDocsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobProdEmlsNum && job.jobProdEmlsNum > 0
              ? <tr>
                <CostTh>Production Emails</CostTh>
                <CostTd>{job.jobProdEmlsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobProdEmlsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobProdAttsNum && job.jobProdAttsNum > 0
              ? <tr>
                <CostTh>Production Attachments</CostTh>
                <CostTd>{job.jobProdAttsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobProdAttsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobProdDocsNum && job.jobProdDocsNum > 0
              ? <tr>
                <CostTh>Production Documents</CostTh>
                <CostTd>{job.jobProdDocsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobProdDocsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobIrrelDocsCost && job.jobIrrelDocsCost > 0
              ? <tr>
                <CostTh>Excluded/Irrelevant Documents</CostTh>
                <CostTd>{job.jobIrrelDocsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobIrrelDocsCost as number) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobIrrelEmlsCost && job.jobIrrelEmlsCost > 0
              ? <tr>
                <CostTh>Excluded/Irrelevant E-mails</CostTh>
                <CostTd>{job.jobIrrelEmlsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobIrrelEmlsCost) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobIrrelAttsCost && job.jobIrrelAttsCost > 0
              ? <tr>
                <CostTh>Excluded/Irrelevant Attachments</CostTh>
                <CostTd>{job.jobIrrelAttsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobIrrelAttsCost) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobHoldDocsCost && job.jobHoldDocsCost > 0
              ? <tr>
                <CostTh>Held Documents</CostTh>
                <CostTd>{job.jobHoldDocsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobHoldDocsCost) : '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobHoldEmlsCost && job.jobHoldEmlsCost > 0
              ? <tr>
                <CostTh>Held E-mails</CostTh>
                <CostTd>{job.jobHoldEmlsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobHoldEmlsCost): '$0.00'}</CostTd>
              </tr>
              : null
            }
            {job.jobHoldAttsCost && job.jobHoldAttsCost > 0
              ? <tr>
                <CostTh>Held Attachments</CostTh>
                <CostTd>{job.jobHoldAttsNum}</CostTd>
                <CostTd>{firstFinish ? currencyFormat(job.jobHoldAttsCost) : '$0.00'}</CostTd>
              </tr>
              : null
            }
          </tbody>
        </CostTable>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pt: 2,
            width: '100%'
        }}>
          <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
          >
              Back
          </Button>
          <Button 
            onClick={handleNext}
            variant="contained"
            >
              Next
            </Button>
        </Box>
      </DialogActions>
    </>
  )
}
