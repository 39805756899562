

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import {
    GridColumnMenuSortItem,
    GridColumnMenuFilterItem,
    GridColumnMenuProps,
    GridColumnMenuHideItem,
    GridColumnMenuColumnsItem,
    GridColumnMenuPinningItem,
    GridColumnMenuContainer,
} from '@mui/x-data-grid-premium'

  export const CustomGridColumnMenu = React.forwardRef<
  HTMLUListElement,
  GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
  // eslint-disable-next-line react/prop-types
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };

  return (
    <GridColumnMenuContainer ref={ref} {...props}>
      <GridColumnMenuSortItem {...itemProps} />
      <GridColumnMenuFilterItem {...itemProps} />
      <GridColumnMenuHideItem {...itemProps} />
      <GridColumnMenuColumnsItem {...itemProps} />
      <GridColumnMenuPinningItem {...itemProps} />
    </GridColumnMenuContainer>
  );
})