import React from 'react'
import {
    DownloadForOfflineIcon,
    GavelIcon,
    HelpIcon,
    ManageAccountsIcon,
    PeopleAltIcon,
  } from 'shared-components/material/icons'


export const navOptions = [
    { 
      id: 5,
      text: 'Cases',
      link: '/app/cases',
      icon: <GavelIcon />,
    },
    { 
      id: 10,
      text: 'Clients',
      link: '/app/clients',
      icon: <PeopleAltIcon />,
    },
    { 
      id: 15,
      text: 'Downloads',
      link: '/app/downloads',
      icon: <DownloadForOfflineIcon />,
    },
    { 
      id: 20,
      text: 'Preferences',
      link: '/app/preferences',
      icon: <ManageAccountsIcon />,
      children: [
        {
          id: 30,
          text: 'Firm Info',
          link: '/app/preferences/firm-info',
        },
        {
          id: 35,
          text: 'User Info',
          link: '/app/preferences/user-info',
        },
        {
          id: 40,
          text: 'Manage Users',
          link: '/app/preferences/manage-users',
        },
        {
          id: 50,
          text: 'Payment Method',
          link: '/app/preferences/payment-method',
        },
        {
          id: 55,
          text: 'Change Password',
          link: '/app/preferences/change-password',
        },
        {
          id: 60,
          text: 'Security',
          link: '/app/preferences/security',
        },
        {
          id: 65,
          text: 'Clio',
          link: '/app/preferences/clio',
        },
        {
          id: 70,
          text: 'Credits',
          link: '/app/preferences/credits',
        },
        {
          id: 75,
          text: 'Rewards',
          link: '/app/preferences/rewards',
        },
        {
          id: 76,
          text: 'GUID',
          link: '/app/preferences/guid',
        },
        {
          id: 77,
          text: 'Billing',
          link: '/app/preferences/billing',
        },
        {
          id: 79,
          text: 'Invoices',
          link: '/app/preferences/invoices',
        },
      ]
    },
  ]
  
  export const helpOptions = [
    { 
      id: 25,
      text: 'Help',
      link: '/app/help',
      icon: <HelpIcon />,
      children: [
        {
          id: 80,
          text: 'Support',
          link: 'https://www.discoverygenie.com/category/support/',
        },
        {
          id: 83,
          text: 'Tutorials',
          link: 'https://www.discoverygenie.com/category/training/',
        },
        {
          id: 86,
          text: 'Blog',
          link: 'https://www.discoverygenie.com/category/blog/',
        },
        {
          id: 88,
          text: 'Templates',
          link: '/app/help/templates',
        },
      ]
    },
  ]