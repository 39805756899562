import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from 'shared-components/inputs'
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from 'shared-components/material/core'
import { gql, useQuery } from '@apollo/client'
import ErrorModal from './ErrorModal'
import { addToConversation, startConversation } from 'api/search'
import { useError } from 'shared-components/hooks'
import Markdown from 'react-markdown'

type HelpBotModalProps = {
    helpBotModalOpen: boolean,
    setHelpBotModalOpen: Dispatch<SetStateAction<boolean>>
}

const schema = yup.object({
    newInput: yup.string().required('Message is required'),
  }).required()

type FormData = {
    newInput: string,
}

export const GET_USER = gql`
    query User($userId: Int) {
        user(userId: $userId) {
            userInitials
        }
    }
`

type Message = {
    type: string,
    message: string,
}

const initialMessage = {
    type: 'bot',
    message: "Please type what you'd like to search for below. I'll help you convert it into the proper format to get the most out of our search platform."
}

function Messages ({messages, botLoading, userInitials}: {messages: Message[], botLoading: boolean, userInitials: string}) {
    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    return(
        <Box style={{ display: 'flex', flexDirection: 'column', maxHeight: '25vw', border: '1px solid #dedede', padding: '1em', overflowY: 'scroll'}}>
            {messages.map((message) => 
                message.type === 'bot' ? (
                    <Box p={2} style={{ position: 'relative', maxWidth: '350px', backgroundColor: '#ededed', margin: '1em 1em 1em 40px', borderRadius: '10px 10px 10px 0' }}>
                        <Markdown>{message.message}</Markdown>
                        <Avatar style={{ position: 'absolute', bottom: 0, left: -50}}>SG</Avatar>
                    </Box>
                ) : (
                    <Box p={2} style={{ position: 'relative', color: '#fff', maxWidth: '350px', backgroundColor: '#3b82f6', margin: '1em 40px 1em 1em', borderRadius: '10px 10px 0px 10px', alignSelf: 'flex-end'}}>
                        {message.message}
                        <Avatar style={{ position: 'absolute', bottom: 0, right: -50}}>{userInitials}</Avatar>
                    </Box>
                )      
            )}
            {botLoading ? (
                <Box p={2} style={{ position: 'relative', maxWidth: '70px', backgroundColor: '#ededed', margin: '1em 1em 1em 40px', borderRadius: '10px 10px 10px 0' }}>
                    <CircularProgress />
                    <Avatar style={{ position: 'absolute', bottom: 0, left: -50}}>SG</Avatar>
                </Box>
            ) : null}
            <div ref={messagesEndRef} />
        </Box>
    )
}

export default function HelpBotModal({ helpBotModalOpen, setHelpBotModalOpen }: HelpBotModalProps) {
    const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER)
    const [ error, href, handleError, resetError ] = useError()
    const [ messages, setMessages] = useState<Message[]>([initialMessage])
    const [ conversationId, setConversationId ] = useState('')
    const [ botLoading, setBotLoading ] = useState(false)

    useEffect(() => {
        const startConversationFn = async () => {
            const startPayload = await startConversation()
                .catch((e) => handleError(e))
            if (startPayload.success) {
                setConversationId(startPayload.data.conversationId)
            } else {
                handleError(startPayload.err, startPayload.href)
            }
        }

        startConversationFn()
    }, [])

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            newInput: '',
        },
        resolver: yupResolver(schema),
    })
    const { handleSubmit, reset, watch, formState: { errors }} = methods

    const onSubmit = async (formData: FormData) => {
        const { newInput } = formData
        const newUserMessage = {
            type: 'user',
            message: newInput
        }
        setMessages([...messages, newUserMessage])
        reset()
        setBotLoading(true)
        const addPayload = await addToConversation(conversationId, newInput)
            .catch((e) => handleError(e))
        if (addPayload.success) {
            const {response} = addPayload.data
            const newBotMessage = {
                type: 'bot',
                message: response
            }
            setMessages([...messages, newUserMessage, newBotMessage])
            setBotLoading(false)
        } else {
            handleError(addPayload.err, addPayload.href)
        }
    }

    const newInputLength = watch('newInput').length

    return (
        <Dialog
            open={helpBotModalOpen}
            onClose={() => setHelpBotModalOpen(false)}
            aria-labelledby="Search-Help-Bot"
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="Search-Help-Bot">
                Search Genie Help Bot
            </DialogTitle>
            {userLoading ? (
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            ) : (
                <DialogContent>
                <Messages 
                    messages={messages}
                    botLoading={botLoading}
                    userInitials={userData.user.userInitials}
                />
                <FormProvider {...methods} >
                    <Box sx={{ display: 'flex', marginTop: 1, marginBottom: 2 }}>
                        <TextInput
                            name="newInput"
                            label="Your Message"
                            required
                            fullWidth
                            error={errors.newInput !== undefined ? true : false }
                            errorMessage={errors.newInput ? errors.newInput.message : undefined}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={handleSubmit(onSubmit)}
                            sx={{ marginLeft: 1}}
                            disabled={messages.length % 2 !== 1 || newInputLength === 0}
                        >
                            Send
                        </Button>
                    </Box>
                </FormProvider>
                <Typography>
                    For security reasons, the bot cannot directly run your query. Copy and paste the results into the search bar when you are happy with the result. While our models are very powerful, they sometimes make mistakes. Please check any results.
                </Typography>
            </DialogContent>
            )}
            <DialogActions>
                <Button variant="contained" onClick={() => setHelpBotModalOpen(false)}>
                    Done
                </Button>
            </DialogActions>
            <ErrorModal 
                error={error || userError && userError.message || ''}
                href={href}
                resetError={resetError}
            />
        </Dialog>
    )
}
