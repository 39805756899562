import React, { useState, useEffect, useCallback } from 'react'
import { Dispute } from 'generated/graphql'

import { DetailWrapper, CenteredContent } from 'shared-components/layout'
import { CircularProgress, Grid, Typography } from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import Disputes from './Disputes'

import { getDownloads, getS3ArchiveUrl } from 'api/jobs'

function Downloads () {
    const [error, href, handleError, resetError] = useError()
    const [disputes, setDisputes] = useState<Array<Dispute>>([])
    const [loading, setLoading] = useState(true)

    const handleGetDownload = useCallback(async (jobId: number) => {
        const payload = await getS3ArchiveUrl(jobId)
          .catch(e => handleError(e))
          
        if (payload.success) {
          window.open(payload.data.signedGetUrl)
        } else {
            handleError(payload.err, payload.href)
        }
    }, [])

    const getDownloadsFn = async () => {
        setLoading(true)
        const payload = await getDownloads()
            .catch(e => handleError(e))

        if (payload.success) {
            setDisputes(payload.data.disputesWithDownloadableJobs)
            setLoading(false)
        }

        setLoading(false)
        handleError(payload.err, payload.href)
    }

    useEffect(() => {
        getDownloadsFn()
    }, [])

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }

    return (
        <DetailWrapper>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Downloads
            </Typography>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12}>
                    <Disputes
                        disputes={disputes}
                        handleGetDownload={handleGetDownload}
                    />
                </Grid>
            </Grid>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </DetailWrapper>
    )
}

export default Downloads