import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CircularProgress, Paper } from 'shared-components/material/core'
import { CenteredContent } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'
import { useError } from 'shared-components/hooks'

import { postSSORedirect } from 'api/clio'

const { REACT_APP_CLIENT_URL } = process.env

const clioLink = `https://app.clio.com/oauth/authorize?response_type=code&client_id=XAobjVcw5u9wSloD9uOLwHZobf9If6AMlI1ETy2C&redirect_uri=${REACT_APP_CLIENT_URL}/app/preferences/clio?redirect=clio-redirect`

export default function ClioIdentityCallback() {
    const { search } = useLocation()
    const [loading, setLoading] = useState(true)
    const [isNewUser, setIsNewUser] = useState(false)
    const [error, href, handleError ] = useError()
    const clioAuthCode = new URLSearchParams(search).get('code')
    const clioState = new URLSearchParams(search).get('state')
    const identityState = localStorage.getItem('identityState')

    const postSSORedirectFn = async (clioAuthCode: string) => {
        const payload = await postSSORedirect(clioAuthCode)
            .catch(e => handleError(e))

        if (payload.success) {
            if (payload.isNewUser) {
                setIsNewUser(true)
                setLoading(false)
            }
            if (payload.data.clioIntegrated) {
                return window.location.assign('/app/cases');
            } else {
                return window.location.assign(clioLink);
            }
        } else {
            setLoading(false)
            handleError(payload.err, payload.href)
        }
    }

    useEffect(() => {
        setLoading(true)
        if (clioState === identityState && clioAuthCode) {
            postSSORedirectFn(clioAuthCode)
        } else {
            setLoading(false)
            handleError('Incorrect Identity State. Please contact support.')
        }
    }, [clioState, identityState, clioAuthCode])

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }
    if (isNewUser) {
        return (
            <CenteredContent>
                <Paper style={{ width: '450px' }}>
                    <h2>Welcome</h2>
                    <p>Thanks for adding Discovery Genie to Clio.</p>
                    <p>Please click on the verification link in your 
                        email to sign in and continue to account setup.</p>
                </Paper>
            </CenteredContent>
        )
    }

    return <ErrorModal error={error} href={href} />
}
