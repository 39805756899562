import React from 'react'
import { useParams } from 'react-router-dom'
import { Paper, styled, sx, Box, Typography, Divider } from 'shared-components/material/core'
import AddUsers from './AddUsers/AddUsers'
import FirmInfo from 'screens/Preferences/FirmInfo/FirmInfo'
import PaymentMethod from 'screens/Preferences/PaymentMethod/PaymentMethod'
// import Subscription from 'screens/AccountSetup/Subscription/Subscription'
import UserInfo from 'screens/Preferences/UserInfo/UserInfo'
import ChangePassword from './ChangePassword'
import Security from './Security'
import Clio from './Clio'
import Credits from './Credits'
import Rewards from './Rewards'
import Billing from './Billing'
import Invoices from './Invoices/Invoices'
import Guid from './Guid'

const options = [
  {
    slug: 'firm-info',
    component: <FirmInfo />,
    title: 'Firm Info'
  },
  {
    slug: 'user-info',
    component: <UserInfo />,
    title: 'User Info'
  },
  {
    slug: 'manage-users',
    component: <AddUsers />,
    title: 'Manage Users'
  },
  {
    slug: 'payment-method',
    component: <PaymentMethod />,
    title: 'Payment Method'
  },
  {
    slug: 'change-password',
    component: <ChangePassword />,
    title: 'Change Password'
  },
  {
    slug: 'security',
    component: <Security />,
    title: 'Security'
  },
  {
    slug: 'clio',
    component: <Clio />,
    title: 'Clio'
  },
  {
    slug: 'credits',
    component: <Credits />,
    title: 'Credits'
  },
  {
    slug: 'rewards',
    component: <Rewards />,
    title: 'Rewards'
  },
  {
    slug: 'guid',
    component: <Guid />,
    title: 'GUID'
  },
  {
    slug: 'billing',
    component: <Billing />,
    title: 'Billing'
  },
  {
    slug: 'invoices',
    component: <Invoices />,
    title: 'Invoices'
  },
]

const ComponentContainer = styled(Paper)(({ theme }) => (
  sx({
    display: 'flex',
    maxWidth: '960px',
    minHeight: '85vh',
    backgroundColor: theme.palette.grey.A50,
    p: 3,
  })
))

function Preferences () {
  const { option } = useParams()
  const selectedOption = options.filter(navOption => navOption.slug === option)[0]

  return (
    <>
      <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
        Preferences
      </Typography>
      <ComponentContainer elevation={5}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h2' gutterBottom>
            {selectedOption.title}
          </Typography>
          <Divider sx={{ mb: 3, width: '100%' }} />
          {selectedOption.component}
        </Box>
      </ComponentContainer>
    </>
  )
}

export default Preferences