import React, { Dispatch, SetStateAction } from 'react';
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CenteredForm } from 'shared-components/layout'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import { TextInput } from 'shared-components/inputs'
import { gql, useMutation } from '@apollo/client'
import ErrorModal from 'shared-components/modals/ErrorModal'

export const ADD_CLIENT = gql`
  mutation ($clientAlphaId: String!, $clientName: String!) {
        addClient(clientAlphaId: $clientAlphaId, clientName: $clientName) {
            clientId
        }
}
`

const schema = yup.object({
    clientName: yup.string().required('Client name required'),
    clientAlphaId: yup.string().required('Client id required'),
  }).required()

type FormData = {
    clientName: string;
    clientAlphaId: string;
}

type AddClientModalProps = {
    openAddClient: boolean,
    setOpenAddClient: Dispatch<SetStateAction<boolean>>,
    refetch: () => void,
    setNewClient?: (clientAlphaId: string) => void
}

export default function AddClientModal({ openAddClient, setOpenAddClient, refetch, setNewClient}: AddClientModalProps) {
    const [addClient, { error }] = useMutation(ADD_CLIENT)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            clientName: '',
            clientAlphaId: '',
        },
        resolver: yupResolver(schema),
    });

    const { handleSubmit, reset, formState: { errors } } = methods

    const onSubmit = async (data: FormData) => {
        const variables = data
        await addClient({ variables })
        refetch()
        setOpenAddClient(false)
        setNewClient && setNewClient(variables.clientAlphaId)
        reset()
    }

    return (
        <FormProvider {...methods} >
            <Dialog open={openAddClient} onClose={() => setOpenAddClient(false)}>
                <DialogTitle>Add Client</DialogTitle>
                <DialogContent>
                    <CenteredForm>
                        <TextInput
                            name="clientName"
                            label="Client Name"
                            required
                            error={errors.clientName !== undefined ? true : false}
                            errorMessage={errors.clientName ? errors.clientName.message : undefined}
                            autoFocus
                        />
                        <TextInput
                            name="clientAlphaId"
                            label="Client ID"
                            required
                            error={errors.clientAlphaId !== undefined ? true : false}
                            errorMessage={errors.clientAlphaId ? errors.clientAlphaId.message : undefined}
                        />
                    </CenteredForm>
                </DialogContent>
                <DialogActions>
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pt: 2,
                        width: '100%'
                    }}>
                        <Button onClick={() => setOpenAddClient(false)}>Cancel</Button>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Add Client
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <ErrorModal error={error ? error.message : ''} />
        </FormProvider>
    );
}
