import React, { useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CenteredForm } from 'shared-components/layout'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import { CheckboxInput, DateInput, SelectInput, TextInput } from 'shared-components/inputs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import { useError } from 'shared-components/hooks'
import { patchClioActivity } from 'api/clio';

const schema = yup.object({
    hoursDecimal: yup.string().required('This field is required'),
    activityCategory: yup.string().required('This field is required'),
    activityDate: yup.date().required('This field is required'),
    activityDescription: yup.string().required('This field is required'),
    nonBillable: yup.bool(),
  }).required()

type FormData = {
    hoursDecimal: string,
    activityCategory: string,
    activityDate: Date,
    activityDescription: string,
    nonBillable: boolean
}

type TimeEntry = {
    activityDescriptionId: number,
    quantity: number,
    description: string,
    date: Date,
    rate: number,
    nonBillable: boolean,
  }

interface Category {
    id: number,
    name: string,
    rate: {
        amount: number,
        non_billable_amount: number,
        hierarchy: string,
        type: string
    },
    created_at: Date,
    accessible_to_user: boolean,
    default: boolean
  }

type ClioTimeEntryModalProps = {
    timerModalOpen: boolean,
    closeTimerModal: () => void,
    hoursDecimal: string,
    activityCategory: string,
    activityDate: Date,
    activityDescription: string,
    nonBillable: boolean,
    categories: Category[],
    activityRate: string,
    getClioDisputesFn: () => void,
    updateTime: (time: number) => void,
    selectedActivity: number | null
}

interface CategoryOption {
    label: string,
    value: number
}

export default function ClioTimeEntryModal({ 
        hoursDecimal,
        activityCategory,
        activityDate,
        activityDescription,
        nonBillable,
        categories,
        timerModalOpen,
        closeTimerModal,
        activityRate,
        getClioDisputesFn,
        updateTime,
        selectedActivity,
    }: ClioTimeEntryModalProps) {
    const [error, href, handleError, resetError] = useError()
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
          hoursDecimal: '',
          activityCategory: '',
          activityDate: new Date(),
          activityDescription: '',
          nonBillable: false,
        },
        resolver: yupResolver(schema),
    })

    let activityCategoryOptions: CategoryOption[]

    if (categories) {
        activityCategoryOptions = categories.map(c => 
            ({ label: `${c.name} - $${c.rate.amount}/hr`, value: c.id }))
    } else {
        activityCategoryOptions = []
    }

    const { handleSubmit, setValue, formState: { errors } } = methods

    useEffect(() => {
        setValue('hoursDecimal', hoursDecimal || '')
        setValue('activityCategory', activityCategory || '')
        setValue('activityDate', activityDate || new Date())
        setValue('activityDescription', activityDescription || '')
        setValue('nonBillable', nonBillable || false)
    }, [hoursDecimal, activityCategory, activityDate, activityDescription, nonBillable])

    const onSubmit = async (data: FormData) => {
        const timeEntry: TimeEntry = {
            quantity: parseFloat(data.hoursDecimal) * 3600,
            activityDescriptionId: parseInt(data.activityCategory),
            description: data.activityDescription,
            date: data.activityDate,
            rate: parseInt(activityRate),
            nonBillable: data.nonBillable,
          }
        
        const payload = await patchClioActivity(selectedActivity, timeEntry)
          .catch(e => handleError(e))

        if (payload.success) {
            const time = parseInt(data.hoursDecimal) * 3600000
            updateTime(time)
            getClioDisputesFn()
            closeTimerModal()
        } else {
            handleError(payload.err, payload.href)
            closeTimerModal()
        }
    }

    return (
        <FormProvider {...methods}>
            <Dialog open={timerModalOpen} onClose={() => closeTimerModal()}>
                <DialogTitle>Finalize Time Entry</DialogTitle>
                <DialogContent>
                    <CenteredForm>
                        <TextInput
                            name="hoursDecimal"
                            label="Duration (hours)"
                            required
                            error={errors.hoursDecimal !== undefined ? true : false}
                            errorMessage={errors.hoursDecimal ? errors.hoursDecimal.message : undefined}
                            autoFocus
                        />
                        <SelectInput
                            name="activityCategory"
                            label="Activity Category"
                            options={activityCategoryOptions}
                            required
                            error={errors.activityCategory !== undefined ? true : false}
                            errorMessage={errors.activityCategory ? errors.activityCategory.message : undefined}
                            sx={{ width: '100%' }}
                        />
                        <DateInput
                            name='activityDate'
                            label='Activity Date'
                            error={errors.activityDate !== undefined ? true : false}
                            errorMessage={errors.activityDate ? errors.activityDate.message : undefined}
                        />
                        <CheckboxInput 
                            name={"nonBillable"}
                            label="Non Billable"
                            error={errors.nonBillable != undefined ? true : false }
                            errorMessage={errors.nonBillable ? errors.nonBillable.message : undefined}
                        />
                        <TextInput
                            name="activityDescription"
                            label="Activity Description"
                            required
                            multiline
                            error={errors.activityDescription !== undefined ? true : false}
                            errorMessage={errors.activityDescription ? errors.activityDescription.message : undefined}
                            autoFocus
                            rows={10}
                        />
                    </CenteredForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit(onSubmit)} variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </FormProvider>
    )
}

