
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form"
import { gql, useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { Client, Dispute } from 'generated/graphql'
import validator from 'validator'

import {
    Box,
    Button,
    CircularProgress,
    Divider,
    LoadingButton,
    Typography,
    Snackbar,
    MuiAlert,
    AlertProps,
    Autocomplete,
    TextField
} from 'shared-components/material/core'
import {
    TextInput,
    RadioInput,
    SelectInput,
    DateInput,
} from 'shared-components/inputs'
import { CenteredContent, RightAlignedForm } from 'shared-components/layout'
import { ErrorModal, AddClientModal, ConfirmationModal } from 'shared-components/modals'
import { useError } from 'shared-components/hooks'

import { associateClioMatter, closeCase, deleteDispute, reopenCase } from 'api/disputes'
import { getClioMattersSearch } from 'api/clio'


export const EDIT_DISPUTE = gql`
  mutation EditDispute(
    $disputeId: Int!,
    $disputeName: String!,
    $disputeMatter: String!,
    $disputeFederal: Boolean!,
    $disputeClientId: Int!,
    $disputeNumber: String,
    $disputeDivision: String,
    $disputeJudge: String,
    $disputeProductionDeadline: DateTime
    $disputeDataRetentionDeadline: DateTime
    ) {
    updateDispute(
        disputeId: $disputeId,
        disputeName: $disputeName,
        disputeMatter: $disputeMatter,
        disputeFederal: $disputeFederal,
        disputeClientId: $disputeClientId,
        disputeNumber: $disputeNumber,
        disputeDivision: $disputeDivision,
        disputeJudge: $disputeJudge,
        disputeProductionDeadline: $disputeProductionDeadline
        disputeDataRetentionDeadline: $disputeDataRetentionDeadline
        ) {
            disputeId
        }
}
`

const GET_DATA = gql`
    query GetData {
        clients {
            clientId
            clientName
        }
        user {
            userClioSetupComplete
        }
    }
`

const schema = yup.object({
    disputeName: yup.string().required('Case name is required'),
    disputeProductionDeadline: yup.date().required(),
    disputeDataRetentionDeadline: yup.date(),
    disputeClientId: yup.number().required('Client is required').positive().integer(),
    disputeMatter: yup.string().required('Matter is required'),
    disputeFederal: yup.boolean(),
    disputeNumber: yup.string(),
    disputeDivision: yup.string(),
    disputeJudge: yup.string(),
}).required()

type FormData = {
    // clioMatter: string,
    disputeName: string,
    disputeClientId: number,
    disputeMatter: string,
    disputeFederal: boolean,
    disputeNumber: string,
    disputeDivision: string,
    disputeJudge: string,
    disputeProductionDeadline: Date | null
    disputeDataRetentionDeadline: Date | null
}

type Matter = {
    id: number,
    description: string,
    display_number: string,
    client: {
        name: string
    }
}

const federalOptions = [
    { label: 'Federal', value: true },
    { label: 'State', value: false },
]

export default function EditCasePanel({ dispute, refetchCase } : { dispute: Dispute, refetchCase: () => void }) {
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const { data, loading: dataLoading, error: dataError, refetch: refetchData } = useQuery(GET_DATA)
    const [editDispute, { error: editDisputeError, loading: editDisputeLoading }] = useMutation(EDIT_DISPUTE, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
    })
    const [matters, setMatters] = useState<readonly Matter[]>([])
    const [clioMatterId, setClioMatterId] = useState(0)
    const [open, setOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const clientOptions = data ? data.clients.map((client: Client) => ({ label: client.clientName, value: client.clientId })): []
    clientOptions.unshift({label: '', value: 0})
    const [openAddClient, setOpenAddClient] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [error, href, handleError, resetError] = useError()
    const navigate = useNavigate()
    const mattersLoading = open && (matters && matters.length === 0)

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            disputeName: '',
            disputeClientId: 0,
            disputeMatter: '',
            disputeFederal: false,
            disputeNumber: '',
            disputeDivision: '',
            disputeJudge: '',
            disputeProductionDeadline: null,
            disputeDataRetentionDeadline: null
        },
        resolver: yupResolver(schema),
    })
    const { handleSubmit, setValue, formState: { errors } } = methods

    useEffect(() => {
        setValue('disputeName', dispute.disputeName && validator.unescape(dispute.disputeName) || '')
        setValue('disputeClientId', dispute.disputeClientId || 0)
        setValue('disputeMatter', dispute.disputeMatter || '')
        setValue('disputeFederal', dispute.disputeFederal as boolean)
        setValue('disputeNumber', dispute.disputeNumber || '')
        setValue('disputeDivision', dispute.disputeDivision || '')
        setValue('disputeJudge', dispute.disputeJudge || '')
        setValue('disputeProductionDeadline', dispute.disputeProductionDeadline || null)
        setValue('disputeDataRetentionDeadline', dispute.disputeDataRetentionDeadline || null)
        setClioMatterId(dispute.disputeClioMatterId || 0)
    }, [dispute])

    useEffect(() => {
        if (!open) {
          setMatters([])
        }
      }, [open])

    const debounce = (func: (userClioSetupComplete: boolean, searchQuery: string, callback: (filteredOptions: Matter[]) => void) => void, wait = 0) => {
        let timeoutID: NodeJS.Timeout
        return (userClioSetupComplete: boolean, searchQuery: string, callback: (filteredOptions: Matter[]) => void) => {
          clearTimeout(timeoutID)
      
          timeoutID = setTimeout(function () {
            func(userClioSetupComplete, searchQuery, callback)
          }, wait)
        }
    }

    const getClioMattersSearchFn = async (userClioSetupComplete: boolean, searchQuery: string) => {
        if (userClioSetupComplete) {
            const payload = await getClioMattersSearch(searchQuery)
                .catch(e => handleError(e))
            if (payload.success) {
                return payload.data.matters
            } else {
                return handleError(payload.err, payload.href)
            }
        }
        return []
    }

    const getClioMattersSearchDelayed = useCallback(
        debounce((userClioSetupComplete, searchQuery, callback) => {

            setMatters([])
            getClioMattersSearchFn(userClioSetupComplete, searchQuery).then(callback)
        }, 1000), [] //use debounce fn to delay getClioMatters 1sec to throttle api calls
    )

    useEffect(() => {
        const callback = (filteredMatters: Matter[]) => {
            setMatters(filteredMatters)
        }
        if (data) {
            getClioMattersSearchDelayed(data.user.userClioSetupComplete as boolean, searchQuery, callback) //Invoke getClioMatters with delay on input change
        }
    }, [data, searchQuery, getClioMattersSearchDelayed])

    const onSubmit = async (data: FormData) => {
        const variables = Object.assign(data, { disputeId: dispute.disputeId })
        await editDispute({ variables })
        refetchCase()
    }

    const handleOpenConfirmation = () => setConfirmation(true)
    const handleCloseConfirmation = () => setConfirmation(false)

    const handleDelete = async () => {
        const payload = await deleteDispute(dispute.disputeId)
            .catch(e => handleError(e))

        if (payload.success) {
            refetchCase()
            setConfirmation(false)
            navigate('/app/cases')
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleCloseSnackbar = () => setSnackbar(null)

    if (dataLoading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    let errorMessage

    if (dataError) {
        errorMessage = dataError.message
    }
    if (editDisputeError) {
        errorMessage = editDisputeError.message
    }
    if (error) {
        errorMessage = error
    }

    const handleCloseCase = async () => {
        const payload = await closeCase(dispute.disputeId)
            .catch((e) => handleError(e.message))
        
        if (payload.success) {
            refetchCase()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleReopenCase = async () => {
        const payload = await reopenCase(dispute.disputeId)
            .catch((e) => handleError(e.message))
        
        if (payload.success) {
            refetchCase()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleAssociateClioMatter = async () => {
        const payload = await associateClioMatter(dispute.disputeId, clioMatterId)
            .catch((e) => handleError(e.message))
        
            if (payload.success) {
                refetchCase()
            } else {
                handleError(payload.err, payload.href)
            }
    }

    return (
        <FormProvider {...methods}>
            {data.user.userClioSetupComplete && (
                <>
                    <Typography variant='h3'>
                        Clio Options
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                </>
            )}
            <RightAlignedForm>
            {data.user.userClioSetupComplete ? (
                <>
                        <p>Associate case with a Clio matter:</p>
                        <Autocomplete
                            fullWidth
                            id="clio-matters"
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            isOptionEqualToValue={(matter, value) => matter.id === value.id}
                            getOptionLabel={(matter) => matter.description}
                            options={matters}
                            loading={mattersLoading}
                            filterOptions={(x) => x}
                            onInputChange={(e, newInputValue) => setSearchQuery(newInputValue)}
                            onChange={(event, value ) => {
                                if (value) {
                                    setClioMatterId(value.id)
                                    // setClioMatter(value)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Clio Matter"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {mattersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Button 
                            sx={{ float: 'right', mt: 2, mb: 2 }}
                            onClick={handleAssociateClioMatter} 
                            variant="contained" color="primary">
                            Save
                        </Button>
                        </>
                ) : null}
            </RightAlignedForm>
            <Typography variant='h3'>
                Edit Case
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <RightAlignedForm>
                
                {dispute.disputeStatus && dispute.disputeStatus < 80 || dispute.disputeStatus === 0 ? (
                <Button
                    variant='outlined'
                    color='error'
                    size='small'
                    onClick={handleCloseCase}
                    sx={{ mt: 1 }}
                >
                    Close Case
                </Button>
            ) : (
                <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={handleReopenCase}
                    sx={{ mt: 1 }}
                >
                    Reopen Case
                </Button>
            )}
                <TextInput
                    name='disputeName'
                    label='Case Name'
                    required
                    error={errors.disputeName !== undefined ? true : false}
                    errorMessage={errors.disputeName ? errors.disputeName.message : undefined}
                />
                <Box sx={{ display: 'flex', '& .MuiFormControl-root': { minWidth: '21.4ch' }}}>
                    <SelectInput
                        name="disputeClientId"
                        label="Client"
                        options={clientOptions}
                        required
                        error={errors.disputeClientId !== undefined ? true : false}
                        errorMessage={errors.disputeClientId ? errors.disputeClientId.message : undefined}
                    />
                    <Button
                        onClick={() => setOpenAddClient(true)}
                        variant="outlined"
                        sx={{ height: '40px', ml: 1, minWidth: '114px' }}
                    >
                        Add Client
                    </Button>
                </Box>
                <AddClientModal openAddClient={openAddClient} setOpenAddClient={setOpenAddClient} refetch={refetchData} />
                <TextInput
                    name='disputeMatter'
                    label='Matter'
                    required
                    error={errors.disputeMatter !== undefined ? true : false}
                    errorMessage={errors.disputeMatter ? errors.disputeMatter.message : undefined}
                />
                <DateInput
                    name='disputeProductionDeadline'
                    label='Production Deadline'
                    error={errors.disputeProductionDeadline !== undefined ? true : false}
                    errorMessage={errors.disputeProductionDeadline ? errors.disputeProductionDeadline.message : undefined}
                />
                {dispute.disputeDataRetentionDeadline && (
                    <DateInput
                        name='disputeDataRetentionDeadline'
                        label='Data Retention Deadline'
                        error={errors.disputeDataRetentionDeadline !== undefined ? true : false}
                        errorMessage={errors.disputeDataRetentionDeadline ? errors.disputeDataRetentionDeadline.message : undefined}
                    />
                )}
                
            </RightAlignedForm>
            <Typography variant='h3'>
                Case Options
            </Typography>
            <Divider />
            <RightAlignedForm>
                <RadioInput
                    name="disputeFederal"
                    label="Jurisdiction"
                    error={errors.disputeFederal !== undefined ? true : false}
                    errorMessage={errors.disputeFederal ? errors.disputeFederal.message : undefined}
                    options={federalOptions}
                    defaultValue={dispute.disputeFederal as boolean}
                />
                <TextInput
                    name='disputeNumber'
                    label='Case Number'
                    error={errors.disputeNumber !== undefined ? true : false}
                    errorMessage={errors.disputeNumber ? errors.disputeNumber.message : undefined}
                />
                <TextInput
                    name='disputeDivision'
                    label='Division/Court'
                    error={errors.disputeDivision !== undefined ? true : false}
                    errorMessage={errors.disputeDivision ? errors.disputeDivision.message : undefined}
                />
                <TextInput
                    name='disputeJudge'
                    label='Judge'
                    error={errors.disputeJudge !== undefined ? true : false}
                    errorMessage={errors.disputeJudge ? errors.disputeJudge.message : undefined}
                />
            </RightAlignedForm>
            <Divider />
            {editDisputeLoading ? (
                <LoadingButton sx={{ float: 'right', mt: 2, mb: 2 }} loading variant="outlined">
                    Save
                </LoadingButton>
            ) : (
                <Button sx={{ float: 'right', mt: 2, mb: 2 }} onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                    Save
                </Button>
            )}
            <Button sx={{ float: 'right', mt: 2, mb: 2, mr: 1 }} onClick={handleOpenConfirmation} variant="outlined" color="error">
                Delete
            </Button>
            <ErrorModal error={errorMessage || ''} href={href} resetError={resetError} />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <ConfirmationModal 
                open={confirmation} 
                handleCloseConfirmation={handleCloseConfirmation} 
                title="Delete Case"
                content="Are you sure you want to delete this case?"
                button="Delete"
                action={handleDelete}
                danger
            />
        </FormProvider>
    )
}