import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { CenteredForm, SplitScreen } from 'shared-components/layout'
import { Button, Typography } from 'shared-components/material/core'
import { PasswordInput } from 'shared-components/inputs'
import { ResetPasswordSVG } from 'shared-components/svg'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import { postResetPassword } from 'api/accounts'


const schema = yup.object({
    password: yup
        .string()
        .required()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
    confirmPassword: yup
        .string()
        .when("password", {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("password")],
                "Passwords must match"
            ),
        })
        .required("Confirm Password Required"),
  }).required()

type FormData = {
    password: string,
    confirmPassword: string
}

function ResetPassword() {
    const params = useParams()
    const { search } = useLocation()
    const newUser = new URLSearchParams(search).get('new-user')
    const [success, setSuccess] = useState(false)
    const [error, href, handleError, resetError ] = useError()
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            password: '',
            confirmPassword: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors }} = methods

    const onSubmit = async (data: FormData) => {
        const resetCode = params.resetCode || ''
        const payload = await postResetPassword(resetCode, data.password)
            .catch(e => handleError(e))

        if (payload.success) {
            setSuccess(true)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    return (
        <FormProvider {...methods} >
            <SplitScreen svg={<ResetPasswordSVG width="75%" />}>
                <CenteredForm>
                    {success ? (
                        <>
                            <img
                                width='336.73px'
                                src='/blueLogo.png'
                                style={{ alignSelf: 'center', marginBottom: '2em' }}
                            />
                            <Typography variant="h2" component="h2" textAlign='left'>
                                {newUser ? 'Set Password' : 'Reset Password'}
                            </Typography>
                            <Typography variant="body1" textAlign='left'>
                            {newUser ? "Success! You've set your password." : "Success! You've reset your password."} 
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                href={'/'}
                            >
                                Login
                            </Button>
                        </>
                    ) : (
                        <>
                            <img
                                width='336.73px'
                                src='/blueLogo.png'
                                style={{ alignSelf: 'center', marginBottom: '2em' }}
                            />
                            <PasswordInput
                                name={"password"}
                                label={"Password"}
                                error={errors.password != undefined ? true : false }
                                errorMessage={errors.password ? errors.password.message : undefined}
                            />
                            <PasswordInput
                                name={"confirmPassword"}
                                label={"Confirm Password"}
                                error={errors.confirmPassword != undefined ? true : false }
                                errorMessage={errors.confirmPassword ? errors.confirmPassword.message : undefined}
                            />
                            <Button
                                variant="contained"
                                size="large"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {newUser ? 'Set Password' : 'Reset Password'}
                            </Button>
                        </>
                    )}
                    
                </CenteredForm>
            </SplitScreen>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </FormProvider>
    )
}

export default ResetPassword
