import React, { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'
import { BatesRec, Job } from 'generated/graphql'

import {
    Box,
    Button,
    Divider,
    LoadingButton,
    Typography,
    Snackbar,
    MuiAlert,
    AlertProps
} from 'shared-components/material/core'
import { TextInput, SwitchInput, SelectInput } from 'shared-components/inputs'
import { RightAlignedForm } from 'shared-components/layout'
import { AddBatesModal, ConfirmationModal, ErrorModal, Feedback } from 'shared-components/modals'
import { deleteJob } from 'api/jobs'
import { useError } from 'shared-components/hooks'
import { useNavigate } from 'react-router-dom'

export const EDIT_JOB = gql`
  mutation EditJob(
    $jobId: Int!,
    $jobName: String!,
    $jobPdfMargin: Boolean!,
    $jobCustomConfidential: String,
    $jobBypassBates: Boolean!
    $jobBatesRecId: Int!,
    ) {
        updateJob(
            jobId: $jobId,
            jobName: $jobName,
            jobPdfMargin: $jobPdfMargin,
            jobCustomConfidential: $jobCustomConfidential,
            jobBypassBates: $jobBypassBates
            jobBatesRecId: $jobBatesRecId
        ) {
            jobId
        }
}
`

const schema = yup.object({
    jobName: yup.string(),
    jobCustomConfidential: yup.string(),
    jobPdfMargin: yup.bool(),
    jobBatesRecId: yup.number(),
}).required()

type FormData = {
    jobName: string,
    jobCustomConfidential: string,
    jobPdfMargin: boolean,
    jobBatesRecId: number | null,
}

type EditJobProps = {
    job: Job,
    refetch: () => void,
    batesRecs: BatesRec[]
    disputeBatesIntegrity: boolean
}

export default function EditJob({ job, refetch, batesRecs, disputeBatesIntegrity } : EditJobProps) {
    const navigate = useNavigate()
    const [openFeedback, setOpenFeedback] = useState(false)
    const [openAddBates, setOpenAddBates] = useState(false)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [editJob, { error: editJobError, loading: editJobLoading }] = useMutation(EDIT_JOB, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
    })
    const [confirmation, setConfirmation] = useState(false)
    
    const [error, href, handleError, resetError] = useError()
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            jobName: '',
            jobCustomConfidential: '',
            jobPdfMargin: false,
            jobBatesRecId: 0,
        },
        resolver: yupResolver(schema),
    })

    const batesOptions = batesRecs.map(batesRec => {
        return {
            label: `Prefix: ${batesRec.batesRecPrefix} - Next bates: ${batesRec.batesRecNextBatesStr} - Next Priv: ${batesRec.batesRecNextPrivStr}`,
            value: batesRec.batesRecId
        }
    })

    batesOptions.push({ 
        label: 'No bates numbers',
        value: 0
    })
    

    const { handleSubmit, setValue, formState: { errors } } = methods

    useEffect(() => {
        setValue('jobName', job.jobName || '')
        setValue('jobCustomConfidential', job.jobCustomConfidential || '')
        setValue('jobPdfMargin', job.jobPdfMargin || false)
        setValue('jobBatesRecId', job.jobBatesRecId || 0)
    }, [job])

    const onSubmit = async (data: FormData) => {
        let variables = Object.assign(data, { jobId: job.jobId })
        if (variables.jobBatesRecId === 0) {
            variables = Object.assign(variables, { jobBypassBates: true })
        } else {
            variables = Object.assign(variables, { jobBypassBates: false })
        }
        await editJob({ variables })
        refetch()
    }

    const handleCloseSnackbar = () => setSnackbar(null)

    const handleOpenConfirmation = () => setConfirmation(true)
    const handleCloseConfirmation = useCallback(() => setConfirmation(false), [])

    const handleDelete = useCallback(async () => {
        const payload = await deleteJob(job.jobId)
            .catch(e => handleError(e))

        if (payload.success) {
            refetch()
            setConfirmation(false)
            navigate(`/app/cases/${job.jobDisputeId}`)
        } else {
            handleError(payload.err, payload.href)
        }
    }, [])

    let errorMessage

    if (editJobError) {
        errorMessage = editJobError.message
    }
    if (error) {
        errorMessage = error
    }

  return (
    <FormProvider {...methods}>
        <Typography variant='h3'>
            Document Set Meta
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <RightAlignedForm>
            <TextInput
                name='jobName'
                label='Document Set Name'
                required
                error={errors.jobName !== undefined ? true : false}
                errorMessage={errors.jobName ? errors.jobName.message : undefined}
            />
        </RightAlignedForm>
        <Typography variant='h3'>
            Document Set Settings
        </Typography>
        <Divider />
        <RightAlignedForm>
            <TextInput
                name='jobCustomConfidential'
                label='Custom Stamp'
                required
                error={errors.jobCustomConfidential !== undefined ? true : false}
                errorMessage={errors.jobCustomConfidential 
                    ? errors.jobCustomConfidential.message 
                    : undefined
                }
                sx={{ mt: 2 }}
            />
            {disputeBatesIntegrity ? (
                <Box sx={{ 
                    display: 'flex',
                    maxWidth: '315px',
                    '& .MuiFormControl-root': { minWidth: '21.4ch' }
                }}>
                    <SelectInput
                        name="jobBatesRecId"
                        label="Bates Prefix"
                        options={batesOptions}
                        required
                        error={errors.jobBatesRecId !== undefined ? true : false}
                        errorMessage={errors.jobBatesRecId ? errors.jobBatesRecId.message : undefined}
                        fullWidth
                    />
                    <Button
                        onClick={() => setOpenAddBates(true)}
                        variant="outlined"
                        sx={{ height: '40px', ml: 1, minWidth: '114px' }}
                    >
                        Add Prefix
                    </Button>
                </Box>
            ) : null}
            <SwitchInput 
                name="jobPdfMargin"
                label="PDF Margin"
                required
                error={errors.jobPdfMargin != undefined ? true : false }
                errorMessage={errors.jobPdfMargin ? errors.jobPdfMargin.message : undefined}
            />
        </RightAlignedForm>
        <Divider />
            {editJobLoading ? (
                <LoadingButton sx={{ float: 'right', mt: 2, mb: 2 }} loading variant="outlined">
                    Save
                </LoadingButton>
            ) : (
                <Button
                    sx={{ float: 'right', mt: 2, mb: 2 }}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="secondary"
                >
                    Save
                </Button>
            )}
            {job.jobStatus && job.jobStatus >= 100 && (
                <Button
                    sx={{ float: 'right', mt: 2, mb: 2, mr: 1 }}
                    onClick={() => setOpenFeedback(true)}
                    variant="outlined"
                    color="primary"
                >
                    Customer Support
                </Button>
            )}
            
            <Button
                sx={{ float: 'right', mt: 2, mb: 2, mr: 1 }}
                onClick={handleOpenConfirmation}
                variant="outlined"
                color="error"
                disabled={(job.jobStatus && job.jobStatus >= 100) || false}
            >
                Delete
            </Button>
        <ConfirmationModal
            open={confirmation} 
            handleCloseConfirmation={handleCloseConfirmation} 
            title="Delete Document Set"
            content="Are you sure you want to delete this document set?"
            button="Delete"
            action={handleDelete}
            danger
        />
        <AddBatesModal
            openAddBates={openAddBates}
            setOpenAddBates={setOpenAddBates}
            refetch={refetch}
            batesRecDisputeId={job.jobDisputeId ? job.jobDisputeId : 0}
        />
        <Feedback openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} />
        {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        <ErrorModal error={errorMessage || ''} href={href} resetError={resetError} />
    </FormProvider>
  )
}
