import React from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { 
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, 
  LoadingButton,
  Snackbar,
  MuiAlert,
  AlertProps,
  CircularProgress,
  Typography,
  Checkbox
} from 'shared-components/material/core'

import { ErrorModal } from 'shared-components/modals'
import { User } from 'generated/graphql'
import { CheckboxInput, SelectInput, TelephoneInput, TextInput } from 'shared-components/inputs'
import { CenteredContent, HorizontalForm } from 'shared-components/layout'

export const GET_DATA = gql`
  query Query {
    firm {
      users {
        userEmail
        userFirstname
        userLastname
        userInitials
        userTelephone
        userFirmRole
        userId
        userSuspended
        userType
      }
    }
    user {
      userType
    }
  }
`

const ADD_USER = gql`
    mutation addUser(
        $userEmail: String!,
        $userFirstname: String!,
        $userLastname: String!,
        $userInitials: String!,
        $userTelephone: String!,
        $userFirmRole: String
        $userType: Int,
    ) {
        addUser(
            userEmail: $userEmail,
            userFirstname: $userFirstname,
            userLastname: $userLastname,
            userInitials: $userInitials,
            userTelephone: $userTelephone,
            userFirmRole: $userFirmRole,
            userType: $userType
        ) {
            userId
        }
}
`

const UPDATE_ADMIN = gql`
  mutation Mutation($userId: Int!, $userType: Int) {
  updateUser(userId: $userId, userType: $userType) {
    userType
  }
}
`
const UPDATE_SUSPEND = gql`
   mutation Mutation($userId: Int!, $userSuspended: Boolean!) {
  updateUserSuspended(userId: $userId, userSuspended: $userSuspended) {
    userId
  }
}
`
const schema = yup.object({
  userFirstname: yup.string().required('First name is required'),
  userLastname: yup.string().required('Last name is required'),
  userInitials: yup
    .string()
    .matches(/^[A-Za-z]{2,3}$/, 'Initials must be two to three letters long')
    .required('Initials is required'),
  userTelephone: yup
      .string()
      .matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Phone number is not valid')
      .required(),
  userEmail: yup.string().required('Email is required'),
  userFirmRole: yup.string().required('Role is required'),
  userType: yup.bool(),
}).required()

type FormData = {
  userFirstname: string;
  userLastname: string;
  userInitials: string;
  userTelephone: string;
  userEmail: string;
  userFirmRole: string;
  userType: boolean | number;
}

const userFirmRoleOptions = [
  { label: 'Partner', value: 'Partner'},
  { label: 'Associate', value: 'Associate'},
  { label: 'Paralegal', value: 'Paralegal'},
  { label: 'Law Clerk', value: 'Law Clerk'},
  { label: 'Support Staff', value: 'Support Staff'},
  { label: 'Other', value: 'Other'},
]

function AddUser() {
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const { data, loading: dataLoading, error: dataError, refetch } = useQuery(GET_DATA, {
    fetchPolicy: 'network-only'
  })
  const [addUser, { loading: addUserLoading, error: addUserError }] = useMutation(ADD_USER, {
    onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
  })
  const [updateSuspend, { error: updateSuspendError }] = useMutation(UPDATE_SUSPEND)
  const [updateAdmin, { error: updateAdminError }] = useMutation(UPDATE_ADMIN)

  let errorMessage

  if (updateSuspendError) {
    errorMessage = updateSuspendError.message
  }
  if (updateAdminError) {
    errorMessage = updateAdminError.message
  }
  if (addUserError) {
    errorMessage = addUserError.message
  }
  if (dataError) {
    errorMessage = dataError.message
  }

  const methods = useForm({
    defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
      userFirstname: '',
      userLastname: '',
      userInitials: '',
      userTelephone: '',
      userEmail: '',
      userType: false,
      userFirmRole: '',
    },
    resolver: yupResolver(schema),
  })

  const { handleSubmit, reset, formState: { errors } } = methods

  const onSubmit = async (formData: FormData) => {
    const variables = formData
    if (variables.userType) {
      variables.userType = 1
    } else {
      variables.userType = 0
    }
    await addUser({ variables })
    reset()
    refetch()
  }

  const handleSuspend = async (userId: number, userSuspended: boolean) => {
    const variables = { userId, userSuspended: !userSuspended}
    await updateSuspend({ variables })
    refetch()
  }

  const handleCloseSnackbar = () => setSnackbar(null)

  const handleAdminChange = async (event: React.ChangeEvent<HTMLInputElement>, userId: number) => {
    const userType = event.target.checked ? 1 : 0
    const variables = { userType, userId}
    await updateAdmin({ variables })
    refetch()
  }

  if (dataLoading || addUserLoading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    )
  }

  const adminUsers = data.firm.users.filter((user: User )=> user.userType === 1)

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      }}
    >
    {data && data.firm.users && data.firm.users.length > 0 ? (
      <TableContainer component={Paper} sx={{ mb: '1em'}}>
        <Table aria-label="manage users">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Initials</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telephone</TableCell>
              <TableCell>Firm Role</TableCell>
              <TableCell>DG Admin</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.firm.users.map((user: User) => (
              <TableRow key={user.userId}>
                <TableCell>{user.userFirstname} {user.userLastname}</TableCell>
                <TableCell>{user.userInitials}</TableCell>
                <TableCell>{user.userEmail}</TableCell>
                <TableCell>{user.userTelephone}</TableCell>
                <TableCell>{user.userFirmRole}</TableCell>
                <TableCell>
                  <Checkbox 
                    checked={user.userType ? true : false} 
                    disabled={
                      user.userType === 0 || 
                      (adminUsers.length === 1 && adminUsers[0].userId === user.userId)}
                    onChange={(e) => handleAdminChange(e, user.userId)}
                  />
                </TableCell>
                <TableCell>
                {user.userSuspended ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleSuspend(user.userId, user.userSuspended as boolean)}
                    >
                        Activate
                    </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => handleSuspend(user.userId, user.userSuspended as boolean)}
                    disabled={user.userType === 1}
                  >
                    Suspend
                  </Button>
                )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      ) : <div /> }
      {data.user && (data.user.userType === 1 || data.user.userType === 99)
      ? (
        <FormProvider {...methods}>
        <HorizontalForm>
        <TextInput
            name="userFirstname"
            label="First Name"
            required
            error={errors.userFirstname !== undefined ? true : false }
            errorMessage={errors.userFirstname ? errors.userFirstname.message : undefined}
        />
        <TextInput
            name="userLastname"
            label="Last Name"
            required
            error={errors.userLastname !== undefined ? true : false }
            errorMessage={errors.userLastname ? errors.userLastname.message : undefined}
        />
        <TextInput
            name="userInitials"
            label="Initials"
            required
            error={errors.userInitials !== undefined ? true : false }
            errorMessage={errors.userInitials ? errors.userInitials.message : undefined}
            sx={{ maxWidth: '85px'}}
        />
        <TelephoneInput
            name="userTelephone"
            label="Telephone"
            required
            error={errors.userTelephone !== undefined ? true : false }
            errorMessage={errors.userTelephone ? errors.userTelephone.message : undefined}
        />
        <TextInput
            name="userEmail"
            label="E-Mail"
            required
            error={errors.userEmail !== undefined ? true : false }
            errorMessage={errors.userEmail ? errors.userEmail.message : undefined}
        />
        <SelectInput
            name="userFirmRole"
            label="Firm Role"
            options={userFirmRoleOptions}
            required
            error={errors.userFirmRole !== undefined ? true : false }
            errorMessage={errors.userFirmRole ? errors.userFirmRole.message : undefined}
            sx={{ maxWidth: '120px'}}
        />
        <CheckboxInput
          name="userType"
          label={
            <Typography variant="body2" textAlign='left'>
                DG Admin
            </Typography>   
        }
          required
          error={errors.userType != undefined ? true : false }
          errorMessage={errors.userType ? errors.userType.message : undefined}
        />
        {addUserLoading ? (
        <LoadingButton loading variant="outlined">
            Add
        </LoadingButton>
        ) : (
          <Button onClick={handleSubmit(onSubmit)} variant="contained">Add</Button>
        )}
        <ErrorModal error={errorMessage || ''} />
        {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </HorizontalForm>
      </FormProvider>
      ) : null}
    </Box>
  )
}

export default AddUser
