import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { Grid, CircularProgress, Typography } from 'shared-components/material/core'
import { CenteredContent, DetailContainer, DetailWrapper } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'

import DisputeMeta from './DisputeMeta/DisputeMeta'
import FileMeta from './FileMeta/FileMeta'
import JobData from './JobData/JobData'
import JobMeta from './JobMeta/JobMeta'
import Progress from './Progress/Progress'

const GET_JOB = gql`
    query job($jobId: Int) {
            job(jobId: $jobId) {
                jobId
                jobOwner
                jobDisputeId
                jobCreatedAt
                jobName
                jobStatus
                jobCustomConfidential
                jobNotes
                jobType
                jobCatType
                jobCatBypass
                jobAllConfidential
                jobAllKey
                jobBatesPrefix
                jobBatesRecId
                jobBatesEnd
                jobBatesStart
                jobPrivEnd
                jobPrivStart
                jobIrrelStart
                jobIrrelEnd
                jobEmlsTotal
                jobAttsTotal
                jobDocsTotal
                jobExpiresTimestamp
                jobReceipt
                jobCost
                jobPrivEmlsNum
                jobPrivEmlsPages
                jobPrivEmlsCost
                jobPrivAttsNum
                jobPrivAttsPages
                jobPrivAttsCost
                jobPrivDocsNum
                jobPrivDocsPages
                jobPrivDocsCost
                jobProdEmlsNum
                jobProdEmlsPages
                jobProdEmlsCost
                jobProdAttsNum
                jobProdAttsPages
                jobProdAttsCost
                jobProdDocsNum
                jobProdDocsPages
                jobProdDocsCost
                jobHoldAttsCost
                jobHoldAttsNum
                jobHoldDocsCost
                jobHoldDocsNum
                jobHoldEmlsCost
                jobHoldEmlsNum
                jobIrrelEmlsNum
                jobIrrelEmlsPages
                jobIrrelEmlsCost
                jobIrrelAttsNum
                jobIrrelAttsPages
                jobIrrelAttsCost
                jobIrrelDocsNum
                jobIrrelDocsPages
                jobIrrelDocsCost
                jobClientEmail
                jobCostOverride
                jobCostActual
                jobBypassBates
                jobPdfMargin
                jobDeadlineId
                jobFinishUserId
                jobUploadFilename
                jobUploadFilesize
                jobCrmKey
                jobBatesLength
                jobPrivLength
                jobIrrelLength
                statusString {
                    status
                    message
                }
                credit {
                    creditCode
                    creditApplied
                }
                atts {
                    attCatFinal
                }
                docs {
                    docCatFinal
                }
                emls {
                    emlCatFinal
                }
                procUnits {
                    procUnitId
                    procUnitProcessable
                    procUnitSize
                    procUnitRecords
                    procUnitS3Location
                    procUnitS3Key
                    procUnitName
                    procUnitProcess
                    procUnitReturn
                }
                downloads {
                    downloadCreatedAt
                    downloadId
                    downloadUserId
                }
                dispute {
                    disputeId
                    disputeName
                    disputeMatter
                    disputeBatesIntegrity
                    batesRecs {
                        batesRecId
                        batesRecDisputeId
                        batesRecPrefix
                        batesRecNextBatesStr
                        batesRecNextPrivStr
                        batesRecNextIrrelStr
                        batesRecIsDefault
                        batesRecBatesLength
                        batesRecPrivLength
                        batesRecHasSpace
                        batesRecParty
                        batesRecLocked
                        batesRecCreatedAt
                    }
                    users {
                        userFirstname
                        userLastname
                        userId
                        userType
                    }
                    client {
                        clientId
                        clientName
                    }
                
            }
        }
    }
`

export default function Job() {
    const { jobId } = useParams()
    const id = jobId ? parseInt(jobId) : 0

    const { error, loading, data, refetch } = useQuery(GET_JOB, {
        fetchPolicy: 'network-only',
        variables: { jobId: id },
    })

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    const { job } = data
    const { dispute, downloads } = job
    const { client, users, batesRecs } = dispute

    return (
        <DetailWrapper>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Document Set
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item md={5}>
                    <DetailContainer>
                        <JobMeta job={job} users={users} refetchJob={refetch} />
                    </DetailContainer>
                </Grid>
                <Grid item md={5}>
                    <DetailContainer>
                        <DisputeMeta dispute={dispute} client={client} jobBatesRecId={job.jobBatesRecId} />
                    </DetailContainer>
                </Grid>
                <Grid item md={2}>
                    <DetailContainer>
                        <Progress job={job} />
                    </DetailContainer>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {job.statusString && job.statusString.message === 'UPLOAD' ? null : (
                    <Grid item md={4}>
                        <DetailContainer>
                            <FileMeta
                                job={job}
                                refetchJob={refetch}
                                downloads={downloads}
                                users={users}
                            />
                        </DetailContainer>
                    </Grid>
                )}
                    <Grid item md={job.statusString && job.statusString.message === 'UPLOAD' ? 12 : 8}>
                    <DetailContainer>
                        <JobData
                            job={job}
                            batesRecs={batesRecs}
                            refetch={refetch}
                            disputeBatesIntegrity={dispute.disputeBatesIntegrity}
                        />
                    </DetailContainer>
                </Grid>
            </Grid>
            <ErrorModal error={error ? error.message : ''} />
        </DetailWrapper>
    )
}