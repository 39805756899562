import React from 'react'

import { 
    GridColumnMenuContainer, 
    GridColumnMenuProps, 
    GridColumnMenuPinningItem, 
    GridColumnMenuColumnsItem, 
    GridColumnMenuFilterItem, 
    GridColumnMenuHideItem, 
    GridColumnMenuSortItem, 
} from '@mui/x-data-grid-premium'

import "intro.js/introjs.css"

export const CustomGridColumnMenu = React.forwardRef<
    HTMLUListElement,
    GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
    // eslint-disable-next-line react/prop-types
    const itemProps = {
        colDef: props.colDef,
        onClick: props.hideMenu,
      };
    return (
        <GridColumnMenuContainer ref={ref} {...props}>
            <GridColumnMenuSortItem {...itemProps} />
            <GridColumnMenuFilterItem {...itemProps} />
            <GridColumnMenuHideItem {...itemProps} />
            <GridColumnMenuColumnsItem {...itemProps} />
            <GridColumnMenuPinningItem {...itemProps} />
        </GridColumnMenuContainer>
    );
})