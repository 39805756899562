const { REACT_APP_API_URL } = process.env

export async function startConversation() {
    const res = await fetch(`${REACT_APP_API_URL}/api/search/start-conversation`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
    })
  
    return res.json()
  }

  export async function addToConversation(conversationId: string, newInput: string) {
    const res = await fetch(`${REACT_APP_API_URL}/api/search/add-to-conversation/${conversationId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ newInput }),
    })
  
    return res.json()
  }