import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import {
    Box,
    Step,
    StepLabel,
    Stepper,
    Paper,
    CircularProgress,
} from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'
import { CenteredContent } from 'shared-components/layout'

import Subscription from './Subscription'
import SetPassword from './SetPassword'

import FirmInfo from './FirmInfo'
import UserInfo from './UserInfo'
import Payment from './Payment'
import { useParams } from 'react-router-dom'

const GET_DATA = gql`
    query Subscription {
      subscription {
        subscriptionCreatedAt
        subscriptionStatus
      }
      user {
        userId
        userRegisteredViaClio
      }
    }
`

function AccountSetup() {
  const { setupType } = useParams()
  const [skipSubscription, setSkipSubscription] = useState(false)
  const { data, loading, error } = useQuery(GET_DATA)
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  let steps = [
    { label: 'Firm Info', component: <FirmInfo handleNext={handleNext} /> },
    { label: 'User Info', component: <UserInfo handleNext={handleNext} handleBack={handleBack} skipSubscription={skipSubscription} /> },
    { label: 'Subscription', component: <Subscription  handleNext={handleNext} handleBack={handleBack} /> },
    { label: 'Finish', component: <Payment handleBack={handleBack} /> },
  ]

  if (data && data.user.userRegisteredViaClio) {
    steps = [
      { label: 'Firm Info', component: <FirmInfo handleNext={handleNext} /> },
      { label: 'User Info', component: <UserInfo handleNext={handleNext} handleBack={handleBack} skipSubscription={skipSubscription} /> },
      { label: 'Set Password', component: <SetPassword userId={data ? data.user.userId : null}  handleNext={handleNext} handleBack={handleBack} /> },
      { label: 'Subscription', component: <Subscription  handleNext={handleNext} handleBack={handleBack} /> },
      { label: 'Finish', component: <Payment handleBack={handleBack} /> },
    ]
  }

  if (data && data.subscription && data.subscription.subscriptionStatus !== 1) {
    steps = [
      { label: 'Firm Info', component: <FirmInfo handleNext={handleNext} /> },
      { label: 'User Info', component: <UserInfo handleNext={handleNext} handleBack={handleBack} skipSubscription={skipSubscription} /> },
      { label: 'Set Password', component: <SetPassword userId={data ? data.user.userId : null}  handleNext={handleNext} handleBack={handleBack} /> },
    ]
  }

  if (setupType && setupType === 'upgrade') {
    steps = [
      { label: 'Subscription', component: <Subscription  handleNext={handleNext} handleBack={handleBack} /> },
      { label: 'Finish', component: <Payment handleBack={handleBack} /> },
    ]
  }


  useEffect(() => {
    if (data && data.subscription && data.subscription.subscriptionStatus !== 1) {
      setSkipSubscription(true)
    }
  }, [data])
    

  if (loading) {
    <CenteredContent>
      <CircularProgress />
    </CenteredContent>
  }

  const href: string = error && error.graphQLErrors[0] 
        ? error.graphQLErrors[0].extensions.href as string 
        : ''

  return (
      <Box sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#494d6b',
        position: 'relative'
      }}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 4,
            height: { xs: '100%', md: 'auto'},
            width: { xs: '100%', md: '80vw' },
            maxWidth: { lg: '960px' },
            backgroundColor: '#f9f9fa',
        }}
          elevation={3}
        >
        <Stepper 
          activeStep={activeStep}
          alternativeLabel
          sx={{ mb: 4, display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
            {steps.map(({ label }) => {
                const stepProps: { completed?: boolean } = {}
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
            )
            })}
        </Stepper>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          {steps[activeStep].component} {/* Component Renders here */}
        </Box>
        </Paper>
        <ErrorModal
          error={(error && error.message) || ''}
          href={href}
        />
      </Box>
  )
}

export default AccountSetup