import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Box, styled, sx, Stepper, Step, StepLabel } from 'shared-components/material/core'

import CustomMessage from './CustomMessage'
import CustomMessageConfirm from './CustomMessageConfirm'
import { getPrintEmailFields } from 'api/exSets'
import { useError } from 'shared-components/hooks'
import ErrorModal from 'shared-components/modals/ErrorModal'


const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

type PrintEmailStepperProps = {
    exSetId: number,
    refetchExSet: () => void,
    setOpenPrintEmailModal: Dispatch<SetStateAction<boolean>>,
}

type EmailFormData = {
    customMessage: string,
}

type PrintData = {
    user: string,
    firm: string,
    phone: string,
    email: string,
    exhibitSet: string,
    totalDocs: number,
    totalPages: number,
}

export default function PrintEmailStepper({ 
    exSetId,
    refetchExSet,
    setOpenPrintEmailModal,
} : PrintEmailStepperProps) {
    const [ error, href, handleError, resetError] = useError()
    const [activeStep, setActiveStep] = useState(0)
    const [customMessage, setCustomMessage] = useState('')
    const [printData, setPrintData] = useState<PrintData>()

    const steps = ['Setup', 'Confirm']

    useEffect(() => {
        const getPrintEmailFieldsFn = async () => {
            const payload = await getPrintEmailFields(exSetId)
                .catch((e) => handleError(e))
            if (payload.success) {
                setPrintData(payload.data as PrintData)
            } else {
                handleError(payload.err, payload.href)
            }
        }
        getPrintEmailFieldsFn()
    }, [])

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

    const getEmailSetupData = (data: EmailFormData) => {
        setCustomMessage(data.customMessage)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    return (
        <UploadContainer>
            <div style={{ width: '500px' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            </div>

            {activeStep === 0 ? (
                <CustomMessage
                    passSetupData={getEmailSetupData}
                    customMessage={customMessage}
                    setOpenPrintEmailModal={setOpenPrintEmailModal}
                />
            ): null}

            {activeStep === 1 ? (
                <CustomMessageConfirm
                    exSetId={exSetId}
                    customMessage={customMessage}
                    printData={printData as PrintData}
                    handleBack={handleBack}
                    refetchExSet={refetchExSet}
                    setOpenPrintEmailModal={setOpenPrintEmailModal}
                />
            ): null}
            <ErrorModal error={error} href={href} resetError={resetError}/>
        </UploadContainer>
    )
}
