import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ErrorModal({ error, href, resetError }: {error: string, href?: string, resetError?: () => void }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setOpen(error.length > 0)
  }, [error])

  const handleClose = () => {
    if (resetError) {
      resetError()
    }
    if (pathname.includes('/account-setup')){
      navigate('/login', { state: { redirect: pathname }})
    }
    if (href === 'refresh') {
      window.location.reload()
    }
    if (href && href !== 'refresh') {
      navigate(href, { state: { redirect: pathname }})
    }
    setOpen(false)
  }

  return (
        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        >
        {/* <DialogTitle id="responsive-dialog-title">
            Error
        </DialogTitle> */}
        <DialogContent>
            <DialogContentText>
              {error}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} autoFocus>
              ok
            </Button>
        </DialogActions>
        </Dialog>
  )
}
