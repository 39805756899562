import React, { Dispatch, SetStateAction } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'
import { Typography, Button } from 'shared-components/material/core'
import { TextInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import { postForgotPassword } from 'api/accounts'

const schema = yup.object({
    userEmail: yup.string().email("please enter a valid email").required('Email is required'),
  }).required()

type FormData = {
    userEmail: string
}

function Form ({ setSuccess } : { setSuccess: Dispatch<SetStateAction<boolean>>}) {
    const [error, href, handleError, resetError ] = useError()
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            userEmail: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors }} = methods

    const onSubmit = async (data: FormData) => {
        const payload = await postForgotPassword(data.userEmail)
            .catch(e => handleError(e))

        if (payload.success) {
            setSuccess(true)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    return (
        <FormProvider {...methods} >
            <img
                width='336.73px'
                src='/blueLogo.png'
                style={{ alignSelf: 'center', marginBottom: '2em' }}
            />
            <Typography variant="h2" component="h2">
                Password Reset
            </Typography>
            <Typography variant="body1" component="p">
                Enter your E-Mail below to reset your password.
            </Typography>
            <TextInput
                name="userEmail"
                label="E-Mail"
                required
                error={errors.userEmail !== undefined ? true : false }
                errorMessage={errors.userEmail ? errors.userEmail.message : undefined}
            />
            <Button
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
            >
                Reset Password
            </Button>
            <Button variant="text" size="small" href='/login'>Back to Login</Button>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </FormProvider>
    )
}

export default Form