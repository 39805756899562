

const { REACT_APP_API_URL } = process.env

export async function getDownloads() {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/downloads`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getS3ArchiveUrl(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/s3-archive-url`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getJobItems(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/job-items`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function postS3DownloadUrl(jobId: number, resourceName: string, itemId?: string | null, procUnitId?: number | null) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/s3-download-url/${resourceName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ itemId, procUnitId }),
  })

  return res.json()
}

export async function postS3UploadUrl({
  jobId, 
  jobUploadFilename, 
  jobUploadFilesize, 
  allConfidential, 
  category
} : {
  jobId: number, 
  jobUploadFilename: string, 
  jobUploadFilesize: number, 
  allConfidential: boolean, 
  category: string  
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/s3-upload-url`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ jobUploadFilename, jobUploadFilesize, allConfidential, category }),
  })

  return res.json()
}

export async function postS3ClientUploadUrl({
  jobUploadCode, 
  jobUploadFilename, 
  jobUploadFilesize, 
} : {
  jobUploadCode: string, 
  jobUploadFilename: string, 
  jobUploadFilesize: number, 
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/client-upload/s3-upload-url/${jobUploadCode}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ jobUploadFilename, jobUploadFilesize }),
  })

  return res.json()
}

export async function putJobItem(jobId: number, {
  id,
  dataType,
  col,
  update 
}: {
  id: number,
  dataType: string,
  col: string,
  update: string | number | boolean | null
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/job-item`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ id, dataType, col, update }),
  })

  return res.json()
}

export async function checkForUncategorizedItems(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/check-for-uncategorized-items`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function calcJobCost(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/calc-job-cost`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function postPayAndFinish({
  jobId,
  stripeSourceId,
  creditId,
  jobCostActualClientCalc,
} : {
  jobId: number,
  stripeSourceId: string,
  creditId: number | null,
  jobCostActualClientCalc: number,
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/pay-and-finish`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ stripeSourceId, creditId, jobCostActualClientCalc }),
  })

  return res.json()
}

export async function analyzeJob(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/analyze-job`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function acceptEstimatedCategories(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/accept-estimated-categories`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function notifyUsers(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/notify-users`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function mergePST(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/merge-pst`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function processAllPST(jobId: number, process: boolean) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/proc-units/process-all`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({process}),
  })

  return res.json()
}

export async function deleteJob(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function putClientUpload(jobId: number, jobClientEmail: string, jobClientInstructions: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/client-upload`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ jobClientEmail, jobClientInstructions }),
  })

  return res.json()
}

export async function getJobAndValidate(jobUploadCode: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/client-upload/validate-upload-code/${jobUploadCode}`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getPayIntentOrFinish({
  jobId,
  jobCostActualClientCalc,
  stripePayMethodId,
  applyCredits,
} : {
  jobId: number,
  jobCostActualClientCalc: number,
  stripePayMethodId: string,
  applyCredits: boolean,
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/get-pay-intent-or-finish`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ jobCostActualClientCalc, stripePayMethodId, applyCredits }),
  })

  return res.json()
}

export async function finishJob({
  jobId,
  savePayMethod,
  applyCredits,
} : {
  jobId: number,
  savePayMethod: boolean,
  applyCredits: boolean,
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/finish-job`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ savePayMethod, applyCredits }),
  })

  return res.json()
}

export async function applyCredit(jobId: number, creditId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}}/apply-credit/${creditId}`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function applyCredits(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}}/apply-credits`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function reopenJob(jobId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/reopen`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getClientUploadInstructions(jobId: number, jobClientEmail: string, jobClientInstructions: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/client-upload-email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ jobClientEmail, jobClientInstructions }),
  })

  return res.json()
}

export async function searchJob(jobId: number, query: string, searchType: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/jobs/${jobId}/search`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ query, searchType }),
  })

  return res.json()
}
