import React, { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'
import { ExSet } from 'generated/graphql'

import {
    Button,
    Divider,
    LoadingButton,
    Typography,
    Snackbar,
    MuiAlert,
    AlertProps
} from 'shared-components/material/core'
import { TextInput, RadioInput } from 'shared-components/inputs'
import { RightAlignedForm } from 'shared-components/layout'
import { ConfirmationModal, ErrorModal, Feedback } from 'shared-components/modals'
import { useNavigate } from 'react-router-dom'

export const UPDATE_EX_SET = gql`
   mutation UpdateExSet($exSetId: Int!, $exSetName: String, $exSetPrefix: String, $exSetSequencingType: Int) {
  updateExSet(exSetId: $exSetId, exSetName: $exSetName, exSetPrefix: $exSetPrefix, exSetSequencingType: $exSetSequencingType) {
    exSetId
  }
}
`
const DELETE_EX_SET = gql`
    mutation DeleteExSet($exSetId: Int!) {
  deleteExSet(exSetId: $exSetId)
}
`

const schema = yup.object({
    exSetName: yup.string(),
    exSetPrefix: yup.string(),
    // exSetStart: yup.string().required('Exhibit start number is required'),
    exSetSequencingType: yup.number()
}).required()

type FormData = {
    exSetName: string,
    exSetPrefix: string,
    // exSetStart: string,
    exSetSequencingType: number,
}

type EditExhibitSetProps = {
    exSet: ExSet,
    refetch: () => void,
}

const exSetSequencingTypeOptions = [
    { label: 'Numeric', value: 1 },
    { label: 'Alphabetic', value: 2 },
]

export default function EditExhibitSet({ exSet, refetch } : EditExhibitSetProps) {
    const navigate = useNavigate()
    const [openFeedback, setOpenFeedback] = useState(false)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [updateExSet, {loading: updateExSetLoading, error: updateExSetError}] = useMutation(UPDATE_EX_SET, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
    })
    const [deleteExSet, {loading: deleteExSetLoading, error: deleteExSetError}] = useMutation(DELETE_EX_SET)

    const [confirmation, setConfirmation] = useState(false)
    
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            exSetName: '',
            exSetPrefix: '',
            // exSetStart: '',
            exSetSequencingType: 1,
        },
        resolver: yupResolver(schema),
    })
    
    const { handleSubmit, setValue, formState: { errors } } = methods

    useEffect(() => {
        setValue('exSetName', exSet.exSetName || '')
        setValue('exSetPrefix', exSet.exSetPrefix || '')
        setValue('exSetSequencingType', exSet.exSetSequencingType || 1)
    }, [])

    const onSubmit = async (data: FormData) => {
        const variables = {...data, exSetId: exSet.exSetId}
        await updateExSet({ variables })
        refetch()
    }

    const handleCloseSnackbar = () => setSnackbar(null)

    const handleOpenConfirmation = () => setConfirmation(true)
    const handleCloseConfirmation = useCallback(() => setConfirmation(false), [])

    const handleDelete = useCallback(async () => {
        const variables = { exSetId: exSet.exSetId}
        await deleteExSet({ variables })
        navigate(`/app/cases/${exSet.exSetDisputeId}`)
    }, [])

  return (
    <FormProvider {...methods}>
        <Typography variant='h3'>
            Exhibit Set Meta
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <RightAlignedForm>
            <TextInput
                name="exSetName"
                label="Exhibit Set Name"
                required
                error={errors.exSetName !== undefined ? true : false }
                errorMessage={errors.exSetName ? errors.exSetName.message : undefined}
            />
            <TextInput
                name="exSetPrefix"
                label="Exhibit Set Prefix"
                required
                error={errors.exSetPrefix !== undefined ? true : false }
                errorMessage={errors.exSetPrefix ? errors.exSetPrefix.message : undefined}
            />
            <RadioInput
                label="Exhibit Set Sequencing Type"
                name="exSetSequencingType"
                options={exSetSequencingTypeOptions}
                defaultValue={exSet.exSetSequencingType || 1}
                required
            />
            {/* <TextInput
                name="exSetStart"
                label="Exhibit Set Start Number"
                required
                error={errors.exSetStart !== undefined ? true : false }
                errorMessage={errors.exSetStart ? errors.exSetStart.message : undefined}
            /> */}
        </RightAlignedForm>
        <Divider />
            {updateExSetLoading ? (
                <LoadingButton sx={{ float: 'right', mt: 2, mb: 2 }} loading variant="outlined">
                    Save
                </LoadingButton>
            ) : (
                <Button
                    sx={{ float: 'right', mt: 2, mb: 2 }}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="secondary"
                >
                    Save
                </Button>
            )}
            {deleteExSetLoading ? (
                <LoadingButton sx={{ float: 'right', mt: 2, mb: 2 }} loading variant="outlined">
                    Delete
                </LoadingButton>
            ) : (
                <Button
                    sx={{ float: 'right', mt: 2, mb: 2, mr: 1 }}
                    onClick={handleOpenConfirmation}
                    variant="outlined"
                    color="error"
                >
                    Delete
                </Button>
            )}
        <ConfirmationModal
            open={confirmation} 
            handleCloseConfirmation={handleCloseConfirmation} 
            title="Delete Exhibit Set"
            content="Are you sure you want to delete this exhibit set?"
            button="Delete"
            action={handleDelete}
            danger
        />
        <Feedback openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} />
        {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        <ErrorModal 
            error={
                (updateExSetError && updateExSetError.message) || 
                (deleteExSetError && deleteExSetError.message) || ''} 
        />
    </FormProvider>
  )
}
