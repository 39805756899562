import React from 'react'
import { Typography, DialogContent, DialogActions, Box, Button } from 'shared-components/material/core'
import { CenteredForm } from 'shared-components/layout'

export default function Success({ disputeId } : { disputeId: number }) {
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
          <CenteredForm>
          <Typography variant="body1" component="p">
              Payment Successful! You will be redirected to the cases screen while your data batch is being analyzed. This will take a few minutes.
          </Typography>
        </CenteredForm>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pt: 2,
            width: '100%'
        }}>
          <Button 
            href={`/app/cases/${disputeId}`}
            variant="contained"
          >
              Done
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
