import React, { Dispatch, SetStateAction, useState } from 'react'
import validator from 'validator'
import { useError } from 'shared-components/hooks'
import { Typography, Box, CircularProgress, Divider, Button } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

import { sendPrintEmail } from 'api/exSets'

type PrintData = {
    user: string,
    firm: string,
    phone: string,
    email: string,
    exhibitSet: string,
    totalDocs: number,
    totalPages: number,
}

type CustomMessageConfirmProps = {
    exSetId: number,
    customMessage: string,
    handleBack: () => void,
    refetchExSet: () => void,
    setOpenPrintEmailModal: Dispatch<SetStateAction<boolean>>,
    printData: PrintData
}



export default function CustomMessageConfirm({
    exSetId,
    customMessage,
    handleBack,
    refetchExSet,
    setOpenPrintEmailModal,
    printData,
}: CustomMessageConfirmProps) {
    const [ error, href, handleError, resetError ] = useError()
    const [ loading, setLoading ] = useState(false)
    const { user, firm, phone, email, exhibitSet, totalDocs, totalPages } = printData

    const handleSubmit = async () => {
        setLoading(true)
        const payload = await sendPrintEmail(exSetId, customMessage)
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
            refetchExSet && refetchExSet()
            setOpenPrintEmailModal(false)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    if (loading) {
        <CircularProgress />
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Divider />
            <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 2}}>
                Information Review
            </Typography>
            <Box sx={{ maxWidth: '700px' }}>
                <span><strong>User:</strong> {validator.unescape(user)}</span><br />
                <span><strong>Firm:</strong> {validator.unescape(firm)}</span><br />
                <span><strong>Phone:</strong> {validator.unescape(phone)}</span><br />
                <span><strong>Email:</strong> {validator.unescape(email)}</span><br />
                <span><strong>Exhibit Set:</strong> {validator.unescape(exhibitSet)}</span><br />
                <span><strong>Total Documents:</strong> {totalDocs}</span><br />
                <span><strong>Total Pages:</strong> {totalPages}</span><br />
                <span><strong>Custom Message:</strong><br /> {customMessage}</span>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                pt: 2,
                mt: 2,
                width: '100%'
            }}>
                <Button
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Button 
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Send Email
                </Button>
            </Box>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </Box>
    )
}
