import React, { memo } from 'react'
import { User } from 'generated/graphql'
import { gql, useQuery } from '@apollo/client'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    CircularProgress,
    Button,
    Box,
} from 'shared-components/material/core'
import { CenteredContent } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'
import { SelectInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks'

import { postRemoveUserFromDispute, postUserToDispute } from 'api/disputes'

const GET_FIRM_USERS = gql`
    query Users{
        firm {
            users {
                userFirstname
                userLastname
                userId
            }
        }
    }
`

type FormData = {
    addUser: number,
}

const schema = yup.object({
    addUser: yup.number(), 
}).required()

type CaseUsersProps = {
    users: User[],
    disputeId: number,
    refetchCase: () => void
}

function CaseUsers({ users, disputeId, refetchCase }: CaseUsersProps) {
    const [error, href, handleError, resetError] = useError()
    const { loading, error: usersError, data } = useQuery(GET_FIRM_USERS)

    let errorMessage

    if (error) {
        errorMessage = error
    }
    if (usersError) {
        errorMessage = usersError.message
    }

    const firmUsers: User[] = data ? data.firm.users : []

    const addFirmUserOptions = firmUsers.map(user => ({ 
        label: `${user.userFirstname} ${user.userLastname}`,
        value: user.userId
    }))

    addFirmUserOptions.unshift({label: '', value: 0})

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            addUser: 0, 
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors }} = methods

    const onSubmit = async (formData: FormData) => {
        const userIdToAdd = formData.addUser
        const payload = await postUserToDispute({ disputeId, userIdToAdd })
            .catch(e => handleError(e))

        if (payload.success) {
            refetchCase()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleRemoveUser = async (userIdToRemove: number) => {
        const payload = await postRemoveUserFromDispute({ disputeId, userIdToRemove })
            .catch(e => handleError(e))

        if (payload.success) {
            refetchCase()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
      <>
        <TableContainer component={Paper}>
            <Table aria-label="user table" stickyHeader>
            <TableHead>
                <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Initials</TableCell>
                <TableCell>Role</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((user) => (
                <TableRow key={user.userId}>
                    <TableCell component="th" scope="row">
                        {user.userFirstname}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {user.userLastname}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {user.userInitials}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {user.userFirmRole}
                    </TableCell>
                    <TableCell align="right">{user.userType === 0 ? 'user' : 'admin'}</TableCell>
                    <TableCell align="right">
                    <Button
                        variant='contained'
                        color='error'
                        onClick={() => handleRemoveUser(user.userId)}
                        size='small'
                    >
                        Remove
                    </Button>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <ErrorModal error={errorMessage ? errorMessage : ''} href={href} resetError={resetError}/>
        <Box sx={{ mt: 2, mb: 2, float: 'right'}}>
            <FormProvider {...methods}>
                <SelectInput
                    name="addUser"
                    label="Add User to Case"
                    options={addFirmUserOptions}
                    error={errors.addUser !== undefined ? true : false}
                    errorMessage={errors.addUser ? errors.addUser.message : undefined}
                    fullWidth
                    sx={{ width: '200px'}}
                />
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleSubmit(onSubmit)}
                    sx={{ ml: 1 }}
                >
                    Add User
                </Button>
            </FormProvider>
        </Box>
      </>
    )
}

export default memo(CaseUsers)
