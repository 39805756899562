import React, { useEffect, useState } from 'react'

import { useError } from 'shared-components/hooks'
import { Typography, Box, CircularProgress, Divider, Button } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

import { getClientUploadInstructions, putClientUpload } from 'api/jobs'
import { User } from 'generated/graphql'

type ClientUploadConfirmProps = {
    jobId: number,
    clientEmail: string,
    jobClientInstructions: string,
    handleBack: () => void,
    refetchJob: () => void
    user: User
}

export default function ClientUploadConfirm({
    jobId,
    clientEmail,
    jobClientInstructions,
    handleBack,
    refetchJob,
    user,
}: ClientUploadConfirmProps) {
    const [ error, href, handleError, resetError ] = useError()
    const [ loading, setLoading ] = useState(false)
    const [ emailBody, setEmailBody] = useState('')

    let errMsg

    if (error){
        errMsg = error
    } else {
        errMsg = ''
    }

    const getClientUploadInstructionsFn = async () => {
        setLoading(true)
        const jobClientEmail = clientEmail
        const payload = await getClientUploadInstructions(jobId, jobClientEmail, jobClientInstructions)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setLoading(false)
            setEmailBody(payload.data.emailBody)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    useEffect(() => {
        getClientUploadInstructionsFn()
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        const jobClientEmail = clientEmail
        const payload = await putClientUpload(jobId, jobClientEmail, jobClientInstructions)
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
            return refetchJob && refetchJob()
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    if (loading) {
        <CircularProgress />
    }

    return (
        <Box>
            <Divider />
            <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 2}}>
                Client request email preview
            </Typography>
            <Box sx={{ maxWidth: '700px' }}>
                <Typography variant="body1" gutterBottom>
                    FROM: {user.userEmail || ''}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    TO: {clientEmail}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    CC: {user.userEmail || ''}
                </Typography>
                
                {emailBody.split('\n').map((str, index) => <p key={index}>{str}</p>)}

            </Box>
            <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pt: 2,
                        width: '100%'
                    }}>
                <Button
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Button 
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Send Email
                </Button>
            </Box>
            <ErrorModal error={errMsg} href={href} resetError={resetError} />
        </Box>
    )
}
