import React, { useEffect, useState } from 'react'
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import Review from './Review'
import Success from './Success'
import Payment from './Payment'
import ErrorModal from '../ErrorModal'
import { Cost, Exhibit } from 'generated/graphql'
import { useNavigate } from 'react-router-dom'
import { calcAiAnalysisCost } from 'api/exSets'

type PayModalProps = { 
    open: boolean,
    toggleAiAnalysisModal: (event?: object, reason?: string) => void,
    exSetId: number,
    disputeId: number,
    selectedRows: Exhibit[],
}

const steps = ['Review', 'Payment', 'Success']

export default function AiAnalysisModal({
    open,
    toggleAiAnalysisModal,
    exSetId,
    disputeId,
    selectedRows,
}: PayModalProps) {
    console.log({selectedRows})   

    const navigate = useNavigate()
    const theme = useTheme()
    const [error, href, handleError, resetError ] = useError()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [activeStep, setActiveStep] = useState(0)
    const [payMethods, setPayMethods] = useState([])
    const [exhibitIds, setExhibitIds] = useState<Array <number>>([])
    const [cost, setCost] = useState({})
    const [loading, setLoading] = useState(false)

    const getAiAnalysisCost = async () => {
        setLoading(true)
        const filteredExhibits = selectedRows.filter((exhibit) => {
            return exhibit.s3PostconvKey && exhibit.converted
        })
        const idArray = filteredExhibits.map((exhibit) => exhibit.exhibitId)

        const payload = await calcAiAnalysisCost(exSetId, idArray)
            .catch(e => handleError(e))
        if (payload.success) {
            setExhibitIds(idArray)
            setPayMethods(payload.data.payMethods)
            setCost(payload.data.cost)
            setLoading(false)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    useEffect(() => {
        getAiAnalysisCost()
    }, [])

    const handleBack = () => {
        if (activeStep === 0) {
            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}`)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
    }

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

    if (loading) {
        return (
            <Dialog open={open} onClose={toggleAiAnalysisModal} fullScreen={fullScreen}>
                <DialogTitle>AI Analysis Payment</DialogTitle>
                <DialogContent sx={{ textAlign: 'center'}}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={toggleAiAnalysisModal} fullWidth maxWidth={'md'} fullScreen={fullScreen}>
                <DialogTitle>AI Analysis Payment</DialogTitle>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {}
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                {activeStep === 0 &&
                    <Review
                        cost={cost as Cost}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                }
                {activeStep === 1 &&
                    <Payment
                        cost={cost as Cost}
                        exSetId={exSetId}
                        exhibitIds={exhibitIds}
                        payMethods={payMethods}
                        handleNext={handleNext}
                        handleBack={handleBack}
                    />
                }
                {activeStep === 2 && <Success disputeId={disputeId} />}
                <ErrorModal error={error} href={href} resetError={resetError} />
        </Dialog>
    )
}
