import React, { useState } from 'react'
import { gql } from '@apollo/client'

import MuiDrawer from '@mui/material/Drawer'
import {
  Box,
  Collapse,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemText,
  styled,
  sx,
  Typography,
  Theme,
  CircularProgress,
  useTheme
} from 'shared-components/material/core'
import {
  ExpandLess,
  ExpandMore,
  ListItemIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'shared-components/material/icons'
import moment from 'moment'
import { ListItemButton } from '@mui/material'
import { navOptions, helpOptions } from './navOptions'
import { useNavigate } from 'react-router-dom'
import { Firm } from 'generated/graphql'

export const EDIT_USER = gql`
    mutation Mutation($userId: Int!, $userTourActive: Boolean) {
  updateUser(userId: $userId, userTourActive: $userTourActive) {
    userTourActive
  }
}
`

const ProgressContainer = styled(Box)((
  sx({
      position: 'relative', 
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%', 
      alignItems: 'center', 
      justifyContent: 'center'
  })
))

const ProgressNumber = styled(Box)((
  sx({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  })
))

const UploadContainer = styled(Box)((
  sx({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 2
  })
))

const drawerWidth = 240

const DrawerContainer = styled(Box)((
  sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: drawerWidth
  })
))

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme } : { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const FlexDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open } : { theme: Theme, open: boolean }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

type DrawerContentType = {
  collapse: boolean,
  uploading: boolean, 
  uploadValue: number, 
  fileSize: number,
  handleCollapse: () => void,
  open: boolean, 
  mobileOpen: boolean,
  handleDrawerClose: () => void,
  handleDrawerToggle: () => void,
  firm: Firm
}

export default function DrawerContent({ 
  uploading, 
  uploadValue, 
  fileSize, 
  collapse, 
  handleCollapse,
  open, 
  mobileOpen,
  handleDrawerClose,
  handleDrawerToggle,
  firm,
}: DrawerContentType) {
  const navigate = useNavigate()
  const theme = useTheme()
  const [helpCollapse, setHelpCollapse] = useState(false)

  const handleHelpCollapse = () => setHelpCollapse(!helpCollapse)

  const drawer = (
    <>
      <DrawerHeader theme={theme}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
      </DrawerHeader>
      <Divider />
      {firm.firmTrialEnds && open ? 
        (
          <Box>
            <Typography sx={{ margin: '1rem 0 1rem 1rem' }}>
              Trial Ends: {moment(firm.firmTrialEnds).format('MM/DD/YY')}
            </Typography>
            <Divider />
          </Box>
        ) 
        : null
      }
      <DrawerContainer>
      <List>  
        {navOptions.map((navOption) => {
          if (Object.prototype.hasOwnProperty.call(navOption, 'children')) {
            return (
              <div key={navOption.id}>
                <ListItemButton onClick={handleCollapse}>
                  <ListItemIcon>
                    {navOption.icon}
                  </ListItemIcon>
                  <ListItemText primary={navOption.text} />
                  {collapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={collapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding dense>
                    {navOption.children && navOption.children.map((child) => {
                      if (firm.firmType === 1 && child.text === "GUID" ) {
                        return null
                      }
                      if (firm.firmType === 2 && child.text === "Rewards" ) {
                        return null
                      }
                      return (
                        <ListItemButton
                          onClick={() => navigate(child.link)}
                          key={child.id}
                          sx={{ pl: 9 }}
                        >
                          <ListItemText primary={child.text} />
                        </ListItemButton>
                      )
                    })}
                  </List>
                </Collapse>
              </div>
            )
          }
          return (
            <ListItemButton onClick={() => navigate(navOption.link)} key={navOption.id}>
              <ListItemIcon>
                {navOption.icon}
              </ListItemIcon>
              <ListItemText primary={navOption.text} />
            </ListItemButton>
          )
        })}
        {helpOptions.map((helpOption) => (
          <div key={helpOption.id}>
          <ListItemButton onClick={handleHelpCollapse}>
            <ListItemIcon>
              {helpOption.icon}
            </ListItemIcon>
            <ListItemText primary={helpOption.text} />
            {helpCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={helpCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              {helpOption.children && helpOption.children.map((child) => (
                <ListItemButton
                  LinkComponent='a'
                  href={child.link}
                  key={child.id}
                  sx={{ pl: 9 }}
                  target="_blank"
                >
                  <ListItemText primary={child.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </div>
        ))}
      </List>
      {uploading && (
        <UploadContainer>
          <Typography variant="body1" gutterBottom>
              Uploading File
          </Typography>
          <ProgressContainer>
              <CircularProgress 
                  variant={uploadValue ? 'determinate' : 'indeterminate'}
                  value={(100 * uploadValue) / fileSize} size={60} 
              />
              <ProgressNumber>
                  <Typography
                      variant="body1"
                      component="div"
                      color="text.secondary"
                      sx={{ textAlign: 'center' }}
                  >
                      {`${Math.round((100 * uploadValue) / fileSize)}%`}
                  </Typography>
              </ProgressNumber>
          </ProgressContainer>
        </UploadContainer>
      )}
      </DrawerContainer>
    </>
  )

  return (
      <Box
          component="nav"
          sx={{ flexShrink: { sm: 0 } }}
          aria-label="sidebar"
      >
          <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          >
          </Drawer>
          <FlexDrawer
          variant="permanent"
          sx={{ display: { xs: 'none', sm: 'block' }}}
          open={open}
          theme={theme}
          >
          {drawer}
          </FlexDrawer>
      </Box>
  )
}
