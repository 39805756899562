import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import { TextField } from "../material/core"

type FormValues = {
    name: string, 
    label: string,
    required?: boolean,
    error?: boolean,
    errorMessage?: string, 
    defaultValue?: string,
    disabled?: boolean,
}

const input = (value: string) => {
    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    if (x) {
        const newVal = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`
        return newVal
    }
}

const TelephoneInput = ({ 
    name, 
    label, 
    required=false, 
    error=false, 
    errorMessage, 
    defaultValue,
    disabled=false,
}: FormValues) => {
    const { control } = useFormContext()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <TextField
                    size="small"
                    onChange={(e) => onChange(input(e.target.value))}
                    value={input(value)}
                    label={label}
                    placeholder={label} 
                    required={required}
                    type="tel"
                    helperText={errorMessage}
                    error={error}
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
            )}
        />
    )
}

export default TelephoneInput
