import React from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'

import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium'

import {
    Box,
    Button,
    LoadingButton,
} from 'shared-components/material/core'
import { FlagIcon } from 'shared-components/material/icons'
import { SelectInput, TextInput, DateTimeInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

export const EDIT_USER = gql`
    mutation editUser(
        $userId: Int!, 
        $userEmlReviewOrderPref: JSON, 
        $userDocReviewOrderPref: JSON
    ) {
        updateUser(
            userId: $userId, 
            userEmlReviewOrderPref: $userEmlReviewOrderPref, 
            userDocReviewOrderPref: $userDocReviewOrderPref
        ) {
            userId
        }
    }
`

type FormData = {
    batchUpdate: string,
    filename?: string,
    subject?: string,
    folder?: string,
    date?: string,
    notes?: string,
    legalResearch?: string,
    flag?: string,
    check?: boolean,
}

const schema = yup.object({
    batchUpdate: yup.string(), 
    notes: yup.string(), 
    legalResearch: yup.string(),
    flag: yup.string(),
}).required()


function CustomToolbar({
    updateRows,
    getSelectedRows,
    getColumnState,
    setFilterButtonEl,
    userId,
     } : { 
        updateRows: (formData: FormData) => void,
        getSelectedRows: () => [],
        getColumnState: () => GridInitialStatePremium,
        setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
        userId: number
    }) {
    const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER)
    const selectedRows = getSelectedRows()
    const batchUpdateOptions = [
        { label: 'Privileged', value: 'priv'},
        { label: 'Produced', value: 'prod'},
        { label: 'Key', value: 'isKey'},
        { label: 'Custom stamp', value: 'confidential'},
        { label: 'Irrelevant', value: 'irrl'},
        // { label: 'Hold', value: 'hold'},
        { label: 'Redact', value: 'redact'},
        { label: 'Native', value: 'native'},
        { label: 'Notes', value: 'notes'},
        { label: 'Research', value: 'legalResearch'},
        { label: 'Flag', value: 'flag'},
        { label: 'Filename', value: 'filename'},
        { label: 'Folder', value: 'folder'},
        { label: 'Date', value: 'date'},
    ]

    const flagOptions = [
        { label: '#dedede', value: '#dedede', component: <FlagIcon sx={{ color: '#dedede' }}/>},
        { label: '#C33C54', value: '#C33C54', component: <FlagIcon sx={{ color: '#C33C54' }}/> },
        { label: '#ED7434', value: '#ED7434', component: <FlagIcon sx={{ color: '#ED7434' }}/> },
        { label: '#FFE347', value: '#FFE347', component: <FlagIcon sx={{ color: '#FFE347' }}/> },
        { label: '#255957', value: '#255957', component: <FlagIcon sx={{ color: '#255957' }}/> },
        { label: '#0A369D', value: '#0A369D', component: <FlagIcon sx={{ color: '#0A369D' }}/> },
        { label: '#593F62', value: '#593F62', component: <FlagIcon sx={{ color: '#593F62' }}/> },
    ]

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            batchUpdate: '', 
            notes: '',
            legalResearch: '',
            flag: '',
            filename: '',
            folder: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, formState: { errors }, getValues } = methods
    const isNotes = watch('batchUpdate') === 'notes'
    const isDate = watch('batchUpdate') === 'date'
    const hasNotes = watch('notes')
    const isFilename = watch('batchUpdate') === 'filename'
    const hasFilename = watch('filename')
    const isFolder = watch('batchUpdate') === 'folder'
    const hasFolder = watch('folder')
    const isResearch = watch('batchUpdate') === 'legalResearch'
    const hasResearch = watch('legalResearch')
    const isFlag = watch('batchUpdate') === 'flag'
    const hasFlag = watch('flag')
    const hasBatchUpdate = getValues('batchUpdate')

    const onSubmit = (formData: FormData, check: boolean | null) => {
        Object.assign(formData, { check })
        updateRows(formData)
    }

    const disableButton = () => {
        if (isNotes && !hasNotes) {
            return true
        }
        if (isFilename && !hasFilename) {
            return true
        }
        if (isFolder && !hasFolder) {
            return true
        }
        if (isResearch && !hasResearch) {
            return true
        }
        if (isFlag && !hasFlag) {
            return true
        }
        if (!hasBatchUpdate) {
            return true
        }
        return false
    }

    const handleSaveColumnOrder = () => {
        const columnState = getColumnState()
        delete columnState['filter']
        delete columnState['pagination']
        const variables = {userId, userDocReviewOrderPref: JSON.stringify(columnState)} // create and use userMixedReviewOrderPref???
        editUser({ variables })
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 5, ml: 2, mr: 2 }}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} className="introColumns"/>
                <GridToolbarFilterButton ref={setFilterButtonEl} className="introFilters" />
                <GridToolbarDensitySelector sx={{ mr: 1}} className="introDensity"/>
                {editUserLoading ? (
                        <LoadingButton loading variant="outlined">
                            Save Column Order
                        </LoadingButton>
                    ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleSaveColumnOrder}
                        sx={{ ml: 1, mr: 1 }}
                        className="introSaveColumns"
                    >
                        Save Column Order
                    </Button>
                    )}
                    {selectedRows.length > 0 ? (
                        <FormProvider {...methods}>
                        <SelectInput
                            name="batchUpdate"
                            label="Update Selected Rows"
                            options={batchUpdateOptions}
                            error={errors.batchUpdate !== undefined ? true : false}
                            errorMessage={errors.batchUpdate ? errors.batchUpdate.message : undefined}
                            fullWidth
                            disabled={selectedRows.length === 0}
                            sx={{ 
                                width: '185px', 
                                mr: 1, 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiSelect-outlined': { 
                                    padding: '3.875px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />

                        {isNotes ? (
                            <TextInput
                            name='notes'
                            label='Notes'
                            error={errors.notes !== undefined ? true : false}
                            errorMessage={errors.notes ? errors.notes.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isResearch ? (
                            <TextInput
                            name='legalResearch'
                            label='Research'
                            error={errors.legalResearch !== undefined ? true : false}
                            errorMessage={errors.legalResearch ? errors.legalResearch.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px',
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem'
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px'
                                }
                            }}
                        />
                        ): null}

                        {isFilename ? (
                            <TextInput
                            name='filename'
                            label='Filename'
                            error={errors.filename !== undefined ? true : false}
                            errorMessage={errors.filename ? errors.filename.message : undefined}
                            sx={{ 
                                ml: 1,
                                width: '150px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px',
                                '& .MuiFormLabel-root': {
                                    fontSize: '0.8125rem',
                                    padding: '4px 10px',
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px'
                                }
                            }}
                        />
                        ): null}

                        {isFolder ? (
                            <TextInput
                            name='folder'
                            label='Folder'
                            error={errors.folder !== undefined ? true : false}
                            errorMessage={errors.folder ? errors.folder.message : undefined}
                            sx={{ 
                                ml: 1,
                                width: '150px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px',
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isFlag ? (
                            <SelectInput
                                name="flag"
                                label="Flag"
                                options={flagOptions}
                                required
                                error={errors.flag !== undefined ? true : false}
                                errorMessage={errors.flag ? errors.flag.message : undefined}
                                fullWidth
                                sx={{ 
                                    width: '150px', 
                                    top: '0px', 
                                    maxHeight: '30.75px !important', 
                                    minHeight: '30.75px', 
                                    '& .MuiFormLabel-root': {
                                        fontSize: '0.8125rem', 
                                        padding: '4px 10px', 
                                        lineHeight: '.4rem' 
                                    }, 
                                    '& .MuiInputBase-input': { 
                                        padding: '3.875px 10px !important', 
                                        height: '23px' 
                                    }
                                }}
                            />
                        ) : null}

                        {isDate ? (
                            <DateTimeInput
                                name="date"
                                label="Date"
                            />
                        ) : null}

                        {isFlag || isNotes || isResearch || isFolder || isFilename || isDate ? (
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={handleSubmit(data => onSubmit(data, null))}
                                sx={{ ml: 1 }}
                                disabled={disableButton()}
                            >
                                apply
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSubmit(data => onSubmit(data, true))}
                                    sx={{ ml: 1 }}
                                    disabled={disableButton()}
                                >
                                    check
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSubmit(data => onSubmit(data, false))}
                                    sx={{ ml: 1 }}
                                    disabled={disableButton()}
                                >
                                    Uncheck
                                </Button>
                            </>
                        )}
                        
                    </FormProvider>
                    ) : null}
            </GridToolbarContainer>
            <ErrorModal error={editUserError && editUserError.message || ''} />
        </Box>
    )
  }

  export default CustomToolbar