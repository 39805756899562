import React, { useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useQuery, useMutation } from '@apollo/client'
import { CenteredForm, CenteredContent } from 'shared-components/layout'
import { 
    Button,
    CircularProgress,
    LoadingButton,
    Snackbar,
    MuiAlert,
    AlertProps
} from 'shared-components/material/core'
import { TextInput, TelephoneInput } from 'shared-components/inputs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import validator from 'validator'

export const GET_DATA = gql`
    query firm {
        firm {
            firmName
            firmAddress1
            firmAddress2
            firmCity
            firmState
            firmZip
            firmTelephone
        }
        user {
            userType
        }
    }
`

export const EDIT_FIRM = gql`
    mutation editFirm (
            $firmName: String,
            $firmTelephone: String,
            $firmAddress1: String,
            $firmAddress2: String,
            $firmCity: String,
            $firmState: String,
            $firmZip: String
        ) {
            updateFirm (
                firmName: $firmName, 
                firmTelephone: $firmTelephone, 
                firmAddress1: $firmAddress1, 
                firmAddress2: $firmAddress2, 
                firmCity: $firmCity, 
                firmState: $firmState, 
                firmZip: $firmZip
            ) {
                firmName
                firmAddress1
                firmAddress2
                firmCity
                firmState
                firmZip
                firmTelephone
            }
    }
`

const schema = yup.object({
    firmTelephone: yup
        .string()
        .required('Firm telephone is required'),
    firmAddress1: yup.string().required('Address is required'),
    firmName: yup.string().required('Firm name is required'),
    firmAddress2: yup.string(),
    firmCity: yup.string().required('City is required'),
    firmState: yup.string().required('State is required'),
    firmZip: yup
        .string()
        .matches(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/, 'Invalid Zip Code')
        .required('Zip code is required'),
  }).required()

type FormData = {
    firmTelephone: string,
    firmAddress1: string,
    firmName: string,
    firmAddress2: string,
    firmCity: string,
    firmState: string,
    firmZip: string,
}

function FirmInfo() {
    const [snackbar, setSnackbar] = React.useState<Pick<AlertProps,'children' | 'severity'> | null>(null)
    const { data, loading, error } = useQuery(GET_DATA)
    const [editFirm, { loading: editFirmLoading, error: editFirmError }] = useMutation(EDIT_FIRM, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
    })
    const firm = data ? data.firm : {}

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            firmTelephone: '',
            firmAddress1: '',
            firmName: '',
            firmAddress2: '',
            firmCity: '',
            firmState: '',
            firmZip: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, formState: { errors }} = methods

    useEffect(() => {
        setValue('firmName', firm.firmName && validator.unescape(firm.firmName) || '')
        setValue('firmTelephone', firm.firmTelephone || '')
        setValue('firmAddress1', firm.firmAddress1 || '')
        setValue('firmAddress2', firm.firmAddress2 || '')
        setValue('firmCity', firm.firmCity || '')
        setValue('firmState', firm.firmState || '')
        setValue('firmZip', firm.firmZip || '')
    }, [firm])

    const onSubmit = (formData: FormData) => {
        editFirm({ variables: formData })
    }
    const handleCloseSnackbar = () => setSnackbar(null)

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <FormProvider {...methods}>
            <CenteredForm align={"right"}>
                <TextInput
                    name="firmName"
                    label="Firm Name"
                    required
                    error={errors.firmName !== undefined ? true : false }
                    errorMessage={errors.firmName ? errors.firmName.message : undefined}
                    disabled={data.user.userType === 0}
                />
                <TelephoneInput
                    name="firmTelephone"
                    label="Firm Telephone"
                    required
                    error={errors.firmTelephone !== undefined ? true : false }
                    errorMessage={errors.firmTelephone ? errors.firmTelephone.message : undefined}
                    disabled={data.user.userType === 0}
                />
                <TextInput
                    name="firmAddress1"
                    label="Address Line 1"
                    required
                    error={errors.firmAddress1 !== undefined ? true : false }
                    errorMessage={errors.firmAddress1 ? errors.firmAddress1.message : undefined}
                    disabled={data.user.userType === 0}
                />
                <TextInput
                    name="firmAddress2"
                    label="Address Line 2"
                    required
                    error={errors.firmAddress2 !== undefined ? true : false }
                    errorMessage={errors.firmAddress2 ? errors.firmAddress2.message : undefined}
                    disabled={data.user.userType === 0}
                />
                <TextInput
                    name="firmCity"
                    label="City"
                    required
                    error={errors.firmCity !== undefined ? true : false }
                    errorMessage={errors.firmCity ? errors.firmCity.message : undefined}
                    disabled={data.user.userType === 0}
                />
                <TextInput
                    name="firmState"
                    label="State"
                    required
                    error={errors.firmState !== undefined ? true : false }
                    errorMessage={errors.firmState ? errors.firmState.message : undefined}
                    disabled={data.user.userType === 0}
                />
                <TextInput
                    name="firmZip"
                    label="Zip Code"
                    required
                    error={errors.firmZip !== undefined ? true : false }
                    errorMessage={errors.firmZip ? errors.firmZip.message : undefined}
                    disabled={data.user.userType === 0}
                />
                {editFirmLoading ? (
                    <LoadingButton loading variant="outlined">
                        Save
                    </LoadingButton>
                ) : (
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleSubmit(onSubmit)}
                        disabled={data.user.userType === 0}
                    >
                        Save
                    </Button>
                )}
            </CenteredForm>
            <ErrorModal 
                error={
                    (error && error.message) ||
                    (editFirmError && editFirmError.message) || ''}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FormProvider>
    )
}

export default FirmInfo