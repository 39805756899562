import React, { Dispatch, SetStateAction }  from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import PrintEmailStepper from './PrintEmailStepper/PrintEmailStepper'

type PrintEmailModalProps = {
    openPrintEmailModal: boolean,
    setOpenPrintEmailModal: Dispatch<SetStateAction<boolean>>,
    exSetId: number,
    refetchExSet: () => void,
}

export default function PrintEmailModal({ 
    openPrintEmailModal,
    setOpenPrintEmailModal,
    exSetId,
    refetchExSet,
}: PrintEmailModalProps) {
  return (
    <Dialog open={openPrintEmailModal} onClose={() => setOpenPrintEmailModal(false)}>
        <DialogTitle>Send Print Email</DialogTitle>
        <DialogContent>
            <PrintEmailStepper
                exSetId={exSetId}
                refetchExSet={refetchExSet}
                setOpenPrintEmailModal={setOpenPrintEmailModal}
            />
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
    </Dialog>
  )
}
