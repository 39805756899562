import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'

import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium'

import {
    Box,
    Button,
    LoadingButton,
} from 'shared-components/material/core'
import { FlagIcon } from 'shared-components/material/icons'
import { SelectInput, TextInput, DateTimeInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

import { reopenJob } from 'api/jobs'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { SearchResultItem } from 'screens/DocumentReview/DocumentReview'
import Tour from 'shared-components/Tour/Tour'

export const EDIT_USER = gql`
    mutation editUser(
        $userId: Int!, 
        $userEmlReviewOrderPref: JSON, 
        $userDocReviewOrderPref: JSON
    ) {
        updateUser(
            userId: $userId, 
            userEmlReviewOrderPref: $userEmlReviewOrderPref, 
            userDocReviewOrderPref: $userDocReviewOrderPref
        ) {
            userId
        }
    }
`

type FormData = {
    batchUpdate: string,
    filename?: string,
    subject?: string,
    folder?: string,
    date?: string,
    notes?: string,
    legalResearch?: string,
    flag?: string,
    check?: boolean,
}

const schema = yup.object({
    batchUpdate: yup.string(), 
    notes: yup.string(), 
    legalResearch: yup.string(),
    flag: yup.string(),
}).required()


function CustomToolbar({
    hasDocs,
    hasEmls,
    handleProduce,
    updateRows,
    getSelectedRows,
    getColumnState,
    jobStatus,
    refetchJob,
    handleError,
    setFilterButtonEl,
    userId,
    selectedItems,
    handleClearSearch
     } : { 
        hasDocs: boolean,
        hasEmls: boolean,
        handleProduce: () => Promise<void>, 
        updateRows: (formData: FormData) => void,
        getSelectedRows: () => [],
        getColumnState: () => GridInitialStatePremium,
        jobStatus: number,
        refetchJob: () => void, 
        handleError: (e: string, href?: string) => void,
        setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
        userId: number
        selectedItems: SearchResultItem[],
        handleClearSearch: () => void
    }) {
        const tourSteps = [
            {
              element: ".MuiDataGrid-pinnedColumnHeaders--right",
              intro: (
                    <>
                        <p>Use the checkboxes on the right to mark items privileged, 
                            produced, key, stamp, irrelevant, hold, redact, or native.</p>
                        <p>“Stamp” means add the word Confidential to every page of the 
                            selected document if you have a protective order in your case. 
                            “Native” gives you the option to keep Word, Excel and Powerpoint 
                            documents in their native format instead of converting to PDF.</p>
                    </>
                )
            },
            {
              element: ".MuiDataGrid-columnHeaders",
              intro: (
                    <p>All columns (other than the checkbox and view columns) are resizeable. 
                        You can also change the order of columns, by simply dragging them and 
                        dropping them. In addition, you can scroll left and right to see more columns.</p>
                )
            },
            {
              element: ".MuiDataGrid-columnHeader.introNotes .MuiSvgIcon-root",
              intro: (
                    <p>Some column headers have a pencil icon. Cells in these columns are editable.</p>
                )
            },
            {
              element: ".introView",
              intro: (
                    <p>Click the magnifying glass icon on the left to view the file, email, or attachment for the row.</p>
                )
            },
            {
              element: ".MuiDataGrid-cellCheckbox",
              intro: (
                    <p>To change multiple rows, select them using the checkboxes on the left. 
                        An &ldquo;update selected rows&rdquo; option will appear at the top. 
                        Use this feature to make the same changes to as many documents as you 
                        want all in one click.</p>
                )
            },
            {
              element: ".MuiDataGrid-detailPanelToggleCell",
              intro: (
                    <p>Click the &ldquo;+&rdquo; on the left to easily add notes or research to an item.</p>
                )
            },
            {
              element: ".introColumns",
              intro: (
                    <p>Click the &ldquo;Columns&rdquo; button to search, hide, or reveal columns.</p>
                )
            },
            {
              element: ".introFilters",
              intro: (
                    <p>Click the &ldquo;Filters&rdquo; button to filter or search items in the table. 
                        Note that you can turn on multiple filters, such as bringing up every email 
                        <i> from</i> a certain address and <i>to</i> a certain address.</p>
                )
            },
            {
              element: ".introDensity",
              intro: (
                    <p>Click the &ldquo;Density&rdquo; button to change the number of rows 
                        that are visible in the table.</p>
                )
            },
            {
              element: ".introSaveColumns",
              intro: (
                    <p>Click the &ldquo;Save Columns&rdquo; button to save the order, size, 
                        and layout of your columns.</p>
                )
            },
            {
              element: ".introProduce",
              intro: (
                    <p>After you complete your review, click the “Produce” button to 
                        sort your production, add Bates numbers, and prepare a privilege 
                        log and indexes of your document set.</p>
                )
            },
          ]
        if (hasEmls) {
            tourSteps.unshift({
                element: ".documentSetList",
                intro: (
                        <p>This is the review screen when you are reviewing emails. 
                            Don&apos;t forget to check out our guided tour when you upload 
                            a set of electronic documents!</p>
                    )
            })
            tourSteps.splice(11,0, {
                element: ".introPredictor",
                intro: (
                        <p>If you need to review for privilege, click here to go to our patented
                            Predictor, which will reduce the time of a privilege review by 50-75%.</p>
                    )
            })
        } else {
            tourSteps.unshift({
                element: ".documentSetList",
                intro: (
                        <p>This is the review screen when you are reviewing electronic documents. 
                            Don&apos;t forget to check out our guided tour when you upload 
                            a set of emails!</p>
                    )
            })
        }
    const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER)
    const { jobId } = useParams<{ jobId: string }>()
    const JOB_ID = jobId ? parseInt(jobId) : 0
    const selectedRows = getSelectedRows()
    const navigate = useNavigate()
    const batchUpdateOptions = [
        { label: 'Privileged', value: 'priv'},
        { label: 'Produced', value: 'prod'},
        { label: 'Key', value: 'isKey'},
        { label: 'Custom stamp', value: 'confidential'},
        { label: 'Irrelevant', value: 'irrl'},
        // { label: 'Hold', value: 'hold'},
        { label: 'Redact', value: 'redact'},
        { label: 'Native', value: 'native'},
        { label: 'Notes', value: 'notes'},
        { label: 'Research', value: 'legalResearch'},
        { label: 'Flag', value: 'flag'},
        { label: 'Filename', value: 'filename'},
        { label: 'Folder', value: 'folder'},
        { label: 'Date', value: 'date'},
    ]

    const flagOptions = [
        { label: '#dedede', value: '#dedede', component: <FlagIcon sx={{ color: '#dedede' }}/>},
        { label: '#C33C54', value: '#C33C54', component: <FlagIcon sx={{ color: '#C33C54' }}/> },
        { label: '#ED7434', value: '#ED7434', component: <FlagIcon sx={{ color: '#ED7434' }}/> },
        { label: '#FFE347', value: '#FFE347', component: <FlagIcon sx={{ color: '#FFE347' }}/> },
        { label: '#255957', value: '#255957', component: <FlagIcon sx={{ color: '#255957' }}/> },
        { label: '#0A369D', value: '#0A369D', component: <FlagIcon sx={{ color: '#0A369D' }}/> },
        { label: '#593F62', value: '#593F62', component: <FlagIcon sx={{ color: '#593F62' }}/> },
    ]

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            batchUpdate: '', 
            notes: '',
            legalResearch: '',
            flag: '',
            filename: '',
            folder: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, formState: { errors }, getValues } = methods
    const isNotes = watch('batchUpdate') === 'notes'
    const isDate = watch('batchUpdate') === 'date'
    const hasNotes = watch('notes')
    const isFilename = watch('batchUpdate') === 'filename'
    const hasFilename = watch('filename')
    const isFolder = watch('batchUpdate') === 'folder'
    const hasFolder = watch('folder')
    const isResearch = watch('batchUpdate') === 'legalResearch'
    const hasResearch = watch('legalResearch')
    const isFlag = watch('batchUpdate') === 'flag'
    const hasFlag = watch('flag')
    const hasBatchUpdate = getValues('batchUpdate')

    const onSubmit = (formData: FormData, check: boolean | null) => {
        Object.assign(formData, { check })
        updateRows(formData)
    }

    const disableButton = () => {
        if (isNotes && !hasNotes) {
            return true
        }
        if (isFilename && !hasFilename) {
            return true
        }
        if (isFolder && !hasFolder) {
            return true
        }
        if (isResearch && !hasResearch) {
            return true
        }
        if (isFlag && !hasFlag) {
            return true
        }
        if (!hasBatchUpdate) {
            return true
        }
        return false
    }

    const handleReopen = async () => {
        const payload = await reopenJob(JOB_ID)
            .catch(e => handleError(e))
        
        if (payload.success) {
            refetchJob()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleSaveColumnOrder = () => {
        const columnState = getColumnState()
        delete columnState['filter']
        delete columnState['pagination']
        let variables
        if (hasEmls) {
            variables = { userId, userEmlReviewOrderPref: JSON.stringify(columnState) }
            editUser({ variables })
        } else {
            variables = {userId, userDocReviewOrderPref: JSON.stringify(columnState)}
            editUser({ variables })
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 5, ml: 2, mr: 2 }}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} className="introColumns"/>
                <GridToolbarFilterButton ref={setFilterButtonEl} className="introFilters" />
                <GridToolbarDensitySelector sx={{ mr: 1}} className="introDensity"/>
                {editUserLoading ? (
                        <LoadingButton loading variant="outlined">
                            Save Column Order
                        </LoadingButton>
                    ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleSaveColumnOrder}
                        sx={{ ml: 1, mr: 1 }}
                        className="introSaveColumns"
                    >
                        Save Column Order
                    </Button>
                    )}
                    {selectedRows.length > 0 ? (
                        <FormProvider {...methods}>
                        <SelectInput
                            name="batchUpdate"
                            label="Update Selected Rows"
                            options={batchUpdateOptions}
                            error={errors.batchUpdate !== undefined ? true : false}
                            errorMessage={errors.batchUpdate ? errors.batchUpdate.message : undefined}
                            fullWidth
                            disabled={selectedRows.length === 0}
                            sx={{ 
                                width: '185px', 
                                mr: 1, 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiSelect-outlined': { 
                                    padding: '3.875px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />

                        {isNotes ? (
                            <TextInput
                            name='notes'
                            label='Notes'
                            error={errors.notes !== undefined ? true : false}
                            errorMessage={errors.notes ? errors.notes.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isResearch ? (
                            <TextInput
                            name='legalResearch'
                            label='Research'
                            error={errors.legalResearch !== undefined ? true : false}
                            errorMessage={errors.legalResearch ? errors.legalResearch.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px',
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem'
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px'
                                }
                            }}
                        />
                        ): null}

                        {isFilename ? (
                            <TextInput
                            name='filename'
                            label='Filename'
                            error={errors.filename !== undefined ? true : false}
                            errorMessage={errors.filename ? errors.filename.message : undefined}
                            sx={{ 
                                ml: 1,
                                width: '150px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px',
                                '& .MuiFormLabel-root': {
                                    fontSize: '0.8125rem',
                                    padding: '4px 10px',
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px'
                                }
                            }}
                        />
                        ): null}

                        {isFolder ? (
                            <TextInput
                            name='folder'
                            label='Folder'
                            error={errors.folder !== undefined ? true : false}
                            errorMessage={errors.folder ? errors.folder.message : undefined}
                            sx={{ 
                                ml: 1,
                                width: '150px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px',
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isFlag ? (
                            <SelectInput
                                name="flag"
                                label="Flag"
                                options={flagOptions}
                                required
                                error={errors.flag !== undefined ? true : false}
                                errorMessage={errors.flag ? errors.flag.message : undefined}
                                fullWidth
                                sx={{ 
                                    width: '150px', 
                                    top: '0px', 
                                    maxHeight: '30.75px !important', 
                                    minHeight: '30.75px', 
                                    '& .MuiFormLabel-root': {
                                        fontSize: '0.8125rem', 
                                        padding: '4px 10px', 
                                        lineHeight: '.4rem' 
                                    }, 
                                    '& .MuiInputBase-input': { 
                                        padding: '3.875px 10px !important', 
                                        height: '23px' 
                                    }
                                }}
                            />
                        ) : null}

                        {isDate ? (
                            <DateTimeInput
                                name="date"
                                label="Date"
                            />
                        ) : null}

                        {isFlag || isNotes || isResearch || isFolder || isFilename || isDate ? (
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={handleSubmit(data => onSubmit(data, null))}
                                sx={{ ml: 1 }}
                                disabled={disableButton()}
                            >
                                apply
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSubmit(data => onSubmit(data, true))}
                                    sx={{ ml: 1 }}
                                    disabled={disableButton()}
                                >
                                    check
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleSubmit(data => onSubmit(data, false))}
                                    sx={{ ml: 1 }}
                                    disabled={disableButton()}
                                >
                                    Uncheck
                                </Button>
                            </>
                        )}
                        
                    </FormProvider>
                    ) : null}
            </GridToolbarContainer>
            <div>
                {selectedItems.length > 0 ? (
                    <Button 
                        variant='contained' 
                        sx={{ mr: 1 }} 
                        size='small' 
                        color="error"
                        onClick={handleClearSearch}
                    >
                        Clear Search
                    </Button>
                ): null}
                {!hasDocs && hasEmls && jobStatus === 60 ? (
                    <Button 
                        variant='contained' 
                        sx={{ mr: 1 }} 
                        size='small' 
                        onClick={() => navigate(`/app/document-set/${JOB_ID}/classify-emails`)}
                        className='introPredictor'
                    >
                        Predictor
                    </Button>
                ): null}
                {jobStatus === 70 ? (
                    <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        sx={{ width: '125px' }}
                        onClick={handleReopen}
                    >
                        Reopen
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        sx={{ width: '125px' }}
                        onClick={handleProduce}
                        className="introProduce"
                    >
                        Produce
                    </Button>
                )}
            </div>
            <ErrorModal error={editUserError && editUserError.message || ''} />
            <Tour tourSteps={tourSteps}/>
        </Box>
    )
  }

  export default CustomToolbar