import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ExSet } from 'generated/graphql'

import { Box, Tab, Tabs } from 'shared-components/material/core'

import Meta from './Meta'
import Payment from './Payment'
import EditExhibitSet from './EditExhibitSet'
import Upload from './Upload/Upload'

type ExSetDataProps = {
    exSet: ExSet,
    refetch: () => void,
}

type TabPanelProps = {
    children?: React.ReactNode,
    index: number,
    value: number,
}

export default function ExSetData({ exSet, refetch } : ExSetDataProps) {
    const [searchParams] = useSearchParams()
    const tab = searchParams.get('tab')
    const [value, setValue] = useState(0)

    useEffect(() => {
        if (tab) {
            setValue(parseInt(tab))
        }
    }, [tab])

    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
      }
    
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props
    
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`job-tabpanel-${index}`}
                aria-labelledby={`job-tab-${index}`}
                {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
            </div>
        )
    }
    
    const a11yProps = (index: number) => ({
        id: `job-tab-${index}`,
        'aria-controls': `job-tabpanel-${index}`,
    })

    const steps = [
        {
            label: 'Meta',
            component: <Meta exSet={exSet} />
        },
        {
            label: 'Payment',
            component: <Payment exSet={exSet} />
        },
        {
            label: "Edit Exhibit Set",
            component: <EditExhibitSet exSet={exSet} refetch={refetch} />
        },
    ]

    if (exSet?.jobs?.length === 0) {
        steps.unshift({
            label: 'Upload',
            component: <Upload exSetId={exSet.exSetId} refetch={refetch} />
        })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="case tabs">
                    {steps.map((s, index) => <Tab key={s.label} label={s.label} {...a11yProps(index)} />)}
                </Tabs>
            </Box>
            {steps.map((s, index) => (
                <TabPanel key={s.label} value={value} index={index}>
                    {s.component}
                </TabPanel>
            ))}
        </Box>
    )

    // return (
    //     <Box sx={{ width: '100%' }}>
    //         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    //             <Tabs value={value} onChange={handleChange} aria-label="case tabs">
    //                 {job.statusString && job.statusString.message === 'UPLOAD' ? (
    //                     <Tab label="Upload"  {...a11yProps(0)} />
    //                 ) : (
    //                     <Tab label="Meta" {...a11yProps(0)} />
    //                 )}
    //                 <Tab label="Payment" {...a11yProps(1)} /> 
    //                 <Tab label="Edit Document Set"  {...a11yProps(2)} />
    //             </Tabs>
    //         </Box>
    //         {job.statusString && job.statusString.message === 'UPLOAD' ? (
    //             <TabPanel value={value} index={0}>
    //                 <Upload jobId={job.jobId} disputeId={job.jobDisputeId || 0} refetchJob={refetch} />
    //             </TabPanel>
    //         ) : (
    //             <TabPanel value={value} index={0}>
    //                 <Meta job={job} />
    //             </TabPanel>
    //         )}
    //         <TabPanel value={value} index={1}>
    //             <Payment job={job} />
    //         </TabPanel>
    //         <TabPanel value={value} index={2}>
    //             <EditJob 
    //                 job={job} 
    //                 refetch={refetch} 
    //                 batesRecs={batesRecs} 
    //                 disputeBatesIntegrity={disputeBatesIntegrity}
    //             />
    //         </TabPanel>
    //     </Box>
    // )
}