import React, { ChangeEvent, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import { DetailContainer, CenteredContent } from 'shared-components/layout'
import { CircularProgress, Grid, Stack, Typography, Switch } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'


import DocumentGrid from './DocumentGrid/DocumentGrid'
import DocumentSearch from './DocumentSearch/DocumentSearch'
import CaseHeader from 'screens/Case/CaseHeader'
import DisputeMeta from 'screens/Case/DisputeMeta'
import { useParams } from 'react-router-dom'

type email = {
    address: string
}

export type SearchResultItem = {
    filename: string,
    subject: string,
    from: email[],
    to: email[],
    cc: email[],
    bcc: email[],
    emlId: number,
    attId: number,
    docId: number,
    date: string,
    catFinal: number,
    confidential: boolean,
    redact: boolean,
    isKey: boolean,
    score: number,
    text: string,
    jobId: number,
    id: string,
}

const GET_DISPUTE = gql`
    query dispute($disputeId: Int) {
        dispute(disputeId: $disputeId) {
            disputeId
            disputeName
            disputeCreatedAt
            disputeProductionDeadline
            disputeMatter
            disputeFederal
            disputeNumber
            disputeDivision
            disputeJudge
            disputeClientId
            disputeBatesIntegrity
            disputeClientKickoffEmailSent
            disputeOpposingCounselEmailSent
            disputeClientEngagementEmailSent
            disputeGamePlanCallScheduled
            disputeStatus
            disputeDataRetentionDeadline
            disputeClioMatterId
            comms {
                commId
                commUserId
                commClientId
                commDisputeId
                commJobId
                commEmailAdds
                commCustomMessage
                commKind
                commCreatedAt
            }
            jobs {
                jobId
            }
            users {
                userFirstname
                userLastname
                userInitials
                userFirmRole
                userType
                userId
            }
            client {
                    clientId
                    clientName
                }
            batesRecs {
                batesRecCreatedAt
                batesRecPrefix
                batesRecNextBatesNum
                batesRecNextPrivNum
                batesRecBatesLength
                batesRecPrivLength
                batesRecId
                batesRecNextBatesStr
                batesRecNextPrivStr
                batesRecNextIrrelStr
               
            }
        }
    }
`

export default function CaseSearch() {
    const [isSwitchDisabled, setIsSwitchDisabled] = useState(true)
    const [isSearch, setIsSearch] = useState(true)
    const [selectedItems, setSelectedItems] = useState<SearchResultItem[]>([])
    const { disputeId } = useParams()
    const id = disputeId ? parseInt(disputeId) : 0

    const { loading: disputeLoading, error: disputeError, data, refetch } = useQuery(GET_DISPUTE, {
        fetchPolicy: 'network-only',
        variables: { disputeId: id },
    })

    const handleReview = () => {
        setIsSwitchDisabled(false)
        setIsSearch(false)
    }

    const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        setIsSearch(e.target.checked)
        setSelectedItems([])
        setIsSwitchDisabled(true)
    }

    if (disputeLoading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    const { dispute } = data
    const { 
        client,
        disputeName,
        disputeCreatedAt,
        disputeProductionDeadline,
        disputeStatus,
        disputeDataRetentionDeadline,
        disputeBatesIntegrity,
        disputeClioMatterId
    } = dispute

    const headerData = {
        disputeId: dispute.disputeId,
        disputeName,
        disputeCreatedAt,
        disputeProductionDeadline,
        disputeStatus,
        disputeDataRetentionDeadline,
        disputeBatesIntegrity,
        disputeClioMatterId,
    }

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Case Search
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item md={6}>
                    <DetailContainer>
                        <CaseHeader headerData={headerData} refetch={refetch} />
                    </DetailContainer>
                </Grid>
                <Grid item md={6}>
                    <DetailContainer>
                        <DisputeMeta dispute={dispute} client={client} />
                    </DetailContainer>
                </Grid>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 2, mt: 2 }}>
                    <Typography>Review</Typography>
                    <Switch 
                        disabled={isSwitchDisabled} 
                        checked={isSearch} 
                        onChange={handleSwitch} 
                        sx={{ m: 1 }}
                    />
                    <Typography>Search</Typography>
                </Stack>
                <Grid item md={12}>
                    {isSearch 
                    ?   <DocumentSearch 
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            handleReview={handleReview}
                        />
                    :   <DocumentGrid selectedItems={selectedItems} />}
                </Grid>
            </Grid>
            <ErrorModal error={disputeError && disputeError.message || ''} />
        </>
    )
}
