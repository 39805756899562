import React from 'react'
import { Typography, DialogContent, DialogActions, Box, Button } from 'shared-components/material/core'
import { CenteredForm } from 'shared-components/layout'

export default function Success({preauthCode} : {preauthCode: string}) {
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
          <CenteredForm>
          <Typography variant="body1" component="p">
              You&apos;ve successfully paid for your subscription! You can now use Discovery Genie with your new subscription plan.
          </Typography>
        </CenteredForm>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pt: 2,
            width: '100%'
        }}>
          <Button 
            href={`/reset-password/${preauthCode}`}
            variant="contained"
          >
              Done
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
