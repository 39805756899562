import { PaymentMethod } from "@stripe/stripe-js"

const { REACT_APP_API_URL } = process.env

export async function login(email: string, password: string, killExistingSession: boolean) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/log-in`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ email, password, killExistingSession }),
  })

  return res.json()
}

export async function logout() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/log-out`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
    // Old way. Multiple settings reduntant. No-cache most secure, so just use that.
    // Google no-cache vs. max-age=0 for more info.
      // 'Cache-Control': 'private, max-age=0, no-cache',
      // Pragma: 'no-cache',
    },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function postForgotPassword(email: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/forgot-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ email }),
  })

  return res.json()
}

export async function postResetPassword(resetCode: string, password: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/reset-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ resetCode, password }),
  })

  return res.json()
}

export async function putPassword(oldPassword: string, newPassword: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/password`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ oldPassword, newPassword }),
  })

  return res.json()
}

export async function putEmailVerified(emailVerificationCode: string | null) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/email-verified`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ emailVerificationCode }),
  })

  return res.json()
}

export async function register({ 
  email,
  firstname,
  lastname,
  firmName,
  firmTelephone,
  password,
  partnerId, }: { 
    email: string,
    firstname: string,
    lastname: string,
    firmName: string,
    firmTelephone: string,
    password: string,
    partnerId: number | null }) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ firstname, lastname, firmName, email, firmTelephone, password, partnerId }),
  })

  return res.json()
}

export async function putAccountSetupComplete() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/account-setup-complete`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getPartner(partner: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/partner/${partner}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function getReferralTypes() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/referral-types`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function getReferrals() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/referrals`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function postReferral(
  {
    firstname, 
    lastname, 
    firmName, 
    email
  } : {
    firstname: string,
    lastname: string,
    firmName: string,
    email: string
  }) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/referrals`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ firstname, lastname, firmName, email }),
  })

  return res.json()
}

export async function getCreditBalance() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/credit-balance`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function getCreditInfo() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/credit-info`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function getCharges() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/charges`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function getInvoices() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/invoices`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function getSubscriptionPaymentPreauth(preauthCode: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/subscription-payment-preauth/${preauthCode}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}

export async function postSubscriptionPaymentPreauth(preauthCode: string, paymentMethod: void | PaymentMethod) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/subscription-payment-preauth/${preauthCode}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ paymentMethod }),
  })
  
  return res.json()
}

export async function createSubscription(subscriptionTierId: number, numberOfAttorneys: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/create-subscription`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ subscriptionTierId, numberOfAttorneys }),
  })
  
  return res.json()
}

export async function getWholesalePartnerParam() {
  const res = await fetch(`${REACT_APP_API_URL}/api/accounts/wholesale-partner-param`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })
  
  return res.json()
}