import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Credit } from 'generated/graphql'
import moment from 'moment'

import { 
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Link
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { currencyFormat } from 'shared-components/utils'
import { CenteredContent, RightAlignedForm } from 'shared-components/layout'

import { getCreditInfo } from 'api/accounts'

type CreditInfo = Credit & {
  jobName: string
}


export const GET_FIRM = gql`
    query firm {
        firm {
            firmDiscount
        }
    }
`

function AppliedCreditsTable({ appliedCredits } : { appliedCredits: CreditInfo[] }) {
  const navigate = useNavigate()
  return (
    <TableContainer component={Paper} >
      <Table aria-label="referrals">
        <TableHead>
          <TableRow>
            <TableCell>Credit Used</TableCell>
            <TableCell>Date Used</TableCell>
            <TableCell>Job</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appliedCredits.map(({ creditAmount, creditCreatedAt, creditJobId, jobName, creditId } : CreditInfo) => (
            <TableRow key={creditId}>
              <TableCell>{creditAmount && currencyFormat(creditAmount)}</TableCell>
              <TableCell>{moment(creditCreatedAt).format('DD/MM/YYYY')}</TableCell>
              <TableCell>
                <Link 
                  component="button"
                  variant="body1"
                  onClick={() => navigate(`/app/document-set/${creditJobId}`)}
                >
                  {jobName}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default function Credits() {
  const { data: firmData, loading: firmLoading, error: firmError } = useQuery(GET_FIRM)
  const [loading, setLoading] = useState(true)
  const [error, href, handleError, resetError] = useError()
  const [creditBalance, setCreditBalance] = useState(0)
  const [appliedCredits, setAppliedCredits] = useState<Array <CreditInfo>>([])

  const getCreditInfoFn = async () => {
    const payload = await getCreditInfo()
        .catch(e => handleError(e))
    
    if (payload.success) {
        setLoading(false)
        setCreditBalance(payload.data.creditBalance)
        setAppliedCredits(payload.data.appliedCredits)
    } else {
        setLoading(false)
        handleError(payload.err, payload.href)
    }
}


  useEffect(() => {
    getCreditInfoFn()
  }, [])

  if (loading || firmLoading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      }}
    >
      <Box sx={{width: '100%'}}>
        <Typography>
          Credit balance and usage history
        </Typography>
        {appliedCredits.length > 0 ? <AppliedCreditsTable appliedCredits={appliedCredits} /> : <div />}
      </Box>
      <div />
      <RightAlignedForm>
        <Typography variant='h3' gutterBottom>
          Credits
        </Typography>
        <Typography variant='body1' gutterBottom>
          Credit Balance: {currencyFormat(creditBalance)}
        </Typography>
        {firmData.firm.firmDiscount > 0 && (
          <Typography variant='body1' gutterBottom>
            Plan Discount: {`${firmData.firm.firmDiscount}%`}
          </Typography>
        )}
      </RightAlignedForm>
      <ErrorModal 
        error={error || (firmError && firmError.message) || ''} 
        href={href} 
        resetError={resetError}
      />
    </Box>
  )
}
