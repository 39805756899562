import React from 'react'
import moment from 'moment'
import { Job } from 'generated/graphql'

import { Button, Table, TableBody, TableCell, TableHead, TableRow } from 'shared-components/material/core'

type DownloadsTableProps = {
    jobs: Job[],
    handleGetDownload: (jobId: number) => Promise<void>,
}

export default function DownloadsTable({ jobs, handleGetDownload } : DownloadsTableProps) {
    const renderBates = (job: Job) => {
        if (job.jobBatesLength && job.jobBatesLength > 1) {
            return `${job.jobBatesStart} - ${job.jobBatesEnd}`
        }
        if (job.jobBatesLength === 1) {
            return job.jobBatesStart
        }
        return ''
    }

    const renderPriv = (job: Job) => {
        if (job.jobPrivLength && job.jobPrivLength > 1) {
            return `${job.jobPrivStart} - ${job.jobPrivEnd}`
        }
        if (job.jobPrivLength === 1) {
            return job.jobPrivStart
        }
        return ''
    }

    const renderIrrel = (job: Job) => {
        if (job.jobIrrelLength && job.jobIrrelLength > 1) {
            return `${job.jobIrrelStart} - ${job.jobIrrelEnd}`
        }
        if (job.jobIrrelLength === 1) {
            return job.jobIrrelStart
        }
            return ''
    } 

    return (
        <Table sx={{ mb: 4 }}>
            <TableHead>
                <TableRow>
                    <TableCell>Document Set</TableCell>
                    <TableCell>Date Produced</TableCell>
                    <TableCell>Date Expired</TableCell>
                    <TableCell>Bates Prefix</TableCell>
                    <TableCell>Bates Range</TableCell>
                    <TableCell>Priv Range</TableCell>
                    <TableCell>Irrel Range</TableCell>
                    <TableCell>Download</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {jobs.map((job) => (
                    <TableRow key={job.jobId}>
                        <TableCell>{job.jobName}</TableCell>
                        <TableCell>{moment(job.jobFinishedTimestamp).format('MM/DD/YYYY')}</TableCell>
                        <TableCell>
                            {job.jobExpiresTimestamp ? 
                                moment(job.jobExpiresTimestamp).format('MM/DD/YYYY') : ''}
                        </TableCell>
                        <TableCell>{job.jobBatesPrefix}</TableCell>
                        <TableCell>{renderBates(job)}</TableCell>
                        <TableCell>{renderPriv(job)}</TableCell>
                        <TableCell>{renderIrrel(job)}</TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                onClick={() => handleGetDownload(job.jobId)}
                                color="secondary"
                            >
                                Download
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
