import React, { useCallback, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import { CenteredContent } from 'shared-components/layout'
import { CircularProgress, Paper, Grid, Typography } from 'shared-components/material/core'
import AddClientModal from 'shared-components/modals/AddClientModal'
import ErrorModal from 'shared-components/modals/ErrorModal'

import ClientsTable from './ClientsTable/ClientsTable'
import ClientsOptions from './ClientsOptions/ClientsOptions'

export const GET_CLIENTS = gql`
    query clients {
        clients {
            clientId
            clientName
            clientCreatedAt
            clientAlphaId
        }
    }
`
interface Client {
    clientId: number,
    clientName: string,
    clientCreatedAt: string,
    clientAlphaId: string,
  
  }

function Clients () {
    const [openAddClient, setOpenAddClient] = useState(false)
    const [filteredClients, setFilteredClients] = useState<Client[]>([])

    const { loading, error, data, refetch } = useQuery(GET_CLIENTS)

    const createFilteredClients = useCallback((value: string) => {
        const searchString = String(value).toLowerCase()
        const filteredClients = data.clients.filter((client: Client) => 
            Object.entries(client).some(entry =>
                String(entry[1]).toLowerCase().includes(searchString)
            )
        )
        setFilteredClients(filteredClients)
    }, [data])

    const clearSearch = useCallback(() => setFilteredClients([]), [])

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }
    const href: string = error && error.graphQLErrors[0] 
        ? error.graphQLErrors[0].extensions.href as string 
        : ''
    const clients = data ? data.clients : []

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Clients
            </Typography>
            <Grid container spacing={2} sx={{ maxWidth: '1440px' }}>
                <Grid item xs={12} md={8} lg={6} xl={5}>
                    <Paper elevation={3} sx={{ display: 'flex', p: 1 }}>
                        <ClientsOptions
                            setOpenAddClient={setOpenAddClient}
                            createFilteredClients={createFilteredClients} 
                            clearSearch={clearSearch}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <ClientsTable 
                            clients={filteredClients.length > 0 ? filteredClients : clients} 
                        />
                    </Paper>
                </Grid>
                <AddClientModal 
                    openAddClient={openAddClient} 
                    setOpenAddClient={setOpenAddClient} 
                    refetch={refetch} 
                />
                <ErrorModal error={error ? error.message : ''} href={href} />
            </Grid>
        </>
    )
}

export default Clients