import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { Typography, Button, Box, CircularProgress } from 'shared-components/material/core'
import { CenteredContent, RightAlignedForm } from 'shared-components/layout/index'
import { TextInput } from 'shared-components/inputs'
import { useLocation } from 'react-router-dom'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { completeClioIntegration, getClioAccessToken, removeClioIntegration } from 'api/clio'
import { gql, useQuery } from '@apollo/client'

export const GET_USER = gql`
    query User {
      user {
        userClioSetupComplete
        userClioRefreshToken
        userEmailVerified
        userAccountSetupComplete
      }
    }
`

/* eslint-disable no-undef */
const { REACT_APP_CLIENT_URL } = process.env
/* eslint-enable */
const clioLink = `https://app.clio.com/oauth/authorize?response_type=code&client_id=XAobjVcw5u9wSloD9uOLwHZobf9If6AMlI1ETy2C&redirect_uri=${REACT_APP_CLIENT_URL}/app/preferences/clio?redirect=clio-redirect`;

const schema = yup.object({
  activityDescriptionName: yup.string().required(),
  activityDescriptionRate: yup.string().required(),
}).required()

type FormData = {
  activityDescriptionName: string;
  activityDescriptionRate: string;
}

function Logo () {
  return (
    <Box sx={{ width: '100%', maxWidth: '360px', textAlign: 'center', mb: 2 }}>
        <img src="/clio-logo.png" width="150" alt="Clio Logo" style={{ textAlign: 'center' }} />
    </Box>
  )
}

export default function Clio() {
  const { data, loading: userLoading, error: userError, refetch } = useQuery(GET_USER)
  const [error, href, handleError ] = useError()
  const [loading, setLoading] = useState(false)
  const { search } = useLocation()
  const redirect = new URLSearchParams(search).get('redirect')
  const clioCode = new URLSearchParams(search).get('code') // This comes from clio's redirect url.

  let errorMessage
  if (error) {
    errorMessage = error
  }
  if (userError) {
    errorMessage = userError.message
  }

  const getClioAccessTokenFn = async () => {
    setLoading(true)
    const payload = clioCode && (await getClioAccessToken(clioCode)
      .catch(e => handleError(e)))

    if (payload.success) {
      refetch()
      setLoading(false)
    } else {
      setLoading(false)
      handleError(payload.err, payload.href)
    }
  }


  useEffect(() => {
    if (redirect === 'clio-redirect') {
      getClioAccessTokenFn()
    }
  },[])

  const handleDisconnectClio = async () => {
    setLoading(true)
    const payload = await removeClioIntegration()
      .catch(e => handleError(e))

    if (payload.success) {
      refetch()
      setLoading(false)
    } else {
      setLoading(false)
      handleError(payload.err, payload.href)
    }
  }

  const methods = useForm({
    defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
        activityDescriptionName: '',
        activityDescriptionRate: '',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, formState: { errors }} = methods

  const onSubmit = async (formData: FormData) => {
    const payload = await completeClioIntegration(formData)
      .catch(e => handleError(e))

    if (payload.success) {
      refetch()
      setLoading(false)
      if (data.user.userAccountSetupComplete) {
        window.location.replace(`${REACT_APP_CLIENT_URL}/app/cases`)
      } else {
        window.location.replace(`${REACT_APP_CLIENT_URL}/account-setup`)
      }
    } else {
      setLoading(false)
      handleError(payload.err, payload.href)
    }
  }

  if (loading || userLoading) {
    return (
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    )
  }

  if (error) {
    return <ErrorModal error={errorMessage ? errorMessage : ''} href={href} />
  }

  const clioConnected = !!data.user.userClioRefreshToken // if clio refresh token exists then user is connected 
  if (clioConnected && !data.user.userClioSetupComplete) {
    return (
      <FormProvider {...methods}>
      <RightAlignedForm width='360px'>
        <Logo />
        <Typography variant='body1' gutterBottom>
          You’re almost ready to experience the power of Clio + Discovery Genie.
        </Typography>
        <Typography variant='body1' gutterBottom>
          Fill in this form to create a default activity category for use in 
          Discovery Genie. (You can easily disconnect the integration at any time.)
        </Typography>
        <TextInput
            name="activityDescriptionName"
            label="Activity Description Name"
            required
            error={errors.activityDescriptionName !== undefined ? true : false }
            errorMessage={errors.activityDescriptionName ? errors.activityDescriptionName.message : undefined}
        />
        <TextInput
            name="activityDescriptionRate"
            label="Activity Description Rate"
            required
            type="number"
            error={errors.activityDescriptionRate !== undefined ? true : false }
            errorMessage={errors.activityDescriptionRate ? errors.activityDescriptionRate.message : undefined}
        />
        <Button
              variant="contained"
              size="large"
              onClick={handleSubmit(onSubmit)}
          >
              Submit Category
        </Button>
        <Button
              variant="text"
              size="large"
              onClick={handleDisconnectClio}
              color="error"
          >
              Disconnect Clio
        </Button>
      </RightAlignedForm>
    </FormProvider>
    )
  }

  return (
    <RightAlignedForm width='360px'>
      <Logo />
      <Typography variant='body1' gutterBottom>
        Clio is an industry leader in cloud-based legal practice management software 
        for running your firm, organizing your cases, collaborating with clients and 
        streamlining your billing.
      </Typography>
      <Typography variant='body1' gutterBottom>
        And now you can get more done by combining Clio’s essential tools with the power 
        of Discovery Genie. This dynamic duo helps you leverage control of your evidence, 
        simplify your document production, and more effectively manage deadlines and billing.
      </Typography>
      {!clioConnected ? (
        <>
          <Button
            variant="contained"
            size="large"
            href="https://www.clio.com/signup/"
            color="primary"
            sx={{ mt: 2 }}
          >
            Create Clio Account
          </Button>
          <Typography variant='body1' gutterBottom sx={{ textAlign: 'center' }}>
            Already a Clio customer?
          </Typography>
          <Button
            variant="outlined"
            size="large"
            href={clioLink}
            color="primary"
            sx={{ mt: 2 }}
          >
            Connect Clio Account
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          size="large"
          onClick={handleDisconnectClio}
          color="error"
          sx={{ mt: 2 }}
        >
          Disconnect Clio
        </Button>
      )}
    </RightAlignedForm>
  )
}
