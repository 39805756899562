import React from 'react'
import moment from 'moment'
import { BatesRec } from 'generated/graphql'

import { Typography } from 'shared-components/material/core'
import { addLeadingZeros } from 'shared-components/utils'

export default function BatesHeader({ batesRec } : { batesRec: BatesRec}) {
  return (
    <>
        <Typography variant="body1">Bates Prefix: {batesRec.batesRecPrefix}</Typography>
        <Typography variant="body1">Next Bates: {addLeadingZeros(batesRec.batesRecNextBatesNum as number, batesRec.batesRecBatesLength as number)}</Typography>
        <Typography variant="body1">Next Priv: {addLeadingZeros(batesRec.batesRecNextPrivNum as number, batesRec.batesRecPrivLength as number)}</Typography>
        <Typography variant="body1">
          Created: {moment(batesRec.batesRecCreatedAt).format('MM/DD/YY')}
        </Typography>
    </>
  )
}
