import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    LoadingButton,
    Typography,
} from 'shared-components/material/core'
import { CheckboxInput, SelectInput, TextInput } from 'shared-components/inputs'
import AddBatesModal from '../AddBatesModal'
import ErrorModal from '../ErrorModal'
import { BatesRec, Job } from 'generated/graphql'
import DocumentSetsTable from './DocumentSetsTable'
import { addLeadingZeros } from 'shared-components/utils'

export const EDIT_JOB = gql`
    mutation editJob(
        $jobId: Int!,
        $jobBypassBates: Boolean!,
        $jobPdfMargin: Boolean!,
        $jobCustomConfidential: String, 
        $jobBatesRecId: Int,
        $jobName: String!,
        $jobPrivStartStr: String,
        $jobIrrelStartStr: String,
        $jobBatesStartStr: String,
        $jobBatesPrefix: String,
        ) {
            updateJob(
                jobId: $jobId,
                jobBypassBates: $jobBypassBates,
                jobPdfMargin: $jobPdfMargin,
                jobCustomConfidential: $jobCustomConfidential,
                jobBatesRecId: $jobBatesRecId,
                jobName: $jobName,
                jobPrivStartStr: $jobPrivStartStr,
                jobIrrelStartStr: $jobIrrelStartStr,
                jobBatesStartStr: $jobBatesStartStr,
                jobBatesPrefix: $jobBatesPrefix
            ) { jobId }
}
`

const schema = yup.object({
    jobCustomConfidential: yup.string(),
    jobBatesRecId: yup.number().required('Bates prefix required'),
    jobPdfMargin: yup.boolean().required(),
    jobBatesStartStr: yup
        .string()
        .when(["jobBypassBates", "disputeBatesIntegrity"], { 
            is: false,
            then: yup.string().required("This field is required")
        }),
    jobPrivStartStr: yup
        .string()
        .when(["jobBypassBates", "disputeBatesIntegrity"], { 
            is: false,
            then: yup.string().required("This field is required")
        }),
    jobIrrelStartStr: yup
        .string()
        .when(["jobBypassBates", "disputeBatesIntegrity"], { 
            is: false,
            then: yup.string().required("This field is required")
        }),
    jobBatesPrefix: yup 
        .string()
        .when(["jobBypassBates", "disputeBatesIntegrity"], { 
            is: false,
            then: yup.string().required("This field is required")
        }),
    jobBypassBates: yup.boolean().required(),
}).required()

type FormData = {
    jobCustomConfidential: string,
    jobBatesRecId: number,
    jobPdfMargin: boolean,
    jobBatesStartStr: string,
    jobPrivStartStr: string,
    jobIrrelStartStr: string,
    jobBatesPrefix: string,
    jobBypassBates: boolean,
    disputeBatesIntegrity?: boolean,
}

type OptionsProps = { 
    job: Job,
    batesRecs: BatesRec[],
    togglePayModal: () => void,
    handleNext: () => void,
    getJobCost: () => void,
    refetch: () => void,
    refetchJob?: () => void,
    disputeJobs: Job[],
    disputeBatesIntegrity: boolean,
}

export default function Options({ 
    job,
    batesRecs,
    togglePayModal,
    handleNext,
    getJobCost,
    refetch,
    refetchJob,
    disputeJobs,
    disputeBatesIntegrity,
} : OptionsProps) {
    const [openAddBates, setOpenAddBates] = useState(false)
    const [editJob, { loading, error }] = useMutation(EDIT_JOB)
    const errorMsg = error ? error.message : ''
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            jobCustomConfidential: '',
            jobBatesRecId: job.jobBatesRecId || 0,
            jobPdfMargin: false,
            jobBatesStartStr: '00001',
            jobPrivStartStr: '00001',
            jobIrrelStartStr: '00001',
            jobBatesPrefix: '',
            jobBypassBates: false,
            disputeBatesIntegrity: false,
        },
        resolver: yupResolver(schema),
    })

    const latestBates = disputeJobs.length > 1 
        ? disputeJobs.reduce((prev, current) => (prev.jobBatesEnd as number) > (current.jobBatesEnd as number) ? prev : current) 
        : null

    const latestPriv = disputeJobs.length > 1 
        ? disputeJobs.reduce((prev, current) => (prev.jobPrivEnd as number) > (current.jobPrivEnd as number) ? prev : current) 
        : null

    const latestIrrel = disputeJobs.length > 1 
        ? disputeJobs.reduce((prev, current) => (prev.jobIrrelEnd as number) > (current.jobIrrelEnd as number) ? prev : current) 
        : null

    const { handleSubmit, setValue, watch, formState: { errors } } = methods

    const bypassBates = watch('jobBypassBates')

    useEffect(() => {
        setValue('jobPdfMargin', job.jobPdfMargin || false)
        setValue('jobBatesRecId', job.jobBatesRecId || 0)
        setValue('jobCustomConfidential', job.jobCustomConfidential || '')
        setValue('disputeBatesIntegrity', disputeBatesIntegrity)
        if (latestBates) {
            const batesStart = latestBates.jobBatesLength === 0 ? latestBates.jobBatesEnd as number : latestBates.jobBatesEnd as number + 1
            const leadingZeros = latestBates.jobBatesNumDigits as number || 5
            const jobBatesStartStr = addLeadingZeros(batesStart, leadingZeros)
            setValue('jobBatesPrefix', latestBates.jobBatesPrefix || '')
            setValue('jobBatesStartStr', jobBatesStartStr)
        }
        if (latestPriv) {
            const privStart = latestPriv.jobPrivLength === 0 ? latestPriv.jobPrivEnd as number : latestPriv.jobPrivEnd as number + 1
            const leadingZeros = latestPriv.jobPrivNumDigits as number || 5
            const jobPrivStartStr = addLeadingZeros(privStart, leadingZeros)
            setValue('jobPrivStartStr', jobPrivStartStr)
        }
        if (latestIrrel) {
            const irrelStart = latestIrrel.jobIrrelLength === 0 ? latestIrrel.jobIrrelEnd as number : latestIrrel.jobIrrelEnd as number + 1
            const leadingZeros = latestIrrel.jobIrrelNumDigits as number || 5
            const jobIrrelStartStr = addLeadingZeros(irrelStart, leadingZeros)
            setValue('jobIrrelStartStr', jobIrrelStartStr)
        }
    }, [job, latestBates, latestPriv, latestIrrel])

    const batesOptions = batesRecs.map(batesRec => {
        return {
            label: `Prefix: ${batesRec.batesRecPrefix} - Next bates: ${batesRec.batesRecNextBatesStr} - Next Priv: ${batesRec.batesRecNextPrivStr}`,
            value: batesRec.batesRecId
        }
    })

    const onSubmit = async (formData: FormData) => {
        const variables = { ...formData, jobId: job.jobId, jobName: job.jobName}
        delete variables.disputeBatesIntegrity

        editJob({ variables })
            .then(() => getJobCost())
            .then(() => refetchJob ? refetchJob() : null)
            .then(() => handleNext())
    }

    if (errorMsg.length > 0) {
        return <ErrorModal error={errorMsg} />
    }

    return (
        <>
            <DialogContent 
                sx={!disputeBatesIntegrity && disputeJobs.length > 1 
                    ? { height: '536px' } 
                    : { display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}
            >
                    <div>
                    <FormProvider {...methods} >
                        {!disputeBatesIntegrity && disputeJobs.length > 1 && (
                            <>
                                <Typography variant="body1" sx={{ mt: 2}}>
                                    Current document sets for this case:
                                </Typography>
                                <DocumentSetsTable jobs={disputeJobs} currentJobId={job.jobId}/>
                                <br />
                                <Divider />
                                <br />
                            </>
                        )}
                        <Box sx={{mb: 4}}>
                            <Typography variant="h3" sx={{ mb: 4}}>
                                Producing Document Set: {job.jobName}
                            </Typography>
                            <CheckboxInput 
                                name={"jobBypassBates"}
                                label="No bates numbers"
                                required
                                error={errors.jobBypassBates != undefined ? true : false }
                                errorMessage={errors.jobBypassBates ? errors.jobBypassBates.message : undefined}
                                sx={{ '& .MuiTypography-root, .MuiCheckbox-root, .MuiFormControl-root': { mt: 1, padding: '0 .5em 0 .5em' }}}
                            />
                            <CheckboxInput 
                                name={"jobPdfMargin"}
                                label="PDF Margin"
                                required
                                error={errors.jobPdfMargin != undefined ? true : false }
                                errorMessage={errors.jobPdfMargin ? errors.jobPdfMargin.message : undefined}
                                sx={{ '& .MuiTypography-root, .MuiCheckbox-root, .MuiFormControl-root': { mt: 1, padding: '0 .5em 0 .5em' }}}
                                tooltip='Adds Bates number margin to pages so that Bates does not print on top of document data. This will result in longer processing times.'
                            />
                            <TextInput
                                name='jobCustomConfidential'
                                label='Custom Stamp'
                                required
                                error={errors.jobCustomConfidential !== undefined ? true : false}
                                errorMessage={errors.jobCustomConfidential ? errors.jobCustomConfidential.message : undefined}
                            />
                        </Box>
                        {(disputeBatesIntegrity && !bypassBates) && (
                            <Box sx={{ display: 'flex' }}>
                                <SelectInput
                                    name="jobBatesRecId"
                                    label="Bates Prefix"
                                    options={batesOptions}
                                    required
                                    error={errors.jobBatesRecId !== undefined ? true : false}
                                    errorMessage={errors.jobBatesRecId ? errors.jobBatesRecId.message : undefined}
                                    fullWidth
                                    sx={{width: '100%'}}
                                />
                                <Button
                                    onClick={() => setOpenAddBates(true)}
                                    variant="outlined"
                                    sx={{ height: '40px', ml: 1 }}
                                >
                                    Add 
                                </Button>
                            </Box>
                        )} 


                        {(!disputeBatesIntegrity && !bypassBates) && (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <TextInput
                                    name='jobBatesPrefix'
                                    label='Bates Prefix'
                                    required
                                    error={errors.jobBatesPrefix !== undefined ? true : false}
                                    errorMessage={errors.jobBatesPrefix ? errors.jobBatesPrefix.message : undefined}
                                    sx={{ mr: 1 }}
                                    inputLabelProps={{shrink: true}}
                                />
                                <TextInput
                                    name='jobBatesStartStr'
                                    label='Next Bates Number'
                                    error={errors.jobBatesStartStr !== undefined ? true : false}
                                    errorMessage={errors.jobBatesStartStr ? errors.jobBatesStartStr.message : undefined}
                                    sx={{ mr: 1 }}
                                    inputLabelProps={{shrink: true}}
                                />
                                <TextInput
                                    name='jobPrivStartStr'
                                    label='Next Privilege Number'
                                    error={errors.jobPrivStartStr !== undefined ? true : false}
                                    errorMessage={errors.jobPrivStartStr ? errors.jobPrivStartStr.message : undefined}
                                    sx={{ mr: 1 }}
                                    inputLabelProps={{shrink: true}}
                                />
                                <TextInput
                                    name='jobIrrelStartStr'
                                    label='Next Irrelevant Number'
                                    error={errors.jobIrrelStartStr !== undefined ? true : false}
                                    errorMessage={errors.jobIrrelStartStr ? errors.jobIrrelStartStr.message : undefined}
                                    sx={{ mr: 1 }}
                                    inputLabelProps={{shrink: true}}
                                />
                            </Box>
                        )}
                    </FormProvider>
                    </div>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pt: 2,
                    width: '100%'
                }}>
                    <Button 
                        onClick={togglePayModal} 
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    {loading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ) : (
                        <Button 
                            onClick={handleSubmit(onSubmit)} 
                            variant="contained"
                        >
                            Submit Options
                        </Button>
                    )}

                </Box>
            </DialogActions>
            <AddBatesModal
                openAddBates={openAddBates}
                setOpenAddBates={setOpenAddBates}
                refetch={refetch}
                batesRecDisputeId={job.jobDisputeId ? job.jobDisputeId : 0}
            />
        </>
  )
}
