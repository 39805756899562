import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { CircularProgress, Grid, IconButton, Typography } from 'shared-components/material/core'
import { CenteredContent, DetailContainer } from 'shared-components/layout'
import ErrorModal from 'shared-components/modals/ErrorModal'

import CaseHeader from './CaseHeader'
import DisputeMeta from './DisputeMeta'
import CaseTabs from './CaseTabs/CaseTabs'
import ToDo from './ToDo'
import { ChevronLeftIcon, ListIcon } from 'shared-components/material/icons'

const GET_DISPUTE = gql`
    query dispute($disputeId: Int) {
        dispute(disputeId: $disputeId) {
            disputeId
            disputeName
            disputeCreatedAt
            disputeProductionDeadline
            disputeMatter
            disputeFederal
            disputeNumber
            disputeDivision
            disputeJudge
            disputeClientId
            disputeBatesIntegrity
            disputeClientKickoffEmailSent
            disputeOpposingCounselEmailSent
            disputeClientEngagementEmailSent
            disputeGamePlanCallScheduled
            disputeStatus
            disputeDataRetentionDeadline
            disputeClioMatterId
            disputeStage 
            comms {
                commId
                commUserId
                commClientId
                commDisputeId
                commJobId
                commEmailAdds
                commCustomMessage
                commKind
                commCreatedAt
            }
            jobs {
                jobId
            }
            users {
                userFirstname
                userLastname
                userInitials
                userFirmRole
                userType
                userId
            }
            client {
                    clientId
                    clientName
                }
            batesRecs {
                batesRecCreatedAt
                batesRecPrefix
                batesRecNextBatesNum
                batesRecNextPrivNum
                batesRecBatesLength
                batesRecPrivLength
                batesRecId
                batesRecNextBatesStr
                batesRecNextPrivStr
                batesRecNextIrrelStr
                jobs {
                    jobPrivStart
                    jobPrivEnd
                    jobPrivLength
                    jobBatesStart
                    jobBatesEnd
                    jobBatesLength
                    jobIrrelStart
                    jobIrrelEnd
                    jobName
                    jobCreatedAt
                }
            }
        }
    }
`

export default function Case () {
    const { disputeId } = useParams()
    const [toDoOpen, setToDoOpen] = useState(true)

    const id = disputeId ? parseInt(disputeId) : null
    
    const { loading, error, data, refetch } = useQuery(GET_DISPUTE, {
        fetchPolicy: 'network-only',
        variables: { disputeId: id },
    })

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    if (error) {
        return <ErrorModal error={error.message} />
    }

    const { dispute } = data
    const { 
        users,
        batesRecs,
        client,
        disputeName,
        disputeCreatedAt,
        disputeProductionDeadline,
        disputeStatus,
        disputeDataRetentionDeadline,
        disputeBatesIntegrity,
        disputeClioMatterId
    } = dispute

    const headerData = {
        disputeId: dispute.disputeId,
        disputeName: disputeName,
        disputeCreatedAt: disputeCreatedAt,
        disputeProductionDeadline: disputeProductionDeadline,
        disputeStatus: disputeStatus,
        disputeDataRetentionDeadline: disputeDataRetentionDeadline,
        disputeBatesIntegrity: disputeBatesIntegrity,
        disputeClioMatterId: disputeClioMatterId
    }

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Case
            </Typography>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={6}>
                    <DetailContainer>
                        <CaseHeader headerData={headerData} refetch={refetch} />
                    </DetailContainer>
                </Grid>
                <Grid item md={6}>
                    <DetailContainer>
                        <DisputeMeta dispute={dispute} client={client} />
                    </DetailContainer>
                </Grid>
                {toDoOpen && (
                    <Grid item md={5} lg={3}>
                        <DetailContainer>
                            <ToDo 
                                disputeId={dispute.disputeId}
                                disputeClientKickoffEmailSent={dispute.disputeClientKickoffEmailSent}
                                disputeOpposingCounselEmailSent={dispute.disputeOpposingCounselEmailSent}
                                disputeClientEngagementEmailSent={dispute.disputeClientEngagementEmailSent}
                                disputeGamePlanCallScheduled={dispute.disputeGamePlanCallScheduled}
                                refetchCase={refetch}
                            />
                        </DetailContainer>
                    </Grid>
                )}
                <Grid item md={toDoOpen ? 7 : 12} lg={toDoOpen ? 9 : 12}>
                    <DetailContainer>
                        <IconButton onClick={() => setToDoOpen(!toDoOpen)}>
                            {toDoOpen 
                            ? <ChevronLeftIcon style={{ cursor: 'pointer' }}  /> 
                            : <ListIcon style={{ cursor: 'pointer' }} />}
                        </IconButton>
                        <CaseTabs
                            batesRecs={batesRecs}
                            refetchCase={refetch}
                            users={users}
                            dispute={dispute}
                        />
                    </DetailContainer>
                </Grid>
            </Grid>
        </>
    )
}


