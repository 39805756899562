import React, { useEffect, useState } from 'react'
import { 
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, 
  CircularProgress,
  Typography} from 'shared-components/material/core'

import { ErrorModal } from 'shared-components/modals'
import { CenteredContent } from 'shared-components/layout'
import validator from 'validator'
import { getCharges } from 'api/accounts'
import { useError } from 'shared-components/hooks'
import { currencyFormat } from 'shared-components/utils'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'

const GET_SUBSCRIPTION = gql`
    query Subscription {
        subscription {
          subscriptionAmount
          subscriptionAutoRenew
          subscriptionCreatedAt
          subscriptionExpirationDate
          subscriptionInterval
          subscriptionPlanName
          subscriptionStatus
        }
    }
`

type Charge = {
  id: string,
  customer: string,
  amount: number,
  created: Date,
  paymentMethodDetails: {
    card: {
      last4: string
    }
  },
}


export default function Billing() {
  const { 
    error: subscriptionError,
    loading: subscriptionLoading,
    data: subscriptionData
  } = useQuery(GET_SUBSCRIPTION)
  const [loading, setLoading] = useState(true)
  const [charges, setCharges] = useState([])
  const [ error, href, handleError, resetError] = useError()

  const subscription = subscriptionData ? subscriptionData.subscription : null

  useEffect(() => {
    const getChargesFn = async () => {
      const payload = await getCharges()
        .catch(e => handleError(e))

      if (payload.success) {
        setCharges(payload.data.charges)
        setLoading(false)
      } else {
        setLoading(false)
        handleError(payload.err, payload.href)
      }
    }
    getChargesFn()
  }, [])

  if (loading || subscriptionLoading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      }}
    >
      {subscription && (
        <>
          <Typography variant="h3">
            Subscription
          </Typography>
          <Typography sx={{ mb: 2, mt: 2, ml: 2}}>
            <b>Subscription plan:</b> {subscription.subscriptionPlanName} <br />
            <b>Subscription start:</b> {moment(subscription.subscriptionCreatedAt).format('MM/DD/YY')} <br />
            <b>Subscription expires:</b> {moment(subscription.subscriptionExpirationDate).format('MM/DD/YY')} <br />
            <b>Subscription cost:</b> {`${currencyFormat(subscription.subscriptionAmount)}/${subscription.subscriptionInterval === 1 ? 'monthly' : 'yearly'}`} <br />
            <b>AutoRenew:</b> {subscription.subscriptionAutoRenew ? 'on' : 'off'}
          </Typography>
        </>
      )} 
      <Typography variant="h3" sx={{ mb: 2}}>
        Payment History
      </Typography>
    {charges.length > 0 ? (
      <TableContainer component={Paper} sx={{ mb: '1em'}}>
        <Table aria-label="manage users">
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Card (last 4)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charges.map((c: Charge) => (
              <TableRow key={c.id}>
                <TableCell>{validator.unescape(c.customer)}</TableCell>
                <TableCell>{currencyFormat(c.amount)}</TableCell>
                <TableCell>{moment(c.created).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{c.paymentMethodDetails.card.last4}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      ) : <div /> }
      <ErrorModal
        error={(subscriptionError && subscriptionError.message) || error || ''} 
        href={href}
        resetError={resetError}
      />
    </Box>
  )
}