import React, { ReactNode } from 'react'
import { Box } from 'shared-components/material/core'

export default function RightAlignedForm({ children, width }: { children: ReactNode[] | ReactNode, width?: string }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: '100%',
            '& .MuiFormControl-root': {
                width: '100%',
                maxWidth: width || '315px',
                mb: 2 },
            '& .MuiAutocomplete-root': {
                width: '100%',
                maxWidth: width || '315px'},
            '& .MuiTypography-root': {
                width: '100%',
                maxWidth: width || '315px' },
            '& .MuiButton-root': {
                width: '100%',
                maxWidth: width || '315px',
                mb: 2 },
            '& .MuiRadio-root': {
                ml: 2 },
            }}
        >
            {children}
        </Box>
    )
}