import React, { useEffect, useState } from 'react'
import { BatesRec, Dispute, User } from 'generated/graphql'

import {
    Box,
    CircularProgress,
    Tab,
    Tabs,
} from 'shared-components/material/core'

import BatesRecsPanel from './BatesRecsPanel'
import EditCasePanel from './EditCasePanel'
import CaseUsers from './CaseUsers'
import JobsDataGrid from './JobsDataGrid/JobsDataGrid'
import Communications from './Communications'
import AddJob from './AddJob'
import SearchGenie from './SearchGenie/SearchGenie'
import ExhibitGenie from './ExhibitGenie/ExhibitGenie'
import { gql, useQuery } from '@apollo/client'
import { CenteredContent } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'
import Billing from './Billing'

const GET_USER = gql`
    query Query {
        user {
            userSearchEnabled
        }
    }
`

type CaseTabsData = {
    batesRecs: BatesRec[], 
    refetchCase: () => void,
    users: User[]
    dispute: Dispute,
}

type TabPanelProps = {
    children?: React.ReactNode,
    index: number,
    value: number,
}

export default function CaseTabs({ 
    batesRecs, 
    refetchCase,
    users,
    dispute,
}: CaseTabsData) {
    // disputeStage - 1: dg 2: eg 3: sg
    const { error: userError, loading: userLoading, data: userData } = useQuery(GET_USER);
    const [value, setValue] = useState(0)

    useEffect(() => {
        if (dispute.disputeStage) {
            setValue(dispute.disputeStage - 1)
        }
    }, [])

    const handleChange = (e: React.SyntheticEvent, newValue: number) => setValue(newValue)
    
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props
    
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`case-tabpanel-${index}`}
                aria-labelledby={`case-tab-${index}`}
                {...other}
            >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
            </div>
        )
    }
    
    const a11yProps = (index: number) => ({
        id: `case-tab-${index}`,
        'aria-controls': `case-tabpanel-${index}`,
    })

    const tabs = [
        {
            label: "Discovery Genie",
            tabPanel: dispute.jobs && dispute.jobs.length > 0 ? (
                <JobsDataGrid
                    disputeId={dispute.disputeId}
                    disputeBatesIntegrity={dispute.disputeBatesIntegrity}
                    refetchCase={refetchCase} 
                    batesRecs={batesRecs}
                />
            ) : (
                <AddJob
                    batesRecs={batesRecs}
                    disputeId={dispute && dispute.disputeId}
                    disputeBatesIntegrity={dispute.disputeBatesIntegrity}
                    refetchCase={refetchCase}
                />
            )
        },
        {
            label: "Exhibit Genie",
            tabPanel: <ExhibitGenie disputeId={dispute.disputeId} />,
        },
        {
            label: "Users",
            tabPanel: <CaseUsers users={users} disputeId={dispute.disputeId} refetchCase={refetchCase} />,
        },
        {
            label: "Communications",
            tabPanel: <Communications dispute={dispute} />,
        },
        {
            label: "Edit Case",
            tabPanel: <EditCasePanel dispute={dispute} refetchCase={refetchCase}/>,
        },
        {
            label: "Billing",
            tabPanel: <Billing disputeId={dispute.disputeId} />,
        },
        
    ]

    if (dispute.disputeBatesIntegrity) {
        tabs.push({
            label: "Bates Records",
            tabPanel: <BatesRecsPanel batesRecs={batesRecs} />, 
        })
    }

    if (userData && userData.user.userSearchEnabled) {
        tabs.splice(2, 0, {
            label: "Search Genie",
            tabPanel: <SearchGenie disputeId={dispute.disputeId}/>,
        },)
    }

    if (userLoading) {
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    }

    const renderTabColor = (label: string) => {
        if (label === "Exhibit Genie") {
            return { 
                color: '#398754',
                '&.Mui-selected': { color: '#398754', borderBottom: '2px solid #398754' }, 
                '&.Mui-focusVisible': { backgroundColor: '#398754'},
                '&.MuiTabs-indicatorSpan': { backgroundColor: '#398754'},
            } 
        }
        if (label === "Discovery Genie") {
            return { 
                    color: '#394587',
                    '&.Mui-selected': { borderBottom: '2px solid #394587' }, 
                }
        }
        return { 
            color: '#333',
            '&.Mui-selected': { color: '#333', borderBottom: '2px solid #333' }, 
            '&.Mui-focusVisible': { backgroundColor: '#333'},
            '&.MuiTabs-indicatorSpan': { backgroundColor: '#333'},
        } 
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    aria-label="case tabs"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    {tabs.map((tab, index) => 
                        <Tab 
                            key={tab.label} 
                            label={tab.label} 
                            {...a11yProps(index)} 
                            sx={renderTabColor(tab.label)}
                        />
                    )}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => 
                <TabPanel key={tab.label} value={value} index={index}>{tab.tabPanel}</TabPanel>)}
            <ErrorModal error={userError && userError.message || ''} />
        </Box>
    )
}
