import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import * as yup from "yup"
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from 'shared-components/inputs'
import { CenteredForm, DetailContainer, } from 'shared-components/layout'
import { Typography, Divider, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from 'shared-components/material/core'
import { HelpBotModal } from 'shared-components/modals'

const schema = yup.object({
    searchQuery: yup.string().required('Search query is required'),
}).required()

type FormData = {
    searchQuery: string,
}

type SearchFormProps = {
    handleSearchJob: ( 
        query: string, 
        searchType: string,
    ) => void 
}

export default function SearchForm({ handleSearchJob }: SearchFormProps) {
    const [searchType, setSearchType] = React.useState<string>('text')
    const [helpBotModalOpen, setHelpBotModalOpen] = useState(false)

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            searchQuery: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors } } = methods

    const handleSearchTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchType(e.target.value)
    }

    const handleHelpBotClick = () => setHelpBotModalOpen(true)

    const onSubmit = async (data: FormData) => {
        const query = data.searchQuery
        handleSearchJob(query, searchType)
    }

    return (
        <DetailContainer>
            <Typography variant='h3'>
                Search Genie
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
                AI powered document content search
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <FormProvider {...methods}>
                <CenteredForm width='100%'>
                <FormControl>
                        <FormLabel id="search-type-label">Search Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="search-type-label"
                            name="searchType"
                            value={searchType}
                            onChange={handleSearchTypeChange}
                        >
                            <FormControlLabel value={'text'} control={<Radio />} label="Text" />
                            <FormControlLabel value={'boolean'} control={<Radio />} label="Boolean" />
                            <FormControlLabel value={'ai'} control={<Radio />} label="AI" />
                        </RadioGroup>
                    </FormControl>
                    <TextInput
                        name='searchQuery'
                        label='Search Query'
                        required
                        error={errors.searchQuery !== undefined ? true : false}
                        errorMessage={errors.searchQuery ? errors.searchQuery.message : undefined}
                        
                    />
                    {searchType === 'boolean' && (
                        <Button onClick={handleHelpBotClick} variant="contained" color="primary">
                            Search Help Bot
                        </Button>
                    )}
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Search
                    </Button>
                </CenteredForm>
            </FormProvider>
            <HelpBotModal helpBotModalOpen={helpBotModalOpen} setHelpBotModalOpen={setHelpBotModalOpen}/>
        </ DetailContainer>
    )
}
