import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import {
  GridRowModel,
  GridRowId,
} from '@mui/x-data-grid-premium'
import { Checkbox, FormControlLabel } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import ErrorModal from './ErrorModal'

export const EDIT_USER = gql`
  mutation Mutation($userId: Int!, $userShowHoldWarning: Boolean) {
    updateUser(userId: $userId, userShowHoldWarning: $userShowHoldWarning) {
      userId
    }
  }
`

type CheckData = {
  id: GridRowId,
  row: GridRowModel,
  col: string,
  update: boolean
}

export default function HoldEmailWarningModal({ 
    openHoldWarning, 
    handleCheck, 
    holdCheckData,
    userId,
  }: {
    openHoldWarning: boolean, 
    handleCheck: ({id, row, col, update}: CheckData) => void,
    holdCheckData: CheckData | null,
    userId: number | null
  }) {
  const [open, setOpen] = useState(false)
  const [doNotWarn, setDoNotWarn] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER)

  useEffect(() => {
    setOpen(openHoldWarning)
  }, [openHoldWarning])

  const handleClose = () => setOpen(false)
  const handleMarkHold = async () => {
    const variables = { userShowHoldWarning: !doNotWarn, userId }
    await editUser({ variables })
    await handleCheck(holdCheckData!)
    await setOpen(false)
    await window.location.reload()
  }

  const handleDoNotWarnCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDoNotWarn(e.target.checked)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
          Warning
      </DialogTitle>
      <DialogContent>
          <DialogContentText>
            Marking an email <i>hold</i> will also mark its attachments <i>hold</i>. Existing priv, prod, and irrel classifications on attachments to this email will be overwritten. Notes, Research and Flags will be retained.
          </DialogContentText>
          <FormControlLabel control={<Checkbox onChange={handleDoNotWarnCheck} checked={doNotWarn} />} label="Do not show this warning again." />
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          {editUserLoading ? (
              <LoadingButton loading variant="outlined">
                  Loading
              </LoadingButton>
          ) : (
            <Button
              variant='contained'
              color='primary'
              onClick={handleMarkHold}
              autoFocus
            >
              Mark Hold
            </Button>
          )}
      </DialogActions>
      <ErrorModal error={editUserError && editUserError.message || ''} />
  </Dialog>
  )
}
