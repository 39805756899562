import React, { useState } from 'react'
import {
    Dialog,
    DialogTitle,
    Step,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import Success from './Success'
import Payment from './Payment'

type SubscriptionPayModalProps = { 
    open: boolean,
}

export default function SubscriptionPayModal({
    open,
}: SubscriptionPayModalProps) {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [activeStep, setActiveStep] = useState(0)

    const steps = ['Payment', 'Success']

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

    return (
        <Dialog open={open} fullWidth maxWidth={'md'} fullScreen={fullScreen}>
            <DialogTitle>Subscription Payment</DialogTitle>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {activeStep === 0 && 
                <Payment handleNext={handleNext} />
            }
            {activeStep === 1 && <Success />}
        </Dialog>
    )
}