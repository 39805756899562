import React from 'react'
import validator from 'validator'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
    GridRenderCellParams,
    GridCellParams,
    GridActionsCellItem,
    GridRenderEditCellParams,
    useGridApiContext,
    getGridStringOperators,
    GridFilterInputValueProps,
    GridRowModel,
    GridValueGetterParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid-premium'

import {
    CheckBoxIcon,
    CheckBoxOutlineBlankIcon,
    EditIcon,
    PageviewIcon
} from 'shared-components/material/icons'

import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material'

export function CustomAdmittedComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props
    const apiRef = useGridApiContext()
  
    const handleValueChange = (event: SelectChangeEvent) => {
      const newValue = event.target.value // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue })
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="admitted-label">Admitted</InputLabel>
            <Select
                labelId="admitted-label"
                id="admitted"
                value={value}
                label="Admitted"
                onChange={handleValueChange}
            >
              <MenuItem value={0}>-</MenuItem>
              <MenuItem value={1}>yes</MenuItem>
              <MenuItem value={2}>no</MenuItem>
            </Select>
        </FormControl>
    )
}

export function AdmittedInputValue(props: GridFilterInputValueProps) {
    const { item, applyValue} = props;
  
    const handleFilterChange = (event: SelectChangeEvent) => {
        const newValue = event.target.value
         applyValue({ ...item, value: newValue });
    };
  
    return (
        <FormControl fullWidth>
            <InputLabel id="admitted-label">Admitted</InputLabel>
            <Select
                labelId="admitted-label"
                id="admitted"
                value={item.value}
                label="Admitted"
                onChange={handleFilterChange}
            >
              <MenuItem value={0}>-</MenuItem>
              <MenuItem value={1}>yes</MenuItem>
              <MenuItem value={2}>no</MenuItem>
            </Select>
        </FormControl>
    );
  }

export function renderAdmitted(params: GridRenderCellParams) {
    const { exhibitAdmitted } = params.row
    switch(exhibitAdmitted) {
        case 1:
            return 'yes'
            break
        case 2:
            return 'no'
            break
        default: 
            return ''
    }

}

export const getColumns = (
    handlePreview: (id: number, resource: string, itemId: string, jobId: number) => void,
    handleToggleExhibitSuppressed: (exhibitId: number, exhibitSuppressed: boolean) => void,
    handleCopySingleDescription: (exhibitId: number) => void,
    handleOfferedCheck: (exhibitId: number, exhibitOffered: boolean) => void,
    // exSetAiAnalyzed: boolean
) => {
    const columns = [
        {
            field: 'order',
            headerName: 'Order',
            sortable: false,
            filterable: false,
            width: 50,
            renderCell: (params: GridCellParams) => {
                if (params.row.exhibitSuppressed) {
                    return null
                }
                return params.row.order
            }
        },
        {
            field: 'View',
            sortable: false,
            filterable: false,
            width: 50,
            disableExport: true,
            renderCell: (params: GridCellParams) => {
                let resource = ''
        let itemId = ''
        const { attId, docId, emlId, converted, id, jobId, recStatus } = params.row

        if (attId) {
            resource = 'att'
            itemId = attId
        } else if (docId){
            resource = 'doc'
            itemId = docId
        } else {
            resource = 'eml'
            itemId = emlId
        }

        const renderTitle = () => {
            if (recStatus === 0) {
                return "processing"
            }
            if (converted === true && recStatus === 10) {
                return 'Preview Document'
            }
            if (converted === false && recStatus === 10) {
                return 'Document not pdf convertible, cannot preview'
            }
            return 'Error converting document.'
        }

        const renderColor = () => {
            if (recStatus === 0) {
                return 'disabled'
            }
            if (converted === true && recStatus === 10) {
                return 'primary'
            }
            if (converted === false && recStatus === 10) {
                return 'error'
            }
            return 'error'
        }

        return (
            <Tooltip
                title={renderTitle()}
            >
                <div>
                    <GridActionsCellItem
                        icon={<PageviewIcon color={renderColor()} />}
                        label="View"
                        onClick={() => handlePreview(id, resource, itemId, jobId)}
                        key={id}
                        disabled={!converted}
                    />
                </div>
            </Tooltip>
        )
            }
        },
        {
            field: 'exhibitDateString',
            sortable: false, 
            headerName: 'Date',
            renderHeader: () => <span>Date <EditIcon sx={{ fontSize: '18px'}} /></span>,
            width: 170,
            editable: true,
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.exhibitAnalysisRequested && params.row.exhibitUseAiDate) {
                    return params.row.aiDate ? validator.unescape(params.row.aiDate) : ''
                }
                if (params.row.exhibitDateString) {
                    return validator.unescape(params.row.exhibitDateString)
                }
                return ''
            },
        },
        {
            field: 'exhibitDescription',
            sortable: false,
            headerName: 'Description',
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.aiDescription && params.row.exhibitUseAiDescription) {
                    return validator.unescape(params.row.aiDescription)
                }
                if (params.row.exhibitDescription) {
                    return validator.unescape(params.row.exhibitDescription)
                }
                return ''
            },
            renderHeader: () => <span>Description <EditIcon sx={{ fontSize: '18px'}} /></span>,
            editable: true,
            width: 250,
        },
        {
            field: 'copy',
            sortable: false,
            filterable: false,
            width: 75,
            renderHeader: () => <span>Copy</span>,
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => {
                const { exhibitId } = params.row
                return (
                    <IconButton
                        aria-label="Copy to Bates"
                        tabIndex={params.hasFocus ? 0 : -1}
                        color='primary'
                        onClick={() => handleCopySingleDescription(exhibitId)}
                    >
                        <ContentCopyIcon />
                        <ArrowRightAltIcon />
                    </IconButton>
                )
            }
        },
        {
            field: 'exhibitBates',
            sortable: false,
            headerName: 'Bates',
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.exhibitBates) {
                    return validator.unescape(params.row.exhibitBates)
                }
                if (params.row.aiBates && params.row.exhibitUseAiBates) {
                    return validator.unescape(params.row.aiBates)
                }
                return ''
            },
            renderHeader: () => <span>Bates <EditIcon sx={{ fontSize: '18px'}} /></span>,
            editable: true,
            width: 250,
        },
        {
            field: 'exhibitNotes',
            headerName: 'Lawyer Notes',
            sortable: false,
            renderHeader: () => <span>Lawyer Notes <EditIcon sx={{ fontSize: '18px'}} /></span>,
            editable: true,
            width: 250,
            valueGetter: (params: GridValueGetterParams) => params.row.exhibitNotes ? validator.unescape(params.row.exhibitNotes) : '',
        },
        {
            field: 'exhibitWitness',
            headerName: 'Witness',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => params.row.exhibitWitness ? validator.unescape(params.row.exhibitWitness) : '',
            renderHeader: () => <span>Witness <EditIcon sx={{ fontSize: '18px'}} /></span>,
            editable: true,
            width: 250,
        },
        {
            field: 'suppress',
            sortable: false,
            filterable: false,
            width: 200,
            disableExport: true,
            renderHeader: () => <span>Suppress/Activate</span>,
            renderCell: (params: GridRenderCellParams) => {
                const { exhibitId, exhibitSuppressed } = params.row
                return (
                    <Button
                        variant="contained"
                        size="small"
                        tabIndex={params.hasFocus ? 0 : -1}
                        color={exhibitSuppressed ? 'primary' : 'error'}
                        onClick={() => handleToggleExhibitSuppressed(exhibitId, !exhibitSuppressed)}
                    >
                        {exhibitSuppressed ? 'activate' : 'suppress'}
                    </Button>
                )
            }
        },
        {
            field: 'exhibitOffered',
            headerName: 'Offered',
            renderHeader: () => <span>Offered <EditIcon sx={{ fontSize: '18px'}} /></span>,
            type: 'actions',
            editable: true,
            disableExport: true,
            valueFormatter: (params: GridValueFormatterParams) => params.value,
            getActions: ({row} : {row: GridRowModel}) => {
                if (row.exhibitOffered) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color='primary' />}
                            onClick={() => handleOfferedCheck(row.exhibitId, false)}
                            key={row.exhibitId}
                            label="offered"
                        />
                    ]
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        onClick={() => handleOfferedCheck(row.exhibitId, true)}
                        key={row.exhibitId}
                        label="offered"
                    />
                ]
            },
        },
        {
            field: 'offered',
            headerName: 'Offered',
            disableExport: false,
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.exhibitOffered) {
                    return 'x'
                } else {
                    return ''
                }
            },
        },
        {
            field: 'exhibitAdmitted',
            headerName: 'Admitted',
            renderHeader: () => <span>Admitted <EditIcon sx={{ fontSize: '18px'}} /></span>,
            renderCell: renderAdmitted,
            renderEditCell: (params: GridRenderEditCellParams) => <CustomAdmittedComponent {...params} />,
            editable: true,
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.exhibitAdmitted === 1) {
                    return 'yes'
                }
                if (params.row.exhibitAdmitted === 2) {
                    return 'no'
                }
                return ''
            },
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === 'equals')
                .map((operator => ({
                    ...operator,
                    InputComponent: operator.InputComponent
                        ? AdmittedInputValue
                        : undefined,
                }))),
        },
    ]

    // if (exSetAiAnalyzed) {
    //     columns.splice(3,0, {
    //         field: 'aiDate',
    //         sortable: false,
    //         headerName: 'AI Date',
    //         valueGetter: (params: GridValueGetterParams) => params.row.aiDate ? validator.unescape(params.row.aiDate) : '',
    //         renderHeader: () => <span>AI Date</span>,
    //         editable: false,
    //         width: 250,
    //     })
    //     columns.splice(5,0, {
    //         field: 'aiDescription',
    //         sortable: false,
    //         headerName: 'AI Description',
    //         valueGetter: (params: GridValueGetterParams) => params.row.aiDescription ? validator.unescape(params.row.aiDescription) : '',
    //         renderHeader: () => <span>AI Bates</span>,
    //         editable: false,
    //         width: 250,
    //     })
    //     columns.splice(8,0, {
    //         field: 'aiBates',
    //         sortable: false,
    //         headerName: 'AI Bates',
    //         valueGetter: (params: GridValueGetterParams) => params.row.aiBates ? validator.unescape(params.row.aiBates) : '',
    //         renderHeader: () => <span>AI Bates</span>,
    //         editable: false,
    //         width: 250,
    //     })
        
    // }
    
    return columns
}