import React, { Dispatch, SetStateAction, useState, useEffect, useCallback } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
    CircularProgress,
    styled,
    AccordionDetails,
    Typography,
    Autocomplete,
    TextField,
    LoadingButton,
} from 'shared-components/material/core'
import {
    TextInput,
    SelectInput,
    DateInput,
    CheckboxInput,
} from 'shared-components/inputs'
import AddClientModal from './AddClientModal'
import ErrorModal from 'shared-components/modals/ErrorModal'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ExpandMore } from '@mui/icons-material';
import { getClioMattersSearch } from 'api/clio';
import { useError } from 'shared-components/hooks';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    border: 'none',
    '&:before': {
      display: 'none',
    },
    width: '100%',
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

export const GET_DATA = gql`
    query clients {
        clients {
            clientId
            clientAlphaId
            clientName
        }
        user {
            userClioSetupComplete
        }
    }
`

export const ADD_DISPUTE = gql`
  mutation AddDispute ($batesRecPrefix: String!, 
    $batesRecNextBatesStr: String!, 
    $batesRecNextPrivStr: String!, 
    $disputeBatesIntegrity: Boolean, 
    $disputeProductionDeadline: DateTime, 
    $disputeClientId: Int, 
    $disputeMatter: String, 
    $disputeName: String,
    $disputeClioMatterId: BigInt,
    ) {
        addDispute(
            batesRecPrefix: $batesRecPrefix, 
            batesRecNextBatesStr: $batesRecNextBatesStr, 
            batesRecNextPrivStr: $batesRecNextPrivStr, 
            disputeBatesIntegrity: $disputeBatesIntegrity, 
            disputeProductionDeadline: $disputeProductionDeadline, 
            disputeClientId: $disputeClientId, 
            disputeMatter: $disputeMatter, 
            disputeName: $disputeName,
            disputeClioMatterId: $disputeClioMatterId
        ) {
            disputeId
        }
    }
`

type FormData = {
    disputeClioMatterId?: number,
    disputeName: string,
    disputeProductionDeadline: Date | null,
    disputeClientId: number | null,
    disputeMatter: string,
    bates?: string,
    batesRecPrefix: string,
    batesRecNextBatesStr: string,
    batesRecNextPrivStr: string,
    disputeBatesIntegrity: boolean,
}

type AddCaseModalProps = {
    openAddCase: boolean,
    setOpenAddCase: Dispatch<SetStateAction<boolean>>,
    getDisputesFc: () => Promise<void>,
}

const batesOptions = [
    { label: 'Defendant', value: '0' },
    { label: 'Plaintiff', value: '1' },
    { label: 'Custom', value: '2' },
]

type clientOption = {
    label: string,
    value: string,
    clientAlphaId: string,
}

type Matter = {
    id: number,
    description: string,
    display_number: string,
    client: {
        name: string
    }
}

export default function AddCaseModal({ openAddCase, setOpenAddCase, getDisputesFc }: AddCaseModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { loading: dataLoading, error: dataError, data, refetch: refetchData } = useQuery(GET_DATA);
    const [addDispute, { loading: disputeLoading, error: disputeError }] = useMutation(ADD_DISPUTE)
    const [openAddClient, setOpenAddClient] = useState(false)
    const [matters, setMatters] = useState<readonly Matter[]>([])
    const [disputeClioMatterId, setDisputeClioMatterId] = useState(0)
    const [clioMatter, setClioMatter] = useState<Matter>()
    const [open, setOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const [error, href, handleError, resetError] = useError()
    const loading = open && (matters && matters.length === 0)
    const [newClient, setNewClient] = useState<string>('')

    const schema = yup.object({
        disputeName: yup.string().required('Case name is required'),
        disputeProductionDeadline: yup.date().min(new Date().toDateString(), "Production deadline must be today's date or later").required(),
        disputeClientId: yup.number().when([], {
            is: () => !disputeClioMatterId,
            then: yup
                .number().integer().positive().required('Client is Required'),
            otherwise: yup
                .number().notRequired(),
        }),
        disputeMatter: yup.string().required('Matter is required'),
        bates: yup.string(),
        batesRecPrefix: yup.string(),
        batesRecNextBatesStr: yup.string(),
        batesRecNextPrivStr: yup.string(),
        disputeBatesIntegrity: yup.boolean().required(),
    }).required()

    let errorMessage = ''

    if (error) {
        errorMessage = error
    }
    if (dataError) {
        errorMessage = dataError.message
    }
    if (disputeError) {
        errorMessage = disputeError.message
    }

    useEffect(() => {
        if (!open) {
          setMatters([])
        }
      }, [open])

    const debounce = (func: (userClioSetupComplete: boolean, searchQuery: string, callback: (filteredOptions: Matter[]) => void) => void, wait = 0) => {
        let timeoutID: NodeJS.Timeout
        return (userClioSetupComplete: boolean, searchQuery: string, callback: (filteredOptions: Matter[]) => void) => {
          clearTimeout(timeoutID)
      
          timeoutID = setTimeout(function () {
            func(userClioSetupComplete, searchQuery, callback)
          }, wait)
        }
    }

    const getClioMattersSearchFn = async (userClioSetupComplete: boolean, searchQuery: string) => {
        if (userClioSetupComplete) {
            const payload = await getClioMattersSearch(searchQuery)
                .catch(e => handleError(e))
            if (payload.success) {
                return payload.data.matters
            } else {
                return handleError(payload.err, payload.href)
            }
        }
        return []
    }

    const getClioMattersSearchDelayed = useCallback(
        debounce((userClioSetupComplete, searchQuery, callback) => {

            setMatters([])
            getClioMattersSearchFn(userClioSetupComplete, searchQuery).then(callback)
        }, 1000), [] //use debounce fn to delay getClioMatters 1sec to throttle api calls
    )

    useEffect(() => {
        const callback = (filteredMatters: Matter[]) => {
            setMatters(filteredMatters)
        }
        getClioMattersSearchDelayed(data && data.user.userClioSetupComplete as boolean, searchQuery, callback) //Invoke getClioMatters with delay on input change
    }, [data, searchQuery, getClioMattersSearchDelayed])

    let clientOptions: clientOption[]

    if (data) {
        clientOptions = data.clients.map(
            ({ clientId, clientAlphaId, clientName }: { clientId: number, clientAlphaId: string, clientName: string}) => {
                return { 
                    value: clientId, 
                    label: `${clientName} - ${clientAlphaId}`,
                    clientAlphaId: clientAlphaId,
                }
            })
    }

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            disputeName: '',
            disputeProductionDeadline: new Date(Date.now()),
            disputeClientId: 0,
            disputeMatter: '',
            bates: '',
            batesRecPrefix: '',
            batesRecNextBatesStr: '00001',
            batesRecNextPrivStr: '00001',
            disputeBatesIntegrity: false,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, reset, setValue, formState: { errors } } = methods
    const batesNumbering = watch('bates')
    const disputeBatesIntegrity = watch('disputeBatesIntegrity')
    const batesPrefix = watch('batesRecPrefix')

    useEffect(() => {
        const option = clientOptions ? clientOptions.filter((client) => client.clientAlphaId === newClient)[0] : null
        if (option) {
            setValue('disputeClientId', parseInt(option.value))
        }
    }, [newClient, data])

    useEffect(() => {
        if (clioMatter) {
            setValue('disputeName', clioMatter.description)
            setValue('disputeMatter', clioMatter.display_number)
            setValue('disputeClientId', 0)
        }
    }, [clioMatter])
    
    useEffect(() => {
        if (batesNumbering === '0') {
            setValue('batesRecPrefix', 'Deft')
        } else if (batesNumbering === '1') {
            setValue('batesRecPrefix', 'Plait')
        } else {
            setValue('batesRecPrefix', '')
        }
    }, [batesNumbering])
    
    const onSubmit = async (formData: FormData) => {
        const nextBates = parseInt(formData.batesRecNextBatesStr)
        const nextPriv = parseInt(formData.batesRecNextPrivStr)

        if (nextBates && nextPriv) {
            let variables = formData
            delete variables.bates
            variables = {...variables, disputeClioMatterId}

            await addDispute({ variables })
            getDisputesFc()
            setOpenAddCase(false)
        } else {
            handleError('Please enter a number for Next Bates Number and Next Privilege Number')
        }

        
    }



    const renderCreateCase = () => (
        <>
            {data && data.user.userClioSetupComplete ? (
                <>
                    <DialogContentText>
                        Create a case from a Clio matter:
                    </DialogContentText>
                        <Autocomplete
                            id="clio-matters"
                            sx={{ maxWidth: '527px'}}
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            isOptionEqualToValue={(matter, value) => matter.id === value.id}
                            getOptionLabel={(matter) => matter.description}
                            options={matters}
                            loading={loading}
                            filterOptions={(x) => x}
                            onInputChange={(e, newInputValue) => setSearchQuery(newInputValue)}
                            onChange={(event, value ) => {
                                if (value) {
                                    setDisputeClioMatterId(value.id)
                                    setClioMatter(value)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Clio Matter"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    <DialogContentText>
                        or create a new case:
                    </DialogContentText>
                </>
            ) : null}
            <TextInput
                name='disputeName'
                label='Case Name'
                required
                error={errors.disputeName !== undefined ? true : false}
                errorMessage={errors.disputeName ? errors.disputeName.message : undefined}
                autoFocus
                disabled={disputeClioMatterId !== 0}
            />
            {clioMatter ? (
                <TextField 
                    id="clio-client" 
                    label="Client" 
                    variant="outlined" 
                    value={clioMatter && clioMatter.client.name}
                />
            ) : (
                <Box sx={{ display: 'flex' }}>
                    {clientOptions && clientOptions.length > 0 ? <SelectInput
                        name="disputeClientId"
                        label="Client"
                        options={clientOptions}
                        required
                        error={errors.disputeClientId !== undefined ? true : false}
                        errorMessage={errors.disputeClientId ? errors.disputeClientId.message : undefined}
                        sx={{ width: '100%' }}
                    /> : null}
                    <Button
                        onClick={() => setOpenAddClient(true)}
                        variant="outlined"
                        sx={clientOptions && clientOptions.length === 0 
                            ? { width: '100%' } 
                            : { height: '40px', minWidth: '115px' }
                        }
                    >
                        Add Client
                    </Button>
                </Box>
            )}
            <AddClientModal
                openAddClient={openAddClient}
                setOpenAddClient={setOpenAddClient}
                refetch={refetchData}
                setNewClient={(clientAlphaId: string) => setNewClient(clientAlphaId)}
            />
            <TextInput
                name='disputeMatter'
                label='Matter'
                required
                error={errors.disputeMatter !== undefined ? true : false}
                errorMessage={errors.disputeMatter ? errors.disputeMatter.message : undefined}
                disabled={disputeClioMatterId !== 0}
            />
            <DateInput
                name='disputeProductionDeadline'
                label='Production Deadline'
                error={errors.disputeProductionDeadline !== undefined ? true : false}
                errorMessage={errors.disputeProductionDeadline 
                    ? errors.disputeProductionDeadline.message 
                    : undefined
                }
            />
        </>
    )

    const renderBatesOptions = () => (
        <>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="advanced-options"
                id="advanced-options"
                >
                    <Typography>Advanced Options</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '1em' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 1,
                    }}>
                        <CheckboxInput
                            name={"disputeBatesIntegrity"}
                            label="Automate Bates Labeling"
                            required
                            error={errors.disputeBatesIntegrity != undefined ? true : false }
                            errorMessage={errors.disputeBatesIntegrity ? errors.disputeBatesIntegrity.message : undefined}
                            sx={{ '& .MuiTypography-root, .MuiCheckbox-root, .MuiFormControl-root': { m: 0, padding: '0 .5em 0 .5em' }}}
                            tooltip='Discovery Genie will automatically keep track of Bates numbers for you and ensure that they remain in sequence across document sets. You can still use multiple prefixes in a single case as necessary.'
                        />
                        {disputeBatesIntegrity ? (
                            <>
                                <SelectInput
                                    name="bates"
                                    label="Bates Numbering"
                                    options={batesOptions}
                                    required
                                    error={errors.bates !== undefined ? true : false}
                                    errorMessage={errors.bates ? errors.bates.message : undefined}
                                />
                                {batesNumbering === '' ? null : (
                                    <TextInput
                                        name='batesRecPrefix'
                                        label='Bates Prefix'
                                        required
                                        error={errors.batesRecPrefix !== undefined ? true : false}
                                        errorMessage={errors.batesRecPrefix ? errors.batesRecPrefix.message : undefined}
                                        disabled={batesNumbering === '0' || batesNumbering === '1'}
                                    />
                                )}
                                <TextInput
                                    name='batesRecNextBatesStr'
                                    label='Next Bates Number'
                                    error={errors.batesRecNextBatesStr !== undefined ? true : false}
                                    errorMessage={errors.batesRecNextBatesStr ? errors.batesRecNextBatesStr.message : undefined}
                                />
                                <TextInput
                                    name='batesRecNextPrivStr'
                                    label='Next Privilege Number'
                                    error={errors.batesRecNextPrivStr !== undefined ? true : false}
                                    errorMessage={errors.batesRecNextPrivStr ? errors.batesRecNextPrivStr.message : undefined}
                                />
                            </>
                        ) : null}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )

    return (
        <FormProvider {...methods} >
            <Dialog 
                open={openAddCase} 
                onClose={() => {
                    reset()
                    setClioMatter(undefined)
                    setOpenAddCase(false)
                }} 
                fullScreen={fullScreen}>
                <DialogTitle>Add Case</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 1,
                        '& .MuiButton-root': { m: 1 },
                        '& .MuiTypography-root': { m: 1, minWidth: '450px' },
                        '& .MuiLink-root': { m: 0 },
                        '& .MuiFormControl-root': { m: 1 },
                        '& .MuiCheckbox-root': { m: 1 },
                    }}>
                        {renderCreateCase()}
                        {renderBatesOptions()}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pt: 2,
                        width: '100%'
                    }}>
                        <Button 
                            onClick={() => {
                                reset()
                                setClioMatter(undefined)
                                setOpenAddCase(false)
                            }} 
                            color="inherit">Cancel
                        </Button>
                        {dataLoading || disputeLoading ? (
                            <LoadingButton loading variant="outlined">
                                Add Case
                            </LoadingButton>
                        ) : ( 
                            <Button 
                                onClick={handleSubmit(onSubmit)} 
                                variant="contained"
                                disabled={disputeBatesIntegrity 
                                    && (batesNumbering.length === 0 || batesPrefix.length === 0)}
                            >
                                Add Case
                            </Button>
                        )}
                        
                    </Box>
                </DialogActions>
                <ErrorModal error={errorMessage} href={href} resetError={resetError} />
            </Dialog>
        </FormProvider>
    )
}
