import React, { Dispatch, SetStateAction }  from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import ResendStepper from './ResendStepper/ResendStepper'

type ResendModalProps = {
    openResendModal: boolean,
    setOpenResendModal: Dispatch<SetStateAction<boolean>>,
    jobId: number,
    refetchCase: () => void,
    jobClientInstructions: string,
    jobClientEmail: string,
}

export default function ResendModal({ 
    openResendModal,
    setOpenResendModal,
    jobId,
    refetchCase,
    jobClientInstructions,
    jobClientEmail,
}: ResendModalProps) {
  return (
    <Dialog open={openResendModal} onClose={() => setOpenResendModal(false)}>
        <DialogTitle>Resend Email</DialogTitle>
        <DialogContent>
            <ResendStepper
                jobId={jobId}
                refetchCase={refetchCase}
                setOpenResendModal={setOpenResendModal}
                clientInstructions={jobClientInstructions}
                clientEmail={jobClientEmail}
            />
        </DialogContent>
    </Dialog>
  )
}
