import React, { Dispatch, SetStateAction, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    Typography,
    AccordionDetails,
    styled,
} from 'shared-components/material/core'
import {
    TextInput,
    SelectInput,
} from 'shared-components/inputs'
import AddBatesModal from './AddBatesModal'
import ErrorModal from 'shared-components/modals/ErrorModal'
import { BatesRec } from 'generated/graphql';
import { ExpandMore } from 'shared-components/material/icons';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    border: 'none',
    '&:before': {
      display: 'none',
    },
    width: '100%',
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

export const ADD_JOB = gql`
  mutation AddJob(
        $jobName: String!,
        $jobBatesRecId: Int!,
        $jobBypassBates: Boolean!,
        $jobDisputeId: Int,
        $jobCustomConfidential: String
    ) {
    addJob(
        jobName: $jobName, 
        jobBatesRecId: $jobBatesRecId, 
        jobBypassBates: $jobBypassBates, 
        jobDisputeId: $jobDisputeId,
        jobCustomConfidential: $jobCustomConfidential
    ) {
      jobId
    }
  }
`

const schema = yup.object({
    jobName: yup.string().required('Document set name required'),
    jobBatesRecId: yup.number().required('Bates prefix required').integer(),  
    jobCustomConfidential: yup.string(),
}).required()

type AddJobModalProps = {
    openAddJob: boolean,
    setOpenAddJob: Dispatch<SetStateAction<boolean>>,
    batesRecs: BatesRec[]
    disputeId: number,
    refetchJobs: () => void,
    refetchCase: () => void,
    disputeBatesIntegrity: boolean,
}

type FormData = {
    jobName: string,
    jobBatesRecId: number,
    jobCustomConfidential: string,
}

type Variables = FormData & { jobDisputeId: number}

export default function AddJobModal({ openAddJob, setOpenAddJob, batesRecs, refetchJobs, refetchCase, disputeId, disputeBatesIntegrity }: AddJobModalProps) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [addJob, { error }] = useMutation(ADD_JOB)
    const errorMsg = error ? error.message : ''
    const [openAddBates, setOpenAddBates] = useState(false)
    const batesOptions = batesRecs.map(batesRec => {
        return {
            label: `Prefix: ${batesRec.batesRecPrefix} - Next bates: ${batesRec.batesRecNextBatesStr} - Next Priv: ${batesRec.batesRecNextPrivStr}`,
            value: batesRec.batesRecId
        }
    })

    batesOptions.push({ 
        label: 'No Bates numbers',
        value: 0
    })

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            jobName: '', 
            jobBatesRecId: batesRecs.length === 1 ? batesRecs[0].batesRecId : 0, 
            jobCustomConfidential: 'Confidential',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors } } = methods

    const onSubmit = async (formData: FormData) => {
        if (disputeId) {
            let variables: Variables = Object.assign(formData, {jobDisputeId: disputeId})
            if (variables.jobBatesRecId === 0) {
                variables = Object.assign(variables, { jobBypassBates: true })
            } else {
                variables = Object.assign(variables, { jobBypassBates: false })
            }
            await addJob({ variables })
            refetchJobs()
            setOpenAddJob(false)
        } else {
            throw new Error("Case does not exist")
        }
    }

    if (errorMsg.length > 0) {
        return <ErrorModal error={errorMsg} />
    }

    return (
        <FormProvider {...methods} >
                <Dialog open={openAddJob} onClose={() => setOpenAddJob(false)} maxWidth={'sm'} fullScreen={fullScreen}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Add Document Set</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, maxWidth: "450px" }}>
                            <TextInput
                                name='jobName'
                                label='Document Set Name'
                                required
                                error={errors.jobName !== undefined ? true : false}
                                errorMessage={errors.jobName ? errors.jobName.message : undefined}
                                sx={{ mb: 2 }}
                                autoFocus
                                fullWidth
                            />
                            {disputeBatesIntegrity && (
                                <Box sx={{ display: 'flex', mt: 2, mb: 2}}>
                                    <SelectInput
                                        name="jobBatesRecId"
                                        label="Bates Prefix"
                                        options={batesOptions}
                                        required
                                        error={errors.jobBatesRecId !== undefined ? true : false}
                                        errorMessage={errors.jobBatesRecId ? errors.jobBatesRecId.message : undefined}
                                        fullWidth
                                    />
                                    <Button
                                        onClick={() => setOpenAddBates(true)}
                                        variant="outlined"
                                        sx={{ height: '40px', ml: 1 }}
                                    >
                                        Add Prefix
                                    </Button>
                                </Box>
                            )}
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="advanced-options"
                                id="advanced-options"
                                >
                                    <Typography>Advanced Options</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingTop: '2em' }}>
                                    <TextInput
                                        name='jobCustomConfidential'
                                        label='Custom Stamp'
                                        required
                                        error={errors.jobCustomConfidential !== undefined ? true : false}
                                        errorMessage={errors.jobCustomConfidential ? errors.jobCustomConfidential.message : undefined}
                                        helperText="This stamp will only be applied to documents checked 'STMP' during review."
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            pt: 2,
                            width: '100%'
                        }}>
                            <Button onClick={() => setOpenAddJob(false)} color="inherit">Cancel</Button>
                            <div>
                            <Button type="submit" variant="contained">Add</Button>
                            </div>
                        </Box>
                    </DialogActions>
                    </form>
                </Dialog>
                <AddBatesModal
                    openAddBates={openAddBates}
                    setOpenAddBates={setOpenAddBates}
                    refetch={refetchCase}
                    batesRecDisputeId={disputeId ? disputeId : 0}
                />
            </FormProvider>
    )
}
