import React, { ReactElement } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { FormControl, FormControlLabel, FormHelperText, Switch } from "shared-components/material/core"

type SwitchProps = {
  name: string,
  label: string | ReactElement,
  required?: boolean,
  error?: boolean,
  errorMessage?: string,
}

function SwitchInput({ name, label, required=false, error=false, errorMessage }: SwitchProps) {
  const { control } = useFormContext()
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl error={error} required={required}>
            <FormControlLabel
              control={
                <Switch 
                  name={name}
                  onChange={onChange}
                  checked={value} 
                  inputProps={{ 'aria-label': name }}
                />
              }
            label={label}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>
      )}
      />
    </>
  )
}

export default SwitchInput