import React, { ReactElement } from 'react'
import { useFormContext, Controller } from "react-hook-form"
import { 
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    FormHelperText } from 'shared-components/material/core'

type RadioProps = {
    name: string,
    label?: string | ReactElement,
    required?: boolean,
    error?: boolean,
    errorMessage?: string,
    options: { label: string, value: string | number | boolean, disabled?: boolean }[],
    disabled?: boolean,
    defaultValue?: string | number | boolean,
    value?: string | number | boolean
  }

function RadioInput ({ 
    label,
    name,
    options,
    error=false,
    errorMessage,
    required=false,
    disabled=false,
    defaultValue,
    value
}: RadioProps) {
    const { control } = useFormContext()
    return <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => (
            <FormControl
                disabled={disabled}
                required={required}
                error={error}
            >
                {label ? <FormLabel id={name} sx={{ margin: '1rem 0' }}>{label}</FormLabel> : null}
                <RadioGroup
                    aria-labelledby={name}
                    defaultValue={defaultValue}
                    name={name}
                    onChange={onChange}
                    value={value}
                >
                    {options.map(option => 
                        <FormControlLabel
                            key={option.label}
                            value={option.value}
                            label={option.label}
                            disabled={option.disabled}
                            control={<Radio size='small' />} 
                        />
                    )}
                </RadioGroup>
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        )}
    />
}

export default RadioInput