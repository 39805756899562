import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { TextInput } from 'shared-components/inputs'
import { Typography, Divider, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from 'shared-components/material/core'
import { FormControlLabel, FormLabel, Radio, RadioGroup, SelectChangeEvent } from '@mui/material'
import { RightAlignedForm } from 'shared-components/layout'
import { useNavigate } from 'react-router-dom'
import { HelpBotModal } from 'shared-components/modals'

const schema = yup.object({
    searchQuery: yup.string().required('Search query is required'),
}).required()

type FormData = {
    searchQuery: string,
}

const jobStatusOptions = [
    'REVIEW',
    'DOWNLOAD',
]

export default function SearchGenie({ disputeId }: { disputeId: number }) {
    const navigate = useNavigate()
    const [jobStatuses, setJobStatuses] = useState<string[]>([])
    const [searchType, setSearchType] = useState<string>('text')
    const [helpBotModalOpen, setHelpBotModalOpen] = useState(false)

    const handleJobStatusesChange = (e: SelectChangeEvent<typeof jobStatuses>) => {
        const {target: { value }} = e;
        setJobStatuses(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      const handleSearchTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchType(e.target.value)
    }

    const handleHelpBotClick = () => setHelpBotModalOpen(true)

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            searchQuery: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors } } = methods

    const onSubmit = async (data: FormData) => {
        const { searchQuery } = data
        navigate(`/app/cases/${disputeId}/case-search`, { state: {searchQuery, jobStatuses, searchType} })
    }

    return (
        <>
            <Typography variant='h3'>
                Search Genie
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
                AI powered document content search
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <FormProvider {...methods}>
                <RightAlignedForm>
                <FormControl>
                        <FormLabel id="search-type-label">Search Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="search-type-label"
                            name="searchType"
                            value={searchType}
                            onChange={handleSearchTypeChange}
                        >
                            <FormControlLabel value={'text'} control={<Radio />} label="Text" />
                            <FormControlLabel value={'boolean'} control={<Radio />} label="Boolean" />
                            <FormControlLabel value={'ai'} control={<Radio />} label="AI" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="jobStatuses-label">Select Job Statuses</InputLabel>
                        <Select
                            labelId="jobStatuses-label"
                            id="jobStatuses"
                            multiple
                            value={jobStatuses}
                            onChange={handleJobStatusesChange}
                            input={<OutlinedInput label="Select Job Statuses" />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                        {jobStatusOptions.map((status) => (
                            <MenuItem key={status} value={status}>
                                <Checkbox checked={jobStatuses.indexOf(status) > -1} />
                                <ListItemText primary={status} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <TextInput
                        name='searchQuery'
                        label='Search Query'
                        required
                        error={errors.searchQuery !== undefined ? true : false}
                        errorMessage={errors.searchQuery ? errors.searchQuery.message : undefined}
                        
                    />
                    {searchType === 'boolean' && (
                        <Button onClick={handleHelpBotClick} variant="contained" color="primary">
                            Search Help Bot
                        </Button>
                    )}
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Search
                    </Button>
                </RightAlignedForm>
            </FormProvider>
            <HelpBotModal helpBotModalOpen={helpBotModalOpen} setHelpBotModalOpen={setHelpBotModalOpen}/>
        </>
    )
}
