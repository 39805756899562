import React, { useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import {
  Typography,
  Button,
  CircularProgress,
  LoadingButton,
  Snackbar,
  MuiAlert,
  AlertProps
} from 'shared-components/material/core'
import { CenteredContent, RightAlignedForm } from 'shared-components/layout/index'
import { RadioInput } from 'shared-components/inputs'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorModal } from 'shared-components/modals'

export const GET_FIRM = gql`
  query firm {
    firm {
      firmSessionTimeoutMins
      firmJobExpirationDays
    }
  }
`

export const EDIT_FIRM = gql`
  mutation UpdateFirm(
    $firmSessionTimeoutMins: Int,
    $firmJobExpirationDays: Int) {
      updateFirm(
        firmSessionTimeoutMins: $firmSessionTimeoutMins, 
        firmJobExpirationDays: $firmJobExpirationDays
      ) {
        firmId
      }
    }
`

const schema = yup.object({
  firmSessionTimeoutMins: yup.number(),
  firmJobExpirationDays: yup.number(),
}).required()

type FormData = {
  firmSessionTimeoutMins: number;
  firmJobExpirationDays: number;
}

const firmSessionTimeoutMins = [
  { label: '15 minutes', value: 15 },
  { label: '1 hour', value: 60 },
  { label: '24 hours', value: 1440 },
]

const firmJobExpirationDaysOptions = [
  { label: '7 days', value: 7 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 },
  { label: '6 months', value: 180 },
  { label: '1 year', value: 365 },
]

export default function Security() {
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const { data, loading, error } = useQuery(GET_FIRM)
  const [editFirm, { loading: editFirmLoading, error: editFirmError }] = useMutation(EDIT_FIRM, {
    onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
  })
  const firm = data ? data.firm : {}

  const methods = useForm({
    defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
        firmSessionTimeoutMins: 0,
        firmJobExpirationDays: 0
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, watch, setValue, formState: { errors }} = methods

  useEffect(() => {
    setValue('firmSessionTimeoutMins', firm.firmSessionTimeoutMins || 0)
    setValue('firmJobExpirationDays', firm.firmJobExpirationDays || 0)
  }, [firm])

  const onSubmit = (formData: FormData) => {
    editFirm({ variables: formData })
  }

  const handleCloseSnackbar = () => setSnackbar(null)

  if (loading) {
    <CenteredContent>
      <CircularProgress />
    </CenteredContent>
  }

  return (
    <FormProvider {...methods}>
      <RightAlignedForm width={'360px'}>
          <Typography variant='h3'>
            Session Timeout
          </Typography>
          <Typography variant='body1'>
            Logs a user out after a period of inactivity. 
            This is important for securing access to your firm’s data. 
            Changes will be applied to new sessions only.
          </Typography>
          <RadioInput
            name="firmSessionTimeoutMins"
            label=""
            error={errors.firmSessionTimeoutMins !== undefined ? true : false }
            errorMessage={errors.firmSessionTimeoutMins 
              ? errors.firmSessionTimeoutMins.message 
              : undefined}
            options={firmSessionTimeoutMins}
            value={watch('firmSessionTimeoutMins')}
          />
          <Typography variant='h3'>
            Data Retention Period
          </Typography>
          <Typography variant='body1'>
            Length of time that cases and their corresponding document sets should be stored 
            after the case is closed. Data is deleted after this period.
          </Typography>
          <RadioInput
            name="firmJobExpirationDays"
            label=""
            error={errors.firmJobExpirationDays !== undefined ? true : false }
            errorMessage={errors.firmJobExpirationDays 
              ? errors.firmJobExpirationDays.message 
              : undefined}
            options={firmJobExpirationDaysOptions}
            value={watch('firmJobExpirationDays')}
          />
          {editFirmLoading ? (
            <LoadingButton loading variant="outlined">
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmit(onSubmit)}
            >
                Save
            </Button>
          )}
          
      </RightAlignedForm>
      {!!snackbar && (
          <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
          >
              <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
      )}
      <ErrorModal error={(error && error.message) || (editFirmError && editFirmError.message) || ''} />
    </FormProvider>
  )
}
