import React from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle 
} from 'shared-components/material/core'

type ConfirmationModalProps = {
    open: boolean,
    handleCloseConfirmation: () => void,
    title: string,
    content: string,
    action: () => void,
    danger: boolean,
    button: string,
}

export default function ConfirmationModal({
    open,
    handleCloseConfirmation,
    title,
    content,
    action,
    danger,
    button 
} : ConfirmationModalProps) {

  return (
      <Dialog
        open={open}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} variant='text' color='primary'>Cancel</Button>
          <Button onClick={action} variant='contained' color={danger ? 'error' : 'secondary'}>
            {button}
          </Button>
        </DialogActions>
      </Dialog>
  )
}