import { deleteClioTimer, getClioTimerAndDisputes, patchStartTimer, postClioActivity } from 'api/clio'
import { Dispute } from 'generated/graphql'
import React, { useEffect, useReducer, useRef } from 'react'
import { useError } from 'shared-components/hooks'
import { Button, IconButton } from 'shared-components/material/core'
import { AccessTimeIcon, PauseIcon, PlayArrowIcon } from 'shared-components/material/icons'
import { ErrorModal } from 'shared-components/modals'
import ClioActivityModal from 'shared-components/modals/ClioActivityModal'
import ClioTimeEntryModal from 'shared-components/modals/ClioTimeEntryModal'

enum TimerActionKind {
  START_TIMER = 'START_TIMER',
  STOP_TIMER = 'STOP_TIMER',
  STOP_TIMER_SUCCESS = 'STOP_TIMER_SUCCESS',
  RESUME_TIMER = 'RESUME_TIMER',
  TICK = 'TICK',
  CLOSE_TIMER_MODAL = 'CLOSE_TIMER_MODAL',
  CLOSE_ACTIVITY_MODAL = 'CLOSE_ACTIVITY_MODAL',
  START_NEW_ACTIVITY = 'START_NEW_ACTIVITY',
  SET_DISPUTES = 'SET_DISPUTES',
  UPDATE_TIME = 'UPDATE_TIME',
  OPEN_ACTIVITY_MODAL = 'OPEN_ACTIVITY_MODAL',
  SET_ACTIVITY_AND_DISPUTE='SET_ACTIVITY_AND_DISPUTE'
}

interface Category {
  id: number,
  name: string,
  rate: {
      amount: number,
      non_billable_amount: number,
      hierarchy: string,
      type: string
  },
  created_at: Date,
  accessible_to_user: boolean,
  default: boolean
}

interface TimerState {
  time: number,
  timerOn: boolean,
  selectedActivity: number,
  selectedDispute: number,
  timerStart: number,
  hoursDecimal: string,
  timerModal: boolean,
  activityModal: boolean,
  disputes: Dispute[],
  activityMatter: string,
  activityFirmUser: string,
  activityRate: string,
  activityDate: Date,
  activityCategory: string,
  nonBillable: boolean,
  activityDescription: string,
  categories: Category[],
}

interface TimerAction {
  type: TimerActionKind,
  payload?: any,
}

const initialState = {
  time: 0,
  timerOn: false,
  selectedActivity: 0,
  selectedDispute: 0,
  timerStart: 0,
  hoursDecimal: '0',
  timerModal: false,
  activityModal: false,
  disputes: [],
  activityMatter: '',
  activityFirmUser: '',
  activityRate: '',
  activityDate: new Date(),
  activityCategory: '',
  nonBillable: false,
  activityDescription: '',
  categories: [],
}


function reducer (state: TimerState, action: TimerAction): TimerState {
  switch(action.type) {
    case TimerActionKind.START_TIMER: {
      return {
        ...state,
        timerOn: true,
        timerStart: Date.now() - state.time
      }
    }
    case TimerActionKind.STOP_TIMER: {
      return {
        ...state,
        timerOn: false,
        hoursDecimal: (state.time / 3600000).toFixed(4),
        timerModal: true,
      }
    }
    case TimerActionKind.STOP_TIMER_SUCCESS: {
      const offset = `GMT-${new Date().getTimezoneOffset() / 60}00`
      let activityCategory
      let activityRate
      let defaultActivity

      const { activity, activityDescriptions } = action.payload
      const { activity_description, matter, date, user, note, quantity, non_billable } = activity

      if (activity_description) {
        activityCategory = activity_description.id
        activityRate = activity_description.rate.amount
      } else {
        defaultActivity = activityDescriptions.filter((c: Category) => c.default)[0]
        activityCategory = defaultActivity.id
        activityRate = defaultActivity.rate.amount
      }
      return {
        ...state,
        activityMatter: matter.display_number,
        activityDate: new Date(`${date} ${offset}`),
        activityFirmUser:`${user.first_name} ${user.last_name}`,
        activityDescription: note,
        hoursDecimal: (quantity / 3600).toFixed(4),
        categories: activityDescriptions,
        nonBillable: non_billable,
        activityCategory: activityCategory,
        activityRate: activityRate,
      }
    }
    case TimerActionKind.RESUME_TIMER: {
      return {
        ...state,
        timerOn: true,
        timerStart: Date.now() - state.time
      }
    }
    case TimerActionKind.TICK: {
      return {
        ...state,
        time: Date.now() - state.timerStart
      }
    }
    case TimerActionKind.CLOSE_TIMER_MODAL: {
      return {
        ...state,
        timerModal: false,
      }
    }
    case TimerActionKind.CLOSE_ACTIVITY_MODAL: {
      return {
        ...state,
        activityModal: false,
      }
    }
    case TimerActionKind.START_NEW_ACTIVITY: {
      return {
        ...state,
        selectedActivity: action.payload.activity.id,
      }
    }
    case TimerActionKind.SET_DISPUTES: {
      const { timer, disputes } = action.payload
      if (timer !== null) {
        const { elapsed_time, activity, selectedDisputeId } = timer
        const elapsedTime = elapsed_time
        const selectedActivity = activity.id
        const activityElapsed = activity.quantity_in_hours
        const totalElapsed = elapsedTime + activityElapsed
        const selectedDispute = selectedDisputeId

        return {
          ...state,
          disputes,
          time: totalElapsed * 3600000,
          selectedActivity: selectedActivity,
          selectedDispute: selectedDispute,
        }
      }
      return { ...state, disputes }
    }
    case TimerActionKind.UPDATE_TIME: {
      return {
        ...state,
        time: action.payload.time
      }
    }
    case TimerActionKind.OPEN_ACTIVITY_MODAL: {
      return {
        ...state,
        activityModal: true,
      }
    }
    case TimerActionKind.SET_ACTIVITY_AND_DISPUTE: {
      return {
        ...state,
        selectedActivity: action.payload.activity,
        selectedDispute: action.payload.dispute,
        time: action.payload.time,
      }
    }
  }
  throw Error('Unknown action: ' + action.type)
}

export default function ClioTimer() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [ error, href, handleError, resetError ] = useError()

  const timerRef = useRef<NodeJS.Timeout>()

  const seconds = (`0${(Math.floor(state.time / 1000) % 60)}`).slice(-2)
  const minutes = (`0${(Math.floor(state.time / 60000) % 60)}`).slice(-2)
  const hours = (`0${Math.floor(state.time / 3600000)}`).slice(-2)

  const resumeTimer = () => {
    dispatch({ type: TimerActionKind.RESUME_TIMER })
    
    timerRef.current = setInterval(() => {
      dispatch({ type: TimerActionKind.TICK })
    }, 10)
  }

  const getClioTimerAndDisputesFn = async () => {
    const payload = await getClioTimerAndDisputes()
      .catch(e => handleError(e))

    if (payload.success) {
      dispatch({ type: TimerActionKind.SET_DISPUTES, payload: payload.data })
      if (payload.data.timer !== null) {
        return resumeTimer()
      }
    }
    return handleError(payload.err, payload.href)
  }

  useEffect(() => {
    getClioTimerAndDisputesFn()
  }, [])

  const startNewActivity = async () => {
    const payload = await postClioActivity(state.selectedDispute)
      .catch(e => handleError(e))

    if (payload.success) {
      dispatch({ type: TimerActionKind.START_NEW_ACTIVITY, payload: payload.data})
    } else {
      clearInterval(timerRef.current)
      handleError(payload.err, payload.href)
    }
  }

  const startTimer = async () => {
    dispatch({ type: TimerActionKind.START_TIMER })

    timerRef.current = setInterval(() => {
      dispatch({ type: TimerActionKind.TICK })
    }, 10)
    
    if (state.selectedActivity !== 0) {
      const payload = await patchStartTimer(state.selectedActivity)
        .catch(e => handleError(e))

      if (!payload.success) {
        clearInterval(timerRef.current)
        handleError(payload.err, payload.href)
      }
    } else {
      startNewActivity()
    }
  }

  const stopTimer = async () => {
    dispatch({ type: TimerActionKind.STOP_TIMER })
    clearInterval(timerRef.current)
    const payload = await deleteClioTimer(state.selectedActivity)
      .catch(e => handleError(e))
    if (payload.success) {
      dispatch({ type: TimerActionKind.STOP_TIMER_SUCCESS, payload: payload.data })
    } else {
      dispatch({ type: TimerActionKind.CLOSE_TIMER_MODAL })
      handleError(payload.err, payload.href)
    }
  }

  const toggleTimer = () => state.timerOn ? stopTimer() : startTimer()

  const openActivityModal = () => dispatch({ type: TimerActionKind.OPEN_ACTIVITY_MODAL })

  const closeTimerModal = () => dispatch({ type: TimerActionKind.CLOSE_TIMER_MODAL })

  const closeActivityModal = () => dispatch({ type: TimerActionKind.CLOSE_ACTIVITY_MODAL })

  const updateTime = (time: number) => dispatch({type: TimerActionKind.UPDATE_TIME, payload: { time }})

  const getActivityAndDispute = (activity: number | null, dispute: number, time: number) => {
    dispatch({ type: TimerActionKind.SET_ACTIVITY_AND_DISPUTE, payload: { activity, dispute, time} })
  }

  return (
    <>
      <Button
        variant="outlined"
        color='inherit'
        startIcon={state.timerOn ? <PauseIcon /> : <PlayArrowIcon />}
        onClick={toggleTimer}
        disabled={state.selectedActivity === null || !state.selectedDispute}
      >
        {`${hours}:${minutes}:${seconds}`}
      </Button>
      <IconButton onClick={openActivityModal} color='inherit' aria-label="Choose Category" component="label">
        <AccessTimeIcon />
      </IconButton>
      <ClioActivityModal 
        openActivityModal={state.activityModal}
        closeActivityModal={closeActivityModal}
        sendActivityAndDispute={getActivityAndDispute}
        disputes={state.disputes}
      />
      <ClioTimeEntryModal 
        timerModalOpen={state.timerModal}
        closeTimerModal={closeTimerModal}
        hoursDecimal={state.hoursDecimal}
        activityCategory={state.activityCategory}
        activityDate={state.activityDate}
        activityDescription={state.activityDescription}
        nonBillable={state.nonBillable}
        categories={state.categories}
        activityRate={state.activityRate}
        getClioDisputesFn={getClioTimerAndDisputesFn}
        updateTime={updateTime}
        selectedActivity={state.selectedActivity}
      />
      <ErrorModal error={error} href={href} resetError={resetError} />
    </>
  )
}
