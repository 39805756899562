import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { CircularProgress } from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { CenteredContent } from 'shared-components/layout'

import { getOrCreateDispute } from 'api/clio'

export default function ClioCustomAction() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { customActionType } = useParams()
  const [ error, href, handleError, resetError ] = useError()
  const subjectUrl = new URLSearchParams(search).get('subject_url')
  const clioMatterId = subjectUrl && subjectUrl.substring(subjectUrl.lastIndexOf('/') + 1);
  // const deepLink = `${pathname}${search}`

  const getOrCreateDisputeFn = async () => {
    const payload = clioMatterId && (await getOrCreateDispute(clioMatterId)
      .catch(e => handleError(e)))
    if (payload.success) {
      if (customActionType === 'request') {
        return navigate(
          `/app/cases/${payload.data.disputeId}/request`,
          {state:  {clientEmail: payload.data.clientEmail} },
        )
      }
      return navigate(`/app/cases/${payload.data.disputeId}/produce`)
    } else {
      return handleError(payload.err, payload.href)
    }
  }

  useEffect(() => {
    getOrCreateDisputeFn()
  }, [])

  return (
    <CenteredContent>
      <CircularProgress />
      <ErrorModal error={error} href={href} resetError={resetError} />
    </CenteredContent>
  )
}
