import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Box, styled, sx, Stepper, Step, StepLabel } from 'shared-components/material/core'

import ClientEmail from './ClientEmail'
import ClientEmailConfirm from './ClientEmailConfirm'
import { getKickOffEmailFields } from 'api/disputes'
import { useError } from 'shared-components/hooks'
import ErrorModal from 'shared-components/modals/ErrorModal'


const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

type KickOffStepperProps = {
    disputeId: number,
    refetchCase: () => void,
    setOpenKickOffModal: Dispatch<SetStateAction<boolean>>,
}

type EmailFormData = {
    clientEmails: string,
    customMessage: string,
}

export default function KickOffStepper({ 
    disputeId,
    refetchCase,
    setOpenKickOffModal,
} : KickOffStepperProps) {
    const [ error, href, handleError, resetError] = useError()
    const [activeStep, setActiveStep] = useState(0)
    const [clientEmails, setClientEmails] = useState('')
    const [customMessage, setCustomMessage] = useState('')

    const steps = ['Setup', 'Confirm']

    useEffect(() => {
        const getKickOffEmailFieldsFn = async () => {
            const payload = await getKickOffEmailFields(disputeId)
                .catch((e) => handleError(e))
            if (payload.success) {
                setClientEmails(payload.data.clientEmails)
                setCustomMessage(payload.data.customMessage)
            } else {
                handleError(payload.err, payload.href)
            }
        }
        getKickOffEmailFieldsFn()
    }, [])

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

    const getEmailSetupData = (data: EmailFormData) => {
        setClientEmails(data.clientEmails)
        setCustomMessage(data.customMessage)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    return (
        <UploadContainer>
            <div style={{ width: '500px' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            </div>

            {activeStep === 0 ? (
                <ClientEmail
                    passSetupData={getEmailSetupData}
                    clientEmails={clientEmails}
                    customMessage={customMessage}
                    setOpenKickOffModal={setOpenKickOffModal}
                />
            ): null}

            {activeStep === 1 ? (
                <ClientEmailConfirm
                    disputeId={disputeId}
                    clientEmails={clientEmails}
                    customMessage={customMessage}
                    handleBack={handleBack}
                    refetchCase={refetchCase}
                    setOpenKickOffModal={setOpenKickOffModal}
                />
            ): null}
            <ErrorModal error={error} href={href} resetError={resetError}/>
        </UploadContainer>
    )
}
