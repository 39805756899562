import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useQuery, useMutation } from '@apollo/client'
import { User } from 'generated/graphql'

import { CenteredForm, CenteredContent } from 'shared-components/layout'
import {
    Button,
    Typography,
    CircularProgress,
    LoadingButton,
    Snackbar,
    MuiAlert,
    AlertProps,
    Box,
} from 'shared-components/material/core'
import { TextInput, SelectInput, TelephoneInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'
import { useNavigate } from 'react-router-dom'
import { putAccountSetupComplete } from 'api/accounts'
import { useError } from 'shared-components/hooks'

export const GET_USER = gql`
    query User($userId: Int) {
        user(userId: $userId) {
            userId
            userEmail
            userFirstname
            userLastname
            userInitials
            userTelephone
            userFirmRole
            userRegisteredViaClio
            userTourActive
        }
    }
`

export const EDIT_USER = gql`
    mutation Mutation($userId: Int!, $userTourActive: Boolean, $userFirstname: String, $userLastname: String, $userInitials: String, $userTelephone: String, $userEmail: String, $userFirmRole: String) {
  updateUser(userId: $userId, userTourActive: $userTourActive, userFirstname: $userFirstname, userLastname: $userLastname, userInitials: $userInitials, userTelephone: $userTelephone, userEmail: $userEmail, userFirmRole: $userFirmRole) {
    userEmail
    userFirstname
    userLastname
    userInitials
    userTelephone
    userFirmRole
    userTourActive
  }
}
`

const schema = yup.object({
    userFirstname: yup.string().required('First name is required'),
    userLastname: yup.string().required('Last name is required'),
    userInitials: yup
        .string()
        .matches(/^[A-Za-z]{2,3}$/, 'Initials must be two to three letters long')
        .required('Initials are required'),
    userTelephone: yup
        .string()
        .required('Telephone is required'),
    userEmail: yup.string().required('Email is required'),
    userFirmRole: yup.string().required('Role is required'),
    userTourActive: yup.bool(),
  }).required()

type FormData = {
    userFirstname: string,
    userLastname: string,
    userInitials: string,
    userTelephone: string,
    userEmail: string,
    userFirmRole: string,
    userTourActive: boolean
}

const userFirmRoleOptions = [
    { label: 'Partner', value: 'Partner'},
    { label: 'Associate', value: 'Associate'},
    { label: 'Paralegal', value: 'Paralegal'},
    { label: 'Law Clerk', value: 'Law Clerk'},
    { label: 'Support Staff', value: 'Support Staff'},
    { label: 'Other', value: 'Other'},
  ]

function UserInfo({ handleNext, handleBack, skipSubscription } : { handleNext: () => void, handleBack: () => void, skipSubscription: boolean }) {
    const [error, href, handleError, resetError] = useError()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const { data, loading: userLoading, error: userError } = useQuery(GET_USER, {fetchPolicy: 'network-only'})
    const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const user: User = data ? data.user : {}

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            userFirstname: '',
            userLastname: '',
            userInitials: '',
            userTelephone: '',
            userEmail: '',
            userFirmRole: '',
            userTourActive: false,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, formState: { errors }} = methods

    useEffect(() => {
        setValue('userFirstname', user.userFirstname || '')
        setValue('userLastname', user.userLastname || '')
        setValue('userInitials', user.userInitials || '')
        setValue('userTelephone', user.userTelephone || '')
        setValue('userEmail', user.userEmail || '')
        setValue('userFirmRole', user.userFirmRole || '')
        setValue('userTourActive', user.userTourActive || false)
    }, [user])

    const completeAccountSetup = async () => {
        setLoading(true)
        const payload = await putAccountSetupComplete()
            .catch(e => handleError(e))

        if (payload.success) {
            return handleNext()
        } else {
            setLoading(false)
            return handleError(payload.err, payload.href)
        }
    }

    const onSubmit = async (formData: FormData) => {
        const variables = {...formData, userId: user.userId} 
        await editUser({ variables })
        if (skipSubscription) {
            await completeAccountSetup()
            return navigate('/app/cases')
        } 
        return handleNext()
    }
    const handleCloseSnackbar = () => setSnackbar(null)

    if (userLoading || loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    const userHref: string = userError && userError.graphQLErrors[0] 
        ? userError.graphQLErrors[0].extensions.href as string 
        : ''

    return (
        <FormProvider {...methods} >
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <CenteredForm align="center">
                    <Typography variant="h2" component="h2" textAlign='left'>
                        User Info
                    </Typography>
                    <TextInput
                        name="userFirstname"
                        label="First Name"
                        required
                        error={errors.userFirstname !== undefined ? true : false }
                        errorMessage={errors.userFirstname ? errors.userFirstname.message : undefined}
                    />
                    <TextInput
                        name="userLastname"
                        label="Last Name"
                        required
                        error={errors.userLastname !== undefined ? true : false }
                        errorMessage={errors.userLastname ? errors.userLastname.message : undefined}
                    />
                    <TextInput
                        name="userInitials"
                        label="Initials"
                        required
                        error={errors.userInitials !== undefined ? true : false }
                        errorMessage={errors.userInitials ? errors.userInitials.message : undefined}
                    />
                    <TelephoneInput
                        name="userTelephone"
                        label="Telephone"
                        required
                        error={errors.userTelephone !== undefined ? true : false }
                        errorMessage={errors.userTelephone ? errors.userTelephone.message : undefined}
                    />
                    <TextInput
                        name="userEmail"
                        label="E-Mail"
                        required
                        error={errors.userEmail !== undefined ? true : false }
                        errorMessage={errors.userEmail ? errors.userEmail.message : undefined}
                    />
                    <SelectInput 
                        name="userFirmRole"
                        label="Role"
                        options={userFirmRoleOptions}
                        required
                        error={errors.userFirmRole !== undefined ? true : false }
                        errorMessage={errors.userFirmRole ? errors.userFirmRole.message : undefined}
                    />
                </CenteredForm>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleBack} variant="contained">
                        Back
                    </Button>
                    {editUserLoading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ): (
                        <Button onClick={handleSubmit(onSubmit)} variant="contained">
                            {skipSubscription ? 'Finish' : 'Next'}
                        </Button>
                    )}
                </Box>
            </Box>
            <ErrorModal 
                error={(userError && userError.message) || (editUserError && editUserError.message) || error || ''}
                href={href || userHref}
                resetError={resetError}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FormProvider>
    )
}

export default UserInfo
