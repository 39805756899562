import React, { Dispatch, SetStateAction }  from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import KickOffStepper from './KickOffStepper/KickOffStepper'

type KickOffModalProps = {
    openKickOffModal: boolean,
    setOpenKickOffModal: Dispatch<SetStateAction<boolean>>,
    disputeId: number,
    refetchCase: () => void,
}

export default function KickOffModal({ 
    openKickOffModal,
    setOpenKickOffModal,
    disputeId,
    refetchCase,
}: KickOffModalProps) {
  return (
    <Dialog open={openKickOffModal} onClose={() => setOpenKickOffModal(false)}>
        <DialogTitle>Client Kick-off Email</DialogTitle>
        <DialogContent>
            <KickOffStepper
                disputeId={disputeId}
                refetchCase={refetchCase}
                setOpenKickOffModal={setOpenKickOffModal}
            />
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
    </Dialog>
  )
}
