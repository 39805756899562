import React, { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import Review from './Review'
import Success from './Success'
import Options from './Options'
import Payment from './Payment'
import Uncategorized from './Uncategorized'
import ErrorModal from '../ErrorModal'
import { checkForUncategorizedItems, calcJobCost } from 'api/jobs'

const GET_JOB = gql`
    query Job($jobId: Int) {
        job(jobId: $jobId) {
            jobId
            jobDisputeId
            jobName
            jobCustomConfidential
            jobCatType
            jobBatesRecId
            jobCatBypass
            jobPrivStart
            jobPrivEnd
            jobPrivLength
            jobEmlsTotal
            jobAttsTotal
            jobDocsTotal
            jobEmailList
            jobCost
            jobPrivEmlsNum
            jobPrivEmlsPages
            jobPrivEmlsCost
            jobPrivAttsNum
            jobPrivAttsPages
            jobPrivAttsCost
            jobPrivDocsNum
            jobPrivDocsPages
            jobPrivDocsCost
            jobProdEmlsNum
            jobProdEmlsPages
            jobProdEmlsCost
            jobProdAttsNum
            jobProdAttsPages
            jobProdAttsCost
            jobProdDocsNum
            jobProdDocsPages
            jobProdDocsCost
            jobIrrelEmlsNum
            jobIrrelEmlsPages
            jobIrrelEmlsCost
            jobIrrelAttsNum
            jobIrrelAttsPages
            jobIrrelAttsCost
            jobIrrelDocsNum
            jobIrrelDocsPages
            jobIrrelDocsCost
            jobHoldAttsCost
            jobHoldAttsNum
            jobHoldDocsCost
            jobHoldDocsNum
            jobHoldEmlsCost
            jobHoldEmlsNum
            jobClientEmail
            jobCostOverride
            jobCostActual
            jobBypassBates
            jobPdfMargin
            jobDeadlineId
            jobFinishUserId
            jobRecordCostOverride
            jobIrrelCostOverride
            jobReprocess
            jobIrrelStart
            jobIrrelEnd
            dispute {
                disputeBatesIntegrity
                batesRecs {
                    batesRecPrefix
                    batesRecId
                    batesRecNextBatesStr
                    batesRecNextPrivStr
                }
                jobs {
                    jobId
                    jobName
                    jobBatesLength
                    jobBatesPrefix
                    jobBatesStart
                    jobBatesEnd
                    jobPrivLength
                    jobPrivStart
                    jobPrivEnd
                    jobIrrelLength
                    jobIrrelStart
                    jobIrrelEnd
                    jobBatesNumDigits
                    jobIrrelNumDigits
                    jobPrivNumDigits
                    jobCreatedAt
                    jobFinishedTimestamp
                }
            }
        }
    }
`

type PayModalProps = { 
    open: boolean,
    togglePayModal: () => void,
    jobId: number,
    getJobItemsFn?: () => void,
    refetchJob?: () => void,
}

export default function PayModal({
    open,
    togglePayModal,
    jobId,
    getJobItemsFn,
    refetchJob,
}: PayModalProps) {

    const { error: jobError, loading: jobLoading, data: jobData, refetch } = useQuery(GET_JOB, {
        variables: { jobId },
    })


    const theme = useTheme()
    const [error, href, handleError, resetError ] = useError()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [steps, setSteps] = useState(['Categorize', 'Options', 'Review', 'Payment', 'Success'])
    const [activeStep, setActiveStep] = useState(0)
    const [payMethods, setPayMethods] = useState([])
    const [firstFinish, setFirstFinish] = useState(false)
    const [loading, setLoading] = useState(true)

    const handleCategoryCheck = async () => {
        setLoading(true)
        
        const payload = await checkForUncategorizedItems(jobId)
            .catch(e => handleError(e))
        
        if (payload.success) {
            
            if (!payload.data.hasUncategorizedItems) {
                setSteps((current) => current.filter((step) => step !== "Categorize"))
                setActiveStep(1)
                return getJobCost()
            }
            return setLoading(false)
        } else {
            return handleError(payload.err, payload.href)
        }
        
      }

    const getJobCost = async () => {
        const payload = await calcJobCost(jobId)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setPayMethods(payload.data.payMethods)
            setFirstFinish(payload.data.firstFinish)
            refetch()
            setLoading(false)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    useEffect(() => {
        handleCategoryCheck()
    }, [])

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

    if (jobLoading || loading) {
        return (
            <Dialog open={open} onClose={() => togglePayModal()} fullScreen={fullScreen}>
                <DialogTitle>Produce Document Set</DialogTitle>
                <DialogContent sx={{ textAlign: 'center'}}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={() => togglePayModal()} fullWidth maxWidth={'md'} fullScreen={fullScreen}>
                <DialogTitle>Produce Document Set</DialogTitle>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {}
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                {activeStep === 0 &&
                    <Uncategorized 
                        togglePayModal={togglePayModal}
                        handleNext={handleNext}
                        getJobCost={getJobCost}
                        getJobItemsFn={getJobItemsFn}
                        jobId={jobId}
                    />
                }
                {activeStep === 1 && 
                    <Options
                        job={jobData.job}
                        batesRecs={jobData.job.dispute.batesRecs}
                        disputeJobs={jobData.job.dispute.jobs}
                        disputeBatesIntegrity={jobData.job.dispute.disputeBatesIntegrity}
                        togglePayModal={togglePayModal}
                        getJobCost={getJobCost}
                        handleNext={handleNext}
                        refetch={refetch}
                        refetchJob={refetchJob}
                    />
                }
                {activeStep === 2 &&
                    <Review
                        job={jobData.job}
                        handleBack={handleBack}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        firstFinish={firstFinish}
                    />
                }
                {activeStep === 3 &&
                    <Payment
                        job={jobData.job}
                        payMethods={payMethods}
                        handleNext={handleNext}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        firstFinish={firstFinish}
                    />
                }
                {activeStep === 4 && <Success disputeId={jobData.job.jobDisputeId} />}
                <ErrorModal error={jobError ? jobError.message : error} href={href} resetError={resetError} />
        </Dialog>
    )
}