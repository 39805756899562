import { yupResolver } from '@hookform/resolvers/yup'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import * as yup from "yup"
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from 'shared-components/inputs'
import { CenteredForm, DetailContainer, } from 'shared-components/layout'
import {
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput
} from 'shared-components/material/core'
import { useLocation, useParams } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material'
import { HelpBotModal } from 'shared-components/modals'

const schema = yup.object({
    searchQuery: yup.string().required('Search query is required'),
}).required()

type FormData = {
    searchQuery: string,
}

const jobStatusOptions = [
    'REVIEW',
    'DOWNLOAD',
]

interface StateType {
    jobStatuses: string[],
    searchType: string,
    searchQuery: string,
 }

 type SearchFormProps = {
    handleSearchDispute: (
        disputeId: number, 
        query: string, 
        searchType: string, 
        jobStatuses: string[]) => void,
    setSearchTerm: Dispatch<SetStateAction<string>>
 }

export default function SearchForm({ handleSearchDispute, setSearchTerm }: SearchFormProps) {
    const location = useLocation()
    const locationState = location.state as StateType
    const { disputeId } = useParams()
    const id = disputeId && parseInt(disputeId) || 0
    const [jobStatuses, setJobStatuses] = useState<string[]>(locationState.jobStatuses || []);
    const [searchType, setSearchType] = useState<string>(locationState.searchType || 'text');
    const [helpBotModalOpen, setHelpBotModalOpen] = useState(false)

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            searchQuery: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, getValues, formState: { errors } } = methods

    useEffect(() => {
        if (locationState) {
            setValue('searchQuery', locationState.searchQuery)  
            setSearchTerm(locationState.searchQuery)
        }
        const query = getValues('searchQuery')
        handleSearchDispute(id, query, searchType, jobStatuses)
    }, [])
    
    const handleJobStatusesChange = (e: SelectChangeEvent<typeof jobStatuses>) => {
        const {target: { value }} = e;
        setJobStatuses(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const handleSearchTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchType(e.target.value)
    }

    const handleHelpBotClick = () => setHelpBotModalOpen(true)

    const onSubmit = async (data: FormData) => {
        const query = data.searchQuery
        handleSearchDispute(id, query, searchType, jobStatuses)
        setSearchTerm(query)
    }

    return (
        <DetailContainer>
            <Typography variant='h3'>
                Search Genie
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
                AI powered document content search
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <FormProvider {...methods}>
                <CenteredForm width='100%'>
                    <FormControl>
                        <FormLabel id="search-type-label">Search Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="search-type-label"
                            name="searchType"
                            value={searchType}
                            onChange={handleSearchTypeChange}
                        >
                            <FormControlLabel value={'text'} control={<Radio />} label="Text" />
                            <FormControlLabel value={'boolean'} control={<Radio />} label="Boolean" />
                            <FormControlLabel value={'ai'} control={<Radio />} label="AI" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="jobStatuses-label">Select Job Statuses</InputLabel>
                        <Select
                            labelId="jobStatuses-label"
                            id="jobStatuses"
                            multiple
                            value={jobStatuses}
                            onChange={handleJobStatusesChange}
                            input={<OutlinedInput label="Select Job Statuses" />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                        {jobStatusOptions.map((status) => (
                            <MenuItem key={status} value={status}>
                                <Checkbox checked={jobStatuses.indexOf(status) > -1} />
                                <ListItemText primary={status} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <TextInput
                        name='searchQuery'
                        label='Search Query'
                        required
                        error={errors.searchQuery !== undefined ? true : false}
                        errorMessage={errors.searchQuery ? errors.searchQuery.message : undefined}
                        
                    />
                    {searchType === 'boolean' && (
                        <Button onClick={handleHelpBotClick} variant="contained" color="primary">
                            Search Help Bot
                        </Button>
                    )}
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Search
                    </Button>
                </CenteredForm>
            </FormProvider>
            <HelpBotModal helpBotModalOpen={helpBotModalOpen} setHelpBotModalOpen={setHelpBotModalOpen}/>
        </ DetailContainer>
    )
}
