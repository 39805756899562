import React, { useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    LoadingButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from 'shared-components/material/core'
import ErrorModal from '../ErrorModal'
import { SelectInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks/index'
import { PayMethod } from 'generated/graphql'
import { CenteredContent, CenteredForm } from 'shared-components/layout'
import { currencyFormat } from 'shared-components/utils'
import { CreatePaymentMethodData, StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { gql, useMutation, useQuery } from '@apollo/client'

const GET_DATA = gql`
    query Subscription {
        subscription {
            subscriptionAmount
            subscriptionId
            subscriptionInterval
            subscriptionPlanName
        }
        payMethodsForFirm {
            payMethodStripeId
            payMethodUserId
            payMethodUiIdentifier
            payMethodExpDate
            payMethodId
        }
    }
`

export const ADD_PAYMENT_METHOD = gql`
  mutation Mutation($paymentMethod: JSON, $payMethodType: Int) {
    addPayMethod(paymentMethod: $paymentMethod, payMethodType: $payMethodType) {
      payMethodId
    }
  }
`

export const FINALIZE_SUBSCRIPTION = gql`
  mutation Mutation($subscriptionPayMethodStripeId: String, $subscriptionStatus: Int) {
  finalizeSubscription(subscriptionPayMethodStripeId: $subscriptionPayMethodStripeId, subscriptionStatus: $subscriptionStatus) {
    subscriptionId
  }
}
`


const schema = yup.object({
    selectedSource: yup.string(),
}).required()

type FormData = {
    selectedSource: string,
}

type PaymentProps = { 
    handleNext: () => void,
}

export default function Payment({ 
    handleNext,
} : PaymentProps) {
    const stripe = useStripe()
    const elements = useElements()
    const [cardEntered, setCardEntered] = useState(false)
    const { error: dataError, loading: dataLoading, data} = useQuery(GET_DATA, { fetchPolicy: 'network-only'})
    const [addPaymentMethod, { loading: addPaymentMethodloading, error: addPaymentMethodError }] = useMutation(ADD_PAYMENT_METHOD)
    const [finalizeSubscription, { loading: finalizeSubscriptionloading, error: finalizeSubscriptionError }] = useMutation(FINALIZE_SUBSCRIPTION)
    const [error, href, handleError, resetError ] = useError()
    const [loading, setLoading] = useState(false)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            selectedSource: '',
            savePayMethod: false,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, formState: { errors } } = methods

    const getPaymentMethod = async () => {
        const cardElement = elements && elements.getElement('card')
    
        const paymentMethodData: CreatePaymentMethodData = {
            type: 'card',
            card: cardElement as StripeCardElement,
        }
    
        if (stripe) {
          const { paymentMethod, error } = await stripe.createPaymentMethod(paymentMethodData)
          if (paymentMethod) {
            return paymentMethod
          }
      
          if (error) {
            return handleError(error.message)
          } 
        } 
      }

    const onSubmit = async (formData: FormData) => {
        setLoading(true)

        const { 
            selectedSource,
        } = formData

        let subscriptionPayMethodStripeId

        if (selectedSource === '0') {
            const paymentMethod = await getPaymentMethod()
            const paymentMethodVariables = { payMethodType: 1, paymentMethod }
            await addPaymentMethod({ variables: paymentMethodVariables })

            subscriptionPayMethodStripeId = paymentMethod && paymentMethod.id
        } else {
            subscriptionPayMethodStripeId = selectedSource
        }

        const subscriptionVariables = {
            subscriptionStatus: 2,
            subscriptionPayMethodStripeId
        }
        
        await finalizeSubscription({ variables: subscriptionVariables })

        handleNext()
    }

    const selectedSource = watch('selectedSource')

    const handleChange = (e: StripeCardElementChangeEvent) => e.complete ? setCardEntered(true) : setCardEntered(false)

    const renderPaymentMethod = () => {
        return (
            <div>
                <h3>Select payment method</h3>
                <Box sx={{'& .MuiFormControl-root': { width: '100%' }}}>
                    <SelectInput
                        name="selectedSource"
                        label="Payment Method"
                        options={payMethodOptions}
                        error={errors.selectedSource !== undefined ? true : false}
                        errorMessage={errors.selectedSource ? errors.selectedSource.message : undefined}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    {selectedSource === '0' ? (
                        <div>
                            <p>Enter credit card below</p>
                            <CardElement onChange={handleChange}/>
                        </div>
                    ) : null}
                </Box>
            </div>
        )
    }

    const paymentMethods = data ? data.payMethodsForFirm : []
    const subscription = data ? data.subscription : {}

    const payMethodOptions = paymentMethods.filter((method: PayMethod) => method.payMethodFirmId !== null).map((payMethod: PayMethod) => {
        const label = `${payMethod.payMethodUserId ? 'User' : 'Firm'} | xxxx-xxxx-xxxx-${payMethod.payMethodUiIdentifier} | exp ${payMethod.payMethodExpDate}`
        return {
            label,
            value: payMethod.payMethodStripeId || '0'
        }
    })

    payMethodOptions.push({label: 'New Payment Method', value: '0'})

    if (dataLoading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }
    
    return (
        <>
            <DialogContent 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {subscription && (
                    <Table style={{maxWidth: '300px' }}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>{subscription.subscriptionPlanName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>
                                {(subscription && currencyFormat(subscription.subscriptionAmount)) || '$0.00'}
                            </TableCell>
                            <TableCell>{subscription.subscriptionInterval === 1 ? 'monthly' : 'yearly'}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table> 
                )}
                
                <CenteredForm width="450px">
                    <FormProvider {...methods}>
                        {renderPaymentMethod()}   
                    </FormProvider>
                </CenteredForm>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    pt: 2,
                    width: '100%'
                }}>
                    {loading || addPaymentMethodloading || finalizeSubscriptionloading ? (
                        <LoadingButton loading variant="outlined">
                            Submit
                        </LoadingButton>
                    ) : (
                        <Button 
                            onClick={handleSubmit(onSubmit)} 
                            variant="contained" 
                            disabled={selectedSource === '' || (selectedSource === '0' && !cardEntered)}
                        >
                            Submit
                        </Button>
                    )}

                </Box>
                <ErrorModal 
                    error={
                        error || 
                        (dataError && dataError.message) || 
                        (addPaymentMethodError && addPaymentMethodError.message) || 
                        (finalizeSubscriptionError && finalizeSubscriptionError.message) || '' } 
                    href={href} 
                    resetError={resetError} 
                />
            </DialogActions>
        </>
  )
}
