/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { Ead } from 'generated/graphql'

import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridColumnMenuContainer,
    GridColumnMenuSortItem,
    GridColumnMenuFilterItem,
    GridColumnMenuProps,
    GridColumnMenuHideItem,
    GridColumnMenuColumnsItem,
    GridColumnMenuPinningItem,
    GridRowModel,
    GridActionsCellItem,
    GridRowId,
    useGridApiRef,
    GridToolbarExport,
    GridPrintExportOptions,
    GridExcelExportOptions,
    GridCsvExportOptions,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium'

import { useError } from 'shared-components/hooks'
import { CenteredContent } from 'shared-components/layout'
import {
    CircularProgress,
    Paper,
    Tooltip,
    Box,
    Button,
    Snackbar,
    MuiAlert,
    AlertProps
} from 'shared-components/material/core'
import { CheckBoxOutlineBlankIcon, CheckBoxIcon } from 'shared-components/material/icons'
import { SelectInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

import "intro.js/introjs.css"

import { analyzeJob } from 'api/jobs'
import Tour from 'shared-components/Tour/Tour'

export const EDIT_EADS = gql`
    mutation Mutation($eadIds: [Int]!, $eadEmailRole: Int) {
        updateEads(eadIds: $eadIds, eadEmailRole: $eadEmailRole) {
            eadId
        }
    }
`

//////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////// Custom Components /////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////////////////

const schema = yup.object({
    batchUpdate: yup.string(), 
}).required()

type FormData = {
    batchUpdate: string,
}

type CustomToolbarProps = { 
    updateRows: (formData: FormData) => void, 
    handleAnalyze: () => void, 
    csvOptions: GridCsvExportOptions,
    excelOptions: GridExcelExportOptions,
    printOptions: GridPrintExportOptions,
}

const tourSteps = [
    {
      element: ".MuiDataGrid",
      intro: (
                <p>When you think about privilege, the first thing you want to know is the 
                    sender and recipient of the message. If it’s solely between a lawyer 
                    and client, the message is very likely privileged. If there’s an adverse 
                    party or third party in the message, it’s likely produced.</p>
        )
    },
    {
      element: ".MuiDataGrid-pinnedColumnHeaders",
      intro: (
                <p>The Predictor gathers every email address used in your document set, and 
                    asks you to classify them by their role in the litigation. You can sort 
                    by the domain, so that all the email addresses from the same company are 
                    grouped together.</p>
        )
    },
    {
      element: ".introAnalyze",
      intro: (
            <p>When you have classified all the email addresses, click “Analyze,” and the 
                Genie will predict whether the email is privileged, and return you to the 
                Review screen with the prediction in the Priv or Prod column. Note that you 
                can confirm or change the prediction by clicking in the box.</p>
        )
    },
  ]

function CustomToolbar({ 
    updateRows, 
    handleAnalyze, 
    csvOptions,
    excelOptions,
    printOptions,
    } : CustomToolbarProps) {
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const navigate = useNavigate()
    const { jobId } = useParams()
    const id = jobId ? parseInt(jobId) : 0

    const batchUpdateOptions = [
        { label: 'Mark all client', value: 1},
        { label: 'Mark all attorney', value: 2},
        { label: 'Mark all adverse', value: 3},
        { label: 'Mark all Third Party', value: 4},
    ]

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            batchUpdate: '', 
        },
        resolver: yupResolver(schema),
    })

    const handleCloseSnackbar = () => setSnackbar(null)

    const { handleSubmit, watch, formState: { errors } } = methods

    const hasBatchUpdate = watch('batchUpdate')

    const onSubmit = (formData: FormData) => updateRows(formData)

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2}}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector sx={{ mr: 1}} />
                <GridToolbarExport 
                    printOptions={printOptions} 
                    csvOptions={csvOptions} 
                    excelOptions={excelOptions} 
                    sx={{ mr: 1}}
                />
                    <FormProvider {...methods}>
                        <SelectInput
                            name="batchUpdate"
                            label="Update Selected Rows"
                            options={batchUpdateOptions}
                            error={errors.batchUpdate !== undefined ? true : false}
                            errorMessage={errors.batchUpdate ? errors.batchUpdate.message : undefined}
                            fullWidth
                            sx={{ 
                                width: '185px',
                                top: '0px',
                                maxHeight: '30.75px !important',
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiSelect-outlined': { 
                                    padding: '3.875px 10px !important', 
                                    height: '23px' 
                                }}}
                        />
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            onClick={handleSubmit(onSubmit)}
                            sx={{ ml: 1 }}
                            disabled={!hasBatchUpdate}
                        >
                            Apply
                        </Button>
                    </FormProvider>
            </GridToolbarContainer>
            <div>
            <Tooltip title="Document Review">
                <Button 
                    className="customButton" 
                    variant="text" 
                    sx={{ mr: 1 }} 
                    size="small" 
                    onClick={() => navigate(`/app/document-set/${id}/document-review`)}
                >
                    Review
                </Button>
            </Tooltip>
            <Tooltip title="Analyze document set">
                <Button 
                    className="customButton introAnalyze" 
                    variant="contained" 
                    color="secondary" 
                    size="small" 
                    onClick={handleAnalyze}
                    >
                        Analyze
                    </Button>
            </Tooltip>
            </div>
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <Tour tourSteps={tourSteps} />
        </Box>
    )
  }

  export const CustomGridColumnMenu = React.forwardRef<
  HTMLUListElement,
  GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
  // eslint-disable-next-line react/prop-types
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };

  return (
      <GridColumnMenuContainer ref={ref} {...props}>
        <GridColumnMenuSortItem {...itemProps} />
        <GridColumnMenuFilterItem {...itemProps} />
        <GridColumnMenuHideItem {...itemProps} />
        <GridColumnMenuColumnsItem {...itemProps} />
        <GridColumnMenuPinningItem {...itemProps} />
      </GridColumnMenuContainer>
  );
})



export default function EmailDataGrid({ eads } : { eads: Ead[]}) {
    const apiRef = useGridApiRef()
    const navigate = useNavigate()
    const [rows] = useState(eads)
    const [error, href, handleError, resetError ] = useError()
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 0,
      });
    const [loading, setLoading] = useState(false)
    const [updateEads, { error: editEadError }] = useMutation(EDIT_EADS)

    const { jobId } = useParams()
    const id = jobId ? parseInt(jobId) : 0

    if (editEadError) {
        handleError(editEadError.message)
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////// Analyze ////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleAnalyze = async () => {
        setLoading(true)
        const payload = await analyzeJob(id)
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
            navigate(`/app/cases/${eads[0].eadDisputeId}`)
        } else {
            setLoading(false)
            handleError(payload.err, payload.href)
        }
      }

    //////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////// EDIT ROWS /////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleCheck = ( eadId: GridRowId, eadEmailRole: number | null ) => {
        const update = {eadIds: [eadId], eadEmailRole}
        apiRef.current.updateRows([{ id: eadId, eadEmailRole }]) // update grid
        updateEads({ variables: update}) // update backend
    }

    const updateRows = async (formData: FormData) => {
        const selectedRows = Array.from(apiRef.current.getSelectedRows().values()) // Turn selected rows Map to Array
        const { batchUpdate } = formData

        const gridUpdates = selectedRows.map(row => ({ 
            id: row.id, 
            eadEmailRole: parseInt(batchUpdate)
        }))

        const apiUpdate = {
            eadIds: selectedRows.map(row => row.id),
            eadEmailRole: parseInt(batchUpdate)
        }

        apiRef.current.updateRows(gridUpdates) // update grid

        updateEads({ variables: apiUpdate}) // update backend
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////// COLUMNS ///////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    const columns = [
        {
            field: 'count', 
            headerName: 'Count',
            width: 80,
            type: 'number',
            disableExport: true,
        },
        {
            field: 'eadEmail', 
            headerName: 'Email', 
            width: 250, 
        },
        {
            field: 'eadName',
            headerName: 'Name',
            width: 200,
            disableExport: true,
        },
        {
            field: 'domainDomain', 
            headerName: 'Domain',
            width: 250,
            disableExport: true,
        },
        {
            field: 'client',
            type: 'actions',
            headerName: 'Client',
            width: 80,
            cellClassName: 'actions',
            filterable: true,
            disableExport: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (row.eadEmailRole === 1) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color='primary' />}
                            label="Client"
                            onClick={() => handleCheck(id, null)}
                            key={id}
                        />
                    ] 
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Client"
                        onClick={() => handleCheck(id, 1)}
                        key={id}
                    />
                ]
            },
        },
        {
            field: 'attorney',
            type: 'actions',
            headerName: 'Attorney',
            width: 80,
            cellClassName: 'actions',
            filterable: true,
            disableExport: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (row.eadEmailRole === 2) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color='primary' />}
                            label="Attorney"
                            onClick={() => handleCheck(id, null)}
                            key={id}
                        />
                    ] 
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Attorney"
                        onClick={() => handleCheck(id, 2)}
                        key={id}
                    />
                ]
            },
        },
        {
            field: 'adverse',
            type: 'actions',
            headerName: 'Adverse',
            width: 80,
            cellClassName: 'actions',
            filterable: true,
            disableExport: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (row.eadEmailRole === 3) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color='primary' />}
                            label="Adverse"
                            onClick={() => handleCheck(id, null)}
                            key={id}
                        />
                    ] 
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Adverse"
                        onClick={() => handleCheck(id, 3)}
                        key={id}
                    />
                ]
            },
        },
        {
            field: 'third-party',
            type: 'actions',
            headerName: '3rd Party',
            width: 80,
            cellClassName: 'actions',
            filterable: true,
            disableExport: false,
            getActions: ({ id, row } : { id: GridRowId, row: GridRowModel}) => {
                if (row.eadEmailRole === 4) {
                    return [
                        <GridActionsCellItem
                            icon={<CheckBoxIcon color='primary' />}
                            label="Third Party"
                            onClick={() => handleCheck(id, null)}
                            key={id}
                        />
                    ] 
                }
                return [
                    <GridActionsCellItem
                        icon={<CheckBoxOutlineBlankIcon />}
                        label="Third Party"
                        onClick={() => handleCheck(id, 4)}
                        key={id}
                    />
                ]
            },
        },
        
    ]


    //////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////// RENDER ////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////


    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <Paper 
            sx={{ 
                height: 'calc(100vh - 112Px)', 
                width: '100%',
                '& .custom-indent': {
                    paddingLeft: '40px !important',
                },
            }}
        >
            <DataGridPremium
                apiRef={apiRef}
                rows={rows} 
                columns={columns}
                pageSizeOptions={[25, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                slots={{
                    toolbar: CustomToolbar,
                    columnMenu: CustomGridColumnMenu,
                  }}
                slotProps={{ 
                    toolbar: { 
                        updateRows, 
                        handleAnalyze, 
                        csvOptions: {disableToolbarButton: true},
                        excelOptions: {disableToolbarButton: true},
                        printOptions: {hideFooter: true, hideToolbar: true, allColumns: true}
                    }
                }}
                sortingOrder={['desc', 'asc']}
                initialState={{
                    columns: { 
                        columnVisibilityModel: {eadName: false, domainDomain: true},
                    },
                    pinnedColumns: { right: ['client', 'attorney', 'adverse', 'third-party'] },
                    sorting: {
                        sortModel: [{ field: 'count', sort: 'desc' }]
                    },
                }}
                defaultGroupingExpansionDepth={-1}
                pagination
                disableRowSelectionOnClick
                checkboxSelection
                sx={{
                    '@media print': {
                      '.customButton': { display: 'none' },
                    },
                  }}
            />
            <ErrorModal 
                error={error || ''} 
                href={href} 
                resetError={resetError}
            />
        </Paper>
    );
}
