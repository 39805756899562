import React, { memo } from 'react'

import { ExSet } from 'generated/graphql'

import { Typography, Divider } from 'shared-components/material/core'


function Meta({ exSet } : { exSet: ExSet }) {

  const recsTotal = exSet.exhibits?.length

  return (
    <>
      <Typography variant="h5" component="h3">
        Records
      </Typography>
      <Divider />
      <Typography variant="body1" sx={{ mb: 4}}>
        <strong>Total Records:</strong> {recsTotal} <br />
      </Typography>

      <Typography variant="h5" component="h3">
        Exhibit Set Settings
      </Typography>
      <Divider />
      <Typography variant="body1" gutterBottom>
        <strong>Exhibit Set Prefix:</strong> {exSet.exSetPrefix} <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Exhibit Set Start:</strong> {exSet.exSetStart} <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Exhibit Set End:</strong> {exSet.exSetEnd} <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Exhibit Set Sequencing Type:</strong> {exSet.exSetSequencingType === 1 ? 'Numeric' : 'Alpha'} <br />
      </Typography>
    </>
  )
}

export default memo(Meta)
