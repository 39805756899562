import * as React from "react";
import { useCallback, useState } from 'react'

export function useError () {
    const [error, setError] = useState('')
    const [href, setHref] = useState('')

    const handleError = useCallback((e: unknown, href?: string) => {
        if (e instanceof Error) {
            console.error(e.message)
            setError(e.message)
        } else if (typeof e === 'string') {
            console.error(e)
            setError(e)
        }

        if (href) {
            setHref(href)
        }
    }, [])

    const resetError = useCallback(() => {
        setError('')
    }, [])

    return [ error, href, handleError, resetError ] as const
}


export function usePageLeave(cb : () => void, condition: boolean) {
  const onLeave = (e: any) => {
    if (condition) {
        const to = e.relatedTarget || e.toElement
        if (!to || to.nodeName === "HTML") {
          cb()
        }
    }
  }

  React.useEffect(() => {
    document.addEventListener("mouseout", onLeave)

    return () => {
      document.removeEventListener("mouseout", onLeave)
    }
  }, [condition])
}
