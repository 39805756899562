import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Button,
    Typography,
} from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'
import ClientEngagementModal from 'shared-components/modals/ClientEngagementModal'
import DiscoveryProtocolModal from 'shared-components/modals/DiscoveryProtocolModal'
import KickOffModal from 'shared-components/modals/KickOffModal/KickOffModal'

export const EDIT_DISPUTE = gql`
  mutation UpdateDispute($disputeId: Int!, $disputeGamePlanCallScheduled: Boolean) {
  updateDispute(disputeId: $disputeId, disputeGamePlanCallScheduled: $disputeGamePlanCallScheduled) {
    disputeId
  }
}
`

type ToDoProps = {
    disputeId: number,
    disputeClientKickoffEmailSent: boolean,
    disputeOpposingCounselEmailSent: boolean,
    disputeClientEngagementEmailSent: boolean,
    disputeGamePlanCallScheduled: boolean,
    refetchCase: () => void,
}

export default function ToDo({
    disputeId, 
    refetchCase, 
    disputeClientKickoffEmailSent, 
    disputeOpposingCounselEmailSent,
    disputeClientEngagementEmailSent,
    disputeGamePlanCallScheduled,
}: ToDoProps) {
    const [editDispute, { error: editDisputeError }] = useMutation(EDIT_DISPUTE)
    const [ openKickOffModal, setOpenKickOffModal ] = useState(false)
    const [ openDiscoveryProtocolModal, setOpenDiscoveryProtocolModal ] = useState(false)
    const [ openClientEngagementModal, setOpenClientEngagementModal ] = useState(false)

    const markGamePlanCallComplete = async () => {
        const variables = { disputeId, disputeGamePlanCallScheduled: true }
        await editDispute({ variables })
        refetchCase()
    }

    const list = [
        { 
            label: 'Game plan call',
            isDone: disputeGamePlanCallScheduled,
            action: () => markGamePlanCallComplete(),
            actionLabel: 'schedule'
        }, 
        { 
            label: 'Client engagement',
            isDone: disputeClientEngagementEmailSent,
            action: () => setOpenClientEngagementModal(true),
            actionLabel: 'send'
        }, 
        { 
            label: 'Client kick-off',
            isDone: disputeClientKickoffEmailSent,
            action: () =>setOpenKickOffModal(true),
            actionLabel: 'send',
            resend: true
        }, 
        {
            label: 'Discovery protocol',
            isDone: disputeOpposingCounselEmailSent,
            action: () => setOpenDiscoveryProtocolModal(true),
            actionLabel: 'send'
        }
    ]

    return (
        <>
            <Typography variant="h5" component="h2" gutterBottom>
                To Do
            </Typography>
            <List sx={{ width: 260, bgcolor: 'background.paper' }} disablePadding>
            {list.map((item) => {
                const labelId = `Communications To Do`;

                return (
                <ListItem
                    key={item.label}
                    secondaryAction={
                    <Button 
                        variant="outlined"
                        size="small" 
                        onClick={item.action}
                        disabled={item.isDone && !item.resend}
                    >
                        {item.resend && item.isDone ? 'resend' : item.actionLabel}
                    </Button>
                    }
                    disablePadding
                >
                    <ListItemIcon style={{ minWidth: 'auto'}}>
                        <Checkbox
                            edge="start"
                            checked={item.isDone || false}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            disabled={item.isDone}
                            sx={{ cursor: 'not-allowed', color: '#c5c5c5' }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={item.label} />
                </ListItem>
                );
            })}
            </List>
            <KickOffModal 
                openKickOffModal={openKickOffModal} 
                setOpenKickOffModal={setOpenKickOffModal}
                disputeId={disputeId}
                refetchCase={refetchCase}
            />
            <DiscoveryProtocolModal 
                openDiscoveryProtocolModal={openDiscoveryProtocolModal} 
                setOpenDiscoveryProtocolModal={setOpenDiscoveryProtocolModal}
                disputeId={disputeId}
                refetchCase={refetchCase}
            />
            <ClientEngagementModal
                openClientEngagementModal={openClientEngagementModal} 
                setOpenClientEngagementModal={setOpenClientEngagementModal}
                disputeId={disputeId}
                refetchCase={refetchCase}
            />
            <ErrorModal error={editDisputeError && editDisputeError.message || ''} />
        </>
    )
}
