import React, { memo } from 'react'
import moment from 'moment'
import { Dispute, Comm } from 'generated/graphql'

import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, 
  IconButton,
  Collapse,
  Typography} from 'shared-components/material/core'
import { KeyboardArrowUpIcon, KeyboardArrowDownIcon } from 'shared-components/material/icons'

function Row({ row, dispute } : { row: Comm, dispute: Dispute }) {
  const [open, setOpen] = React.useState(false);

  const { users } = dispute

  const renderUser = (userId: number) => {
    const user = users && users.filter(u => u && u.userId === userId)[0]
    return user ? user.userInitials : ''
  }

  const renderKind = (kind: number) => {
    if (kind === 1) {
      return 'Kick-off Email'
    }
    if (kind === 2) {
      return 'Client Document Request'
    }
    if (kind === 5) {
      return 'Order Print'
    }
    return ''
  }

  return (
    <>
      <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row && renderUser(row.commUserId as number)}</TableCell>
          <TableCell>{dispute.client && dispute.client.clientName}</TableCell>
          <TableCell>
            {row && row.commEmailAdds.map((e: string) => e && <span key={e}>{e}<br /></span>)}
          </TableCell>
          <TableCell>{row && renderKind(row.commKind as number)}</TableCell>
          <TableCell>{moment(row && row.commCreatedAt).format('MM/DD/YY')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 3 }}>
              <Typography variant="h3" gutterBottom>
                Custom Message:
              </Typography>
              <br />
              <Typography variant="body1" gutterBottom>
                {row.commCustomMessage}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Communications({ dispute }:{ dispute: Dispute }) {
  const { comms } = dispute

  return (
    <TableContainer component={Paper} sx={{ minWidth: '690px'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Custom Message</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Email Addresses</TableCell>
            <TableCell>Communication Type</TableCell>
            <TableCell>Date Sent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {comms && comms.map((comm) => 
              comm && <Row key={comm.commId} row={comm} dispute={dispute} />
            )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(Communications)


