
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'

import {
    DataGridPremium,
    GridRowModel,
    GridActionsCellItem,
    GridRowId,
    useGridApiRef,
    DataGridPremiumProps,
    GridRenderCellParams,
    GridSortItem,
    GridInitialState,
    GridRowSelectionModel,
} from '@mui/x-data-grid-premium'

import { useError } from 'shared-components/hooks'
import { CenteredContent } from 'shared-components/layout'
import {
    CircularProgress,
    Paper,
    Snackbar,
    MuiAlert,
    AlertProps,
    Tooltip,
} from 'shared-components/material/core'
import { PageviewIcon } from 'shared-components/material/icons'
import { ErrorModal, HoldEmailWarningModal, PayModal } from 'shared-components/modals'
import DetailPanel from './gridComponents/DetailPanel'
import CustomToolbar from './gridComponents/CustomToolbar'

import { getJobItems, putJobItem, postS3DownloadUrl} from 'api/jobs'
import "intro.js/introjs.css"
import { getEmlColumns } from './columns/emlColumns'
import { getDocColumns } from './columns/docColumns'
import { CustomGridColumnMenu } from './gridComponents/CustomGridColumnMenu'
import { getSearchRecs, putRecordBatch } from 'api/disputes'
import { SearchResultItem } from '../DocumentReview'

export const GET_DATA = gql`
    query ExSetsForDispute($disputeId: Int) {
        exSetsForDispute(disputeId: $disputeId) {
            exSetId
            exSetName
        }
        user {
            userId,
            userShowHoldWarning
        }
    }
`

export const ADD_REC_TO_EX_SET = gql`
    mutation AddRecToExSet($exSetId: Int!, $recType: String!, $recId: Int!) {
        addRecToExSet(exSetId: $exSetId, recType: $recType, recId: $recId) {
            exSetId
        }
    }
`

interface UpdateData {
    id: number,
    dataType: string,
    col: string,
    update: string | number | boolean | null
}

type FormData = {
    batchUpdate: string,
    filename?: string,
    subject?: string,
    folder?: string,
    date?: string,
    notes?: string,
    legalResearch?: string,
    flag?: string,
    check?: boolean,
}

type CheckData = {
    id: GridRowId,
    row: GridRowModel,
    col: string,
    update: boolean
}

export default function DocumentGrid({ 
    selectedItems,
    setSelectedItems,
    disputeId,
    jobStatus, 
    refetchJob 
} : {
    selectedItems: SearchResultItem[],
    setSelectedItems: Dispatch<SetStateAction<SearchResultItem[]>>
    disputeId: number,
    jobStatus: number, 
    refetchJob: () => void
}) {
    const { data, loading: dataLoading, error: dataError } = useQuery(GET_DATA, { variables: { disputeId }})
    const [addRec, { loading: addRecLoading, error: addRecError }] = useMutation(ADD_REC_TO_EX_SET, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })

    const apiRef = useGridApiRef()
    const [error, href, handleError, resetError ] = useError()
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 0,
      });
    const [loading, setLoading] = useState(true)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [jobItems, setJobItems] = useState<Array<Record<string, unknown>>>([])
    const [payModal, setPayModal] = useState(false)
    const [hasEmls, setHasEmls] = useState(false)
    const [hasDocs, setHasDocs] = useState(false)
    const [userId, setUserId] = useState(null)
    const [openHoldWarning, setOpenHoldWarning] = useState(false)
    const [holdCheckData, setHoldCheckData] = useState<CheckData | null>(null)
    const [savedState, setSavedState] = useState<GridInitialState | null>(null)

    const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

    const { jobId } = useParams<{ jobId: string }>()

    const JOB_ID = jobId ? parseInt(jobId) : 0

    const handleCloseSnackbar = () => setSnackbar(null)


    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // a. GET ROWS 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const getSelectedRows = () => Array.from(apiRef.current.getSelectedRows().values())

    const getAllJobItems = async () => {
        setLoading(true)
        const payload = await getJobItems(JOB_ID)
            .catch(e => handleError(e))
        if (payload.success) {
            setHasDocs(payload.data.hasDocs)
            setHasEmls(payload.data.hasEmls)
            setJobItems(payload.data.jobItems)
            setLoading(false)
            setUserId(payload.data.user.userId)
            if (payload.data.hasEmls && payload.data.user.userEmlReviewOrderPref) {
                setSavedState(payload.data.user.userEmlReviewOrderPref)
            }
            if (!payload.data.hasEmls && payload.data.user.userDocReviewOrderPref) {
                setSavedState(payload.data.user.userDocReviewOrderPref)
            }
        }
        setLoading(false)
        handleError(payload.err, payload.href)
    }

    const getSelectedSearchResultItems = async () => {
        setLoading(true)
        const searchResults = selectedItems
        const payload = await getSearchRecs(disputeId, searchResults)
            .catch(e => handleError(e))
            if (payload.success) {
                setHasDocs(payload.data.hasDocs)
                setHasEmls(payload.data.hasEmls)
                setJobItems(payload.data.searchRecs)
                setLoading(false)
                setUserId(payload.data.userId)
                if (payload.data.hasEmls && payload.data.user.userEmlReviewOrderPref) {
                    setSavedState(payload.data.user.userEmlReviewOrderPref)
                }
                if (!payload.data.hasEmls && payload.data.user.userDocReviewOrderPref) {
                    setSavedState(payload.data.user.userDocReviewOrderPref)
                }
            }
            setLoading(false)
            handleError(payload.err, payload.href)
    }

    const getJobItemsFn = async () => {
        if (selectedItems.length > 0) {
            return getSelectedSearchResultItems()
        }
        return getAllJobItems()
    }

    useEffect(() => {
        getJobItemsFn()
    }, [])

    const rows = jobItems

    const totalItems = rows.length

    const handleClearSearch = () => {
        setSelectedItems([])
        getAllJobItems()
    }

    const getDetailPanelContent = React.useCallback((row: GridRowModel) => <DetailPanel row={row} />, [])

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // b. ROW GROUPING

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const handlePreview = async (rowId: GridRowId, resource: string, itemId: string) => {
        apiRef.current.setRowSelectionModel([rowId]) // this selects the row and changes its color
        const payload = await postS3DownloadUrl(JOB_ID, resource, itemId, null)
            .catch(e => handleError(e))

        if (payload.success) {
            // const width = window.screen.width
            // if (width <= 1024) {
            //     return window.open(payload.data.signedGetUrl, '_self')
            // }
            return window.open(
                payload.data.signedGetUrl,
                'Document View',
                'width=1200,height=900,left=200,top=200',
              )
        } 
        
        return handleError(payload.err, payload.href)
    }

    const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
        let resource = ''
        let itemId = ''
        const { attId, docId, emlId, converted, id, recStatus } = props.row

        if (attId) {
            resource = 'att'
            itemId = attId
        } else if (docId){
            resource = 'doc'
            itemId = docId
        } else {
            resource = 'eml'
            itemId = emlId
        }

        const renderTitle = () => {
            if (recStatus === 0) {
                return "processing"
            }
            if (converted === true && recStatus === 10) {
                return 'Preview Document'
            }
            if (converted === false && recStatus === 10) {
                return 'Document not pdf convertible, cannot preview'
            }
            if (recStatus === null && converted === true) {
                return 'Preview Document'
            }
            return 'Error converting document.'
        }

        const renderColor = () => {
            if (recStatus === 0) {
                return 'disabled'
            }
            if (converted === true && recStatus === 10) {
                return 'primary'
            }
            if (converted === false && recStatus === 10) {
                return 'error'
            }
            if (recStatus === null && converted === true) {
                return 'primary'
            }
            return 'error'
        }

        return (
            <Tooltip
                title={renderTitle()}
            >
                <div>
                    <GridActionsCellItem
                        icon={<PageviewIcon color={renderColor()} />}
                        label="View"
                        onClick={() => handlePreview(id, resource, itemId)}
                        key={id}
                        disabled={!converted}
                    />
                </div>
            </Tooltip>
        )
    }

    const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => 
        hasEmls ? row.hierarchy : [row.docId]

    const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
        headerName: 'View',
        hideable: false,
        width: 52,
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
        cellClassName: "introView"
      }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // c. EDIT ROWS 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const putJobItemFn = React.useCallback(
        async (updateData: UpdateData) => {
        if (updateData.col) {
            const payload = await putJobItem(JOB_ID, updateData)
            .catch(e => handleError(e))

            if (payload.success) {
                return setSnackbar({ children: 'Item successfully saved', severity: 'success' })
            }
            return handleError(payload.err, payload.href)
        }
    }, [])

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            const { 
                emlId, 
                docId, 
                attId, 
                itemType, 
                notes, 
                filename, 
                folder, 
                date, 
                legalResearch, 
                flag,
                subject,
                exSet,
            } = newRow

            const updateData = {
                id: emlId || docId || attId,
                dataType: itemType,
                col: '',
                update: null,
            }

            if (oldRow.notes !== notes) {
                updateData.col = 'notes'
                updateData.update = newRow.notes
            }
            if (oldRow.filename !== filename) {
                updateData.col = 'filename'
                updateData.update = newRow.filename
            }
            if (emlId && oldRow.filename !== subject) {
                updateData.col = 'filename'
                updateData.update = newRow.subject
                newRow.filename = newRow.subject
            }
            if (oldRow.folder !== folder) {
                updateData.col = 'folder'
                updateData.update = newRow.folder
            }
            if (oldRow.date !== date) {
                updateData.col = 'date'
                updateData.update = newRow.date
                const formattedDate = date === null ? null : new Date(date).toISOString()
                newRow.date = formattedDate
            }
            if (oldRow.legalResearch !== legalResearch) {
                updateData.col = 'legalResearch'
                updateData.update = legalResearch
            }
            if (oldRow.flag !== flag) {
                updateData.col = 'flag'
                if (updateData.update === '#dedede') {
                    updateData.update = null
                } else {
                    updateData.update = flag
                }
            }
            if (oldRow.exSet !== exSet) {
                const variables ={ exSetId: exSet, recType: itemType, recId: emlId || docId || attId}
                await addRec({variables})
                return newRow
            }

            await putJobItemFn(updateData) // save in the backend
            return newRow // save in datagrid
        },
        [putJobItemFn],
    )

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' })
    }, [])

    const handleHoldCheck = (
        id: GridRowId,
        row: GridRowModel,
        col: string,
        update: boolean
    ) => {
        if (row.emlId && data.user.userShowHoldWarning && update === true) {
            setHoldCheckData({id, row, col, update})
            return setOpenHoldWarning(true)
        }
        return handleCheck({id, row, col, update})
    }

    const handleCheck = ({
        id,
        row,
        col,
        update
    }: CheckData) => {
        const { updateRows } = apiRef.current // update grid
        const { emlId, docId, attId, itemType } = row
        let catFinalStr
        let catFinal

        switch(col){
            case 'priv':
                catFinalStr = 'Privileged'
                catFinal = 1
                break
            case 'prod':
                catFinalStr = 'Produced'
                catFinal = 2
                break
            case 'irrl':
                catFinalStr = 'Irrelevant'
                catFinal = 4
                break
            default:
                catFinalStr = ''
        }

        if ((col === 'priv' || col === 'irrl') && update === true) {
            updateRows([{ id, redact: false, isKey: false, catFinal, catFinalStr }])
        } else if ((col === 'priv' || col === 'irrl') && update === false) {
            updateRows([{ id, redact: false, catFinal: null, catFinalStr }])
        } else if (col === 'prod') {
            if (update === false) {
                updateRows([{ id, catFinal: null, redact: false, catFinalStr }])
            } else {
                updateRows([{ id, catFinal, catFinalStr }])
            }
        } else {
            updateRows([{ id, [col]: update }])
        }
        
        const data = {
            id: emlId || docId || attId,
            dataType: itemType,
            col,
            update
        }
        putJobItemFn(data) //update database
    }

    const updateRows = async (formData: FormData) => {
        const selectedRows = getSelectedRows()
        const { batchUpdate, notes, legalResearch, filename, folder, date, flag, check } = formData
        let catFinal = 0
        let update: string | boolean | undefined

        switch(batchUpdate) {
            case 'notes':
                update = notes
                break
            case 'filename':
                update = filename
                break
            case 'folder':
                update = folder
                break
            case 'date':
                update = date
                break
            case 'legalResearch':
                update = legalResearch
                break
            case 'flag':
                update = flag
                break
            case 'priv':
                catFinal = 1
                update = check
                break
            case 'prod':
                catFinal = 2
                update = check
                break
            case 'irrl':
                catFinal = 4
                update = check
                break
            default:
                update = check
        }

        const gridUpdates = selectedRows.map(row => {
            if ((
                row.emlId !== null || 
                !row.nativeDownloadable || 
                jobStatus === 70 || 
                !row.converted) && batchUpdate === 'native'
            ) {
                return row
            }
            if ((
                batchUpdate === 'priv' || 
                batchUpdate === 'irrl') && update === true
            ) {
                return ({ 
                    id: row.id, 
                    catFinal,
                    redact: false
                })
            }
            if ((
                batchUpdate === 'priv' || 
                batchUpdate === 'irrl') && update === false && row.catFinal === catFinal
            ) {
                return ({ 
                    id: row.id, 
                    catFinal: null,
                    redact: false
                })
            }
            if (batchUpdate === 'prod' && update === true) {
                return ({ 
                    id: row.id, 
                    catFinal,
                })
            }
            if (batchUpdate === 'prod' && update === false && row.catFinal === catFinal) {
                return ({ 
                    id: row.id, 
                    catFinal: null
                })
            }
            return ({ 
                id: row.id, 
                [batchUpdate]: update
            })
        })

        const apiUpdate = {
            items: selectedRows.map(row => ({
                id: row.emlId || row.attId || row.docId,
                dataType: row.itemType
            })),
            col: batchUpdate,
            update
        }

        apiRef.current.updateRows(gridUpdates)

        const payload = await putRecordBatch(disputeId, apiUpdate)
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // d. GET COLUMNS

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const columns = useMemo(() => {
        if (hasEmls) {
            return getEmlColumns(handleCheck, handleHoldCheck, jobStatus)
        } else {
            return getDocColumns(handleCheck, handleHoldCheck, jobStatus)
        }
    }, [hasEmls, jobStatus])

    const getColumnState = () => apiRef.current.exportState()

    const renderVisibleColumns = () => {
        if (hasEmls) {
            return {
                cc: true,
                bcc: true,
                legalResearch: true,
                flag: true,
                notes: true,
                catEstimated: false,
                from: true,
                to: true,
                filepath: false,
                reviewInitials: false,
                reviewTimestamp: false,
                categorized: false,
                converted: false,
                native: false,
                redact: false,
                confidential: false,
                isKey: false,
                priv: true,
                hold: true,
                prod: true,
                irrel: true,
                isHold: false,
                catFinalStr: false,
                catEstimatedStr: false,
                outputName: false,
                sortAlpha: false,
                sortChrono: false,
                ogFilename: false,
                ogFolder: false,
                ogDate: false,
                ogSubject: false,
                folder: true,
                // exSet: false,
            }
        }
        return {
            cc: false,
            bcc: false,
            legalResearch: true,
            flag: true,
            notes: true,
            catEstimated: false,
            from: false,
            to: false,
            filepath: false,
            reviewInitials: false,
            reviewTimestamp: false,
            categorized: false,
            converted: false,
            native: false,
            redact: false,
            confidential: false,
            isKey: false,
            priv: true,
            prod: true,
            irrel: true,
            isHold: false,
            hold: true,
            catFinalStr: false,
            catEstimatedStr: false,
            outputName: false,
            sortAlpha: true,
            sortChrono: false,
            ogFilename: false,
            ogFolder: false,
            ogDate: false,
            ogSubject: false,
            folder: true,
            // exSet: false,
        }
    }

    const sortModel: GridSortItem[] = hasEmls 
        ? [{ field: 'date', sort: 'desc' }] 
        : [{ field: 'sortAlpha', sort: 'asc' }]

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // e. PRODUCE 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleProduce = () => togglePayModal()

    const togglePayModal = () => setPayModal(payModal => !payModal)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // f. RENDER

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null)

    if (loading || dataLoading || addRecLoading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <Paper 
            sx={{ 
                height: 'calc(100vh - 112Px)', 
                width: '100%',
                '& .custom-indent': {
                    paddingLeft: '40px !important',
                },
            }}
            className="documentSetList"
        >   
            <DataGridPremium
                apiRef={apiRef}
                rows={rows} 
                columns={columns!}

                // Grouping

                treeData // allows nesting of Atts
                groupingColDef={groupingColDef}
                getTreeDataPath={getTreeDataPath}
                defaultGroupingExpansionDepth={-1}

                // Pagination
                pagination
                pageSizeOptions={[25, 50, 100, totalItems]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}

                // Custom Components

                slots={{
                    toolbar: CustomToolbar,
                    columnMenu: CustomGridColumnMenu,
                  }}
                slotProps={{ 
                    filterPanel: {
                        columnsSort: 'asc'
                    },
                    panel: {
                        anchorEl: filterButtonEl,
                    },
                    toolbar: { 
                        hasDocs,
                        hasEmls, 
                        handleProduce, 
                        updateRows, 
                        getSelectedRows, 
                        getColumnState,
                        jobStatus, 
                        refetchJob, 
                        handleError,
                        setFilterButtonEl,
                        userId,
                        selectedItems,
                        handleClearSearch,
                    } 
                }}

                // Initial State

                sortingOrder={['desc', 'asc']}
                initialState={
                    savedState || {
                    columns: { 
                        columnVisibilityModel: renderVisibleColumns(),
                    },
                    pinnedColumns: { 
                        right: ['view', 'priv', 'prod', 'key', 'stmp', 'irrl', 'rdct', 'natv']
                    },
                    sorting: {
                        sortModel
                    },
                    pagination: { 
                        paginationModel: { page: 0, pageSize: 100 }
                    } 
                }
            }

                // Edit Rows

                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}

                disableRowSelectionOnClick
                checkboxSelection
                checkboxSelectionVisibleOnly
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                rowSelectionModel={rowSelectionModel}

                onFilterModelChange={() => setRowSelectionModel([])}

                // Detail panel

                getDetailPanelContent={(row) => getDetailPanelContent(row)}
                getDetailPanelHeight={() => 'auto'}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <ErrorModal 
                error={error || (dataError && dataError.message) || (addRecError && addRecError.message) || ''}
                href={href}
                resetError={resetError}
            />
            <HoldEmailWarningModal 
                openHoldWarning={openHoldWarning} 
                handleCheck={handleCheck} 
                holdCheckData={holdCheckData}
                userId={userId && userId}
            />
            {payModal && (
                <PayModal 
                    open={payModal}
                    togglePayModal={togglePayModal}
                    jobId={JOB_ID}
                    getJobItemsFn={getJobItemsFn}
                    refetchJob={refetchJob}
                />
            )}
        </Paper>
    );
}