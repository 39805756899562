import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BatesRec, Job } from 'generated/graphql'

import { Box, Tab, Tabs } from 'shared-components/material/core'

import Meta from './Meta'
import Payment from './Payment'
import EditJob from './EditJob'
import PSTFolders from './PSTFolders'
import Upload from './Upload/Upload'

type JobDataProps = {
    job: Job,
    refetch: () => void,
    batesRecs: BatesRec[],
    disputeBatesIntegrity: boolean,
}

type TabPanelProps = {
    children?: React.ReactNode,
    index: number,
    value: number,
}

export default function JobData({ job, refetch, batesRecs, disputeBatesIntegrity } : JobDataProps) {
    const [searchParams] = useSearchParams()
    const tab = searchParams.get('tab')
    const [value, setValue] = useState(0)

    useEffect(() => {
        if (tab) {
            setValue(parseInt(tab))
        }
    }, [tab])

    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
      }
    
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props
    
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`job-tabpanel-${index}`}
                aria-labelledby={`job-tab-${index}`}
                {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
            </div>
        )
    }
    
    const a11yProps = (index: number) => ({
        id: `job-tab-${index}`,
        'aria-controls': `job-tabpanel-${index}`,
    })

    if (!job.procUnits || job.procUnits.length < 1) {
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="case tabs">
                        {job.statusString && job.statusString.message === 'UPLOAD' ? (
                            <Tab label="Upload"  {...a11yProps(0)} />
                        ) : (
                            <Tab label="Meta" {...a11yProps(0)} />
                        )}
                        <Tab label="Payment" {...a11yProps(1)} /> 
                        <Tab label="Edit Document Set"  {...a11yProps(2)} />
                    </Tabs>
                </Box>
                {job.statusString && job.statusString.message === 'UPLOAD' ? (
                    <TabPanel value={value} index={0}>
                        <Upload jobId={job.jobId} disputeId={job.jobDisputeId || 0} refetchJob={refetch} />
                    </TabPanel>
                ) : (
                    <TabPanel value={value} index={0}>
                        <Meta job={job} />
                    </TabPanel>
                )}
                <TabPanel value={value} index={1}>
                    <Payment job={job} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EditJob 
                        job={job} 
                        refetch={refetch} 
                        batesRecs={batesRecs} 
                        disputeBatesIntegrity={disputeBatesIntegrity}
                    />
                </TabPanel>
            </Box>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="case tabs">
                    {job.statusString && job.statusString.message === 'UPLOAD' ? (
                        <Tab label="Upload"  {...a11yProps(0)} />
                    ) : (
                        <Tab label="Meta" {...a11yProps(0)} />
                    )}
                    <Tab label="Payment" {...a11yProps(1)} />
                    <Tab label="PST Folders" {...a11yProps(2)} /> 
                    <Tab label="Edit Document Set"  {...a11yProps(3)} />
                </Tabs>
            </Box>
            {job.statusString && job.statusString.message === 'UPLOAD' ? (
                <TabPanel value={value} index={0}>
                    <Upload jobId={job.jobId} disputeId={job.jobDisputeId || 0} refetchJob={refetch} />
                </TabPanel>
            ) : (
                <TabPanel value={value} index={0}>
                    <Meta job={job} />
                </TabPanel>
            )}
            <TabPanel value={value} index={1}>
                <Payment job={job} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PSTFolders job={job} refetch={refetch} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <EditJob 
                    job={job} 
                    refetch={refetch} 
                    batesRecs={batesRecs} 
                    disputeBatesIntegrity={disputeBatesIntegrity}
                />
            </TabPanel>
        </Box>
    )
}