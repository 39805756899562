import React from 'react'
import moment from 'moment'
import { Download, Job, User } from 'generated/graphql'
import { ApolloQueryResult } from '@apollo/client'

import { Typography, Link } from 'shared-components/material/core'
import { formatBytes } from 'shared-components/utils'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { postS3DownloadUrl } from 'api/jobs'

type FileMetaProps = { 
  job: Job,
  users: User[],
  downloads: Download[],
  refetchJob: (variables?: Partial<{ jobId: number | undefined}> | undefined) => Promise<ApolloQueryResult<unknown>>
}

export default function FileMeta({ job, users, downloads } : FileMetaProps) {
  const [error, href, handleError, resetError ] = useError()

  const handleGetSignedUrl = async (resource: string) => {
    const { jobId } = job
    const payload = await postS3DownloadUrl(jobId, resource, null, null)
      .catch(e => handleError(e))

    if (payload.success) {
      window.open(payload.data.signedGetUrl)
    } else {
        handleError(payload.err, payload.href)
    }
    
  }

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        File Data
      </Typography>

      {job.jobUploadFilename ? (
          <Typography variant="body1" gutterBottom>
            <strong>Document Set File:</strong> <Link component="button" variant="body1" onClick={() => handleGetSignedUrl('upload')} disabled={job.jobStatus && job.jobStatus <= 50 ? true : false}>{job.jobUploadFilename} - {formatBytes(job.jobUploadFilesize || 0)}</Link> <br />
          </Typography>
      ): null}

      {/* <BullsEye jobId={job.jobId} disputeId={job.jobDisputeId as number} refetchJob={refetchJob} /> */}

      {downloads.length > 0 ? (
        <Typography variant="body1">
          <strong>Download History</strong> <br />
          {downloads.map((download) => {
            const user = users.filter(user => user.userId === download.downloadUserId)[0]
            return (
              <span key={download.downloadId}>
                {moment(download.downloadCreatedAt).format("MM/DD/YY hh:mmA")} | {user.userFirstname} {user.userLastname}<br />
              </span>
            )
          })}
        </Typography>
      ) : null}

      <ErrorModal error={error} href={href} resetError={resetError} />
    </>
  )
}