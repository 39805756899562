import React from 'react'
import moment from 'moment'
import { Job } from 'generated/graphql'

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody } from 'shared-components/material/core'
import { addLeadingZeros } from 'shared-components/utils'

export default function DocumentSetsTable({ jobs, currentJobId } : { jobs: Job[], currentJobId: number }) {

  const renderBates = (job: Job) => {
    const jobBatesStart = job.jobBatesStart && job.jobBatesNumDigits 
      ? addLeadingZeros(job.jobBatesStart, job.jobBatesNumDigits) : ''

    const jobBatesEnd = job.jobBatesEnd && job.jobBatesNumDigits 
      ? addLeadingZeros(job.jobBatesEnd, job.jobBatesNumDigits) : ''

      if (job.jobBatesLength && job.jobBatesLength > 1) {
        return `${jobBatesStart} - ${jobBatesEnd}`
      }
      if (job.jobBatesLength === 1) {
        return jobBatesStart
      }
      return ''
    }

  const renderPriv = (job: Job) => {
    const jobPrivStart = job.jobPrivStart && job.jobPrivNumDigits 
      ? addLeadingZeros(job.jobPrivStart, job.jobPrivNumDigits) : ''
    const jobPrivEnd = job.jobPrivEnd && job.jobPrivNumDigits 
      ? addLeadingZeros(job.jobPrivEnd, job.jobPrivNumDigits) : ''
      
      if (job.jobPrivLength && job.jobPrivLength > 1) {
        return `${jobPrivStart} - ${jobPrivEnd}`
      }
      if (job.jobPrivLength === 1) {
        return jobPrivStart
      }
      return ''
    }

  const renderIrrel = (job: Job) => {
    const jobIrrelStart = job.jobIrrelStart && job.jobIrrelNumDigits 
      ? addLeadingZeros(job.jobIrrelStart, job.jobIrrelNumDigits) : ''
    const jobIrrelEnd = job.jobIrrelEnd && job.jobIrrelNumDigits 
      ? addLeadingZeros(job.jobIrrelEnd, job.jobIrrelNumDigits) : ''

      if (job.jobIrrelLength && job.jobIrrelLength > 1) {
        return `${jobIrrelStart} - ${jobIrrelEnd}`
      }
      if (job.jobIrrelLength === 1) {
        return jobIrrelStart
      }
      return ''
    }

  const jobArrayForSort = [...jobs]

  const sortedJobs = jobArrayForSort.sort((a, b) => 
    new Date(b.jobFinishedTimestamp).valueOf() - new Date(a.jobFinishedTimestamp).valueOf())

  return (
    <TableContainer component={Paper} sx={{ minWidth: '690px'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              DocumentSet
            </TableCell>
            <TableCell>
                Prefix
            </TableCell>
            <TableCell>
                Bates Range
            </TableCell>
            <TableCell>
                Priv Range
            </TableCell>
            <TableCell>
                Irrel Range
            </TableCell>
            <TableCell>
                Created
            </TableCell>
            <TableCell>
                Finished
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedJobs.map((job) => (
            <TableRow key={job.jobId} sx={job.jobId === currentJobId ? { backgroundColor: '#ddf7e9'} : null}>
              <TableCell>{job.jobName}</TableCell>
              <TableCell>{job.jobBatesPrefix}</TableCell>
              <TableCell>{renderBates(job)}</TableCell>
              <TableCell>{renderPriv(job)}</TableCell>
              <TableCell>{renderIrrel(job)}</TableCell>
              <TableCell>{moment(job.jobCreatedAt).format('MM/DD/YY')}</TableCell>
              <TableCell>
                {job.jobFinishedTimestamp 
                  ? moment(job.jobFinishedTimestamp).format('MM/DD/YY') 
                  : ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
