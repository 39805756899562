import React, { Dispatch, SetStateAction, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { Button } from 'shared-components/material/core'
import { TextInput } from 'shared-components/inputs'

type ClientsOptionsProps = { 
    setOpenAddClient: Dispatch<SetStateAction<boolean>>, 
    createFilteredClients: (value: string) => void,
    clearSearch: () => void,
}

export default function ClientsOptions({
    setOpenAddClient, 
    createFilteredClients, 
    clearSearch 
}: ClientsOptionsProps ) {
    const [clearButton, setClearButton] = useState(false)
    const schema = yup.object({
        clientSearch: yup.string(),
      }).required()

    type FormData = {
        clientSearch: string
    }

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            clientSearch: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, reset, formState: { errors }} = methods

    const onSubmit = (data: FormData) => {
        const searchString = data.clientSearch
        setClearButton(true)
        createFilteredClients(searchString)
    }

    const handleClear = () => {
        reset()
        setClearButton(false)
        clearSearch()
    }

  return (
    <FormProvider {...methods}>
        <Button
            variant="contained"
            color="secondary"
            sx={{ minHeight: '40px', mr: 2, minWidth: '115px' }}
            onClick={() => setOpenAddClient(true)}
        >
            Add Client
        </Button>
        <TextInput
            name={`clientSearch`}
            label="Search Clients"
            error={errors.clientSearch !== undefined ? true : false }
            errorMessage={errors.clientSearch ? errors.clientSearch.message : undefined}
            type="search"
            fullWidth
            sx={{ '& fieldset': { borderRadius: '4px 0 0 4px' }}}
        />
        <Button
            variant="contained" 
            color="primary"
            onClick={handleSubmit(onSubmit)}
            sx={{ borderRadius: '0 4px 4px 0 !important'}}
            disableElevation
        >
            Search
        </Button>
        {clearButton ? (
            <Button
            variant="outlined"
            sx={{ minHeight: '40px', ml: 2 }}
            onClick={handleClear}
        >
            Clear
        </Button>
        ) : null}
    </FormProvider>
  )
}
