
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useMemo } from 'react'

import {
    DataGridPremium,
    GridRowModel,
    GridActionsCellItem,
    GridRowId,
    useGridApiRef,
    DataGridPremiumProps,
    GridRenderCellParams,
    GridSortItem,
    GridInitialState,
} from '@mui/x-data-grid-premium'

import { useError } from 'shared-components/hooks'
import { CenteredContent } from 'shared-components/layout'
import {
    CircularProgress,
    Paper,
    Snackbar,
    MuiAlert,
    AlertProps,
    Tooltip,
} from 'shared-components/material/core'
import { PageviewIcon } from 'shared-components/material/icons'
import { ErrorModal, HoldEmailWarningModal } from 'shared-components/modals'
import DetailPanel from './gridComponents/DetailPanel'
import CustomToolbar from './gridComponents/CustomToolbar'

import { putJobItem, postS3DownloadUrl } from 'api/jobs'
import "intro.js/introjs.css"
import { getColumns } from './columns/columns'
import { CustomGridColumnMenu } from './gridComponents/CustomGridColumnMenu'
import { SearchResultItem } from '../CaseSearch'
import { getSearchRecs, putRecordBatch } from 'api/disputes'
import { useParams } from 'react-router-dom'
import Tour from 'shared-components/Tour/Tour'
import { gql, useQuery } from '@apollo/client'

export const GET_USER = gql`
    query User($userId: Int) {
        user(userId: $userId) {
            userId,
            userShowHoldWarning
        }
    }
`

interface UpdateData {
    id: number,
    dataType: string,
    col: string,
    update: string | number | boolean | null,
    jobId: number,
}

type FormData = {
    batchUpdate: string,
    filename?: string,
    subject?: string,
    folder?: string,
    date?: string,
    notes?: string,
    legalResearch?: string,
    flag?: string,
    check?: boolean,
}

type CheckData = {
    id: GridRowId,
    row: GridRowModel,
    col: string,
    update: boolean
}

const tourSteps = [
    {
      element: ".MuiDataGrid-pinnedColumnHeaders",
      intro: (
            <>
                <p>Use the checkboxes under these headers to mark items privileged, 
                    produced, key, stamp, irrelevant, hold, redact, or native.</p>
                <p>“Stamp” means add the word Confidential to every page of the 
                    selected document if you have a protective order in your case. 
                    “Native” gives you the option to keep Word, Excel and Powerpoint 
                    documents in their native format instead of converting to PDF.</p>
            </>
        )
    },
    {
      element: ".MuiDataGrid-columnHeaders",
      intro: (
            <p>All columns (other than the checkbox and view columns) are resizeable. 
                You can also change the order of columns, by simply dragging them and 
                dropping them. In addition, you can scroll left and right to see more columns.</p>
        )
    },
    {
      element: ".introNotes .MuiSvgIcon-root",
      intro: (
            <p>Some column headers have a pencil icon. Cells in these columns are editable.</p>
        )
    },
    {
      element: ".introView",
      intro: (
            <p>Click the magnifying glass to view the file, email, or attachment for the row.</p>
        )
    },
    {
      element: ".MuiDataGrid-cellCheckbox",
      intro: (
            <p>To change multiple rows, select them using these checkboxes. 
                An &ldquo;update selected rows&rdquo; option will appear at the top. 
                Use this feature to make the same changes to as many documents as you 
                want all in one click.</p>
        )
    },
    {
      element: ".MuiDataGrid-detailPanelToggleCell",
      intro: (
            <p>Click the &ldquo;+&rdquo; to easily add notes or research to an item.</p>
        )
    },
    {
      element: ".introColumns",
      intro: (
            <p>Click the &ldquo;Columns&rdquo; button to search, hide, or reveal columns.</p>
        )
    },
    {
      element: ".introFilters",
      intro: (
            <p>Click the &ldquo;Filters&rdquo; button to filter or search items in the table. 
                Note that you can turn on multiple filters, such as bringing up every email 
                <i> from</i> a certain address and <i>to</i> a certain address.</p>
        )
    },
    {
      element: ".introDensity",
      intro: (
            <p>Click the &ldquo;Density&rdquo; button to change the number of rows 
                that are visible in the table.</p>
        )
    },
    {
      element: ".introSaveColumns",
      intro: (
            <p>Click the &ldquo;Save Columns&rdquo; button to save the order, size, 
                and layout of your columns.</p>
        )
    },
    {
      element: ".introProduce",
      intro: (
            <p>After you complete your review, click the “Produce” button to 
                sort your production, add Bates numbers, and prepare a privilege 
                log and indexes of your document set.</p>
        )
    },
  ]

export default function DocumentGrid({ selectedItems }: { selectedItems: SearchResultItem[]}) {
    const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER)
    const { disputeId } = useParams<{ disputeId: string }>()
    const DISPUTE_ID = disputeId ? parseInt(disputeId) : 0
    const apiRef = useGridApiRef()
    const [error, href, handleError, resetError ] = useError()
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 0,
      });
    const [loading, setLoading] = useState(true)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [searchRecs, setSearchRecs] = useState<Array<Record<string, unknown>>>([])
    const [userId, setUserId] = useState(null)
    const [openHoldWarning, setOpenHoldWarning] = useState(false)
    const [holdCheckData, setHoldCheckData] = useState<CheckData | null>(null)
    const [savedState, setSavedState] = useState<GridInitialState>({})

    const handleCloseSnackbar = () => setSnackbar(null)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // a. GET ROWS 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const getSelectedRows = () => Array.from(apiRef.current.getSelectedRows().values())

    const getSearchRecsFn = async () => {
        const searchResults = selectedItems
        const payload = await getSearchRecs(DISPUTE_ID, searchResults)
            .catch(e => handleError(e))
        if (payload.success) {
            setSearchRecs(payload.data.searchRecs)
            setLoading(false)
            setUserId(payload.data.userId)
            setSavedState(payload.data.userDocReviewOrderPref)
        }
        setLoading(false)
        handleError(payload.err, payload.href)
    }

    useEffect(() => {
        getSearchRecsFn()
    }, [])

    const rows = searchRecs

    const totalItems = rows.length

    const getDetailPanelContent = React.useCallback((row: GridRowModel) => <DetailPanel row={row} />, [])

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // b. ROW GROUPING

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const handlePreview = async (rowId: GridRowId, resource: string, itemId: string, jobId: number) => {
        apiRef.current.setRowSelectionModel([rowId]) // this selects the row and changes its color
        const payload = await postS3DownloadUrl(jobId, resource, itemId, null)
            .catch(e => handleError(e))

        if (payload.success) {
            return window.open(
                payload.data.signedGetUrl,
                'Document View',
                'width=1200,height=900,left=200,top=200',
              )
        } 
        
        return handleError(payload.err, payload.href)
    }

    const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
        let resource = ''
        let itemId = ''
        const { attId, docId, emlId, converted, id, jobId } = props.row

        if (attId) {
            resource = 'att'
            itemId = attId
        } else if (docId){
            resource = 'doc'
            itemId = docId
        } else {
            resource = 'eml'
            itemId = emlId
        }

        const renderTitle = () => {
            if (converted === true) {
                return 'Preview Document'
            }
            if (converted === false) {
                return 'Document not pdf convertible, cannot preview'
            }
            return 'Error converting document.'
        }

        const renderColor = () => {
            if (converted === true) {
                return 'primary'
            }
            if (converted === false) {
                return 'disabled'
            }
            return 'error'
        }

        return (
            <Tooltip
                title={renderTitle()}
            >
                <div>
                    <GridActionsCellItem
                        icon={<PageviewIcon color={renderColor()} />}
                        label="View"
                        onClick={() => handlePreview(id, resource, itemId, jobId)}
                        key={id}
                        disabled={!converted}
                    />
                </div>
            </Tooltip>
        )
    }

    const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => 
        row.hierarchy ? row.hierarchy : [row.docId]

    const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
        headerName: 'View',
        hideable: false,
        width: 52,
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
        cellClassName: "introView"
      }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // c. EDIT ROWS 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const putJobItemFn = React.useCallback(
        async (updateData: UpdateData) => {
        if (updateData.col) {
            const payload = await putJobItem(updateData.jobId, updateData)
            .catch(e => handleError(e))

            if (payload.success) {
                return setSnackbar({ children: 'Item successfully saved', severity: 'success' })
            }
            return handleError(payload.err, payload.href)
        }
    }, [])

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            const { 
                emlId, 
                docId, 
                attId, 
                itemType, 
                notes, 
                filename, 
                folder, 
                date, 
                legalResearch, 
                flag,
                subject,
                jobId,
            } = newRow

            const updateData = {
                id: emlId || docId || attId,
                dataType: itemType,
                col: '',
                update: null,
                jobId
            }

            if (oldRow.notes !== notes) {
                updateData.col = 'notes'
                updateData.update = newRow.notes
            }
            if (oldRow.filename !== filename) {
                updateData.col = 'filename'
                updateData.update = newRow.filename
            }
            if (oldRow.filename !== subject) {
                updateData.col = 'filename'
                updateData.update = newRow.subject
                newRow.filename = newRow.subject
            }
            if (oldRow.folder !== folder) {
                updateData.col = 'folder'
                updateData.update = newRow.folder
            }
            if (oldRow.date !== date) {
                updateData.col = 'date'
                updateData.update = newRow.date
                const formattedDate = date === null ? null : new Date(date).toISOString()
                newRow.date = formattedDate
            }
            if (oldRow.legalResearch !== legalResearch) {
                updateData.col = 'legalResearch'
                updateData.update = legalResearch
            }
            if (oldRow.flag !== flag) {
                updateData.col = 'flag'
                if (updateData.update === '#dedede') {
                    updateData.update = null
                } else {
                    updateData.update = flag
                }
            }

            await putJobItemFn(updateData) // save in the backend
            return newRow // save in datagrid
        },
        [putJobItemFn],
    )

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' })
    }, [])

    const handleHoldCheck = (
        id: GridRowId,
        row: GridRowModel,
        col: string,
        update: boolean
    ) => {
        if (row.emlId && userData.user.userShowHoldWarning && update === true) {
            setHoldCheckData({id, row, col, update})
            return setOpenHoldWarning(true)
        }
        return handleCheck({id, row, col, update})
    }

    const handleCheck = ({
        id,
        row,
        col,
        update
    }: CheckData) => {
        const { updateRows } = apiRef.current // update grid
        const { emlId, docId, attId, itemType, jobId } = row
        let catFinalStr
        let catFinal

        switch(col){
            case 'priv':
                catFinalStr = 'Privileged'
                catFinal = 1
                break
            case 'prod':
                catFinalStr = 'Produced'
                catFinal = 2
                break
            case 'irrl':
                catFinalStr = 'Irrelevant'
                catFinal = 4
                break
            default:
                catFinalStr = ''
        }

        if ((col === 'priv' || col === 'irrl') && update === true) {
            updateRows([{ id, redact: false, isKey: false, catFinal, catFinalStr }])
        } else if ((col === 'priv' || col === 'irrl') && update === false) {
            updateRows([{ id, redact: false, catFinal: null, catFinalStr }])
        } else if (col === 'prod') {
            if (update === false) {
                updateRows([{ id, catFinal: null, redact: false, catFinalStr }])
            } else {
                updateRows([{ id, catFinal, catFinalStr }])
            }
        } else {
            updateRows([{ id, [col]: update }])
        }
        
        const data = {
            id: emlId || docId || attId,
            dataType: itemType,
            col,
            update,
            jobId
        }
        putJobItemFn(data) //update database
    }

    const updateRows = async (formData: FormData) => {
        const selectedRows = getSelectedRows()
        const { batchUpdate, notes, legalResearch, filename, folder, date, flag, check } = formData
        let catFinal = 0
        let update: string | boolean | undefined

        switch(batchUpdate) {
            case 'notes':
                update = notes  
                break
            case 'filename':
                update = filename
                break
            case 'folder':
                update = folder
                break
            case 'date':
                update = date
                break
            case 'legalReserch':
                update = legalResearch
                break
            case 'flag':
                update = flag
                break
            case 'priv':
                catFinal = 1
                update = check
                break
            case 'prod':
                catFinal = 2
                update = check
                break
            case 'irrl':
                catFinal = 4
                update = check
                break
            default:
                update = check
        }

        const gridUpdates = selectedRows.map(row => {
            if ((
                row.emlId !== null || 
                !row.nativeDownloadable || 
                // jobStatus === 70 || 
                !row.converted) && batchUpdate === 'native'
            ) {
                return row
            }
            if ((
                batchUpdate === 'priv' || 
                batchUpdate === 'irrl') && update === true
            ) {
                return ({ 
                    id: row.id, 
                    catFinal,
                    redact: false
                })
            }
            if ((
                batchUpdate === 'priv' || 
                batchUpdate === 'irrl') && update === false && row.catFinal === catFinal
            ) {
                return ({ 
                    id: row.id, 
                    catFinal: null,
                    redact: false
                })
            }
            if (batchUpdate === 'prod' && update === true) {
                return ({ 
                    id: row.id, 
                    catFinal,
                })
            }
            if (batchUpdate === 'prod' && update === false && row.catFinal === catFinal) {
                return ({ 
                    id: row.id, 
                    catFinal: null
                })
            }
            return ({ 
                id: row.id, 
                [batchUpdate]: update
            })
        })

        const apiUpdate = {
            items: selectedRows.map(row => ({
                id: row.emlId || row.attId || row.docId,
                dataType: row.itemType
            })),
            col: batchUpdate,
            update
        }

        apiRef.current.updateRows(gridUpdates)

        const payload = await putRecordBatch(DISPUTE_ID, apiUpdate) // Ian to create new route for jobIds per row
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
            getSearchRecsFn()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // d. GET COLUMNS

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const columns = useMemo(() => getColumns(handleCheck, handleHoldCheck), [])

    const getColumnState = () => apiRef.current.exportState()

    const renderVisibleColumns = () => ({
        cc: true,
        bcc: true,
        legalResearch: true,
        flag: true,
        notes: true,
        catEstimated: false,
        from: true,
        to: true,
        filepath: false,
        reviewInitials: false,
        reviewTimestamp: false,
        categorized: false,
        native: false,
        redact: false,
        confidential: false,
        isKey: false,
        priv: true,
        hold: true,
        prod: true,
        irrel: true,
        isHold: false,
        catFinalStr: false,
        catEstimatedStr: false,
        outputName: false,
        sortAlpha: false,
        sortChrono: false,
        ogFilename: false,
        ogFolder: false,
        ogDate: false,
        ogSubject: false,
        folder: true,
    })

    const sortModel: GridSortItem[] = [{ field: 'sortAlpha', sort: 'asc' }]

    // const sortModel: GridSortItem[] = hasEmls 
    //     ? [{ field: 'date', sort: 'desc' }] 
    //     : [{ field: 'sortAlpha', sort: 'asc' }]


    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // f. RENDER

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null)

    if (loading || userLoading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <Paper 
            sx={{ 
                height: 'calc(100vh - 112Px)', 
                width: '100%',
                '& .custom-indent': {
                    paddingLeft: '40px !important',
                },
            }}
            className="documentSetList"
        >
            
            <DataGridPremium
                apiRef={apiRef}
                rows={rows} 
                columns={columns!}

                // Grouping

                treeData // allows nesting of Atts
                groupingColDef={groupingColDef}
                getTreeDataPath={getTreeDataPath}
                defaultGroupingExpansionDepth={-1}

                // Pagination
                pagination
                pageSizeOptions={[25, 50, 100, totalItems]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}

                // Custom Components

                slots={{
                    toolbar: CustomToolbar,
                    columnMenu: CustomGridColumnMenu,
                  }}
                slotProps={{ 
                    filterPanel: {
                        columnsSort: 'asc'
                    },
                    panel: {
                        anchorEl: filterButtonEl,
                    },
                    toolbar: { 
                        updateRows, 
                        getSelectedRows, 
                        getColumnState,
                        setFilterButtonEl,
                        userId,
                    } 
                }}

                // Initial State

                sortingOrder={['desc', 'asc']}
                initialState={savedState || {
                    columns: { 
                        columnVisibilityModel: renderVisibleColumns(),
                    },
                    pinnedColumns: { 
                        right: ['view', 'priv', 'prod', 'key', 'stmp', 'irrl', 'rdct', 'natv']
                    },
                    sorting: {
                        sortModel
                    },
                    pagination: { 
                        paginationModel: { page: 0, pageSize: 100 }
                    } 
                }}

                // Edit Rows

                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}

                disableRowSelectionOnClick
                checkboxSelection
                checkboxSelectionVisibleOnly

                // Detail panel

                getDetailPanelContent={(row) => getDetailPanelContent(row)}
                getDetailPanelHeight={() => 'auto'}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <ErrorModal 
                error={error || (userError && userError.message) || ''}
                href={href}
                resetError={resetError}
            />
            <HoldEmailWarningModal 
                openHoldWarning={openHoldWarning} 
                handleCheck={handleCheck} 
                holdCheckData={holdCheckData}
                userId={userId && userId}
            />
            <Tour tourSteps={tourSteps} />
        </Paper>
    );
}