import React, { useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'

import {
    Box,
    Button,
    Typography,
    AccordionDetails,
    styled,
    Divider,
    CircularProgress,
} from 'shared-components/material/core'
import { TextInput, SelectInput } from 'shared-components/inputs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import { BatesRec } from 'generated/graphql'
import { ExpandMore } from 'shared-components/material/icons'
import { AddBatesModal } from 'shared-components/modals'

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { CenteredContent, RightAlignedForm } from 'shared-components/layout'
import "intro.js/introjs.css"
import Tour from 'shared-components/Tour/Tour'

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    border: 'none',
    '&:before': {
      display: 'none',
    },
    width: '100%',
  }))
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }))

export const ADD_JOB = gql`
  mutation AddJob(
        $jobName: String!,
        $jobBatesRecId: Int!,
        $jobBypassBates: Boolean!,
        $jobDisputeId: Int,
        $jobCustomConfidential: String
    ) {
    addJob(
        jobName: $jobName, 
        jobBatesRecId: $jobBatesRecId, 
        jobBypassBates: $jobBypassBates, 
        jobDisputeId: $jobDisputeId,
        jobCustomConfidential: $jobCustomConfidential
    ) {
      jobId
    }
  }
`

const schema = yup.object({
    jobName: yup.string().required('Document set name required'),
    jobBatesRecId: yup.number().required('Bates prefix required').integer(),  
    jobCustomConfidential: yup.string(),
}).required()

type AddJobProps = {
    batesRecs: BatesRec[]
    disputeId: number,
    refetchCase: () => void
    disputeBatesIntegrity: boolean | null | undefined,
}

type FormData = {
    jobName: string,
    jobBatesRecId: number,
    jobCustomConfidential: string,
}

type Variables = FormData & { jobDisputeId: number}

const tourSteps = [
    {
      element: ".addDocumentSetForm",
      intro: 'To create your first document set, give it a name and click the "Add" button.'
    },
  ]

export default function AddJob({ 
    batesRecs, 
    refetchCase, 
    disputeId, 
    disputeBatesIntegrity, 
}: AddJobProps) {
    const [addJob, { error: addJobError, loading: addJobLoading }] = useMutation(ADD_JOB)
    const [openAddBates, setOpenAddBates] = useState(false)
    const batesOptions = batesRecs.map(batesRec => {
        return {
            label: `Prefix: ${batesRec.batesRecPrefix} - Next bates: ${batesRec.batesRecNextBatesStr} - Next Priv: ${batesRec.batesRecNextPrivStr}`,
            value: batesRec.batesRecId
        }
    })

    batesOptions.push({ 
        label: 'No Bates numbers',
        value: 0
    })

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            jobName: '', 
            jobBatesRecId: batesRecs.length === 1 ? batesRecs[0].batesRecId : 0, 
            jobCustomConfidential: 'Confidential',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors } } = methods

    const onSubmit = async (formData: FormData) => {
        if (disputeId) {
            let variables: Variables = Object.assign(formData, {jobDisputeId: disputeId})
            if (variables.jobBatesRecId === 0) {
                variables = Object.assign(variables, { jobBypassBates: true })
            } else {
                variables = Object.assign(variables, { jobBypassBates: false })
            }
            await addJob({ variables })
            await refetchCase()
        } else {
            throw new Error("Case does not exist")
        }
    }

    if (addJobLoading) {
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    }

    return (
        <FormProvider {...methods} >
                <Typography variant="h5" component="h2" sx={{ mt: 2}}>
                    Create Document Set
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <RightAlignedForm>
                    <div className='addDocumentSetForm'>
                        <TextInput
                            name='jobName'
                            label='Document Set Name'
                            required
                            error={errors.jobName !== undefined ? true : false}
                            errorMessage={errors.jobName ? errors.jobName.message : undefined}
                            sx={{ mb: 2 }}
                            // onFocus={() => setStepsEnabled(false)}
                            fullWidth
                        />
                        {disputeBatesIntegrity && (
                            <Box sx={{ display: 'flex', mt: 2, mb: 2}}>
                                <SelectInput
                                    name="jobBatesRecId"
                                    label="Bates Prefix"
                                    options={batesOptions}
                                    required
                                    error={errors.jobBatesRecId !== undefined ? true : false}
                                    errorMessage={errors.jobBatesRecId ? errors.jobBatesRecId.message : undefined}
                                    fullWidth
                                />
                                <Button
                                    onClick={() => setOpenAddBates(true)}
                                    variant="outlined"
                                    sx={{ height: '40px', ml: 1 }}
                                >
                                    Add Prefix
                                </Button>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, maxWidth: "320px" }}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="advanced-options"
                                id="advanced-options"
                                >
                                    <Typography>Advanced Options</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingTop: '2em' }}>
                                    <TextInput
                                        name='jobCustomConfidential'
                                        label='Custom Stamp'
                                        required
                                        error={errors.jobCustomConfidential !== undefined ? true : false}
                                        errorMessage={errors.jobCustomConfidential ? errors.jobCustomConfidential.message : undefined}
                                        helperText="This stamp will only be applied to documents checked 'STMP' during review."
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Button 
                            sx={{ mt: 2 }} 
                            type="submit" 
                            variant="contained" 
                            color="secondary" 
                            onClick={handleSubmit(onSubmit)}
                        >
                            Add
                        </Button>
                    </div>
                </RightAlignedForm>
                
            <AddBatesModal
                openAddBates={openAddBates}
                setOpenAddBates={setOpenAddBates}
                refetch={refetchCase}
                batesRecDisputeId={disputeId ? disputeId : 0}
            />
            <Tour tourSteps={tourSteps} />
            <ErrorModal error={(addJobError && addJobError.message) || ''} />
         </FormProvider>
    )
}
