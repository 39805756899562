import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import moment from 'moment'
import validator from 'validator'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  visuallyHidden,
} from 'shared-components/material/core'


type Dispute = {
  disputeId: number,
  disputeMatter: string,
  disputeName: string,
  disputeCreatedAt: string,
  disputeStatus: number,
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof Dispute>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

type HeadCell = {
  disablePadding: boolean,
  id: keyof Dispute,
  label: string,
  numeric: boolean,
  mobile: boolean,
}

const headCells: readonly HeadCell[] = [
    {
      id: 'disputeName',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      mobile: true,
    },
    {
      id: 'disputeMatter',
      numeric: true,
      disablePadding: false,
      label: 'Matter',
      mobile: false,
    },
    {
      id: 'disputeCreatedAt',
      numeric: true,
      disablePadding: false,
      label: 'Date Created',
      mobile: false,
    },
  ]

  type EnhancedTableProps = {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Dispute) => void,
    order: Order,
    orderBy: string,
    rowCount: number,
  }

function EnhancedTableHead(props: EnhancedTableProps) {
  const mobile = useMediaQuery('(max-width: 767px)')
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof Dispute) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => mobile && headCell.mobile || !mobile ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
        ) : null )}
      </TableRow>
    </TableHead>
  )
}

export default function CasesTable({ disputes }: { disputes: Dispute[] }) {
  const mobile = useMediaQuery('(max-width: 767px)')
  const navigate = useNavigate()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Dispute>('disputeName')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Dispute,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (event: React.MouseEvent<unknown>, disputeId: number) => 
    navigate(`/app/cases/${disputeId}`)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - disputes.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }} >
        <TableContainer>
          <Table
            sx={{ height: '100%', maxWidth: '100%'}}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={disputes.length}
            />
            <TableBody>
              {disputes.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({
                  disputeId,
                  disputeName,
                  disputeMatter,
                  disputeCreatedAt,
                }) => {
                    return (
                        <TableRow
                            hover
                            onClick={(event) => handleClick(event, disputeId)}
                            tabIndex={-1}
                            key={disputeId}
                            style={{ cursor: 'pointer' }}
                        >
                            <TableCell
                              component="th"
                              id={disputeName}
                              scope="row"
                              padding="normal"
                            >
                              {validator.unescape(disputeName)}
                            </TableCell>
                            {!mobile ? (
                              <>
                                <TableCell align="right">{disputeMatter}</TableCell>
                                <TableCell align="right">
                                  {moment(disputeCreatedAt).format('MM/DD/YY')}
                                </TableCell>
                              </>
                            ) : null}
                            
                        </TableRow>
                        )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={disputes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
