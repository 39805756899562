import React from 'react'
import { AdapterDayjs, LocalizationProvider, DateTimePicker, TextField } from 'shared-components/material/core'
import { useFormContext, Controller } from "react-hook-form"

type FormValues = {
    name: string, 
    label: string,
    error?: boolean,
    errorMessage?: string,
}

export default function DateTimeInput({
    name,
    label,
    error=false,
    errorMessage, 
    }: FormValues) {
    const { control } = useFormContext()
    return <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} error={error} helperText={errorMessage} />}
                    label={label}
                    value={value}
                    onChange={onChange}
                />
            </LocalizationProvider>
        )}
        />
}
