import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExSet } from 'generated/graphql'
import { ApolloQueryResult } from '@apollo/client'

import { 
  Typography,
  Button,
  Box,
  Tooltip,
  Snackbar,
  MuiAlert,
  AlertProps,
} from 'shared-components/material/core'
import { formatBytes } from 'shared-components/utils'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { exSetStatuses } from 'shared-components/utils'

import { postS3DownloadUrl } from 'api/jobs'
import Upload from '../ExSetData/Upload/Upload'
import { getS3ArchiveUrl } from 'api/exSets'
import PrintEmailModal from 'shared-components/modals/PrintEmailModal/PrintEmailModal'

type FileMetaProps = { 
  exSet: ExSet,
  refetchExSet: (variables?: Partial<{ exSetId: number | undefined}> | undefined) => 
    Promise<ApolloQueryResult<unknown>>
}

export default function FileMeta({ exSet, refetchExSet } : FileMetaProps) {
  const navigate = useNavigate()
  const [error, href, handleError, resetError ] = useError()
  const [openPrintEmailModal, setOpenPrintEmailModal] = useState(false)
  const { jobs, exSetStatus, exSetId } = exSet
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleGetDownload = async () => {
    const payload = await getS3ArchiveUrl(exSetId)
      .catch(e => handleError(e))

    if (payload.success) {
      window.open(payload.data.signedGetUrl)
      refetchExSet()
    } else {
        handleError(payload.err, payload.href)
    }
}

  const handleGetSignedUrl = async (resource: string, jobId: number) => {
    const payload = await postS3DownloadUrl(jobId, resource, null, null)
      .catch(e => handleError(e))

    if (payload.success) {
      window.open(payload.data.signedGetUrl)
    } else {
        handleError(payload.err, payload.href)
    }
    
  }

  const handlePrint = () => setOpenPrintEmailModal(true)

  const handleReview = () => navigate(`/app/cases/${exSet.exSetDisputeId}/exhibit-set/${exSet.exSetId}/exhibit-set-review`)

  const handleCloseSnackbar = () => setSnackbar(null) 

  const renderActionButtons = () => {
    if (exSetStatus === 70) { // Download
      return (
        <>
          <Button
            variant="contained"
            onClick={handleReview}
            color="primary"
          >
            Review
          </Button>
          <Button
            variant="contained"
            onClick={handleGetDownload}
            color="primary"
            sx={{ml: 1}}
          >
            Download
          </Button>
          <Tooltip title="Order Printed Exhibit Binders">
            <Button
              variant="contained"
              onClick={handlePrint}
              color="secondary"
              sx={{ml: 1}}
            >
              Order Print
            </Button>
          </Tooltip>
        </>
      )
    }
    if (exSetStatus === 75) { // Download
      return (
        <>
          <Button
            variant="contained"
            onClick={handleReview}
            color="primary"
          >
            Review
          </Button>
          <Button
            variant="contained"
            onClick={handleGetDownload}
            color="primary"
            sx={{ml: 1}}
          >
            Download
          </Button>
          <Tooltip title="Order Printed Exhibit Binders">
            <Button
              variant="contained"
              onClick={handlePrint}
              color="secondary"
              sx={{ml: 1}}
              disabled
            >
              Print Ordered
            </Button>
          </Tooltip>
        </>
      )
    }
    if (exSetStatus === 60 || exSetStatus === 59) { // Review, Unpaid
      return (
          <Button
            variant="contained"
            onClick={handleReview}
            color="secondary"
          >
            Review
          </Button>
      )
    }
    return (
      <Button
        variant="contained"
        color="secondary"
        disabled={exSetStatus !== 60 && exSetStatus !== 70} // Review, Download
      >
        {exSetStatuses[exSetStatus as number].message}
      </Button>
    )
  }

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        File Data
      </Typography>

      {jobs?.map((j, i) => (
        <Typography key={j?.jobId} variant="body1" gutterBottom>
          <strong>Exhibit Set File {i + 1}:</strong> <Button size="small" variant="text" onClick={() => j && j.jobId && handleGetSignedUrl('upload', j.jobId)} disabled={j?.jobStatus as number <= 50}>{j?.jobUploadFilename}</Button> <br />
          <strong>File Size:</strong> {formatBytes(j?.jobUploadFilesize || 0)}
        </Typography>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', m: 3}}>
        {renderActionButtons()}
      </Box>
      <Upload exSetId={exSet.exSetId} refetch={refetchExSet} />
      <PrintEmailModal
          openPrintEmailModal={openPrintEmailModal}
          setOpenPrintEmailModal={setOpenPrintEmailModal}
          exSetId={exSetId}
          refetchExSet={refetchExSet}
      />
      <ErrorModal error={error} href={href} resetError={resetError} />
      {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
    </>
  )
}