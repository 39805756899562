import React from 'react'
import moment from 'moment'
import { Button } from 'shared-components/material/core'
import {
    GridRenderCellParams,
    GridValueFormatterParams,
    GridValueGetterParams,
} from '@mui/x-data-grid-premium'

import { currencyFormat } from 'shared-components/utils'

export const getColumns = (handleGetSignedUrl: (jobId: number) => Promise<void>) => {
    return [
        {
            field: 'clientName',
            headerName: 'Client',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.dispute.clientName
        },
        {
            field: 'disputeName',
            headerName: 'Case',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.dispute.disputeName
        },
        {
            field: 'jobName',
            headerName: 'Document Set',
            width: 200
        },
        {
            field: 'jobFinishedTimestamp', 
            headerName: 'Date',
            type: 'dateTime',
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (!params.value) {
                    return null
                }
                return moment(params.value).format('MM/DD/YYYY')
            }
        },
        {
            field: 'jobCostActual',
            headerName: 'Invoiced',
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value === null) {
                    return null
                }
                return currencyFormat(params.value)
            }
        },
        {
            field: 'billedAmount',
            headerName: 'Billed',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => {
                if (!params.row.jobReceipt.amount) {
                    return null
                }
                return currencyFormat(params.row.jobReceipt.amount)
            }
        },
        {
            field: 'jobId',
            headerName: 'Invoice',
            filterable: false,
            sortable: false,
            exportable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    variant="contained"
                    sx={{ width: '135px' }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleGetSignedUrl(params.value)
                    }}
                >
                    Invoice
                </Button>
            )
        }
    
    ]
}