import React, { Dispatch, SetStateAction } from 'react';
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CenteredForm } from 'shared-components/layout'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'shared-components/material/core'
import { SelectInput, TextInput } from 'shared-components/inputs'
import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client'
import ErrorModal from 'shared-components/modals/ErrorModal'

export const ADD_TICKET = gql`
  mutation Mutation($ticketParams: JSON, $ticketStatus: Int, $ticketOs: String, $ticketBrowser: String, $ticketSubject: String, $ticketFeedback: String) {
  addTicket(ticketParams: $ticketParams, ticketStatus: $ticketStatus, ticketOs: $ticketOs, ticketBrowser: $ticketBrowser, ticketSubject: $ticketSubject, ticketFeedback: $ticketFeedback) {
    ticketId
  }
}
`

const schema = yup.object({
    ticketSubject: yup.string().required('Client name required'),
    ticketFeedback: yup.string().required('Client id required'),
    ticketOs: yup.string(),
    ticketBrowser: yup.string(),
  }).required()

type FormData = {
    ticketSubject: string;
    ticketFeedback: string;
    ticketOs: string;
    ticketBrowser: string;
}

type FeedbackModalProps = {
    openFeedback: boolean,
    setOpenFeedback: Dispatch<SetStateAction<boolean>>,
}

const osOptions = [
    { label: 'Windows', value: 'windows' },
    { label: 'MacOS', value: 'MacOs' },
]
const browserOptions = [
    { label: 'Brave', value: 'brave' },
    { label: 'Chrome', value: 'chrome' },
    { label: 'Edge', value: 'edge' },
    { label: 'Firefox', value: 'firefox' },
    { label: 'Opera', value: 'opera' },
    { label: 'Safari', value: 'safari' },
]

export default function FeedbackModal({ openFeedback, setOpenFeedback }: FeedbackModalProps) {
    const [addTicket, { error }] = useMutation(ADD_TICKET)
    const ticketParams = useParams()
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            ticketSubject: '',
            ticketFeedback: '',
            ticketOs: '',
            ticketBrowser: ''
        },
        resolver: yupResolver(schema),
    });

    const { handleSubmit, reset, formState: { errors } } = methods

    const onSubmit = async (data: FormData) => {
        const variables = Object.assign(data, { ticketParams })
        await addTicket({ variables })
        setOpenFeedback(false)
        reset()
    }

    return (
        <Dialog open={openFeedback} onClose={() => setOpenFeedback(false)}>
            <DialogTitle>Provide Feedback or Report an Issue</DialogTitle>
            <FormProvider {...methods} >
                <DialogContent>
                    <CenteredForm>
                        <SelectInput
                            name="ticketOs"
                            label="Operating System"
                            options={osOptions}
                            required
                            error={errors.ticketOs !== undefined ? true : false}
                            errorMessage={errors.ticketOs ? errors.ticketOs.message : undefined}
                            fullWidth
                        />
                        <SelectInput
                            name="ticketBrowser"
                            label="Browser"
                            options={browserOptions}
                            required
                            error={errors.ticketBrowser !== undefined ? true : false}
                            errorMessage={errors.ticketBrowser ? errors.ticketBrowser.message : undefined}
                            fullWidth
                        />
                        <TextInput
                            name="ticketSubject"
                            label="Subject"
                            required
                            error={errors.ticketSubject !== undefined ? true : false}
                            errorMessage={errors.ticketSubject ? errors.ticketSubject.message : undefined}
                        />
                        <TextInput
                            name="ticketFeedback"
                            label="Feedback"
                            required
                            error={errors.ticketFeedback !== undefined ? true : false}
                            errorMessage={errors.ticketFeedback ? errors.ticketFeedback.message : undefined}
                            multiline
                            rows={10}
                        />
                    </CenteredForm>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenFeedback(false)
                            reset()
                        }}
                    >
                            Cancel
                        </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSubmit(onSubmit)}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </FormProvider>
            <ErrorModal error={error ? error.message : ''} />
        </Dialog>
    );
}
