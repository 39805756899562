import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import { useError } from 'shared-components/hooks'
import { Typography, Box, CircularProgress, Divider, Button } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

import { getKickoffEmailBody, sendKickoffEmail } from 'api/disputes'

const GET_USER = gql`
    query Query {
        user {
            userEmail
        }
    }
`

type ClientUploadConfirmProps = {
    disputeId: number,
    clientEmails: string,
    customMessage: string,
    handleBack: () => void,
    refetchCase: () => void,
    setOpenKickOffModal: Dispatch<SetStateAction<boolean>>,
}

export default function ClientEmailConfirm({
    disputeId,
    clientEmails,
    customMessage,
    handleBack,
    refetchCase,
    setOpenKickOffModal,
}: ClientUploadConfirmProps) {
    const [ error, href, handleError, resetError ] = useError()
    const [ loading, setLoading ] = useState(false)
    const [ defaultBody, setDefaultBody] = useState('')
    const { error: userError, loading: userLoading, data: userData } = useQuery(GET_USER)

    let errMsg

    if (error){
        errMsg = error
    } else if (userError) {
        errMsg = userError.message
    } else {
        errMsg = ''
    }

    const getKickoffEmailBodyFn = async () => {
        setLoading(true)
        const payload = await getKickoffEmailBody(customMessage)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setLoading(false)
            setDefaultBody(payload.data.defaultEmail)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    useEffect(() => {
        getKickoffEmailBodyFn()
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        const payload = await sendKickoffEmail(disputeId, clientEmails, customMessage)
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
            refetchCase && refetchCase()
            setOpenKickOffModal(false)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    if (loading || userLoading) {
        <CircularProgress />
    }

    return (
        <Box>
            <Divider />
            <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 2}}>
                Client kickoff email preview
            </Typography>
            <Box sx={{ maxWidth: '700px' }}>
                <Typography variant="body1" gutterBottom>
                    TO: {clientEmails}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    CC: {userData ? userData.user.userEmail : ''}
                </Typography>
                <>
                    {defaultBody.split('\n').map((str, index) => <p key={index}>{str}</p>)}
                </>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                pt: 2,
                width: '100%'
            }}>
                <Button
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Button 
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Send Email
                </Button>
            </Box>
            <ErrorModal error={errMsg} href={href} resetError={resetError} />
        </Box>
    )
}
