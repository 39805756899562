import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useQuery, useMutation } from '@apollo/client'
import { CenteredForm, CenteredContent } from 'shared-components/layout'
import { 
    Button,
    Typography,
    CircularProgress,
    LoadingButton,
    Snackbar,
    MuiAlert,
    AlertProps,
    Box
} from 'shared-components/material/core'
import { TextInput, TelephoneInput, SelectInput } from 'shared-components/inputs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import validator from 'validator'
import { getReferralTypes } from 'api/accounts'
import { useError } from 'shared-components/hooks'

export const GET_FIRM = gql`
    query firm {
        firm {
            firmName
            firmAddress1
            firmAddress2
            firmCity
            firmState
            firmZip
            firmTelephone
            firmReferralTypeId
            firmReferralString
        }
    }
`

export const EDIT_FIRM = gql`
    mutation editFirm (
            $firmName: String,
            $firmTelephone: String,
            $firmAddress1: String,
            $firmAddress2: String,
            $firmCity: String,
            $firmState: String,
            $firmZip: String
            $firmReferralTypeId: Int!,
            $firmReferralString: String!,
        ) {
            updateFirm (
                firmName: $firmName, 
                firmTelephone: $firmTelephone, 
                firmAddress1: $firmAddress1, 
                firmAddress2: $firmAddress2, 
                firmCity: $firmCity, 
                firmState: $firmState, 
                firmZip: $firmZip
                firmReferralTypeId: $firmReferralTypeId, 
                firmReferralString: $firmReferralString,
            ) {
                firmName
                firmAddress1
                firmAddress2
                firmCity
                firmState
                firmZip
                firmTelephone
                firmReferralTypeId
                firmReferralString
            }
    }
`

const schema = yup.object({
    firmTelephone: yup
        .string()
        .required('Firm telephone is required'),
    firmAddress1: yup.string().required('Address is required'),
    firmName: yup.string().required('Firm name is required'),
    firmAddress2: yup.string(),
    firmCity: yup.string().required('City is required'),
    firmState: yup.string().required('State is required'),
    firmZip: yup
        .string()
        .matches(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/, 'Invalid Zip Code')
        .required('Zip code is required'),
    firmReferralTypeId: yup.number().required('This Field is Required'),
    firmReferralString: yup.string(),
  }).required()

type FormData = {
    firmTelephone: string,
    firmAddress1: string,
    firmName: string,
    firmAddress2: string,
    firmCity: string,
    firmState: string,
    firmZip: string,
}

type ReferralType = {
    referralTypeId: number,
    referralTypeName: string,
    referralTypeMessage: string,
    referralTypeShowString: boolean,
    referralTypeCreatedAt: Date,
}

type ReferralOptionsType = {
    key: number, 
    label: string, 
    value: number, 
    show: boolean,
    message: string,
}

function FirmInfo({handleNext} : {handleNext: () => void}) {
    const [snackbar, setSnackbar] = React.useState<Pick<AlertProps,'children' | 'severity'> | null>(null)
    const [referralTypeOptions, setReferralTypeOptions] = useState<ReferralOptionsType[]>([])
    const [loading, setLoading] = useState(true)
    const [error, href, handleError, resetError] = useError()
    const { data: firmData, loading: firmLoading, error: firmError } = useQuery(GET_FIRM, {fetchPolicy: 'network-only'})
    const [editFirm, { loading: editFirmLoading, error: editFirmError }] = useMutation(EDIT_FIRM, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [showTextInput, setShowTextInput] = useState(false)
    const [textInputLabel, setTextInputLabel] = useState('')

    const firm = firmData ? firmData.firm : {}

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            firmTelephone: '',
            firmAddress1: '',
            firmName: '',
            firmAddress2: '',
            firmCity: '',
            firmState: '',
            firmZip: '',
            firmReferralTypeId: 0,
            firmReferralString: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, watch, formState: { errors }} = methods

    useEffect(() => {
        const subscription = watch((value) =>{
            const referralType = value.firmReferralTypeId      
            const selectedReferralType = referralTypeOptions.filter(o => o.value === referralType)[0]
            if (selectedReferralType) {
                setShowTextInput(selectedReferralType.show || false) 
                setTextInputLabel(selectedReferralType.message)
            }
        })
        return () => subscription.unsubscribe()
      }, [watch, referralTypeOptions])

    useEffect(() => {
        if (firm) {
            setValue('firmName', firm.firmName && validator.unescape(firm.firmName) || '')
            setValue('firmTelephone', firm.firmTelephone || '')
            setValue('firmAddress1', firm.firmAddress1 || '')
            setValue('firmAddress2', firm.firmAddress2 || '')
            setValue('firmCity', firm.firmCity || '')
            setValue('firmState', firm.firmState || '')
            setValue('firmZip', firm.firmZip || '')
            setValue('firmReferralTypeId', firm.firmReferralTypeId || 0)
            setValue('firmReferralString', firm.firmReferralString || '')
        }
    }, [firm])

    const onSubmit = (formData: FormData) => {
        editFirm({ variables: formData })
        handleNext()
    }
    const handleCloseSnackbar = () => setSnackbar(null)

    const getReferralTypesFn = async () => {
        setLoading(true)
        const payload = await getReferralTypes()
            .catch(e => handleError(e))

        if (payload.success) {
            const options = payload.data.referralTypes.map((r: ReferralType) => ({ 
                key: r.referralTypeId, 
                label: r.referralTypeName, 
                value: r.referralTypeId, 
                show: r.referralTypeShowString,
                message: r.referralTypeMessage,
            }))
            setReferralTypeOptions(options)
            setLoading(false)
        } else {
            setLoading(false)
            handleError(payload.err, payload.href)
        }
    }

    useEffect(() => {
        getReferralTypesFn()
    }, [])

    if (firmLoading || loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    const firmHref: string = firmError && firmError.graphQLErrors[0] 
        ? firmError.graphQLErrors[0].extensions.href as string 
        : ''


    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <CenteredForm align="center">
                    <Typography variant="h2" component="h2" textAlign='left'>
                        Firm Info
                    </Typography>
                    <TextInput
                        name="firmName"
                        label="Firm Name"
                        required
                        error={errors.firmName !== undefined ? true : false }
                        errorMessage={errors.firmName ? errors.firmName.message : undefined}
                    />
                    <TelephoneInput
                        name="firmTelephone"
                        label="Firm Telephone"
                        required
                        error={errors.firmTelephone !== undefined ? true : false }
                        errorMessage={errors.firmTelephone ? errors.firmTelephone.message : undefined}
                    />
                    <TextInput
                        name="firmAddress1"
                        label="Address Line 1"
                        required
                        error={errors.firmAddress1 !== undefined ? true : false }
                        errorMessage={errors.firmAddress1 ? errors.firmAddress1.message : undefined}
                    />
                    <TextInput
                        name="firmAddress2"
                        label="Address Line 2"
                        required
                        error={errors.firmAddress2 !== undefined ? true : false }
                        errorMessage={errors.firmAddress2 ? errors.firmAddress2.message : undefined}
                    />
                    <TextInput
                        name="firmCity"
                        label="City"
                        required
                        error={errors.firmCity !== undefined ? true : false }
                        errorMessage={errors.firmCity ? errors.firmCity.message : undefined}
                    />
                    <TextInput
                        name="firmState"
                        label="State"
                        required
                        error={errors.firmState !== undefined ? true : false }
                        errorMessage={errors.firmState ? errors.firmState.message : undefined}
                    />
                    <TextInput
                        name="firmZip"
                        label="Zip Code"
                        required
                        error={errors.firmZip !== undefined ? true : false }
                        errorMessage={errors.firmZip ? errors.firmZip.message : undefined}
                    />
                    <Typography variant="h2" component="h2" textAlign='left'>
                        How did you hear about us?
                    </Typography>
                    <SelectInput
                        name="firmReferralTypeId"
                        label="Referral"
                        options={referralTypeOptions}
                        required
                        error={errors.firmReferralTypeId !== undefined ? true : false }
                        errorMessage={errors.firmReferralTypeId 
                            ? errors.firmReferralTypeId.message 
                            : undefined}
                    />
                {showTextInput ? (
                    <TextInput
                        name="firmReferralString"
                        label={textInputLabel}
                        required
                        error={errors.firmReferralString !== undefined ? true : false }
                        errorMessage={errors.firmReferralString 
                            ? errors.firmReferralString.message 
                            : undefined}
                    />
                ) : null}
                </CenteredForm>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                        {editFirmLoading ? (
                            <LoadingButton loading variant="outlined">
                                Next
                            </LoadingButton>
                        ): (
                            <Button onClick={handleSubmit(onSubmit)} variant="contained">
                                Next
                            </Button>
                        )}
                </Box>
            </Box>
            <ErrorModal
                error={
                    (firmError && firmError.message) ||
                    (editFirmError && editFirmError.message) || error || ''}
                href={href || firmHref}
                resetError={resetError}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FormProvider>
    )
}

export default FirmInfo