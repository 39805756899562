import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'

import { Typography, Box, CircularProgress, styled, sx, Paper } from 'shared-components/material/core'
import { CenteredContent } from 'shared-components/layout'
import { AlbumIcon } from 'shared-components/material/icons'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { getFileExtension } from 'shared-components/utils'

import { getJobAndValidate, postS3ClientUploadUrl } from 'api/jobs'

const UploadContainer = styled(Box)((
  sx({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
  })
))

const BullsEye = styled(AlbumIcon)((
  sx({
      fontSize: '15em',
      cursor: 'pointer',
      margin: '0 auto'
  })
))

const ProgressContainer = styled(Box)((
  sx({
      position: 'relative', 
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%', 
      alignItems: 'center', 
      justifyContent: 'center'
  })
))

const ProgressNumber = styled(Box)((
  sx({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  })
))

type S3ClientUploadUrlData = {
  jobUploadCode: string, 
  jobUploadFilename: string, 
  jobUploadFilesize: number, 
}

export default function ClientPortal() {
  const [error, href, handleError, resetError ] = useError()
  const [uploadValue, setUploadValue] = useState(0)
  const [fileSize, setFileSize] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [jobName, setJobName] = useState('')
  const [jobClientInstructions, setJobClientInstructions] = useState('')
  const { jobUploadCode } = useParams()

  const handleValidate = async () => {
      const payload = jobUploadCode && (await getJobAndValidate(jobUploadCode)
        .catch(e => handleError(e)))
      
      if (payload.success) {
        setJobName(payload.data.jobName)
        setJobClientInstructions(payload.data.jobClientInstructions)
      } else {
        handleError(payload.err, payload.href)
      }
  }

  useEffect(() => {
    handleValidate()
  }, [])

  const putUploadToS3 = (file: File, url: string) => {
    return axios({
      url,
      method: 'PUT',
      data: file,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
          setUploadValue(progressEvent.loaded)
      },
    })
      .then(() => {
          setUploading(false)
          return setSuccess(true)
        })
      .catch(e => handleError(e))
  }

  const getS3ClientUploadUrl = async (data: S3ClientUploadUrlData, file: File) => {
    const payload = await postS3ClientUploadUrl(data)
        .catch(e => handleError(e))

    if (payload.success) {
        const { signedPutUrl } = payload.data
        putUploadToS3(file, signedPutUrl)
    }

    return handleError(payload.err, payload.href)
}


  const onDrop = useCallback((acceptedFiles: File[]) => {
      const jobUploadFilename = acceptedFiles[0].name
      const jobUploadFilesize = acceptedFiles[0].size
      const extension = getFileExtension(jobUploadFilename)

      if (jobUploadFilesize > 3.5 * 1024 * 1024 * 1024) {
          return handleError("The File size too large. Please upload a file that is 3GB or smaller.")
      }
        
      if (extension !== 'mbox' && extension !== 'pst' && extension !== 'zip') {
          return handleError("Invalid file type. Please upload an MBOX, PST, or ZIP file.")
      }
      setUploading(true)
      setFileSize(jobUploadFilesize)
      return jobUploadCode && getS3ClientUploadUrl({ 
          jobUploadCode, 
          jobUploadFilename, 
          jobUploadFilesize, 
      }, acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

  if (success) {
    return (
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#3f4572', minHeight: '100vh' }}>
        <CenteredContent>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
                  Success
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
                We have received your upload.
            </Typography>
          </Paper>
        </CenteredContent>
      </Box>
    )
}

if (uploading) {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#3f4572', minHeight: '100vh' }}>
    <CenteredContent>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom>
              Uploading File
          </Typography>
          <ProgressContainer>
              <CircularProgress 
                  variant={uploadValue ? 'determinate' : 'indeterminate'}
                  value={(100 * uploadValue) / fileSize} size={100} 
              />
              <ProgressNumber>
                  <Typography
                      variant="h2"
                      component="div"
                      color="text.secondary"
                      sx={{ textAlign: 'center' }}
                  >
                      {`${Math.round((100 * uploadValue) / fileSize)}%`}
                  </Typography>
              </ProgressNumber>
          </ProgressContainer>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
              Please do not navigate away from this page while upload is in progress.<br />
              Leaving the page will terminate your upload.
          </Typography>
          </Paper>
        </CenteredContent>
      </Box>
  )
}

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#3f4572', minHeight: '100vh' }}>
        <CenteredContent>
            <Paper sx={{ p: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
                Upload File - {jobName}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <strong>Instructions:</strong> {jobClientInstructions}
            </Typography>
            <UploadContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div {...getRootProps()} style={{ textAlign: 'center' }}>
                    <input {...getInputProps()} />
                        <BullsEye color="primary" />
                        <p>
                            Drag a .pst, .mbox or .zip file onto bullseye or click to select upload file
                            <br />
                            (3GB max file size)
                        </p>
                    </div>
                </Box>
            </ UploadContainer>
            <ErrorModal error={error} href={href} resetError={resetError} />
            </Paper>
        </CenteredContent>
    </Box>
  )
}
