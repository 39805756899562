import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    LoadingButton,
    Table,
    TableCell,
    TableRow,
    Typography,
} from 'shared-components/material/core'
import ErrorModal from '../ErrorModal'
import { CheckboxInput, SelectInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks/index'
import { Job, PayMethod } from 'generated/graphql'
import { CenteredForm } from 'shared-components/layout'
import { finishJob, getPayIntentOrFinish } from 'api/jobs'
import { getCreditBalance } from 'api/accounts'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'


const schema = yup.object({
    selectedSource: yup.string(),
    savePayMethod: yup.boolean(),
}).required()

type FormData = {
    selectedSource: string,
    savePayMethod: boolean,
}

type PaymentProps = { 
    job: Job,
    payMethods: PayMethod[],
    handleNext: () => void,
    activeStep: number,
    handleBack: () => void,
    firstFinish: boolean,
}

export default function Payment({ 
    job,
    payMethods,
    handleNext,
    activeStep,
    handleBack,
    firstFinish,
} : PaymentProps) {
    const stripe = useStripe()
    const elements = useElements()
    const [cardEntered, setCardEntered] = useState(false)
    const [error, href, handleError, resetError ] = useError()
    const [loading, setLoading] = useState(false)
    const [creditBalance, setCreditBalance] = useState(0)
    const [applyCredits, setApplyCredits] = useState(false)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            selectedSource: '',
            savePayMethod: false,
        },
        resolver: yupResolver(schema),
    })

    const getCreditBalananceFn = async () => {
        const payload = await getCreditBalance()
            .catch(e => handleError(e))
        
        if (payload.success) {
            setCreditBalance(payload.data.creditBalance)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    useEffect(() => {
        getCreditBalananceFn()
    }, [])

    const { handleSubmit, watch, formState: { errors } } = methods

    const payMethodOptions = payMethods.map(payMethod => {
        const label = `${payMethod.payMethodUserId ? 'User' : 'Firm'} | xxxx-xxxx-xxxx-${payMethod.payMethodUiIdentifier} | exp ${payMethod.payMethodExpDate}`
        return {
            label,
            value: payMethod.payMethodStripeId || '0'
        }
    })

    payMethodOptions.push({label: 'New Payment Method', value: '0'})
    

    const calculateTotal = () => {
        const { jobCost, jobStatus, jobCostOverride } = job

        if (jobCostOverride === 0) {
            return 0
        }
        
        if (jobStatus === 71) { // 71 is Reopened
            return 0
        } 

        if (jobCostOverride && jobCostOverride < 50) {
            return 0
        }

        if (jobCostOverride && jobCostOverride > 50) {
            return jobCostOverride
        }

        if (jobCost && jobCost < 50) {
            return 0
        }
        
        return jobCost || 0
    }

    const calculateAppliedCredits = () => {
        const calculatedTotal = calculateTotal()
        if (applyCredits) {
            if (creditBalance >= calculatedTotal) {
                return calculatedTotal
            } else if (calculatedTotal >= creditBalance) {
                return creditBalance
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    const calculateJobCostMinusCredits = () => {
        const calculatedTotal = calculateTotal()
        if (applyCredits) {
            if (creditBalance >= calculatedTotal) {
                 return 0
            } else {
                return calculatedTotal - creditBalance
            }
        } else {
            return calculatedTotal
        }       
    }

    const calculateCreditBalanceMinusCreditsApplied = () => {
        const calculatedTotal = calculateTotal()
        if (applyCredits) {
            if (creditBalance >= calculatedTotal) {
                return creditBalance-calculatedTotal
            } else {
                return 0
            }
        } else {
            return creditBalance
        }
    }

    const handleFinish = async (savePayMethod: boolean) => {
        const { jobId } = job
        const payload = await finishJob({ jobId, savePayMethod, applyCredits})
            .catch(e => handleError(e))
        
        if (payload.success) {
            setLoading(false)
            handleNext()
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const handleStripe = async (clientSecret: string, payment_method: string, stripePayMethodId: string, savePayMethod: boolean) => {
        const cardElement = elements && elements.getElement('card')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let stripeData: any
        if (cardElement && stripePayMethodId === '0') { // User entered new card
            stripeData = {
                payment_method: {
                    card: cardElement,
                }
            }
            if (savePayMethod) {
                stripeData.setup_future_usage = 'on_session'
            }
        } else {
            stripeData = { payment_method }
        }

        const payload = stripe && (await stripe.confirmCardPayment(clientSecret, stripeData))

        if (payload && payload.error) {
            handleError(payload && payload.error.message)
            setLoading(false)
        } else {
            handleFinish(savePayMethod)
        }
        
    }

    const onSubmit = async (formData: FormData) => {
        setLoading(true)
        const { 
            selectedSource: stripePayMethodId,
            savePayMethod
        } = formData
        const { jobId } = job
        const jobCostActualClientCalc = calculateJobCostMinusCredits()

        const payload = await getPayIntentOrFinish({ jobId, jobCostActualClientCalc, stripePayMethodId, applyCredits })
            .catch(e => handleError(e))

        if (payload.success) {
            if (payload.data.sentToFinisher) {
                setLoading(false)
                handleNext()
            } else {
                handleStripe(payload.data.payIntent.client_secret, payload.data.payIntent.payment_method, stripePayMethodId, savePayMethod)
            }
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const selectedSource = watch('selectedSource')

    const handleChange = (e: StripeCardElementChangeEvent) => e.complete ? setCardEntered(true) : setCardEntered(false)

    const renderPaymentMethod = () => {
        return (
            <div>
                <h3>Select payment method</h3>
                <Box sx={{'& .MuiFormControl-root': { width: '100%' }}}>
                    <SelectInput
                        name="selectedSource"
                        label="Payment Method"
                        options={payMethodOptions}
                        error={errors.selectedSource !== undefined ? true : false}
                        errorMessage={errors.selectedSource ? errors.selectedSource.message : undefined}
                    />
                </Box>
                {selectedSource === '0' ? (
                    <>
                    <p>Enter credit card below</p>
                    <CardElement onChange={handleChange} />
                    <CheckboxInput
                        name='savePayMethod'
                        label={
                            <Typography variant="body1" component="p" textAlign='left'>
                                Save payment method
                            </Typography>   
                        }
                        required
                        error={errors.savePayMethod != undefined ? true : false }
                        errorMessage={errors.savePayMethod ? errors.savePayMethod.message : undefined}
                    />
                </>
                ) : null}
            </div>
        )
    }

    const renderCost = () => {

        if (!firstFinish) {
            return <span>$0.00</span>
        }
        
        if (job.jobCostOverride === 0 && job.jobCost) {
          return <span><s>${(job.jobCost / 100).toFixed(2)}</s> $0.00</span>
        } 
        
        if (job.jobCostOverride && job.jobCostOverride > 0 && job.jobCost) {
          return <span><s>${(job.jobCost / 100).toFixed(2)}</s> ${(job.jobCostOverride / 100).toFixed(2)}</span>
        } 

        return <span>${job.jobCost && (job.jobCost / 100).toFixed(2)}</span>
    }
    
    if (error.length > 0) {
        return <ErrorModal error={error} href={href} resetError={resetError} />
    }
    
    return (
        <>
            <DialogContent 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    height: "536px",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                
                <Table style={{maxWidth: '500px' }}>
                    <TableRow>
                        <TableCell>{creditBalance > 0 ? 'Sub Total' : "Total"}</TableCell>
                        <TableCell />
                        <TableCell>{renderCost()}</TableCell>
                    </TableRow>
                    {creditBalance > 0 && (
                        <>
                            <TableRow>
                                <TableCell>
                                    Credit Balance: ${(calculateCreditBalanceMinusCreditsApplied()/100).toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    <Button 
                                        size='small'
                                        onClick={() => setApplyCredits(!applyCredits)} 
                                        variant="outlined" 
                                        disabled={calculateTotal() === 0}
                                    >
                                        {applyCredits ? 'Remove' : 'Apply'}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    ${(calculateAppliedCredits()/100).toFixed(2)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell />
                                <TableCell>
                                    ${(calculateJobCostMinusCredits()/100).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        </>
                    )}
                </Table> 
                <CenteredForm>
                    <FormProvider {...methods}>
                        {
                            (
                                (calculateTotal() > 50) &&
                                firstFinish && 
                                calculateJobCostMinusCredits() > 50
                            ) 
                            && renderPaymentMethod()
                        }   
                    </FormProvider>
                </CenteredForm>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pt: 2,
                    width: '100%'
                }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    {loading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ) : (
                        <Button 
                            onClick={handleSubmit(onSubmit)} 
                            variant="contained" 
                            disabled={
                                calculateTotal() > 50 && 
                                selectedSource === '' && 
                                firstFinish && 
                                calculateJobCostMinusCredits() > 50 ||
                                (selectedSource === '0' && !cardEntered)
                            }
                        >
                            Produce
                        </Button>
                    )}

                </Box>
            </DialogActions>
        </>
  )
}
