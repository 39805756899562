import React, { ChangeEvent, useState } from 'react'
import { Job } from 'generated/graphql'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Checkbox,
  LoadingButton 
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import PSTProcUnit from './PSTProcUnit'

import { mergePST, processAllPST } from 'api/jobs'

export default function PSTFolders({ job, refetch } : { job: Job, refetch: () => void }) {
  const [error, href, handleError, resetError ] = useError()
  const [loading, setLoading] = useState(false)

  const handleProcess = async () => {
    setLoading(true)
    const payload = await mergePST(job.jobId)
      .catch(e => handleError(e))

    if (payload.success) {
      refetch()
      setLoading(false)
    } else {
      handleError(payload.err, payload.href)
      setLoading(false)
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const processAll = e.target.checked
    const payload = await processAllPST(job.jobId, processAll)
      .catch(e => handleError(e))

    if (payload.success) {
      refetch()
    } else {
        handleError(payload.err, payload.href)
    }
  }

  const renderProcessButton = () => {
    if (job.jobStatus === 33 && !loading) {
      return (
        <Button
          variant='contained'
          color='primary'
          onClick={handleProcess}
          sx={{ mt: 2, mb: 2, float: 'right' }}
        >
          Process
        </Button>
      )
    }
    if (job.jobStatus === 33 && loading) {
      return (
        <LoadingButton loading variant="outlined" sx={{ mt: 2, mb: 2, float: 'right' }}>
          Process
        </LoadingButton>
      )
    }
    return null
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Emails</TableCell>
            <TableCell>Download</TableCell>
            <TableCell>Process</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ height: '30px' }}>
            <TableCell
              colSpan={4}
              style={{ textAlign: 'left', textTransform: 'uppercase', height: '30px' }}
            >
                Processable Files:
            </TableCell>
            <TableCell
              style={{ textAlign: 'left', textTransform: 'uppercase', height: '30px' }}
            >
              <Checkbox
                  disabled={job.jobStatus !== 33}
                  onChange={(e) => handleChange(e)}
                />
            </TableCell>
          </TableRow>
          {job.procUnits && job.procUnits.map((procUnit) => {
            if (procUnit && procUnit.procUnitProcessable) {
              return (
                <PSTProcUnit
                  key={procUnit.procUnitId}
                  jobId={job.jobId}
                  procUnit={procUnit}
                  status={job.jobStatus ?? 0}
                />)
            }
            return null
          },
          )}
          <TableRow style={{ height: '30px' }}>
            <TableCell
              colSpan={5}
              style={{ textAlign: 'left', textTransform: 'uppercase', height: '30px' }}
            >
                Unprocessable Files:
            </TableCell>
          </TableRow>
          {job.procUnits && job.procUnits.map((procUnit) => {
            if (procUnit && !procUnit.procUnitProcessable) {
              return (
                <PSTProcUnit
                  key={procUnit.procUnitId}
                  jobId={job.jobId}
                  procUnit={procUnit}
                  status={job.jobStatus ?? 0}
                />)
            }
            return null
          },
          )}
        </TableBody>
      </Table>
      {renderProcessButton()}
      <ErrorModal error={error} href={href} resetError={resetError} />
    </>
  )
}
