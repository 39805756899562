import { SearchResultItem } from "screens/CaseSearch/CaseSearch"


const { REACT_APP_API_URL } = process.env

export async function getDisputes() {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function deleteDispute(disputeId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function postUserToDispute({
  disputeId,
  userIdToAdd
} : {
  disputeId: number,
  userIdToAdd: number,
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/add-user`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ userIdToAdd }),
  })

  return res.json()
}

export async function postRemoveUserFromDispute({
  disputeId,
  userIdToRemove
} : {
  disputeId: number,
  userIdToRemove: number,
}) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/remove-user`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ userIdToRemove }),
  })

  return res.json()
}


export async function sendKickoffEmail(disputeId: number, clientEmails: string, customMessage: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/send-kickoff-email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ clientEmails, customMessage }),
  })

  return res.json()
}

export async function getKickoffEmailBody(customMessage: string) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/kickoff-email-body`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ customMessage }),
  })

  return res.json()
}

export async function getKickOffEmailFields(disputeId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/kickoff-email-fields/${disputeId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getOpposingCounselEmailBody(disputeId: number | null) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/opposing-counsel-email-body`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getClientEngagementEmailBody() {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/client-engagement-email-body`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function closeCase(disputeId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/close-dispute`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function reopenCase(disputeId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/reopen-dispute`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function associateClioMatter(disputeId: number, clioMatterId: number) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/associate-clio-matter`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ clioMatterId }),
  })

  return res.json()
}

export async function searchDispute(disputeId: number, query: string, searchType: string, jobStatuses: string[]) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/search`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ query, searchType, jobStatuses }),
  })

  return res.json()
}

export async function getSearchRecs(disputeId: number, searchResults: SearchResultItem[]) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/search-recs`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify({ searchResults }),
  })

  return res.json()
}

export async function putRecordBatch(disputeId: number, update: object) {
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/record-batch`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify(update),
  })

  return res.json()
}

export async function getDisputePayments(disputeId: number) { //getPayments
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/payments`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}

export async function getInvoiceData(disputeId: number, paymentId: number) { //getPayment
  const res = await fetch(`${REACT_APP_API_URL}/api/disputes/${disputeId}/payments/${paymentId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-cache',
  })

  return res.json()
}
