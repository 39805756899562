import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
  useTheme,
  Theme,
  Button,
  Switch,
  FormControlLabel,
} from 'shared-components/material/core'
import {
  AccountCircle,
  MenuIcon,
} from 'shared-components/material/icons'
import { useError } from 'shared-components/hooks'

import { logout } from '../api/accounts'
import ClioTimer from 'shared-components/ClioTimer/ClioTimer'
import { ErrorModal } from 'shared-components/modals'
import { Subscription, User } from 'generated/graphql'

export const GET_DATA = gql`
  query User($userId: Int) {
      user(userId: $userId) {
        userId
        userClioSetupComplete
        userTourActive
      }
      subscription {
        subscriptionStatus
      }
  }
`
export const EDIT_USER = gql`
  mutation Mutation($userId: Int!, $userTourActive: Boolean) {
    updateUser(userId: $userId, userTourActive: $userTourActive) {
      userTourActive
    }
  }
`

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean,
  theme: Theme
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

type AppBarContentProps = {
  handleFeedback: () => void
  open: boolean, 
  handleDrawerOpen: () => void,
  handleDrawerToggle: () => void,
  user: User,
  subscription: Subscription,
}

export default function AppBarContent({ handleFeedback, handleDrawerOpen, handleDrawerToggle, user, subscription, open } : AppBarContentProps) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [error, href, handleError, resetError] = useError()
  const [userTourActive, setUserTourActive] = useState(user.userTourActive as boolean)
  const [editUser, { error: editUserError }] = useMutation(EDIT_USER)
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleLogout = async () => {
    const payload = await logout()
      .catch(e => handleError(e))

      if (payload.success) {
        return navigate('/')
      } else {
        return handleError(payload.err, payload.href)
      }
  }

  const renderAppBarColor = () => {
    if (pathname.includes('exhibit-set')) {
      return '#245636'
    }
    if (pathname.includes('document-set') || pathname.includes('document-Set')) {
      return '#394587'
    }
    return '#444'
  }

  return (
        <AppBar
          position="fixed"
          aria-label="topbar"
          open={open}
          theme={theme}
          sx={{backgroundColor: renderAppBarColor()}}
        >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                display: { xs: 'none', sm: 'block' },
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img
              width='300px'
              height='34.34px'
              src='/whiteLogo.png'
              style={{ alignSelf: 'center'}}
            />
            <Typography 
              variant='caption' 
              sx={{ alignSelf: 'flex-end', lineHeight: .9, ml: .5 }}
            >
              v2
            </Typography>
          </Box>
          <div>
          <FormControlLabel 
            sx={{ 
              '& .MuiTypography-root': { 
                color: '#fff',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 500,
                fontSize: '0.875rem',
                lineHeight: 1.75,
                letterSpacing: '0.02857em',
              }
            }} 
            control={
              <Switch 
                checked={userTourActive} 
                onChange = {() => {
                  const variables = { userId: user.userId,  userTourActive: !userTourActive }
                  editUser({ variables })
                  setUserTourActive(!userTourActive)
                  window.location.reload()
                }}
                color="default" 
              />
            } 
            label="GUIDES" 
          />
            
            <Button 
              variant="outlined" 
              color='inherit' 
              sx={{ mr: 1, '&:hover': {backgroundColor: '#303873'} }} 
              onClick={handleFeedback}
            >
              Feedback
            </Button>
            {!subscription && (
                <Button 
                variant="contained" 
                color='secondary' 
                sx={{ mr: 1 }} 
                href='/account-setup/upgrade'
              >
                Upgrade
              </Button>
            )}
            
            {user.userClioSetupComplete ? <ClioTimer /> : null}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <ErrorModal 
          error={error || (editUserError && editUserError.message) || ''}  
          href={href || ''} 
          resetError={resetError}
        />
        </AppBar>
  )
}
