import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { Typography, Box, styled, sx, Button } from 'shared-components/material/core'
import { TextInput } from 'shared-components/inputs'
import { CenteredForm } from 'shared-components/layout'

const ProcessingOptionsContainer = styled(Box)((
    sx({
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        width: '100%'
    })
))

const schema = yup.object({
    jobClientEmail: yup.string().required('Client E-Mails are required'),
    jobClientInstructions: yup.string().required('Custom Message is required'),
}).required()

type FormData = {
    jobClientEmail: string,
    jobClientInstructions: string, 
}

type ClientEmailProps = {
    jobClientEmail: string,
    jobClientInstructions: string,
    passSetupData: (data: FormData) => void,
    setOpenResendModal: Dispatch<SetStateAction<boolean>>,
}

export default function ClientEmail({
    jobClientEmail,
    jobClientInstructions,
    passSetupData,
    setOpenResendModal,
}: ClientEmailProps) {

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            jobClientEmail: '',
            jobClientInstructions: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, formState: { errors } } = methods

    useEffect(() => {
        if (jobClientEmail || jobClientInstructions) {
            setValue('jobClientEmail', jobClientEmail)
            setValue('jobClientInstructions', jobClientInstructions)
        } 
    }, [jobClientEmail, jobClientInstructions])

    const onSubmit = (data: FormData) => {
        passSetupData(data)
    }

    return (
        <ProcessingOptionsContainer>
            <CenteredForm>
                <FormProvider {...methods}>
                    <Typography variant="h5" component="h2" sx={{ mt: 2}}>
                        Email Setup
                    </Typography>
                    <>
                        <TextInput
                            name="jobClientEmail"
                            label="Client E-Mail"
                            required
                            error={errors.jobClientEmail !== undefined ? true : false }
                            errorMessage={errors.jobClientEmail ? errors.jobClientEmail.message : undefined}
                            sx={{ mb: 1 }}
                        />
                        <TextInput
                            name="jobClientInstructions"
                            label="Client Instructions"
                            required
                            error={errors.jobClientInstructions !== undefined ? true : false }
                            errorMessage={errors.jobClientInstructions ? errors.jobClientInstructions.message : undefined}
                            multiline
                            rows={10}
                        />
                    </>
                    
                </FormProvider>
            </CenteredForm>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                pt: 2,
                width: '100%'
            }}>
                <Button
                    onClick={() => setOpenResendModal(false)}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Button 
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                >
                    Next
                </Button>
            </Box>
        </ProcessingOptionsContainer>
    )
}
