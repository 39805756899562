import React, { useState, useEffect } from 'react'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'
import { BatesRec, Job } from 'generated/graphql'
import { io, Socket } from 'socket.io-client'

import { 
    DataGridPremium, 
    GridRenderCellParams, 
    GridRowParams, 
    GridRowsProp, 
    GridValueFormatterParams, 
    gridStringOrNumberComparator, 
    useGridApiRef
} from '@mui/x-data-grid-premium'
import { lighten, styled } from '@mui/material/styles'

import { Box, Button, CircularProgress, LoadingButton } from 'shared-components/material/core'
import { AddJobModal, ErrorModal, Feedback, PayModal } from 'shared-components/modals'
import { CenteredContent } from 'shared-components/layout'
import { useError } from 'shared-components/hooks'

import { getS3ArchiveUrl } from 'api/jobs'
import { addLeadingZeros } from 'shared-components/utils'

import { Step } from 'intro.js-react'
import "intro.js/introjs.css"
import { RefreshIcon } from 'shared-components/material/icons'
import CustomToolbar from './CustomToolbar'
import { CustomGridColumnMenu } from './CustomGridColumnMenu'
import ResendModal from 'shared-components/modals/ResendEmailModal/ResendModal'
import validator from 'validator'
import Tour from 'shared-components/Tour/Tour'
import MergeModal from 'shared-components/modals/MergeModal'

const GET_DATA = gql`
    query dispute($disputeId: Int) {
        dispute(disputeId: $disputeId) {
            disputeId
            jobs {
                jobId,
                jobOwner,
                jobCreatedAt,
                jobName,
                jobStatus,
                jobDisputeId,
                jobNeedsRefinish,
                jobBatesPrefix,
                jobBatesStart,
                jobBatesEnd,
                jobBatesNumDigits,
                jobBatesLength,
                jobFinishedTimestamp,
                jobExpiresTimestamp,
                jobPrivStart,
                jobPrivEnd,
                jobPrivNumDigits,
                jobPrivLength,
                jobIrrelStart,
                jobIrrelEnd,
                jobIrrelNumDigits,
                jobIrrelLength,
                jobClientEmail,
                jobClientInstructions,
                statusString {
                    status
                    message
                }
            }
        }
        user {
            userJobListOrderPref
        }
    }
`

const getBackgroundColor = (color: string) => lighten(color, 0.3)

const getHoverBackgroundColor = (color: string) => lighten(color, 0.2)

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    '& .job-needs-refinish-true': {
      backgroundColor: getBackgroundColor(theme.palette.error.main),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
      },
    },
  }))

type JobsDataGridProps = { 
    batesRecs: BatesRec[]
    refetchCase: () => void,
    disputeId: number,
    disputeBatesIntegrity: boolean | null | undefined,
}

type LocationState = {
    clientEmail: string
  }

let socket: Socket

export default function JobsDataGrid({ 
    batesRecs,
    disputeId,
    disputeBatesIntegrity,
    refetchCase
} : JobsDataGridProps ) {
    const navigate = useNavigate()
    const apiRef = useGridApiRef()
    const [ error, href, handleError, resetError] = useError()
    const [openFeedback, setOpenFeedback] = useState(false)
    const [openAddJob, setOpenAddJob] = useState(false)
    const [openMergeModal, setOpenMergeModal] = useState(false)
    const [payModal, setPayModal] = useState(false)
    const [openResendModal, setOpenResendModal] = useState(false)
    const [producedJob, setProducedJob] = useState(0)
    const [pendingJobs, setPendingJobs] = useState([])
    const [updatedRows, setUpdatedRows] = useState([])
    const { loading, error: dataError, data, refetch } = useQuery(GET_DATA, {
        fetchPolicy: 'network-only',
        variables: { disputeId },
    })

    const { clioCustomAction } = useParams()
    const location = useLocation()
    const state = location.state as LocationState

    let clientEmail = ''
    if (location.state) {
        clientEmail = state.clientEmail 
    }

    const pendingStatuses = [11, 31, 32, 34, 35, 36, 41, 42, 51, 61, 62, 72, 73, 91]


    useEffect(() => {
        if (data) {
            const pendingJobs = data.dispute.jobs.filter((job: Job) => job.jobStatus && pendingStatuses.includes(job.jobStatus))
            setPendingJobs(pendingJobs)
        }
    }, [data])

    useEffect(() => {
        if (pendingJobs && pendingJobs.length > 0) {

            // establish socket connection
            if ((!socket || !socket.connected) && process.env.REACT_APP_API_URL) {
                socket = io(process.env.REACT_APP_API_URL, { withCredentials: true })

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                socket.emit('pending-job-check', pendingJobs, res => {
                    console.log({res})
                    // res should say 'okay'
                })
            }
    
                socket.on('pending-job-update', (data) => {
                    // data should be array of jobs that have updated
                    // update pendingJobs
                    const update = data && data.updatedJobs.map((job: Job) => ({jobId: job.jobId, statusString: job.statusString}))
                    setUpdatedRows(update)

                    if (data.updatesComplete) {
                        socket.disconnect()
                    }
                    
                })
                
        }
        return () => {
            if (socket) {
                socket.disconnect()
            }
          }
           
    }, [pendingJobs])

    useEffect(() => {
        if (pendingJobs && pendingJobs.length > 0) {
            apiRef.current.updateRows(updatedRows)
        }  
    }, [updatedRows])

    const handleGetS3ArchiveUrl = async (jobId: number) => {
        const payload = await getS3ArchiveUrl(jobId)
          .catch(e => handleError(e))

        if (payload.success) {
          window.open(payload.data.signedGetUrl)
        } else {
            handleError(payload.err, payload.href)
        }
        
    }

    const togglePayModal = () => setPayModal(payModal => !payModal)

    const handleProduce = (jobId: number) => {
        setProducedJob(jobId)
        togglePayModal()
    }
    
    const renderStatus = (params: GridRenderCellParams) => {
        if (params.row.statusString.message === 'FINISHING') {
            return (
                <LoadingButton
                    loading
                    loadingPosition="start"
                    variant="contained"
                    disabled
                    sx={{ width: '180px'}}
                    startIcon={<RefreshIcon />}
                >
                    Finishing
                </LoadingButton>
            )
        }
        
        if (params.row.statusString.message === 'PROCESSING') {
            return (
                <LoadingButton
                    loading
                    loadingPosition="start"
                    variant="contained"
                    disabled
                    sx={{ width: '180px'}}
                    startIcon={<RefreshIcon />}
                >
                    Processing
                </LoadingButton>
            )
        }

        if (params.row.statusString.message === 'CLASSIFY EMAILS') {
            return (
                <Button
                    variant="contained"
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/app/document-set/${params.row.jobId}/classify-emails`)
                    }}
                    color="primary"
                    sx={{ width: '135px' }}
                >
                    Classify
                </Button>
            )
        }
        if (params.row.statusString.message === 'REVIEW') {
            return (
                <>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            return navigate(`/app/document-Set/${params.row.jobId}/document-review`)
                        }}
                        color="primary"
                        // sx={{ width: '135px' }}
                    >
                        Review
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleProduce(params.row.jobId)
                        }}
                        color="primary"
                        sx={{ marginLeft: 1 }}
                    >
                        Produce
                    </Button>
                </>
            )
        }

        if (params.row.statusString.message === 'UPLOAD') {
            return (
                <Button
                    variant="contained"
                    onClick={(e) => {
                        e.stopPropagation()
                        return navigate(`/app/document-Set/${params.row.jobId}`)
                    }}
                    color="primary"
                    sx={{ width: '135px' }}
                    className='introUpload'
                >
                    UPLOAD
                </Button>
            )
        }

        if (params.row.statusString.message === 'REVIEW PST') {
            return (
                <Button
                    variant="contained"
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate({
                        pathname: `/app/document-set/${params.row.jobId}`,
                        search: createSearchParams({
                            tab: '2'
                        }).toString()
                    })}}
                    color="primary"
                    sx={{ width: '135px' }}
                >
                Review PST
            </Button>
            )
        }

        if (params.row.statusString.message === 'REQUEST SENT') {
            return (
                <>
                    <Button
                        variant="contained"
                        disabled
                        sx={{ width: '135px' }}
                    >
                        Email Sent
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ width: '100px', marginLeft: 1 }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenResendModal(true)
                        }}
                    >
                        Resend
                    </Button>
                    <ResendModal
                        openResendModal={openResendModal} 
                        setOpenResendModal={setOpenResendModal}
                        jobId={params.row.jobId}
                        jobClientInstructions={params.row.jobClientInstructions}
                        jobClientEmail={params.row.jobClientEmail}
                        refetchCase={refetchCase}
                    />
                </>
            )
        }

        if (params.row.statusString.message === 'EXCEPTION') {
            return (
                <>
                    <Button
                        variant="contained"
                        disabled
                    >
                        EXCEPTION
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenFeedback(true)
                        }}
                        color="primary"
                        sx={{ marginLeft: 1 }}
                    >
                        Support
                    </Button>
                </>
            )
        }

        if (params.row.statusString.message === 'DOWNLOAD') {
                return (
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleGetS3ArchiveUrl(params.row.jobId)
                        }}
                        color="primary"
                        sx={{ width: '135px' }}
                    >
                        Download
                    </Button>
                )
        }

        if (params.row.statusString.message === 'EXPIRED') {
                return (
                    <Button
                        variant="contained"
                        disabled
                        sx={{ width: '135px' }}
                    >
                        Expired
                    </Button>
                )
        }
    }

    const handleRowClick = (params: GridRowParams) => {
        if (clioCustomAction === 'request' && clientEmail) {
            return navigate(
                `/app/document-set/${params.row.jobId}`,
                { state:  {clientEmail} },
            )
        } 
        return navigate(`/app/document-set/${params.row.jobId}`)
    }

    const rows: GridRowsProp = data && data.dispute.jobs
      
    const columns = [
        {
            field: 'jobName', 
            headerName: 'Document Set',
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.jobNeedsRefinish && params.row.jobStatus === 71) {
                    return (
                        <span style={{ color: '#fff'}}>
                            {validator.unescape(params.row.jobName)}
                            <br />
                            Document Set reopened but not completed. Refinish document set.
                        </span>
                    )
                }
                if (params.row.jobNeedsRefinish && params.row.jobStatus === 70) {
                    return (
                        <span style={{ color: '#fff'}}>
                            {validator.unescape(params.row.jobName)}
                            <br />
                            Bates Discrepancy. Refinish Document Set.
                        </span>
                    )
                }
                return validator.unescape(params.row.jobName)
            }
        },
        {
            field: 'jobCreatedAt', 
            headerName: 'Date Created',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'jobFinishedTimestamp', 
            headerName: 'Date Finished',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'jobExpiresTimestamp', 
            headerName: 'Date Expires',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'jobBatesPrefix',
            headerName: 'Bates Prefix',
            width: 100,
        },
        {
            field: 'batesRange', 
            headerName: 'Bates Range',
            width: 100,
            sortComparitor: gridStringOrNumberComparator,
            valueGetter: (params: GridRenderCellParams) => {
                const { jobBatesStart, jobBatesEnd, jobBatesNumDigits, jobBatesLength } = params.row
                const start = jobBatesStart && jobBatesNumDigits 
                    ? addLeadingZeros(jobBatesStart, jobBatesNumDigits) 
                    : ''

                const end = jobBatesEnd && jobBatesNumDigits 
                    ? addLeadingZeros(jobBatesEnd, jobBatesNumDigits) 
                    : ''

                if (jobBatesLength && jobBatesLength > 1) {
                    return `${start} - ${end}`
                }
                if (jobBatesLength === 1) {
                    return start
                }
                    return ''
            },
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value === null) {
                    return ''
                }
                return params.value
            }
        },
        {
            field: 'privRange', 
            headerName: 'Priv Range',
            width: 100,
            type: 'string',
            sortComparitor: gridStringOrNumberComparator,
            valueGetter: (params: GridRenderCellParams) => {
                const { jobPrivStart, jobPrivEnd, jobPrivNumDigits, jobPrivLength } = params.row
                const start = jobPrivStart && jobPrivNumDigits 
                    ? addLeadingZeros(jobPrivStart, jobPrivNumDigits) 
                    : ''

                const end = jobPrivEnd && jobPrivNumDigits 
                    ? addLeadingZeros(jobPrivEnd, jobPrivNumDigits) 
                    : ''

                if (jobPrivLength && jobPrivLength > 1) {
                    return `${start} - ${end}`
                }
                if (jobPrivLength === 1) {
                    return start
                }
                    return ''
            },
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value === null) {
                    return ''
                }
                return params.value
            }
        },
        {
            field: 'irrelRange', 
            headerName: 'Irrel Range',
            width: 100,
            type: 'string',
            sortComparitor: gridStringOrNumberComparator,
            valueGetter: (params: GridRenderCellParams) => {
                const { jobIrrelStart, jobIrrelEnd, jobIrrelNumDigits, jobIrrelLength } = params.row
                const start = jobIrrelStart && jobIrrelNumDigits 
                    ? addLeadingZeros(jobIrrelStart, jobIrrelNumDigits) 
                    : ''

                const end = jobIrrelEnd && jobIrrelNumDigits 
                    ? addLeadingZeros(jobIrrelEnd, jobIrrelNumDigits) 
                    : ''

                if (jobIrrelLength && jobIrrelLength > 1) {
                    return `${start} - ${end}`
                }
                if (jobIrrelLength === 1) {
                    return start
                }
                    return ''
            },
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value === null) {
                    return ''
                }
                return params.value
            }
        },
        {
            field: 'statusString', 
            headerName: 'Status',
            width: 200,
            renderCell: (params: GridRenderCellParams) => renderStatus(params)
        },
    ]

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }

    if (data.user && data.user.userJobListOrderPref) {
        setTimeout(() => {
            apiRef.current.restoreState(data.user.userJobListOrderPref)
          });
    }


    let tourSteps: Step[] = []

    if (rows && rows.length === 1 && rows[0].statusString.message === 'UPLOAD') {
        tourSteps = [
            {
                element: ".documentSetList",
                intro: (
                        <p>This is your list of document sets for this case. Click the &ldquo;upload&rdquo; button to upload a file for the document set you just created.</p>
                    )
            },
        ]
    }

    if (rows && rows.length === 1 && rows[0].statusString.message === 'PROCESSING') {
        tourSteps = [
            {
              element: ".documentSetList",
              intro: (
                    <p>The genie is extracting metadata from your files and preparing them for your review and production. This will take some time, the status column will update when your file is ready.</p>
                )
              
            },
        ]
    }

    if (rows && rows.length === 1 && rows[0].statusString.message === 'REVIEW') {
        tourSteps = [
            {
              element: ".documentSetList",
              intro: (
                    <p>Your file is ready! Click &ldquo;review&rdquo; to review your documents 
                        for privilege and/or substance, and add notes and flags to help you find 
                        your key evidence throughout the litigation. Or, if you prefer, simply produce 
                        your documents without further ado by clicking &ldquo;produce.&rdquo;</p>
                )
            },
        ]
    }

    if (rows && rows.length === 1 && rows[0].statusString.message === 'FINISHING') {
        tourSteps = [
            {
              element: ".documentSetList",
              intro: (
                    <p>Your documents are being Bates stamped and your privilege log and production index are being generated. This will take some time. The status column will update when your files are ready to download.</p>
                )
            },
        ]
    }

    if (rows && rows.length === 1 && rows[0].statusString.message === 'DOWNLOAD') {
        tourSteps = [
            {
              element: ".documentSetList",
              intro: (
                    <p>Congratulations! Your output files are ready for download. Click &ldquo;download&rdquo; to get your output files.</p>
                )
            },
        ]
    }

    const getColumnState = () => apiRef.current.exportState()
    const getSelectedRows = () => Array.from(apiRef.current.getSelectedRows().values())

    return (
        <Box sx={{ height: 'calc(100vh - 400Px)', width: '100%', padding: 0 }} className="documentSetList">
            <StyledDataGrid
                apiRef={apiRef}
                rows={rows} 
                columns={columns} 
                getRowId={(row) => row.jobId}
                disableRowSelectionOnClick
                // checkboxSelection
                // checkboxSelectionVisibleOnly
                isRowSelectable={(params: GridRowParams) => 
                    params.row.jobStatus === 0 ||
                    params.row.jobStatus === 50 ||
                    params.row.jobStatus === 59 ||
                    params.row.jobStatus === 60 ||
                    params.row.jobStatus === 71
                }
                onRowClick={(params) => handleRowClick(params)}
                pagination
                getRowClassName={(params) => `job-needs-refinish-${params.row.jobNeedsRefinish}`}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'jobCreatedAt', sort: 'desc' }],
                    },
                    pinnedColumns: { 
                        right: ['statusString']
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                    columnMenu: CustomGridColumnMenu,
                }}
                slotProps={{ 
                    toolbar: { 
                        setOpenAddJob,
                        setOpenMergeModal,
                        userId: data.user.userId,
                        getColumnState,
                        getSelectedRows,
                    }
                }}
            />
            <AddJobModal
                openAddJob={openAddJob} 
                setOpenAddJob={setOpenAddJob} 
                batesRecs={batesRecs} 
                refetchJobs={refetch} 
                refetchCase={refetchCase} 
                disputeId={disputeId}
                disputeBatesIntegrity={disputeBatesIntegrity as boolean}
            />
            <MergeModal
                openMergeModal={openMergeModal} 
                setOpenMergeModal={setOpenMergeModal} 
                batesRecs={batesRecs} 
                refetchJobs={refetch} 
                refetchCase={refetchCase} 
                disputeId={disputeId}
                disputeBatesIntegrity={disputeBatesIntegrity as boolean}
            />
            <ErrorModal 
                error={error || (dataError && dataError.message) || ''} 
                href={href} 
                resetError={resetError}
            />
            <Feedback openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} />

        {payModal && (
            <PayModal
                open={payModal}
                togglePayModal={togglePayModal}
                jobId={producedJob}
            />
        )}
        <Tour tourSteps={tourSteps} />
        </Box>
    )
}
