import React from 'react'
import { Dispute, Job } from 'generated/graphql'

import { Paper, Typography, Box } from 'shared-components/material/core'

import DownloadsTable from './DownloadsTable'

type DisputesProps = { 
  disputes: Dispute[], 
  handleGetDownload: (jobId: number) => Promise<void>, 
}

export default function Disputes({ disputes, handleGetDownload } : DisputesProps) {
  return (
    <>
      {disputes.map((dispute) => {
        if (dispute.jobs && dispute.jobs.length > 0) {
          return (
            <Paper sx={{ p: 2, mb: 2 }}  key={dispute.disputeId}>
              <Box>
                <Typography component='h3' variant='h3'>
                  {dispute.disputeMatter} - {dispute.disputeNumber} - {dispute.disputeName}
                </Typography>
                <DownloadsTable 
                  jobs={dispute.jobs as Job[]} 
                  handleGetDownload={handleGetDownload} 
                />
              </Box>
            </Paper>
          )
        } else {
          return null
        }})}
    </>
  )
}
