import React, { ReactNode } from 'react'
import { Box } from 'shared-components/material/core'

function CenteredForm({ children, width, align }: { children: ReactNode[] | ReactNode, width?: string, align?: string | null }) {
    const setJustifyContent = () => {
        if (align === 'right') {
            return 'flex-end'
        } else if (align === 'center') {
            return 'center'
        } else {
            return null
        }
    }
    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                justifyContent: setJustifyContent(),
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: width || '35ch',
                maxWidth: width || '35ch',
                '& .MuiButton-root': { m: 1 },
                '& .MuiTypography-root': { m: 1 },
                '& .MuiLink-root': { m: 0 },
                '& .MuiFormControl-root': { m: 1 },
                '& .MuiCheckbox-root': { m: 1 }
            }}>
                {children}
            </Box>
        </Box>
    )
}

export default CenteredForm