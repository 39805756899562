import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

type AccountInUseProps = {
  openAccountInUseModal: boolean, 
  login: (formData: { email: string, password: string}, killExistingSession: boolean) => void
  formData: { email: string, password: string}
}

export default function AccountInUseModal({ openAccountInUseModal, login, formData }: AccountInUseProps) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setOpen(openAccountInUseModal)
  }, [openAccountInUseModal])

  const handleClose = () => setOpen(false)

  const handleTerminateSession = () => login(formData, true)

  return (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="account-in-use-modal"
        >
        <DialogTitle id="account-in-use-modal">
            {"Account in use"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
              This account is currently in use. Would you like to terminate the current session? Note: If someone else is using the system and their session is terminated they may lose their data.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleTerminateSession}>
              Terminate Current Session
            </Button>
        </DialogActions>
        </Dialog>
  )
}
