import React, { useState } from 'react'
import moment from 'moment'
import { DataGridPremium, GridRenderCellParams, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid-premium'
import { 
    Box, 
    Button, 
    Tooltip,
    Snackbar,
    MuiAlert,
    AlertProps, 
} from 'shared-components/material/core'
import { useNavigate, useParams } from 'react-router-dom'
import { ExSet } from 'generated/graphql'
import CustomToolbar from './CustomToolbar'
import AddExhibitSetModal from 'shared-components/modals/AddExhibitSetModal'
import { getS3ArchiveUrl } from 'api/exSets'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import PrintEmailModal from 'shared-components/modals/PrintEmailModal/PrintEmailModal'
import validator from 'validator'

export default function ExhibitSetDataGrid({ rows, refetch }:{ rows: ExSet[], refetch: () => void }) {
    const [error, href, handleError, resetError] = useError()
    const [openAddExhibitSet, setOpenAddExhibitSet] = useState(false)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const navigate = useNavigate()
    const { disputeId } = useParams()
    const [openPrintEmailModal, setOpenPrintEmailModal] = useState(false)
    const [exSetId, setExSetId] = useState(0)

    const handleRowClick = (params: GridRowParams) => navigate(`/app/cases/${disputeId}/exhibit-set/${params.row.exSetId}`)

    const handleGetDownload = async (exSetId: number) => {
        const payload = await getS3ArchiveUrl(exSetId)
          .catch(e => handleError(e))
    
        if (payload.success) {
          window.open(payload.data.signedGetUrl)
          refetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handlePrint = (exSetId: number) => {
        setOpenPrintEmailModal(true)
        setExSetId(exSetId)
    }

    const handleCloseSnackbar = () => setSnackbar(null)

    const renderStatus = (params: GridRenderCellParams) => {
        const { exSetId, exSetStatus } = params.row
        if (exSetStatus === 11 || exSetStatus === 22 || exSetStatus === 51) { // processing
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                >
                    Processing
                </Button>
            )
        }
        if (exSetStatus === 60 || exSetStatus === 59) { // Review && Unpaid
            return (
                <>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}`)
                        }}
                        color="primary"
                    >
                        Upload
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}/exhibit-set-review`)
                        }}
                        color="primary"
                        sx={{ marginLeft: 1 }}
                    >
                        Review
                    </Button>
                </>
            )
        }
        if (exSetStatus === 61 || exSetStatus === 62) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                >
                    Finishing
                </Button>
            )
        }
        if (exSetStatus === 70) {
            return (
                <>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}`)
                        }}
                        color="primary"
                    >
                        Upload
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}/exhibit-set-review`)
                        }}
                        color="primary"
                        sx={{ marginLeft: 1 }}
                    >
                        Review
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleGetDownload(exSetId)}
                        sx={{ marginLeft: 1 }}
                    >
                        Download
                    </Button>
                    <Tooltip title="Order Printed Exhibit Binders">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation()
                                handlePrint(exSetId)
                            }}
                            sx={{ marginLeft: 1 }}
                        >
                            Order Print
                        </Button>
                    </Tooltip>
                </>
            )
        }
        if (exSetStatus === 75) {
            return (
                <>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}`)
                        }}
                        color="primary"
                    >
                        Upload
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}/exhibit-set-review`)
                        }}
                        color="primary"
                        sx={{ marginLeft: 1 }}
                    >
                        Review
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleGetDownload(exSetId)}
                        sx={{ marginLeft: 1 }}
                    >
                        Download
                    </Button>
                    <Tooltip title="Order Printed Exhibit Binders">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation()
                                handlePrint(exSetId)
                            }}
                            sx={{ marginLeft: 1 }}
                            disabled
                        >
                            Print Ordered
                        </Button>
                    </Tooltip>
                </>
            )
        }
        if (exSetStatus === 110) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                >
                    Exception
                </Button>
            )
        }
        return (
            <Button
                variant="contained"
                onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}`)
                }}
                color="primary"
                sx={{ width: '135px' }}
            >
                Upload
            </Button>
        )
    }

    const columns = [
        {
            field: 'exSetName', 
            headerName: 'Exhibit Set',
            width: 250,
            renderCell: (params: GridRenderCellParams) => {
                return validator.unescape(params.value)
            },
        },
        {
            field: 'exSetCreatedAt', 
            headerName: 'Date Created',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'exSetPrefix', 
            headerName: 'Prefix',
            width: 100,
        },
        {
            field: 'exSetStart',
            headerName: 'Exhibit Start',
            width: 100,
        },
        {
            field: 'exSetEnd',
            headerName: 'Exhibit End',
            width: 100,
        },
        {
            field: 'status', 
            headerName: 'Action',
            width: 440,
            renderCell: (params: GridRenderCellParams) => renderStatus(params)
        },
    ]

    return (
        <Box sx={{ width: '100%' }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns} 
                    onRowClick={(params) => handleRowClick(params)}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.exSetId}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{ 
                        toolbar: { setOpenAddExhibitSet }
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'exSetCreatedAt', sort: 'desc' }],
                        },
                    }}
                />

            <PrintEmailModal
                openPrintEmailModal={openPrintEmailModal}
                setOpenPrintEmailModal={setOpenPrintEmailModal}
                exSetId={exSetId}
                refetchExSet={refetch}
            />
            <AddExhibitSetModal
                openAddExhibitSet={openAddExhibitSet} 
                setOpenAddExhibitSet={setOpenAddExhibitSet} 
                refetchExhibitSets={refetch} 
                disputeId={parseInt(disputeId as string)}
            />
            <ErrorModal error={error} href={href} resetError={resetError} />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    )
}
