
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useMemo } from 'react'

import {
    DataGridPremium,
    GridRowModel,
    GridRowId,
    useGridApiRef,
    GridRowOrderChangeParams,
    GridColDef,
    GridValidRowModel,
    GridRowSelectionModel,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_REORDER_COL_DEF,
} from '@mui/x-data-grid-premium'

import { useError } from 'shared-components/hooks'
import {
    Paper,
    Snackbar,
    MuiAlert,
    AlertProps,
} from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'
import CustomToolbar from './gridComponents/CustomToolbar'

import { postS3DownloadUrl } from 'api/jobs'
import "intro.js/introjs.css"
import { getColumns } from './columns/columns'
import { CustomGridColumnMenu } from './gridComponents/CustomGridColumnMenu'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { lighten, styled } from '@mui/material/styles'
import { putExhibitBatch } from 'api/exSets'
import { Exhibit } from 'generated/graphql'

const GET_EX_SET = gql`
    query Query($exSetId: Int) {
  exSet(exSetId: $exSetId) {
    exhibits {
      exhibitId,
      exhibitExSetId,
      exhibitJobId,
      exhibitDisputeId,
      exhibitRecId,
      exhibitRecType,
      exhibitDescription,
      exhibitBates,
      exhibitOutputName,
      exhibitSuppressed,
      exhibitCreatedAt,
      exhibitUpdatedAt,
      exhibitWitness,
      exhibitNotes,
      exhibitDateString,
      exhibitOffered,
      exhibitAdmitted,
      exhibitAnalysisRequested,
      reorder,
      attId, 
      docId, 
      emlId, 
      converted, 
      id,
      jobId, 
      recStatus,
      aiBates,
      aiDescription,
      aiDate,
      exhibitUseAiDescription,
      exhibitUseAiDate,
      exhibitUseAiBates,
      s3PostconvKey
    }
  }
}
`

const UPDATE_EX_SET_ORDER = gql`
    mutation UpdateExSetOrder($exSetId: Int!, $exSetOrder: JSON!) {
  updateExSetOrder(exSetId: $exSetId, exSetOrder: $exSetOrder) {
    exSetId
  }
}
`

const TOGGLE_EXHIBIT_SUPPRESSED = gql`
    mutation Mutation($exhibitId: Int!, $exhibitSuppressed: Boolean) {
        updateExhibit(exhibitId: $exhibitId, exhibitSuppressed: $exhibitSuppressed) {
            exhibitId
        }
    }
`

const UPDATE_DESCRIPTION = gql`
    mutation Mutation($exhibitId: Int!, $exhibitDescription: String) {
        updateExhibit(exhibitId: $exhibitId, exhibitDescription: $exhibitDescription) {
            exhibitId
        }
    }
`
const UPDATE_BATES = gql`
    mutation Mutation($exhibitId: Int!, $exhibitBates: String) {
        updateExhibit(exhibitId: $exhibitId, exhibitBates: $exhibitBates) {
            exhibitId
        }
    }
`
const UPDATE_WITNESS = gql`
    mutation Mutation($exhibitId: Int!, $exhibitWitness: String) {
        updateExhibit(exhibitId: $exhibitId, exhibitWitness: $exhibitWitness) {
            exhibitId
        }
    }
`

const UPDATE_NOTES = gql`
    mutation Mutation($exhibitId: Int!, $exhibitNotes: String) {
        updateExhibit(exhibitId: $exhibitId, exhibitNotes: $exhibitNotes) {
            exhibitId
        }
    }
`

const UPDATE_DATE = gql`
    mutation Mutation($exhibitId: Int!, $exhibitDateString: String) {
        updateExhibit(exhibitId: $exhibitId, exhibitDateString: $exhibitDateString) {
            exhibitId
        }
    }
`

const UPDATE_OFFERED = gql`
    mutation Mutation($exhibitId: Int!, $exhibitOffered: Boolean) {
        updateExhibit(exhibitId: $exhibitId, exhibitOffered: $exhibitOffered) {
            exhibitId
        }
    }
`

const UPDATE_ADMITTED = gql`
    mutation Mutation($exhibitId: Int!, $exhibitAdmitted: Int) {
        updateExhibit(exhibitId: $exhibitId, exhibitAdmitted: $exhibitAdmitted) {
            exhibitId
        }
    }
`

type updateData = {
    batchUpdate: string,
    update: string | boolean
}

const getBackgroundColor = (color: string) => lighten(color, 0.5)

const getHoverBackgroundColor = (color: string) => lighten(color, 0.4)

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    '& .exhibit-suppressed': {
      backgroundColor: getBackgroundColor(theme.palette.error.main),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
      },
    },
  }))

export default function ExhibitGrid({ userExhibitSetsEnabled }: { userExhibitSetsEnabled: boolean }) {
    const { disputeId, exSetId } = useParams()

    const DISPUTE_ID = disputeId ? parseInt(disputeId) : 0
    const EX_SET_ID = exSetId ? parseInt(exSetId) : 0
    const apiRef = useGridApiRef()
    const [error, href, handleError, resetError ] = useError()
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [loading, setLoading] = useState(false)
    const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null)
    const [recs, setRecs] = useState<Array<Record<string, unknown>>>([])
    console.log({recs})
    const [rowReordering, setRowReordering] = useState(true)
    const { error: exSetError, loading: exSetLoading, refetch: exSetRefetch } = useQuery(GET_EX_SET, {
        fetchPolicy: 'network-only',
        variables: { exSetId: EX_SET_ID },
        onCompleted: (data) => {
            const rows = data.exSet.exhibits.map((exhibit: Exhibit, index: number) => 
                ({...exhibit, order: index + 1, __reorder__: exhibit.reorder}))
            setRecs(rows)
        }
    })
    const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

    const [exSetOrder, { loading: exSetOrderLoading, error: exSetOrderError }] = useMutation(UPDATE_EX_SET_ORDER, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [toggleExhibitSuppressed, { loading: toggleExhibitSuppressedLoading, error: toggleExhibitSuppressedError }] = useMutation(TOGGLE_EXHIBIT_SUPPRESSED, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateDescription, { loading: updateDescriptionLoading, error: updateDescriptionError }] = useMutation(UPDATE_DESCRIPTION, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateBates, { loading: updateBatesLoading, error: updateBatesError }] = useMutation(UPDATE_BATES, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateWitness, { loading: updateWitnessLoading, error: updateWitnessError }] = useMutation(UPDATE_WITNESS, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateNotes, { loading: updateNotesLoading, error: updateNotesError }] = useMutation(UPDATE_NOTES, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateDate, { loading: updateDateLoading, error: updateDateError }] = useMutation(UPDATE_DATE, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateOffered, { loading: updateOfferedLoading, error: updateOfferedError }] = useMutation(UPDATE_OFFERED, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })
    const [updateAdmitted, { loading: updateAdmittedLoading, error: updateAdmittedError }] = useMutation(UPDATE_ADMITTED, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' }),
    })

    const handleCloseSnackbar = () => setSnackbar(null)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // a. GET ROWS 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const getSelectedRows = () => Array.from(apiRef.current.getSelectedRows().values())

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // c. EDIT ROWS 

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleToggleExhibitSuppressed = async (exhibitId: number, exhibitSuppressed: boolean) => {
        const variables = { exhibitId, exhibitSuppressed }
        await toggleExhibitSuppressed({ variables })
        exSetRefetch()
    }

    const handleUpdateDescription = async (exhibitId: number, exhibitDescription: string) => {
        const variables = { exhibitId, exhibitDescription }
        await updateDescription({ variables })
        exSetRefetch()
    }
    const handleUpdateBates = async (exhibitId: number, exhibitBates: string) => {
        const variables = { exhibitId, exhibitBates }
        await updateBates({ variables })
        exSetRefetch()
    }
    const handleUpdateWitness = async (exhibitId: number, exhibitWitness: string) => {
        const variables = { exhibitId, exhibitWitness }
        await updateWitness({ variables })
        exSetRefetch()
    }
    const handleUpdateNotes = async (exhibitId: number, exhibitNotes: string) => {
        const variables = { exhibitId, exhibitNotes }
        await updateNotes({ variables })
        exSetRefetch()
    }
    const handleUpdateDate = async (exhibitId: number, exhibitDateString: string) => {
        const variables = { exhibitId, exhibitDateString }
        await updateDate({ variables })
        exSetRefetch()
    }
    const handleUpdateAdmitted = async (exhibitId: number, exhibitAdmitted: number) => {
        const variables = { exhibitId, exhibitAdmitted }
        await updateAdmitted({ variables })
        exSetRefetch()
    }
    const handleUpdateOffered = async (exhibitId: number, exhibitOffered: boolean) => {
        const variables = { exhibitId, exhibitOffered }
        await updateOffered({ variables })
        exSetRefetch()
    }

    const handlePreview = async (rowId: GridRowId, resource: string, itemId: string, jobId: number) => {
        apiRef.current.setRowSelectionModel([rowId]) // this selects the row and changes its color
        const payload = await postS3DownloadUrl(jobId, resource, itemId, null)
            .catch(e => handleError(e))

        if (payload.success) {
            return window.open(
                payload.data.signedGetUrl,
                'Document View',
                'width=1200,height=900,left=200,top=200',
              )
        } 
        
        return handleError(payload.err, payload.href)
    }

    const updateRowPosition = (
        initialIndex: number,
        newIndex: number,
        rows: Array<GridRowModel>,
      ) => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row)
        const newRowsClone = rowsClone.map((exhibit, index) => ({...exhibit, order: index + 1}))
        return newRowsClone
      }

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(true);
        const newRows = await updateRowPosition(
          params.oldIndex,
          params.targetIndex,
          recs,
        );

        const variables = { exSetId: EX_SET_ID, exSetOrder: newRows }

        if (params.row.exhibitSuppressed) {
            handleError("You cannot change the order of a suppressed exhibit", 'refresh')
            setLoading(false)
        } else {
            await exSetOrder({variables})
            setRecs(newRows)
            setLoading(false)
        }
      };

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            const { 
                exhibitDescription,
                exhibitBates,
                exhibitWitness,
                exhibitNotes,
                exhibitId,
                exhibitDateString,
                exhibitAdmitted,
                exhibitOffered,
            } = newRow

            if (oldRow.exhibitDescription !== exhibitDescription) {
                handleUpdateDescription(exhibitId, exhibitDescription)
            }
            if (oldRow.exhibitBates !== exhibitBates) {
                handleUpdateBates(exhibitId, exhibitBates)
            }
            if (oldRow.exhibitWitness !== exhibitWitness) {
                handleUpdateWitness(exhibitId, exhibitWitness)
            }
            if (oldRow.exhibitNotes !== exhibitNotes) {
                handleUpdateNotes(exhibitId, exhibitNotes)
            }
            if (oldRow.exhibitDateString !== exhibitDateString) {
                handleUpdateDate(exhibitId, exhibitDateString)
            }
            if (oldRow.exhibitAdmitted !== exhibitAdmitted) {
                handleUpdateAdmitted(exhibitId, exhibitAdmitted)
            }
            if (oldRow.exhibitOffered !== exhibitOffered) {
                handleUpdateOffered(exhibitId, exhibitOffered)
            }
            
            return newRow // save in datagrid
        },
        [],
    )

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' })
    }, [])

    const updateRows = async (updateData: updateData) => {
        const { batchUpdate, update } = updateData
        const selectedRows = getSelectedRows()
        const exhibitIds = selectedRows.map((row) => row.exhibitId)

        const apiUpdate = {
            exSetId: EX_SET_ID,
            exhibitIds,
            [batchUpdate]: update
        }
        console.log({apiUpdate})
        
        const payload = await putExhibitBatch(apiUpdate)
            .catch(e => handleError(e))

        if (payload.success) {
            exSetRefetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleCopySingleDescription = async (exhibitId: number) => {
        const apiUpdate = {
            exSetId: EX_SET_ID,
            exhibitIds: [exhibitId],
            'copyDescriptionToBates': true
        }

        const payload = await putExhibitBatch(apiUpdate)
            .catch(e => handleError(e))

        if (payload.success) {
            exSetRefetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const batchReorder = async (order: number) => {
        const selectedRows: GridValidRowModel[] = getSelectedRows().sort((a, b) => a.order - b.order) // get selected rows and sort them into correct order (otherwise they are in the order they were selected)
        const selectedRowsDuplicate = selectedRows.map((exhibit) => ({...exhibit, duplicate: true})) // add duplicate key to selected rows
        const recsCopy = [...recs]
        recsCopy.splice(order, 0, ...selectedRowsDuplicate) // insert selected rows with duplicate key at new index
        const newExhibitArray = recsCopy.filter(exhibit => !selectedRows.includes(exhibit)) // remove selected rows (but leave duplicates)
        newExhibitArray.map((exhibit, index) => {
            if (exhibit.duplicate) {
                delete exhibit['duplicate'] // remove duplicate keys for clean up
            }
            exhibit.order = index + 1 // update order
        }) 

        const variables = { exSetId: EX_SET_ID, exSetOrder: newExhibitArray }

        await exSetOrder({ variables }) // update database
        setRecs(newExhibitArray) // update grid
    }

    const handleOfferedCheck = (exhibitId: number, exhibitOffered: boolean) => {
        const { updateRows } = apiRef.current 
        updateRows([{exhibitId, exhibitOffered}]) // update grid
        handleUpdateOffered(exhibitId, exhibitOffered) // update database
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // d. GET COLUMNS

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    const columns: GridColDef[] = useMemo(() => getColumns(
        handlePreview, handleToggleExhibitSuppressed, handleCopySingleDescription, handleOfferedCheck), [])

    const getColumnState = () => apiRef.current.exportState()


    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // f. RENDER

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <Paper 
            sx={{ 
                height: 'calc(100vh - 112Px)', 
                width: '100%',
                '& .custom-indent': {
                    paddingLeft: '40px !important',
                },
            }}
            className="documentSetList"
        >
            
            <StyledDataGrid
                loading={
                    loading || 
                    exSetLoading || 
                    exSetOrderLoading || 
                    toggleExhibitSuppressedLoading || 
                    updateDescriptionLoading || 
                    updateWitnessLoading || 
                    updateNotesLoading || 
                    updateDateLoading ||
                    updateBatesLoading || 
                    updateOfferedLoading ||
                    updateAdmittedLoading
                }
                apiRef={apiRef}
                rows={recs || []} 
                getRowId={(row) => row.exhibitId}
                getRowClassName={(params) => params.row.exhibitSuppressed && 'exhibit-suppressed'}
                columns={columns!}
                disableColumnReorder={true}
                initialState={{
                    columns: {
                        columnVisibilityModel: { offered: false },
                    },
                    pinnedColumns: { 
                        left: [GRID_REORDER_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field, 'order', 'View', 'exhibitDateString', 'exhibitDescription']
                    },
                }}
                // Custom Components

                slots={{
                    toolbar: CustomToolbar,
                    columnMenu: CustomGridColumnMenu,
                    
                  }}
                slotProps={{ 
                    filterPanel: {
                        columnsSort: 'asc'
                    },
                    panel: {
                        anchorEl: filterButtonEl,
                    },
                    toolbar: { 
                        updateRows, 
                        getSelectedRows, 
                        getColumnState,
                        exSetId: EX_SET_ID,
                        disputeId: DISPUTE_ID,
                        setFilterButtonEl,
                        recs,
                        batchReorder,
                        exSetRefetch,
                        userExhibitSetsEnabled,
                    } 
                }}

                localeText={{
                    toolbarExport: "Download List"
                  }}

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                rowSelectionModel={rowSelectionModel}

                onFilterModelChange={(model) => {
                    setRowSelectionModel([])
                    if (model.items.length > 0 && model.items[0].value) {
                        setRowReordering(false)
                    } else {
                        setRowReordering(true) 
                    }
                }}

                // Edit Rows

                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}

                disableRowSelectionOnClick
                checkboxSelection

                rowReordering={rowReordering}
                onRowOrderChange={handleRowOrderChange}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <ErrorModal 
                error={
                    error || 
                    (exSetError && exSetError.message ) || 
                    (exSetOrderError && exSetOrderError.message) || 
                    (toggleExhibitSuppressedError && toggleExhibitSuppressedError.message) || 
                    (updateDescriptionError && updateDescriptionError.message) || 
                    (updateWitnessError && updateWitnessError.message) || 
                    (updateNotesError && updateNotesError.message) || 
                    (updateDateError && updateDateError.message) || 
                    (updateAdmittedError && updateAdmittedError.message) || 
                    (updateOfferedError && updateOfferedError.message) || 
                    (updateBatesError && updateBatesError.message) || ''}
                href={href}
                resetError={resetError}
            />
        </Paper>
    );
}