import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form"
import { gql, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-premium'
import { GetApp } from '@mui/icons-material'

import { useError } from 'shared-components/hooks'
import {
  CircularProgress,
  Paper,
  Box,
  Button,
  Link,
  Typography,
  LoadingButton,
  Snackbar,
  MuiAlert,
  AlertProps
} from 'shared-components/material/core'
import { TextInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

import { postS3DownloadUrl } from 'api/jobs'

export const EDIT_ATT = gql`
  mutation Mutation(
    $attId: Int!, 
    $attLegalResearch: String, 
    $attNotes: String) {
      updateAtt(
        attId: $attId, 
        attLegalResearch: $attLegalResearch, 
        attNotes: $attNotes) {
          attId
        }
}
`
export const EDIT_EML = gql`
  mutation Mutation(
    $emlId: Int!, 
    $emlLegalResearch: String, 
    $emlNotes: String) {
      updateEml(
        emlId: $emlId, 
        emlLegalResearch: $emlLegalResearch, 
        emlNotes: $emlNotes) {
          emlId
        }
}
`

export const EDIT_DOC = gql`
  mutation Mutation(
    $docId: Int!, 
    $docLegalResearch: String, 
    $docNotes: String) {
      updateDoc(
        docId: $docId, 
        docLegalResearch: $docLegalResearch, 
        docNotes: $docNotes) {
          docId
        }
}
`

type FormData = {
  notes?: string,
  legalResearch?: string,
  flag?: string,
}

const schema = yup.object({
  notes: yup.string(), 
  legalResearch: yup.string(),
}).required()

export default function DetailPanel(row: GridRowModel) {
  const { jobId } = useParams<{ jobId: string }>()
  const JOB_ID = jobId ? parseInt(jobId) : 0
  const { 
    id, 
    notes, 
    legalResearch, 
    attId, 
    docId, 
    emlId, 
    to,
    from,
    cc,
    bcc,
    nativeDownloadable, 
    converted,
    filename,
    subject,
    itemType,
  } = row.row.row
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const [editAtt, { loading: editAttLoading, error: editAttError }] = useMutation(EDIT_ATT, {
    onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
  })
  const [editEml, { loading: editEmlLoading, error: editEmlError }] = useMutation(EDIT_EML, {
    onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
  })
  const [editDoc, { loading: editDocLoading, error: editDocError }] = useMutation(EDIT_DOC, {
    onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
  })
  const [error, href, handleError, resetError ] = useError()
  const [downloadLink, setDownloadLink] = useState('')
  const [loading, setLoading] = useState(true)
  const apiRef = useGridApiContext()

  const methods = useForm({
    defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
        notes: '',
        legalResearch: '',
    },
    resolver: yupResolver(schema),
  })

  const { handleSubmit, formState: { errors }, setValue } = methods

  const handleCloseSnackbar = () => setSnackbar(null)

  const handleDownload = async () => {
    setLoading(true)
    let resource, itemId

    if (attId) {
      resource = 'attNative'
      itemId = attId
    } else if (docId){
      resource = 'docNative'
      itemId = docId
    } else {
      resource = 'emlNative'
      itemId = emlId
    }

    const payload = await postS3DownloadUrl(JOB_ID, resource, itemId, null)
      .catch(e => handleError(e))

    if (payload.success) {
        setLoading(false)
        setDownloadLink(payload.data.signedGetUrl)
    } else {
      setLoading(false)
      handleError(payload.err, payload.href)
    }
}

  useEffect(() => {
    if (nativeDownloadable || !converted) {
      handleDownload()
    }
  }, [])

  useEffect(() => {
    setValue('notes', notes || '')
    setValue('legalResearch', legalResearch || '')
  }, [notes, legalResearch])

  const onSubmit = (formData: FormData) => {
    const { notes, legalResearch} = formData
    let variables
    if (itemType === 'att') {
      variables = {attId, attLegalResearch: legalResearch, attNotes: notes}
      editAtt({variables})
    }
    if (itemType === 'eml') {
      variables = {emlId, emlLegalResearch: legalResearch, emlNotes: notes}
      editEml({variables})
    }
    if (itemType === 'doc') {
      variables = {docId, docLegalResearch: legalResearch, docNotes: notes}
      editDoc({variables})
    }
    return apiRef.current.updateRows([{ id, notes, legalResearch }])
  }

  if (loading) {
    <Paper sx={{ m: 3, ml: 6, p: 3,  width: '100%', maxWidth: '1040px' }} elevation={3}>
      <CircularProgress />
    </Paper>
  }

  return (
    <Paper sx={{ m: 3, ml: 6, p: 3,  width: '100%', maxWidth: '1040px' }} elevation={3}>
      <FormProvider {...methods}>
        <Box>
          {(nativeDownloadable || !converted) && (
          <Link href={downloadLink} title="download native file" download={filename} target="_blank">
            <GetApp color="primary" sx={{ width: 25, height: 25, mb: -.8 }} /> {filename}
          </Link>
          )}
          {subject ? (
              <Typography>{subject}</Typography>
            ): null}
          <br />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {from ? (
              <Typography sx={{ mr: 3 }}>
                From:<br />{from}
              </Typography>
            ): null}
            <br />
            {to && to.length > 0 ? (
              <Typography sx={{ mr: 3 }}>To:<br />{to}</Typography>
            ): null}
            <br />
            {cc && cc.length > 0 ? (
              <Typography sx={{ mr: 3 }}>CC: <br />{cc}</Typography>
            ): null}
            <br />
            {bcc && bcc.length > 0 ? (
              <Typography>BCC: <br />{bcc}</Typography>
            ): null}
          </Box>
          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
            <TextInput
              name='notes'
              label='Notes'
              error={errors.notes !== undefined ? true : false}
              errorMessage={errors.notes ? errors.notes.message : undefined}
              multiline
              rows={10}
              sx={{ mr: 2}}
              fullWidth
            />   
            <TextInput
              name='legalResearch'
              label='Research'
              error={errors.legalResearch !== undefined ? true : false}
              errorMessage={errors.legalResearch ? errors.legalResearch.message : undefined}
              multiline
              rows={10}
              fullWidth
            />
          </Box>
          {editAttLoading || editDocLoading || editEmlLoading ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={handleSubmit(onSubmit)}
            >
              Submit
          </Button>
          )}
        </Box>
      </FormProvider>
      <ErrorModal 
        error={
          error || 
          (editAttError ? editAttError.message : '') ||
          (editDocError ? editDocError.message : '') ||
          (editEmlError ? editEmlError.message : '')
        } 
        href={href}
        resetError={resetError}
      />
      {!!snackbar && (
          <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
          >
              <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
      )}
    </Paper>
  )
}
