import React from 'react'
import { currencyFormat } from 'shared-components/utils'
import { Cost } from 'generated/graphql'
import { DialogContent, DialogActions, styled, Button, Box } from 'shared-components/material/core'
import moment from 'moment'

const CostTable = styled('table')`
    width: 100%;
    max-width: 750px;
    margin: 2em auto 1.5em auto;
    padding: 1em;
    border: 0;
    border-collapse: collapse;
`

const CostThead = styled('thead')`
    border-bottom: .5px solid #e0e0e0;
`

const CostTd = styled('td')`
    text-align: right;
    color: #999;
    padding: 2px 4px;
    border: 0;
`

const CostTh = styled('th')`
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #333;
    padding: 2px 4px;
    border: 0;
    text-align: left;
`

type ReviewProps = { 
  costs: Cost[],
  handleBack: () => void,
  handleNext: () => void,
}

export default function Review({ costs, handleBack, handleNext } : ReviewProps) {
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
        <CostTable>
          <CostThead>
            <tr>
              <CostTh>Type</CostTh>
              <CostTh>Description</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Date</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Pages</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Cost per page</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Cost</CostTh>
            </tr>
          </CostThead>
          <tbody>
            {costs.map((cost) => {
              const renderCost = () => {
                if (cost.costTotal !== cost.costTotalActual) {
                  return (
                    <span><s>{currencyFormat(cost.costTotal as number)}</s> {currencyFormat(cost.costTotalActual as number)}</span>
                  )
                }
                return <span>{currencyFormat(cost.costTotalActual as number)}</span>
              }
              return (
                <tr key={cost.costId}>
                  <CostTd style={{ textAlign: 'left' }}>{cost.costType === 1 ? 'Setup Charge' : 'Upload Charge'}</CostTd>
                  <CostTd style={{ textAlign: 'left' }}>{cost.costDescription?.substring(0, 30)}</CostTd>
                  <CostTd>{moment(cost.costCreatedAt).format('MM/DD/YYYY')}</CostTd>
                  <CostTd>{cost.costUploadDocsPages}</CostTd>
                  {cost.costType === 1 ? <CostTd /> : <CostTd>{cost.costPerUploadPageOverride ? currencyFormat(cost.costPerUploadPageOverride) : currencyFormat(cost.costPerUploadPage as number)}</CostTd>}
                  <CostTd>{renderCost()}</CostTd>
                </tr>
              )}
            )}
          </tbody>
        </CostTable>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pt: 2,
            width: '100%'
        }}>
          <Button
              onClick={handleBack}
              sx={{ mr: 1 }}
          >
              Back
          </Button>
          <Button 
            onClick={handleNext}
            variant="contained"
            >
              Next
            </Button>
        </Box>
      </DialogActions>
    </>
  )
}

