import React, { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Dispute, User } from 'generated/graphql'
import validator from 'validator'

import { 
    DataGridPremium, 
    GridColDef, 
    GridColumnMenuContainer, 
    GridColumnMenuProps, 
    GridColumnMenuPinningItem, 
    GridColumnMenuColumnsItem, 
    GridColumnMenuFilterItem, 
    GridRenderCellParams, 
    GridRowsProp, 
    GridToolbarColumnsButton, 
    GridToolbarContainer, 
    GridToolbarDensitySelector, 
    GridToolbarFilterButton, 
    GridValueFormatterParams, 
    GridColumnMenuHideItem, 
    GridColumnMenuSortItem 
} from '@mui/x-data-grid-premium'
import { Box, Button } from 'shared-components/material/core'
import "intro.js/introjs.css"
import { ErrorModal } from 'shared-components/modals'
import { lighten, styled } from '@mui/material/styles'
import { closeCase, reopenCase } from 'api/disputes'
import { useError } from 'shared-components/hooks'
import Tour from 'shared-components/Tour/Tour'

const getBackgroundColor = (color: string) => lighten(color, 0.5)

const getHoverBackgroundColor = (color: string) => lighten(color, 0.4)

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    '& .case-closed-80': {
      backgroundColor: getBackgroundColor(theme.palette.error.main),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
      },
    },
  }))

function CustomToolbar({ setOpenAddCase } : { user: User, setOpenAddCase: Dispatch<SetStateAction<boolean>> }) {
    
    const tourSteps = [
        {
          element: ".addCaseButton",
          intro: (
                <p>Welcome to Discovery Genie! Click the &lsquo;Add Case&rsquo; button to add your first case and get started.</p>
            )
        },
    ]
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector sx={{ mr: 1}} />
            </GridToolbarContainer>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setOpenAddCase(true)
                    }}
                    size='small'
                    className="addCaseButton"
                >
                    Add Case
                </Button>
            </div>
            <Tour 
                tourSteps={tourSteps} 
            />
        </Box>
    )
  }

export const CustomGridColumnMenu = React.forwardRef<
HTMLUListElement,
GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
// eslint-disable-next-line react/prop-types
const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };

return (
    <GridColumnMenuContainer ref={ref} {...props}>
      <GridColumnMenuSortItem {...itemProps} />
      <GridColumnMenuFilterItem {...itemProps} />
      <GridColumnMenuHideItem {...itemProps} />
      <GridColumnMenuColumnsItem {...itemProps} />
      <GridColumnMenuPinningItem {...itemProps} />
    </GridColumnMenuContainer>
);
})

type CasesDataGridProps = {
    disputes: Dispute[], 
    setOpenAddCase: Dispatch<SetStateAction<boolean>>,
    refetch: () => void
}

export default function CasesDataGrid({ disputes, setOpenAddCase, refetch }: CasesDataGridProps ) {
    const [ error, href, handleError, resetError ] = useError()

    const handleCloseCase = async (e: React.MouseEvent<HTMLButtonElement>, disputeId: number) => {
        e.stopPropagation()
        const payload = await closeCase(disputeId)
            .catch((e) => handleError(e.message))
        
        if (payload.success) {
            refetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleReopenCase = async (e: React.MouseEvent<HTMLButtonElement>, disputeId: number) => {
        e.stopPropagation()
        const payload = await reopenCase(disputeId)
            .catch((e) => handleError(e.message))
        
        if (payload.success) {
            refetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const navigate = useNavigate()

    const rows: GridRowsProp = disputes

    const columns: GridColDef[] = [
        {
            field: 'disputeName', 
            headerName: 'Dispute Name',
            width: 350,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = validator.unescape(params.value)
                return valueFormatted
            }
        },
        {
            field: 'userInitials', 
            headerName: 'Owner',
            width: 75
        },
        {
            field: 'clientName', 
            headerName: 'Client',
            width: 300,
        },
        {
            field: 'disputeMatter', 
            headerName: 'Matter',
            width: 150,
        },
        {
            field: 'disputeCreatedAt', 
            headerName: 'Date Created',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'disputeProductionDeadline', 
            headerName: 'Production Deadline',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'disputeDataRetentionDeadline', 
            headerName: 'Data Retention Deadline',
            width: 175,
            editable: true,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = params.value ? moment(params.value).format('MM/DD/YYYY hh:mm a') : null
                return valueFormatted
            }
        },
        {
            field: 'action',
            headerName: 'action',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.disputeStatus < 80) {
                    return (
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            tabIndex={params.hasFocus ? 0 : -1}
                            onClick={(e) => handleCloseCase(e, params.row.disputeId)}
                        >
                            Close
                        </Button>
                    )
                } else {
                    return ( 
                    <Button
                        variant='outlined'
                        color='primary'
                        size='small'
                        tabIndex={params.hasFocus ? 0 : -1}
                        onClick={(e) => handleReopenCase(e, params.row.disputeId)}
                    >
                        Reopen
                    </Button>
                    )
                }
            }
        },
    ]

    return (
        <Box sx={{ height: 'calc(100vh - 150Px)', width: '100%' }}>
            <StyledDataGrid
                rows={rows} 
                columns={columns} 
                getRowId={(row) => row.disputeId}
                disableRowSelectionOnClick
                pagination
                getRowClassName={(params) => `case-closed-${params.row.disputeStatus}`}
                onRowClick={(params) => navigate(`/app/cases/${params.row.disputeId}`)}
                slots={{
                    toolbar: CustomToolbar,
                    columnMenu: CustomGridColumnMenu,
                }}
                slotProps={{ 
                    toolbar: { 
                        setOpenAddCase,
                    }
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'disputeCreatedAt', sort: 'desc' }],
                    },
                  }}
            />
            <ErrorModal 
                error={error || ''}
                href={href} 
                resetError={resetError}
            />
        </Box>
    )
}
