import React, { memo } from 'react'
import moment from 'moment'
import { Typography, Button, Link } from 'shared-components/material/core'
import { OpenInNewIcon } from 'shared-components/material/icons'
import validator from 'validator'
import { closeCase, reopenCase } from 'api/disputes'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { useNavigate } from 'react-router-dom'

type HeaderData = {
    disputeId: number,
    disputeName: string,
    disputeCreatedAt: string,
    disputeProductionDeadline: string,
    disputeStatus: number,
    disputeDataRetentionDeadline: string,
    disputeBatesIntegrity: boolean,
    disputeClioMatterId: number,
}

function CaseHeader({ headerData, refetch }: { headerData: HeaderData, refetch: () => void }) {
    const navigate = useNavigate()
    const [error, href, handleError, resetError ] = useError()
    const { 
        disputeId,
        disputeName,
        disputeCreatedAt,
        disputeProductionDeadline,
        disputeStatus,
        disputeDataRetentionDeadline,
        disputeBatesIntegrity,
        disputeClioMatterId,
    } = headerData

    const handleCloseCase = async () => {
        const payload = await closeCase(disputeId)
            .catch((e) => handleError(e.message))
        
        if (payload.success) {
            refetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleReopenCase = async () => {
        const payload = await reopenCase(disputeId)
            .catch((e) => handleError(e.message))
        
        if (payload.success) {
            refetch()
        } else {
            handleError(payload.err, payload.href)
        }
    }

    return (
        <>
            <Typography variant="h3">
            <Link component="button" variant="h3" onClick={() => navigate(`/app/cases/${disputeId}`)}>{validator.unescape(disputeName)}{' '}</Link> <br />
                {disputeClioMatterId && (
                    <Link variant="overline" href={`https://app.clio.com/nc/#/matters/${disputeClioMatterId}`} target="_blank">
                        View in Clio <OpenInNewIcon style={{ marginBottom: '-3px', fontSize: '1rem' }}/>
                    </Link>
                )}
                
            </Typography>
            <Typography variant="body1">
                Created: {moment(disputeCreatedAt).format('MM/DD/YY')}
            </Typography>
            {disputeProductionDeadline ? (
                <Typography variant="body1">
                    Production Deadline: {moment(disputeProductionDeadline).format('MM/DD/YY')}
                </Typography>
            ) : null}
            {disputeDataRetentionDeadline && (
                <Typography variant="body1" color='error'>
                    Data Retention Deadline: {moment(disputeDataRetentionDeadline).format('MM/DD/YY')}
                </Typography>
            )}
            <Typography variant="body1">
                {disputeBatesIntegrity ? "Automated Bates Labeling and Tracking" : "Manual Bates Labeling"}
            </Typography>
            {disputeStatus < 80 ? (
                <Button
                    variant='outlined'
                    color='error'
                    size='small'
                    onClick={handleCloseCase}
                    sx={{ mt: 1 }}
                >
                    Close Case
                </Button>
            ) : (
                <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={handleReopenCase}
                    sx={{ mt: 1 }}
                >
                    Reopen Case
                </Button>
            )}
            <ErrorModal error={error} href={href} resetError={resetError} />
        </>
    )
}

export default memo(CaseHeader)
