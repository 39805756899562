import React, { ReactNode } from 'react'
import { Box } from 'shared-components/material/core'

function HorizontalForm({ children }: { children: ReactNode[] | ReactNode }) {
    return (
        <Box component="form" sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '100%',
            '& .MuiButton-root': { margin: '0 0 4px 4px' },
            '& .MuiFormControl-root': { margin: '0 8px 0 0', width: '30ch' },
        }}>
            {children}
        </Box>
    )
}

export default HorizontalForm