import React from 'react'

import { Typography, Divider, styled, sx, Button } from 'shared-components/material/core'
import { currencyFormat } from 'shared-components/utils'
import { Job } from 'generated/graphql'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import { postS3DownloadUrl } from 'api/jobs'

const Table = styled('table')((
    sx({
        width: '100%',
        maxWidth: '600px',
        m: '2em auto 1.5em auto',
        p: '1em',
        border: 0,
        borderCollapse: 'collapse',
    })
))

const TableCell = styled('td')(({ theme }) => (
    sx({
        textAlign: 'right',
        color: theme.palette.text.primary,
        p: '2px 4px',
        border: 0,
    })
))

const TableHead = styled('thead')(({ theme }) => (
    sx({
        borderBottom: `.5px solid ${theme.palette.grey.A500}`,
    })
))

const HeadCell = styled('th')(({ theme }) => (
    sx({
        textTransform: 'uppercase',
        letterSpacing: '.5px',
        color: theme.palette.text.primary,
        p: '2px 4px',
        border: 0,
        textAlign: 'left',
    })
))

const TableFoot = styled('tfoot')(({ theme }) => (
    sx({
        backgroundColor: theme.palette.grey.A300,
    })
))

const FootCell = styled('td')(({ theme }) => (
    sx({
        color: theme.palette.text.primary,
        p: '2px 4px',
        border: 0,
        textAlign: 'right',
    })
))

export default function Payment({ job } : { job: Job }) {
  const [error, href, handleError, resetError ] = useError()
  const docsSubtotal = (job.jobPrivEmlsNum || 0) + (job.jobPrivAttsNum || 0) + (job.jobPrivDocsNum || 0) + (job.jobProdEmlsNum || 0) + (job.jobProdAttsNum || 0) + (job.jobProdDocsNum || 0)
  const totalPages = (job.jobPrivEmlsPages || 0) + (job.jobPrivAttsPages || 0) + (job.jobPrivDocsPages || 0) + (job.jobProdEmlsPages || 0) + (job.jobProdAttsPages || 0) + (job.jobProdDocsPages || 0)
  const costSubtotal = (job.jobPrivEmlsCost || 0) + (job.jobPrivAttsCost || 0) + (job.jobPrivDocsCost || 0) + (job.jobProdEmlsCost || 0) + (job.jobProdAttsCost || 0) + (job.jobProdDocsCost || 0)
  const irrelDocsTotal = (job.jobIrrelEmlsNum || 0) + (job.jobIrrelAttsNum || 0) + (job.jobIrrelDocsNum || 0)
  const irrelCostTotal = (job.jobIrrelEmlsCost || 0) + (job.jobIrrelAttsCost || 0) + (job.jobIrrelDocsCost || 0)
  const holdDocsTotal = (job.jobHoldEmlsNum || 0) + (job.jobHoldAttsNum || 0) + (job.jobHoldDocsNum || 0)
  const holdCostTotal = (job.jobHoldEmlsCost || 0) + (job.jobHoldAttsCost || 0) + (job.jobHoldDocsCost || 0)
  const totalDocs = docsSubtotal + irrelDocsTotal + holdDocsTotal
  const totalCost = costSubtotal + irrelCostTotal + holdCostTotal
  const discount = job.jobCostOverride && job.jobCostOverride > 0 ? (job.jobCost || 0) - (job.jobCostOverride || 0) : 0
  const billed = job.jobCostActual === null ? job.jobCost : job.jobCostActual

  const handleGetSignedUrl = async (resource: string) => {
    const { jobId } = job
    const payload = await postS3DownloadUrl(jobId, resource, null, null)
      .catch(e => handleError(e))

    if (payload.success) {
      window.open(payload.data.signedGetUrl)
    } else {
        handleError(payload.err, payload.href)
    }
    
  }
    
  return (
    <>
        <Typography variant="h5" component="h3">
          Payment Info
        </Typography>
        <Divider />
        <Table>
        <TableHead>
          <tr>
            <HeadCell>Category</HeadCell>
            <HeadCell style={{ textAlign: 'right' }}>Docs</HeadCell>
            <HeadCell style={{ textAlign: 'right' }}>Pages</HeadCell>
            <HeadCell style={{ textAlign: 'right' }}>Cost</HeadCell>
          </tr>
        </TableHead>
        <tbody>
          {job.jobPrivEmlsNum && job.jobPrivEmlsNum > 0
            ? <tr>
              <HeadCell>Privileged Emails</HeadCell>
              <TableCell>{job.jobPrivEmlsNum}</TableCell>
              <TableCell>{job.jobPrivEmlsPages}</TableCell>
              <TableCell>{currencyFormat(job.jobPrivEmlsCost || 0)}</TableCell>
            </tr>
            : null
          }
          {job.jobPrivAttsNum && job.jobPrivAttsNum > 0
            ? <tr>
              <HeadCell>Privileged Attachments</HeadCell>
              <TableCell>{job.jobPrivAttsNum}</TableCell>
              <TableCell>{job.jobPrivAttsPages}</TableCell>
              <TableCell>{currencyFormat(job.jobPrivAttsCost || 0)}</TableCell>
            </tr>
            : null
          }
          {job.jobPrivDocsNum && job.jobPrivDocsNum > 0
            ? <tr>
              <HeadCell>Privileged Documents</HeadCell>
              <TableCell>{job.jobPrivDocsNum}</TableCell>
              <TableCell>{job.jobPrivDocsPages}</TableCell>
              <TableCell>{currencyFormat(job.jobPrivDocsCost || 0)}</TableCell>
            </tr>
            : null
          }
          {job.jobProdEmlsNum && job.jobProdEmlsNum > 0
            ? <tr>
              <HeadCell>Production Emails</HeadCell>
              <TableCell>{job.jobProdEmlsNum}</TableCell>
              <TableCell>{job.jobProdEmlsPages}</TableCell>
              <TableCell>{currencyFormat(job.jobProdEmlsCost || 0)}</TableCell>
            </tr>
            : null
          }
          {job.jobProdAttsNum && job.jobProdAttsNum > 0
            ? <tr>
              <HeadCell>Production Attachments</HeadCell>
              <TableCell>{job.jobProdAttsNum}</TableCell>
              <TableCell>{job.jobProdAttsPages}</TableCell>
              <TableCell>{currencyFormat(job.jobProdAttsCost || 0)}</TableCell>
            </tr>
            : null
          }
          {job.jobProdDocsNum && job.jobProdDocsNum > 0
            ? <tr>
              <HeadCell>Production Documents</HeadCell>
              <TableCell>{job.jobProdDocsNum}</TableCell>
              <TableCell>{job.jobProdDocsPages}</TableCell>
              <TableCell>{currencyFormat(job.jobProdDocsCost || 0)}</TableCell>
            </tr>
            : null
          }
          {job.jobHoldDocsCost && job.jobHoldDocsCost > 0
              ? <tr>
                <HeadCell>Held Documents</HeadCell>
                <TableCell>{job.jobHoldDocsNum}</TableCell>
                <TableCell />
                <TableCell>{currencyFormat(job.jobHoldDocsCost || 0)}</TableCell>
              </tr>
              : null
            }
            {job.jobHoldEmlsCost && job.jobHoldEmlsCost > 0
              ? <tr>
                <HeadCell>Held E-mails</HeadCell>
                <TableCell>{job.jobHoldEmlsNum}</TableCell>
                <TableCell />
                <TableCell>{currencyFormat(job.jobHoldEmlsCost || 0)}</TableCell>
              </tr>
              : null
            }
            {job.jobHoldAttsCost && job.jobHoldAttsCost > 0
              ? <tr>
                <HeadCell>Held Attachments</HeadCell>
                <TableCell>{job.jobHoldAttsNum}</TableCell>
                <TableCell />
                <TableCell>{currencyFormat(job.jobHoldAttsCost || 0)}</TableCell>
              </tr>
              : null
            }
          {irrelDocsTotal > 0
            ? <tr>
              <HeadCell>Excluded/Irrelevant</HeadCell>
              <TableCell>{irrelDocsTotal}</TableCell>
              <td />
              <TableCell>{currencyFormat(irrelCostTotal)}</TableCell>
            </tr>
            : null
          }
          <tr>
            <HeadCell>Invoiced Totals</HeadCell>
            <TableCell>{totalDocs}</TableCell>
            <TableCell>{totalPages}</TableCell>
            <TableCell>{currencyFormat(totalCost)}</TableCell>
          </tr>
          {job.jobCostOverride && job.jobCostOverride > 0 &&
            <div>
              <tr>
                <HeadCell colSpan={4}>Billing Adjustments:</HeadCell>
              </tr>
              <tr>
                <HeadCell colSpan={3}>Discount(s) applied</HeadCell>
                <TableCell>{currencyFormat(discount)}</TableCell>
              </tr>
            </div>
          }
          {job.credit && job.credit.creditApplied &&
            <tr>
              <HeadCell>Credit: {job.credit.creditCode}</HeadCell>
              <td />
              <td />
              <TableCell>{`(${currencyFormat(job.credit.creditApplied || 0)})`}</TableCell>
            </tr>
          }
        </tbody>
        <TableFoot>
          <tr>
            <HeadCell style={{ textAlign: 'right' }} colSpan={3}>Billed Total</HeadCell>
            <FootCell>
              {!job.jobCostOverride || job.jobCostOverride > 0 
                ? currencyFormat(billed || 0) 
                : currencyFormat(job.jobCost || 0)}
            </FootCell>
          </tr>
          {job.jobReceipt &&
            <tr>
              <HeadCell style={{ textAlign: 'right' }} colSpan={3}>Payment Method</HeadCell>
              <FootCell>
                **** **** **** {job.jobReceipt.source.last4 || job.jobReceipt.source.card.last4}
              </FootCell>
            </tr>
          }
        </TableFoot>
      </Table>
      <div style={{ textAlign: 'center', width: '100%'}}>
        <Button 
          variant="contained" 
          style={{ marginRight: '1em' }} 
          onClick={() => handleGetSignedUrl('invoice')}
        >
          Invoice
        </Button>
        {job.credit && job.credit.creditApplied && (
          <Button variant="contained" onClick={() => handleGetSignedUrl('credit')}>
            Credit Statement
          </Button>)}
      </div>
      <ErrorModal error={error} href={href} resetError={resetError} />
    </>
  )
}
