import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { 
    Typography,
    Box, 
    CircularProgress, 
    styled, 
    sx, 
} from 'shared-components/material/core'
import { AlbumIcon } from 'shared-components/material/icons'
import "intro.js/introjs.css"
import { useUploadContext } from 'App/App'

const ProgressContainer = styled(Box)((
    sx({
        position: 'relative', 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        alignItems: 'center', 
        justifyContent: 'center'
    })
))

const ProgressNumber = styled(Box)((
    sx({
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    })
))

const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

const BullsEyeIcon = styled(AlbumIcon)((
    sx({
        fontSize: '15em',
        cursor: 'pointer',
        margin: '0 auto'
    })
))

type BullsEyeProps = {
    exSetId: number, 
    refetch: () => void,
}

export default function BullsEye({ exSetId, refetch }: BullsEyeProps) {
    const { getExSetUploadData, fileSize, uploadValue, uploading, uploadJobId } = useUploadContext()

    useEffect(() => {
        refetch()
    }, [uploading])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        return getExSetUploadData(acceptedFiles, exSetId )
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    if (uploading && uploadJobId === exSetId) {
        return (
            <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Uploading File
                </Typography>
                <ProgressContainer>
                    <CircularProgress 
                        variant={uploadValue ? 'determinate' : 'indeterminate'}
                        value={(100 * uploadValue) / fileSize} size={100} 
                    />
                    <ProgressNumber>
                        <Typography
                            variant="h2"
                            component="div"
                            color="text.secondary"
                            sx={{ textAlign: 'center' }}
                        >
                            {`${Math.round((100 * uploadValue) / fileSize)}%`}
                        </Typography>
                    </ProgressNumber>
                </ProgressContainer>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
                    Uploading and preparing your exhibits for review.
                </Typography>
            </>
        )
    }

    if (uploading && uploadJobId !== exSetId) {
        return (
            <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Upload in Progress
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
                    Another file is being uploaded. Please wait for that file to finish uploading.
                </Typography>
            </>
        )
    }

    return (
        <Box>
            <Typography variant="h5" component="h2" sx={{ mt: 2, textAlign: 'center'}}>
                Upload File
            </Typography>
            <UploadContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div {...getRootProps()} style={{ textAlign: 'center' }}>
                    <input {...getInputProps()} />
                        <BullsEyeIcon color="primary" />
                        <p className='introInstructions'>
                            Drag a .zip file onto bullseye or click to select upload file
                            <br />
                            (3GB max file size)
                        </p>
                    </div>
                </Box>
                
            </ UploadContainer>
        </Box>
    )
}
