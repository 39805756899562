import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import { SxProps, Theme } from '@mui/material/styles';
import { TextField } from "../material/core"
import { InputLabelProps } from "@mui/material";

type FormValues = {
    name: string, 
    label: string,
    required?: boolean,
    error?: boolean,
    errorMessage?: string,
    helperText?: string,
    defaultValue?: string,
    disabled?: boolean,
    width?: string,
    type?: string,
    fullWidth?: boolean,
    sx?: SxProps<Theme>,
    multiline?: boolean,
    rows?: number,
    inputLabelProps?: InputLabelProps
    autoFocus?: boolean,
    onFocus?: () => void,
}

const TextInput = ({ 
    name, 
    label, 
    required=false, 
    error=false, 
    errorMessage, 
    helperText,
    defaultValue,
    disabled=false,
    type='text',
    fullWidth=false,
    sx,
    multiline=false,
    rows,
    inputLabelProps,
    autoFocus,
    onFocus,
}: FormValues) => {
    const { control } = useFormContext()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={label}
                    placeholder={label} 
                    required={required}
                    type={type}
                    helperText={errorMessage ? errorMessage : helperText}
                    error={error}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    sx={sx}
                    multiline={multiline}
                    rows={rows}
                    InputLabelProps={inputLabelProps}
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                />
            )}
        />
    )
}

export default TextInput
