import React, { useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    LoadingButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from 'shared-components/material/core'
import ErrorModal from '../ErrorModal'
import { useError } from 'shared-components/hooks/index'
import { CenteredForm } from 'shared-components/layout'
import { CreatePaymentMethodData, StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { getSubscriptionPaymentPreauth, postSubscriptionPaymentPreauth } from 'api/accounts'
import { currencyFormat } from 'shared-components/utils'
import { Subscription } from 'generated/graphql'

type PaymentProps = { 
    handleNext: () => void,
    preauthCode: string
}

export default function Payment({ 
    handleNext,
    preauthCode,
} : PaymentProps) {
    const stripe = useStripe()
    const elements = useElements()
    const [cardEntered, setCardEntered] = useState(false)
    const [error, href, handleError, resetError ] = useError()
    const [loading, setLoading] = useState(true)
    const [subscription, setSubscription] = useState<Subscription>()

    useEffect(() => {
        const getSubscriptionPaymentPreauthFn = async () => {
            const payload = await getSubscriptionPaymentPreauth(preauthCode)
            .catch((e) => handleError(e))

            if (payload.success) {
                setLoading(false)
                setSubscription(payload.data.subscription)
            } else {
                setLoading(false)
                handleError(payload.err, payload.href)
            }
        }
        getSubscriptionPaymentPreauthFn()
    }, [])

    const getPaymentMethod = async () => {
        const cardElement = elements && elements.getElement('card')
    
        const paymentMethodData: CreatePaymentMethodData = {
            type: 'card',
            card: cardElement as StripeCardElement,
        }
    
        if (stripe) {
          const { paymentMethod, error } = await stripe.createPaymentMethod(paymentMethodData)
          if (paymentMethod) {
            return paymentMethod
          }
      
          if (error) {
            return handleError(error.message)
          } 
        } 
      }

    const handleSubmit = async () => {
        setLoading(true)

        const paymentMethod = await getPaymentMethod()
        const payload = await postSubscriptionPaymentPreauth(preauthCode, paymentMethod)
            .catch((e) => handleError(e))

            if (payload.success) {
                setLoading(false)
                handleNext()
            } else {
                setLoading(false)
                handleError(payload.err, payload.href)
            }
    }

    const handleChange = (e: StripeCardElementChangeEvent) => e.complete ? setCardEntered(true) : setCardEntered(false)
    
    return (
        <>
            <DialogContent 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {subscription && (
                    <Table style={{maxWidth: '300px' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3}>{subscription.subscriptionPlanName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>
                                    {(subscription.subscriptionAmount && currencyFormat(subscription.subscriptionAmount)) || '$0.00'}
                                </TableCell>
                                <TableCell>{subscription.subscriptionInterval === 1 ? 'monthly' : 'yearly'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table> 
                )}
                
                <CenteredForm width="450px">
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                        <div>
                            <p>Enter credit card below</p>
                            <CardElement onChange={handleChange} />
                        </div>
                    </Box>  
                </CenteredForm>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    pt: 2,
                    width: '100%'
                }}>
                    {loading ? (
                        <LoadingButton loading variant="outlined">
                            Submit
                        </LoadingButton>
                    ) : (
                        <Button 
                            onClick={handleSubmit} 
                            variant="contained" 
                            disabled={!cardEntered}
                        >
                            Submit
                        </Button>
                    )}

                </Box>
                <ErrorModal 
                    error={error ||  '' } 
                    href={href} 
                    resetError={resetError} 
                />
            </DialogActions>
        </>
  )
}
