import React, { Dispatch, SetStateAction, useState } from 'react'

import { Box, styled, sx, Stepper, Step, StepLabel} from 'shared-components/material/core'

import ClientEmail from './ClientEmail'
import ClientEmailConfirm from './ClientEmailConfirm'


const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

type ResendStepperProps = {
    jobId: number,
    refetchCase: () => void,
    setOpenResendModal: Dispatch<SetStateAction<boolean>>,
    clientInstructions: string,
    clientEmail: string,
}

type EmailFormData = {
    jobClientEmail: string,
    jobClientInstructions: string,
}

export default function ResendStepper({ 
    jobId,
    refetchCase,
    setOpenResendModal,
    clientEmail,
    clientInstructions,
} : ResendStepperProps) {
    const [activeStep, setActiveStep] = useState(0)
    const [jobClientEmail, setjobClientEmail] = useState(clientEmail || '')
    const [jobClientInstructions, setjobClientInstructions] = useState(clientInstructions || '')

    const steps = ['Setup', 'Confirm']

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

    const getEmailSetupData = (data: EmailFormData) => {
        setjobClientEmail(data.jobClientEmail)
        setjobClientInstructions(data.jobClientInstructions)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    return (
        <UploadContainer>
            <div style={{ width: '500px' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            </div>

            {activeStep === 0 ? (
                <ClientEmail
                    passSetupData={getEmailSetupData}
                    jobClientEmail={jobClientEmail}
                    jobClientInstructions={jobClientInstructions}
                    setOpenResendModal={setOpenResendModal}
                />
            ): null}

            {activeStep === 1 ? (
                <ClientEmailConfirm
                    jobId={jobId}
                    jobClientEmail={jobClientEmail}
                    jobClientInstructions={jobClientInstructions}
                    handleBack={handleBack}
                    refetchCase={refetchCase}
                    setOpenResendModal={setOpenResendModal}
                />
            ): null}
        </UploadContainer>
    )
}
