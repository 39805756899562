import React, { useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useQuery, useMutation } from '@apollo/client'
import { User } from 'generated/graphql'

import { CenteredForm, CenteredContent } from 'shared-components/layout'
import {
    Button,
    CircularProgress,
    LoadingButton,
    Snackbar,
    MuiAlert,
    AlertProps,
} from 'shared-components/material/core'
import { TextInput, SelectInput, TelephoneInput, SwitchInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

export const GET_USER = gql`
    query User($userId: Int) {
        user(userId: $userId) {
            userId
            userEmail
            userFirstname
            userLastname
            userInitials
            userTelephone
            userFirmRole
            userRegisteredViaClio
            userTourActive
        }
    }
`

export const EDIT_USER = gql`
    mutation Mutation($userId: Int!, $userTourActive: Boolean, $userFirstname: String, $userLastname: String, $userInitials: String, $userTelephone: String, $userEmail: String, $userFirmRole: String) {
  updateUser(userId: $userId, userTourActive: $userTourActive, userFirstname: $userFirstname, userLastname: $userLastname, userInitials: $userInitials, userTelephone: $userTelephone, userEmail: $userEmail, userFirmRole: $userFirmRole) {
    userEmail
    userFirstname
    userLastname
    userInitials
    userTelephone
    userFirmRole
    userTourActive
  }
}
`

const schema = yup.object({
    userFirstname: yup.string().required('First name is required'),
    userLastname: yup.string().required('Last name is required'),
    userInitials: yup
        .string()
        .matches(/^[A-Za-z]{2,3}$/, 'Initials must be two to three letters long')
        .required('Initials are required'),
    userTelephone: yup
        .string()
        .required('Telephone is required'),
    userEmail: yup.string().required('Email is required'),
    userFirmRole: yup.string().required('Role is required'),
    userTourActive: yup.bool(),
  }).required()

type FormData = {
    userFirstname: string,
    userLastname: string,
    userInitials: string,
    userTelephone: string,
    userEmail: string,
    userFirmRole: string,
    userTourActive: boolean
}

const userFirmRoleOptions = [
    { label: 'Partner', value: 'Partner'},
    { label: 'Associate', value: 'Associate'},
    { label: 'Paralegal', value: 'Paralegal'},
    { label: 'Law Clerk', value: 'Law Clerk'},
    { label: 'Support Staff', value: 'Support Staff'},
    { label: 'Other', value: 'Other'},
  ]

function UserInfo() {
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const { data, loading, error } = useQuery(GET_USER)
    const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER, {
        onCompleted: () => setSnackbar({ children: 'Save successful', severity: 'success' })
    })
    const user: User = data ? data.user : {}

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            userFirstname: '',
            userLastname: '',
            userInitials: '',
            userTelephone: '',
            userEmail: '',
            userFirmRole: '',
            userTourActive: false,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, formState: { errors }} = methods

    useEffect(() => {
        setValue('userFirstname', user.userFirstname || '')
        setValue('userLastname', user.userLastname || '')
        setValue('userInitials', user.userInitials || '')
        setValue('userTelephone', user.userTelephone || '')
        setValue('userEmail', user.userEmail || '')
        setValue('userFirmRole', user.userFirmRole || '')
        setValue('userTourActive', user.userTourActive || false)
    }, [user])

    const onSubmit = async (formData: FormData) => {
        const variables = {...formData, userId: user.userId} 
        editUser({ variables })  
    }
    const handleCloseSnackbar = () => setSnackbar(null)

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <FormProvider {...methods} >
            <CenteredForm align="right">
                
                <TextInput
                    name="userFirstname"
                    label="First Name"
                    required
                    error={errors.userFirstname !== undefined ? true : false }
                    errorMessage={errors.userFirstname ? errors.userFirstname.message : undefined}
                />
                <TextInput
                    name="userLastname"
                    label="Last Name"
                    required
                    error={errors.userLastname !== undefined ? true : false }
                    errorMessage={errors.userLastname ? errors.userLastname.message : undefined}
                />
                <TextInput
                    name="userInitials"
                    label="Initials"
                    required
                    error={errors.userInitials !== undefined ? true : false }
                    errorMessage={errors.userInitials ? errors.userInitials.message : undefined}
                />
                <TelephoneInput
                    name="userTelephone"
                    label="Telephone"
                    required
                    error={errors.userTelephone !== undefined ? true : false }
                    errorMessage={errors.userTelephone ? errors.userTelephone.message : undefined}
                />
                <TextInput
                    name="userEmail"
                    label="E-Mail"
                    required
                    error={errors.userEmail !== undefined ? true : false }
                    errorMessage={errors.userEmail ? errors.userEmail.message : undefined}
                />
                <SelectInput 
                    name="userFirmRole"
                    label="Role"
                    options={userFirmRoleOptions}
                    required
                    error={errors.userFirmRole !== undefined ? true : false }
                    errorMessage={errors.userFirmRole ? errors.userFirmRole.message : undefined}
                />
                <SwitchInput
                    name="userTourActive"
                    label="Enable App Guides"
                    required
                    error={errors.userTourActive != undefined ? true : false }
                    errorMessage={errors.userTourActive ? errors.userTourActive.message : undefined}
                />
                
                {editUserLoading ? (
                    <LoadingButton loading variant="outlined">
                        Save
                    </LoadingButton>
                ) : (
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save
                    </Button>
                )}
            </CenteredForm>
            <ErrorModal 
                error={(error && error.message) || (editUserError && editUserError.message) || ''}
            />
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FormProvider>
    )
}

export default UserInfo
