import React from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'


import { CenteredContent } from 'shared-components/layout'
import { CircularProgress, Grid, Paper, Typography } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

import CasesTable from './CasesTable/CasesTable'

const GET_ClIENT = gql`
    query client($clientId: Int) {
    client(clientId: $clientId) {
        clientId
        clientName
        clientCreatedAt
        clientAlphaId
        disputes {
            disputeName
            disputeCreatedAt
            disputeMatter
            disputeStatus
            disputeId
        }
    }
}
`

function Client () {
    const { clientId } = useParams()
    const id = clientId ? parseInt(clientId) : 0

    const { loading, error, data } = useQuery(GET_ClIENT, {
        variables: { clientId: id },
    })

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    const { client } = data
    const { disputes } = client

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Client
            </Typography>
            <Grid container spacing={2} sx={{ maxWidth: '1440px' }}>
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 4 }}>
                        <Typography variant="h3">
                            {client.clientName} | {client.clientAlphaId}
                        </Typography>
                        <Typography variant="body1" component="h4" gutterBottom>
                            Created: {moment(client.clientCreatedAt).format('MM/DD/YYYY')}
                        </Typography>
                        <CasesTable disputes={disputes} />
                    </Paper>
                </Grid>
                <ErrorModal error={error ? error.message : ''} />
            </Grid>
        </>
    )
}

export default Client