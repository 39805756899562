import { CircularProgressProps} from '@mui/material'
import moment from 'moment'
import React, { Dispatch, SetStateAction, useState } from 'react'
// import Highlighter from 'react-highlight-words'
import { 
  Checkbox, 
  Typography, 
  Box, 
  Paper, 
  CircularProgress, 
  Button, 
  IconButton, 
  styled,
} from 'shared-components/material/core'
import { AttachFileIcon, PageviewIcon } from 'shared-components/material/icons'
import { SearchResultItem } from '../CaseSearch'
import { useError } from 'shared-components/hooks'
import { postS3DownloadUrl } from 'api/jobs'
import { ErrorModal } from 'shared-components/modals'

type SearchResultsProps = {
  searchResults: SearchResultItem[]
  setSelectedItems: Dispatch<SetStateAction<SearchResultItem[]>>,
  selectedItems: SearchResultItem[],
  handleReview: () => void,
  searchTerm: string,
}

const ScoreInnerContainer = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ScoreOuterContainer = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
});

const SearchHeaderContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  maxWidth: '700px',
  marginBottom: theme.spacing(2)
}));

function Score (props: CircularProgressProps & { value: number }) {
  return (
    <ScoreOuterContainer>
      <CircularProgress variant="determinate" value={props.value * 100} />
      <ScoreInnerContainer>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value * 100)}%`}
        </Typography>
      </ScoreInnerContainer>
    </ScoreOuterContainer>
  );
}

function SearchItem({
  item,
  handlePreview,
  handleAddToSelectedItems,
  renderCategory
} : { 
  item: SearchResultItem[], 
  handlePreview: (item: SearchResultItem) => void, 
  handleAddToSelectedItems: (item: SearchResultItem, checked: boolean) => void 
  renderCategory: (catFinal: number) => void
}) {
  const [checked, setChecked] = useState(false)
  const handleCheck = (item: SearchResultItem, e: React.ChangeEvent<HTMLInputElement>) => {
    handleAddToSelectedItems(item, e.target.checked)
    setChecked(e.target.checked)
  }

  return (
    <Paper sx={{ p: 2, pb: 3, pr: 8, maxWidth: '700px', mb: 2}}>
      <Box sx={{ display: 'flex', justifyContent:'space-between'}}>
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <Checkbox checked={checked} onChange={(e) => handleCheck(item[0], e)} />
          <IconButton onClick={() => handlePreview(item[0])}>
            <PageviewIcon 
              color='primary' 
              sx={{ display: 'inline-block', width: '26px', height: '26px' }}
            />
          </IconButton>
        </Box>
        <Score value={item[0].score} />
      </Box>
      <Box sx={{ ml: 6 }}>
        <Typography variant="body1">
          <span style={{display: 'flex'}}>
            {item[0].subject ? (
              <><b>Subject</b>: {item[0].subject} </>
            ): (
              <><b>Filename</b>: {item[0].attId && <AttachFileIcon fontSize="small" />}{item[0].filename} </>
            )}
          </span>
          {item[0].emlId && (
            <>
              {item[0].from && (<><b>From</b>: {item[0].from}<br/></>)}
              {item[0].to && (<><b>To</b>: {item[0].to}<br /></>)}
              {item[0].cc &&  (<><b>CC</b>: {item[0].cc}<br /></>)}
              {item[0].bcc && (<><b>BCC</b>: {item[0].bcc}<br /></>)}
            </>
          )}
          <b>Date</b>: {moment(item[0].date).format("MM/DD/YY HH:MMA")}<br/>
          {item[0].catFinal && (<><b>Selected Category</b>: {renderCategory(item[0].catFinal)}<br/></>)}
          {item[0].confidential && <><b>Confidential</b><br/></>}
          {item[0].isKey && <><b>Key</b><br/></>}
          {item[0].redact && <><b>Redact</b></>}
        </Typography>
        <br />
        {item.map((doc, index) => {
          // const searchTermIndexStart = item.text.indexOf(searchTerm)
          // const chunk = item.text.slice(searchTermIndexStart - 150, searchTermIndexStart + 150)
          const chunk = doc.text.slice(0, 300)
          return (
            <Box key={`${index}${doc.text.slice(0,25)}`} sx={{ backgroundColor: '#f1f1f1', p: 2, mb: 2}}>
              <Typography variant="body1">
                {chunk}...
                {/* ...{<Highlighter
                  searchWords={[searchTerm]}
                  autoEscape={true}
                  textToHighlight={chunk}
                />}... */}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Paper>
  )
}


export default function SearchResults({ 
  searchResults, 
  setSelectedItems, 
  selectedItems,
  handleReview,
  searchTerm,
} : SearchResultsProps) {
  const [error, href, handleError, resetError] = useError()

  const handleAddToSelectedItems = (item: SearchResultItem, checked: boolean) => {
    if (checked) {
      return setSelectedItems(selectedItems.concat(item))
    }
    return setSelectedItems(selectedItems.filter(i => i.filename !== item.filename))
  }

  const renderCategory = (catFinal: number) => {
    if (catFinal === 2) {
      return "Produced"
    }
    if (catFinal === 1) {
      return "Privileged"
    }
  }

  const handlePreview = async (item: SearchResultItem) => {
    const { jobId, attId, docId, emlId } = item
    let resource
    let itemId
    if (attId) {
      resource = 'att'
      itemId = `${attId}`
    } else if (docId){
        resource = 'doc'
        itemId = `${docId}`
    } else {
        resource = 'eml'
        itemId = `${emlId}`
    }
    const payload = await postS3DownloadUrl(jobId, resource, itemId, null)
        .catch(e => handleError(e))

    if (payload.success) {
        return window.open(
            payload.data.signedGetUrl,
            'Document View',
            'width=1200,height=900,left=200,top=200',
          )
    } 
    
    return handleError(payload.err, payload.href)
  }

  const groupedResults = Object.groupBy(searchResults, ({id}) => id)

  const groupArr = Object.values(groupedResults)

  return (
    <>
      <SearchHeaderContainer>
        <Typography variant='h3'>
          {searchResults.length} results
        </Typography>
        <Button variant='contained' disabled={selectedItems.length === 0} onClick={handleReview}>
          Review {selectedItems.length > 0 && selectedItems.length}
        </Button>
      </SearchHeaderContainer>
      {groupArr.map((item) => item && (
          <SearchItem
            key={item[0].docId || item[0].emlId || item[0].attId}
            item={item} 
            handlePreview={handlePreview}
            handleAddToSelectedItems={handleAddToSelectedItems}
            renderCategory={renderCategory}
          />
        )
      )}
      <ErrorModal error={error} href={href} resetError={resetError} />
    </>
  )
}
