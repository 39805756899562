import React, { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { DetailContainer, CenteredContent } from 'shared-components/layout'
import { CircularProgress, Grid, Stack, Typography, Switch } from 'shared-components/material/core'
import { ErrorModal, WarningModal } from 'shared-components/modals'

import DisputeMeta from 'screens/Job/DisputeMeta/DisputeMeta'
import JobMeta from './JobMeta/JobMeta'
import DocumentGrid from './DocumentGrid/DocumentGrid'
import DocumentSearch from './DocumentSearch/DocumentSearch'

const GET_DATA = gql`
    query job($jobId: Int) {
            job(jobId: $jobId) {
                jobId
                jobOwner
                jobCreatedAt
                jobName
                jobStatus
                jobBatesRecId
                jobCustomConfidential
                statusString {
                    status
                    message
                }
                dispute {
                    disputeId
                    disputeName
                    disputeMatter
                    users {
                        userFirstname
                        userLastname
                        userId
                        userType
                    }
                    client {
                        clientId
                        clientName
                    }
                    batesRecs {
                        batesRecId
                        batesRecPrefix
                        batesRecNextBatesStr
                        batesRecNextPrivStr
                    }
                }
        }
        user {
            userSearchEnabled
        }
    }
`

type email = {
    address: string
}

export type SearchResultItem = {
    filename: string,
    subject: string,
    from: email[],
    to: email[],
    cc: email[],
    bcc: email[],
    emlId: number,
    attId: number,
    docId: number,
    date: string,
    catFinal: number,
    confidential: boolean,
    redact: boolean,
    isKey: boolean,
    score: number,
    text: string,
    jobId: number,
    id: string,
  }

export default function DocumentReview() {
    // const [isSwitchDisabled, setIsSwitchDisabled] = useState(false)
    const [selectedItems, setSelectedItems] = useState<SearchResultItem[]>([])
    const [isSearch, setIsSearch] = useState(false)
    const { jobId } = useParams()
    const id = jobId ? parseInt(jobId) : 0

    const handleReview = () => {
        // setIsSwitchDisabled(false)
        setIsSearch(false)
    }

    const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        setIsSearch(e.target.checked)
        setSelectedItems([])
        // setIsSwitchDisabled(true)
    }

    const { error: dataError, loading: dataLoading, data, refetch: dataRefetch } = useQuery(GET_DATA, {
        variables: { jobId: id },
        fetchPolicy: 'network-only',
    });

    if (dataLoading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    const { job } = data
    const { dispute, jobStatus } = job
    const { client, users } = dispute

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Document Review
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item md={6}>
                    <DetailContainer>
                        <JobMeta job={job} users={users} />
                    </DetailContainer>
                </Grid>
                <Grid item md={6}>
                    <DetailContainer>
                        <DisputeMeta 
                            dispute={dispute} 
                            client={client} 
                            jobBatesRecId={job.jobBatesRecId}
                        />
                    </DetailContainer>
                </Grid>
                {data.user.userSearchEnabled && (
                    <>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 2, mt: 2 }}>
                        <Typography>Review</Typography>
                        <Switch 
                            checked={isSearch} 
                            onChange={handleSwitch} 
                            sx={{ m: 1 }}
                            // disabled={isSwitchDisabled} 
                        />
                        <Typography>Content Search</Typography>
                    </Stack>
                    </>
                )}
                <Grid item md={12}>
                    {isSearch 
                        ?   <DocumentSearch 
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                handleReview={handleReview}
                            />
                        :   <DocumentGrid
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                disputeId={job.dispute.disputeId}
                                jobStatus={jobStatus} 
                                refetchJob={dataRefetch} 
                            />
                    }
                </Grid>
            </Grid>
            <ErrorModal error={(dataError && dataError.message) || ''} />
           
            {job.jobStatus === 59 && (
                <WarningModal 
                    warning="We're giving you faster access to the Document Review screen by converting documents to PDF in the background while you review. Please refresh the page after a few minutes to see converted PDFs that you can preview."
                />
            )}
        </>
    )
}
