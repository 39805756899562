import React from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { RightAlignedForm } from 'shared-components/layout'
import { 
    Button,
    Snackbar,
    MuiAlert,
    AlertProps
} from 'shared-components/material/core'
import { PasswordInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import { putPassword } from '../../api/accounts'

const schema = yup.object({
    oldPassword: yup.string().required(),
    newPassword: yup
        .string()
        .required()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
    confirmPassword: yup
        .string()
        .when("password", {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("newPassword")],
                "Passwords must match"
            ),
        })
        .required("Confirm Password Required"),
  }).required()

type FormData = {
    newPassword: string;
    oldPassword: string;
    confirmPassword: string;
}

function ChangePassword() {
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const [error, href, handleError, resetError ] = useError()

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        resolver: yupResolver(schema),
    });

    const { handleSubmit, formState: { errors }} = methods

    const handleCloseSnackbar = () => setSnackbar(null)

    const onSubmit = async (data: FormData) => {
      const { oldPassword, newPassword } = data
      const payload = await putPassword(oldPassword, newPassword)
          .catch(e => handleError(e))

          if (payload.success) {
            setSnackbar({ children: 'Password saved successfully', severity: 'success' })
        }

        return handleError(payload.err, payload.href)
  }

    return (
        <FormProvider {...methods} >
                <RightAlignedForm>
                    <PasswordInput
                        name={"oldPassword"}
                        label={"Current Password"}
                        error={errors.oldPassword != undefined ? true : false }
                        errorMessage={errors.oldPassword ? errors.oldPassword.message : undefined}
                    />
                    <PasswordInput
                        name={"newPassword"}
                        label={"New Password"}
                        error={errors.newPassword != undefined ? true : false }
                        errorMessage={errors.newPassword ? errors.newPassword.message : undefined}
                    />
                    <PasswordInput
                        name={"confirmPassword"}
                        label={"Confirm Password"}
                        error={errors.confirmPassword != undefined ? true : false }
                        errorMessage={errors.confirmPassword ? errors.confirmPassword.message : undefined}
                    />
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Change Password
                    </Button>
                </RightAlignedForm>
                <ErrorModal error={error} href={href} resetError={resetError} />
                {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </FormProvider>
    )
}

export default ChangePassword
