import { getClientEngagementEmailBody, getOpposingCounselEmailBody } from 'api/disputes'
import React, { useState } from 'react'
import { useError } from 'shared-components/hooks'
import { CenteredContent, DetailContainer, DetailWrapper } from 'shared-components/layout'
import { 
    Typography,
    Grid,
    List,
    ListItemIcon,
    ListItemText,
    Box,
    CircularProgress,
    ListItemButton,
    Button 
} from 'shared-components/material/core'
import { DescriptionIcon } from 'shared-components/material/icons'
import { ErrorModal } from 'shared-components/modals'

export default function Templates() {
    const [ error, href, handleError, resetError ] = useError()
    const [loading, setLoading] = useState(false)
    const [template, setTemplate] = useState('')
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

    const getDiscoveryProtocol = async () => {
        setLoading(true)
        const payload = await getOpposingCounselEmailBody(null)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setLoading(false)
            setTemplate(payload.data.emailBody)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const getClientEngagement = async () => {
        setLoading(true)
        const payload = await getClientEngagementEmailBody()
            .catch(e => handleError(e))
        
        if (payload.success) {
            setLoading(false)
            setTemplate(payload.data.emailBody)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const templateList = [
        {
            name: 'Discovery Protocol Email',
            apiCall: () => getDiscoveryProtocol()
        },
        {
            name: 'Client Engagement Agreement',
            apiCall: () => getClientEngagement()
        }
    ]

    return (
        <DetailWrapper>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={3}>
                    <DetailContainer>
                        <Typography variant='h2' gutterBottom>
                            Templates
                        </Typography>
                        <List>
                            {templateList.map((t, index) => (
                                <ListItemButton 
                                    key={t.name}
                                    selected={selectedIndex === index}
                                    onClick={() => {
                                        t.apiCall()
                                        setSelectedIndex(index)
                                    }}
                                >
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t.name}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </DetailContainer>
                </Grid>
                <Grid item xs={9}>
                    <DetailContainer>
                        {template ? (
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <Box sx={{ 
                                    maxWidth: '700px',
                                    backgroundColor: '#f2f3f8',
                                    p: 3,
                                    mt: 2,
                                    position: 'relative'
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {navigator.clipboard.writeText(`${template}`)}}
                                        sx={{ position: 'absolute', top: 10, right: 10 }}
                                    >
                                        Copy
                                    </Button>
                                    {template.split('\n').map((str, index) => <p key={index}>{str}</p>)}
                                </Box>
                            </Box>
                        ): null}
                        {loading ? (
                            <CenteredContent>
                                <CircularProgress />
                            </CenteredContent>
                        ): null}
                        {!template && !loading ? (
                            <CenteredContent>
                                <Typography variant='body1' gutterBottom>
                                    Please select an option in the template menu.
                                </Typography>
                            </CenteredContent>
                        ): null}
                    </DetailContainer>
                </Grid>
            </Grid>
            <ErrorModal
                    error={error} 
                    href={href} 
                    resetError={resetError}
                />
        </DetailWrapper>
    )
}
