import { putEmailVerified } from 'api/accounts'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useError } from 'shared-components/hooks'
import { CenteredContent } from 'shared-components/layout'
import { CircularProgress } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

function VerifyEmail() {
  const { search } = useLocation()
  const emailVerificationCode = new URLSearchParams(search).get('code')
  const navigate = useNavigate()
  const [error, href, handleError, resetError] = useError()

  const verifyEmail = async () => {
    const {success, err, href} = await putEmailVerified(emailVerificationCode)
      .catch(e => handleError(e))

    return success ? navigate('/account-setup') : handleError(err, href)
  }


  useEffect(() => {
    if (emailVerificationCode) {
      verifyEmail()
    }
  }, [])

  return (
    <CenteredContent>
      <CircularProgress />
      <ErrorModal error={error} href={href} resetError={resetError} />
    </CenteredContent>
  )
}

export default VerifyEmail