import React from 'react'
import {
    GridValueGetterParams,
    GridRenderCellParams,
    useGridApiContext,
    GridRenderEditCellParams,
    GridFilterInputValueProps,
    GridComparatorFn,
} from '@mui/x-data-grid-premium'
import { SelectChangeEvent } from '@mui/material'

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from 'shared-components/material/core'
import { FlagIcon } from 'shared-components/material/icons'
import validator from 'validator'

export function renderFlag(params: GridRenderCellParams) {
    const { flag } = params.row
    switch(flag) {
        case '#C33C54':
            return <FlagIcon sx={{ color: '#C33C54' }}/>
            break
        case '#ED7434':
            return <FlagIcon sx={{ color: '#ED7434' }}/>
            break
        case '#FFE347':
            return <FlagIcon sx={{ color: '#FFE347' }}/>
            break
        case '#255957':
            return <FlagIcon sx={{ color: '#255957' }}/>
            break
        case '#0A369D':
            return <FlagIcon sx={{ color: '#0A369D' }}/>
            break
        case '#593F62':
            return <FlagIcon sx={{ color: '#593F62' }}/>
            break
        default: 
            return <FlagIcon sx={{ color: '#dedede' }}/>
    }

}

export function CustomFlagComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props
    const apiRef = useGridApiContext()
  
    const handleValueChange = (event: SelectChangeEvent) => {
      const newValue = event.target.value // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue })
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="flag-label">Flag</InputLabel>
            <Select
                labelId="flag-label"
                id="flag"
                value={value}
                label="Flag"
                onChange={handleValueChange}
            >
              <MenuItem value={'#dedede'}><FlagIcon sx={{ color: '#dedede' }}/></MenuItem>
              <MenuItem value={'#C33C54'}><FlagIcon sx={{ color: '#C33C54' }}/></MenuItem>
              <MenuItem value={'#ED7434'}><FlagIcon sx={{ color: '#ED7434' }}/></MenuItem>
              <MenuItem value={'#FFE347'}><FlagIcon sx={{ color: '#FFE347' }}/></MenuItem>
              <MenuItem value={'#255957'}><FlagIcon sx={{ color: '#255957' }}/></MenuItem>
              <MenuItem value={'#0A369D'}><FlagIcon sx={{ color: '#0A369D' }}/></MenuItem>
              <MenuItem value={'#593F62'}><FlagIcon sx={{ color: '#593F62' }}/></MenuItem>
            </Select>
        </FormControl>
    )
}

  export function getSortAlpha(params: GridValueGetterParams) {
    return `${validator.unescape(params.row.folder) || ''}${validator.unescape(params.row.filename) || ''}`;
  }

  export function getSortChrono(params: GridValueGetterParams) {
    const date = new Date(params.row.date).toISOString()
    return `${params.row.folder || ''}${date || ''}`;
  }

export function getCategorized(params: GridValueGetterParams) {
    const { catFinal } = params.row
    
    return catFinal === 6 || catFinal === 4 || catFinal === 2 || catFinal === 1
}

export function FlagInputValue(props: GridFilterInputValueProps) {
    const { item, applyValue} = props;
  
    const handleFilterChange = (event: SelectChangeEvent) => {
        const newValue = event.target.value
         applyValue({ ...item, value: newValue });
    };
  
    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="flag-label">Flag</InputLabel>
            <Select
                labelId="flag-label"
                id="flag"
                value={item.value}
                label="Flag"
                onChange={handleFilterChange}
            >
                <MenuItem value={'#dedede'}><FlagIcon sx={{ color: '#dedede' }}/></MenuItem>
                <MenuItem value={'#C33C54'}><FlagIcon sx={{ color: '#C33C54' }}/></MenuItem>
                <MenuItem value={'#ED7434'}><FlagIcon sx={{ color: '#ED7434' }}/></MenuItem>
                <MenuItem value={'#FFE347'}><FlagIcon sx={{ color: '#FFE347' }}/></MenuItem>
                <MenuItem value={'#255957'}><FlagIcon sx={{ color: '#255957' }}/></MenuItem>
                <MenuItem value={'#0A369D'}><FlagIcon sx={{ color: '#0A369D' }}/></MenuItem>
                <MenuItem value={'#593F62'}><FlagIcon sx={{ color: '#593F62' }}/></MenuItem>
            </Select>
        </FormControl>
    );
  }

export function SelectedCategoryInputValue(props: GridFilterInputValueProps) {
    const { item, applyValue} = props;
  
    const handleFilterChange = (event: SelectChangeEvent) => {
        const newValue = event.target.value
         applyValue({ ...item, value: newValue });
    };
  
    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="selected-category-label">Selected Category</InputLabel>
            <Select
                labelId="selected-category-label"
                id="selected-category"
                value={item.value}
                label="selected-category"
                onChange={handleFilterChange}
            >
                <MenuItem value={'Privileged'}>Privileged</MenuItem>
                <MenuItem value={'Produced'}>Produced</MenuItem>
                <MenuItem value={'Irrelevant'}>Irrelevant</MenuItem>
                <MenuItem value={'Hold'}>Hold</MenuItem>
            </Select>
        </FormControl>
    );
  }

export const customStringSortComparitor: GridComparatorFn<string> = (v1, v2) => 
    v1.localeCompare(v2, 'en', { numeric: true, sensitivity: 'base' });
    
        export function getValue(params: GridValueGetterParams) {
            const { attId, filename, subject } = params.row
            if (attId) {
                return filename
            }
            return filename || subject
        }
    
      export function PredictedInputValue(props: GridFilterInputValueProps) {
        const { item, applyValue} = props;
      
        const handleFilterChange = (event: SelectChangeEvent) => {
            const newValue = event.target.value
             applyValue({ ...item, value: newValue });
        };
      
        return (
            <FormControl variant="standard" fullWidth>
                <InputLabel id="predicted-label">Predicted Category</InputLabel>
                <Select
                    labelId="predicted-label"
                    id="predicted"
                    value={item.value}
                    label="predicted"
                    onChange={handleFilterChange}
                >
                    <MenuItem value={'Very Likely Produced'}>Very Likely Produced</MenuItem>
                    <MenuItem value={'Likely Produced'}>Likely Produced</MenuItem>
                    <MenuItem value={'Possibly Produced'}>Possibly Produced</MenuItem>
                    <MenuItem value={'Likely Privileged'}>Likely Privileged</MenuItem>
                    <MenuItem value={'Very Likely Privileged'}>Very Likely Privileged</MenuItem>
                </Select>
            </FormControl>
        );
      }