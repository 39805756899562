import React, { useCallback, useState } from 'react'
import { Box, styled, sx, Stepper, Step, StepLabel, CircularProgress } from 'shared-components/material/core'
import BullsEye from './BullsEye'
import Source from './Source'
import ClientUploadConfirm from './ClientUploadConfirm'
import { gql, useQuery } from '@apollo/client'
import { CenteredContent } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'

const GET_USER = gql`
    query Query {
        user {
            userEmail
        }
    }
`


const UploadContainer = styled(Box)((
    sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    })
))

type UploadProps = {
    jobId: number,
    disputeId: number,
    refetchJob: () => void,
}

type FormData = {
    source: string,
    category: string,
    allConfidential: boolean,
    clientEmail: string,
    jobClientInstructions: string,
}

const steps = ['Setup', 'Upload']

export default function Upload({ jobId, disputeId, refetchJob } : UploadProps) {
    const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER)
    const [activeStep, setActiveStep] = useState(0)
    const [source, setSource] = useState('0')
    const [allConfidential, setAllConfidential] = useState(false)
    const [category, setCategory] = useState('')
    const [clientEmail, setClientEmail] = useState('')
    const [jobClientInstructions, setJobClientInstructions] = useState('')

    const handleBack = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep - 1), [])

    const getSetupData = useCallback((data: FormData) => {
        data.allConfidential && setAllConfidential(data.allConfidential)
        data.category && setCategory(data.category)
        data.clientEmail && setClientEmail(data.clientEmail)
        data.jobClientInstructions && setJobClientInstructions(data.jobClientInstructions)
        data.source && setSource(data.source)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }, [])

    if (userLoading) {
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    }

    return (
        <UploadContainer>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {activeStep === 0 ? (
                <Source
                    clientEmail={clientEmail}
                    jobClientInstructions={jobClientInstructions}
                    passSetupData={getSetupData}
                    user={userData && userData.user}
                />
            ): null}
            {activeStep === 1 && source === '0' ? (
                <BullsEye
                    refetchJob={refetchJob}
                    allConfidential={allConfidential}
                    category={category}
                    jobId={jobId}
                    disputeId={disputeId}
                    handleBack={handleBack}
                    user={userData.user}
                />
            ): null}
            {activeStep === 1 && source === '1' ? (
                <ClientUploadConfirm
                    jobId={jobId}
                    clientEmail={clientEmail}
                    jobClientInstructions={jobClientInstructions}
                    handleBack={handleBack}
                    refetchJob={refetchJob}
                    user={userData.user}
                />
            ): null}
            <ErrorModal error={(userError && userError.message) || ''}/>
        </UploadContainer>
    )
}
