import { gql, useMutation } from '@apollo/client'
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import { 
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    LoadingButton,
    useMediaQuery,
    useTheme 
} from 'shared-components/material/core'
import ErrorModal from './ErrorModal'

export const UPDATE_SHOW_VIDEO = gql`
    mutation UpdateUser($userShowExGenVideo: Boolean) {
        updateUser(userShowExGenVideo: $userShowExGenVideo) {
            userId
        }
    }
`

type VideoModalProps = {
    title: string,
    video: ReactElement
    text?: string,
    openVideoModal: boolean,
    setOpenVideoModal: Dispatch<SetStateAction<boolean>>
}

export default function VideoModal({ 
    openVideoModal,
    setOpenVideoModal,
    title,
    video,
    text 
} : VideoModalProps) {
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [updateShowVideo, { loading: updateShowVideoloading, error: updateShowVideoError }] = useMutation(UPDATE_SHOW_VIDEO, {
        onCompleted: () => setLoading(false)
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked)

    const handleClose = async () => {
        setLoading(true)
        const variables = { userShowExGenVideo: !checked}
        await updateShowVideo({ variables })
        setOpenVideoModal(false)
    }
    
    return (
        <Dialog
            fullScreen={fullScreen}
            open={openVideoModal}
            onClose={handleClose}
            aria-labelledby="video-dialog-title"
        >
            <DialogTitle id="video-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {text ? (
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                ): null}
                <Box sx={{ width:'550px' }}>
                    {video}
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', ml: 2}}>
                <FormControlLabel
                    value="end"
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Do not show this again"
                    labelPlacement="end"
                />
                {loading || updateShowVideoloading ? (
                    <LoadingButton loading variant="outlined">
                        Close
                    </LoadingButton>
                ) : (
                    <Button onClick={handleClose} autoFocus variant='contained'>
                        Close
                    </Button>
                )}
            </DialogActions>
            <ErrorModal error={updateShowVideoError && updateShowVideoError.message || ''} />
        </Dialog>
    )
}
