import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Typography, Link, CircularProgress } from 'shared-components/material/core'
import { CenteredContent } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'

const GET_DISPUTE = gql`
    query dispute($disputeId: Int) {
        dispute(disputeId: $disputeId) {
            disputeName
        }
    }
`

export default function DisputeMeta({ disputeId } : { disputeId: number}) {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(GET_DISPUTE, {
    fetchPolicy: 'network-only',
    variables: { disputeId },
  })

  if (loading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    )
  }

  return (
    <>
      <Typography variant="body1" gutterBottom>
        <strong>Dispute Name: </strong>
        <Link component="button" variant="body1" onClick={() => navigate(`/app/cases/${disputeId}`)}>{validator.unescape(data.dispute.disputeName)}</Link> <br />
      </Typography>
      <ErrorModal error={error && error.message || ''} />
    </>
  )
}
