import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { Grid, CircularProgress, Typography } from 'shared-components/material/core'
import { CenteredContent, DetailContainer, DetailWrapper } from 'shared-components/layout'
import { ErrorModal } from 'shared-components/modals'

import DisputeMeta from './DisputeMeta/DisputeMeta'
import FileMeta from './FileMeta/FileMeta'
import ExSetMeta from './ExSetMeta/ExSetMeta'
import ExSetData from './ExSetData/ExSetData'

const GET_DATA = gql`
    query ExSet($exSetId: Int, $disputeId: Int) {
        exSet(exSetId: $exSetId) {
            exSetId
            exSetDisputeId
            exSetOwner
            exSetName
            exSetNotes
            exSetPrefix
            exSetStart
            exSetEnd
            exSetCreatedAt
            exSetSequencingType
            exSetStatus
            jobs {
                jobUploadFilename
                jobUploadFilesize
                jobId
                jobStatus
            }
            owner {
                userFirstname
                userLastname
            }
        }
        dispute(disputeId: $disputeId) {
            disputeName
            disputeBatesIntegrity
            disputeMatter
            disputeId
            client {
                clientId
                clientName
            }
            batesRecs {
                batesRecId
                batesRecNextBatesStr
                batesRecNextPrivStr
            }
        }
    }
`

export default function ExhibitSet() {
    const { exSetId, disputeId } = useParams()
    const exId = exSetId ? parseInt(exSetId) : 0
    const disId = disputeId ? parseInt(disputeId) : 0
    const { error, loading, data, refetch } = useQuery(GET_DATA, {
        fetchPolicy: 'network-only',
        variables: { exSetId: exId, disputeId: disId },
    })

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <DetailWrapper>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Exhibit Set
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item md={6}>
                    <DetailContainer>
                        <ExSetMeta exSet={data.exSet} />
                    </DetailContainer>
                </Grid>
                <Grid item md={6}>
                    <DetailContainer>
                        <DisputeMeta dispute={data.dispute} client={data.dispute.client} />
                    </DetailContainer>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {data.exSet.jobs.length > 0 && (
                    <Grid item md={4}>
                        <DetailContainer>
                            <FileMeta
                                exSet={data.exSet}
                                refetchExSet={refetch}
                            />
                        </DetailContainer>
                    </Grid>
                )}
                <Grid item md={data.exSet.jobs.length > 0 ? 8 : 12}>
                    <DetailContainer>
                        <ExSetData
                            exSet={data.exSet}
                            refetch={refetch}
                        />
                    </DetailContainer>
                </Grid>
            </Grid>
            <ErrorModal error={error ? error.message : ''} />
        </DetailWrapper>
    )
}