import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation, useQuery } from '@apollo/client'

import { CenteredForm, CenteredContent } from 'shared-components/layout'
import {
    Button,
    Typography,
    CircularProgress,
    Link,
    Snackbar,
    MuiAlert,
    AlertProps,
    Box,
    LoadingButton
} from 'shared-components/material/core'
import { TextInput, RadioInput } from 'shared-components/inputs'
import { ErrorModal } from 'shared-components/modals'

import { SubscriptionTier } from 'generated/graphql'
import { createSubscription, putAccountSetupComplete } from 'api/accounts'
import { useError } from 'shared-components/hooks'
import { useParams } from 'react-router-dom'

const DELETE_SUBSCRIPTION = gql`
    mutation Mutation {
        deleteSubscription
    }
`

const UPDATE_SUBSCRIPTION = gql`
    mutation Mutation($subscriptionTier: Int, $subscriptionAttorneyCount: Int) {
  updateSubscription(subscriptionTier: $subscriptionTier, subscriptionAttorneyCount: $subscriptionAttorneyCount) {
    subscriptionId
  }
}
`

const GET_SUBSCRIPTION = gql`
    query Subscription {
        subscription {
            subscriptionTier
            subscriptionAttorneyCount
        }
        subscriptionTiersEnabled {
            subscriptionTierId
            subscriptionTierName
            subscriptionTierMonthlyRate
            subscriptionTierEnabled
        }
    }
`

const schema = yup.object({
    subscriptionTierId: yup.number().required('This Field is Required'),
    numberOfAttorneys: yup.number(),
  }).required()

type SubscriptionProps = {
    handleNext: () => void,
    handleBack: () => void,
} 

export type FormData = {
    subscriptionTierId: number,
    numberOfAttorneys: number,
  }

function Subscription({ handleNext, handleBack } : SubscriptionProps) {
    const { setupType } = useParams()
    const [error, href, handleError, resetError] = useError()
    const [loading, setLoading] = useState(false)
    const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
    const { 
        error: subscriptionError,
        loading: subscriptionLoading,
        data: subscriptionData} = useQuery(GET_SUBSCRIPTION, { fetchPolicy: 'network-only'})
    const [updateSubscription, { 
        loading: updateSubscriptionLoading, 
        error: updateSubscriptionError }] = useMutation(UPDATE_SUBSCRIPTION)
    const [deleteSubscription, { 
        loading: deleteSubscriptionLoading, 
        error: deleteSubscriptionError }] = useMutation(DELETE_SUBSCRIPTION)

    const subscription = subscriptionData ? subscriptionData.subscription : {}

    let errorMessage = ''

    if (deleteSubscriptionError) {
        errorMessage = deleteSubscriptionError.message
    }
    if (updateSubscriptionError) {
        errorMessage = updateSubscriptionError.message
    }
    if (subscriptionError) {
        errorMessage = subscriptionError.message
    }

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            subscriptionTierId: 0,
            numberOfAttorneys: 0,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, setValue, formState: { errors }} = methods

    const subscriptionTiers = subscriptionData ? subscriptionData.subscriptionTiersEnabled : null
    let subscriptionTierOptions = subscriptionTiers ? subscriptionTiers.map((s: SubscriptionTier) => ({ label: s.subscriptionTierName, value: s.subscriptionTierId })) : []
    subscriptionTierOptions = [{ label: 'Pay-as-you-go $1.13/doc', value: 0 }, ...subscriptionTierOptions]
    const selectedPlan = watch('subscriptionTierId')

    useEffect(() => {
        if (subscription) {
            setValue('numberOfAttorneys', subscription.subscriptionAttorneyCount)
        }
    }, [subscription])

    const createSubscriptionFn = async (subscriptionTierId: number, numberOfAttorneys: number) => {
        setLoading(true)
        const payload = await createSubscription(subscriptionTierId, numberOfAttorneys)
              .catch(e => handleError(e))
    
          if (payload.success) {
              setLoading(false)
          } else {
            setLoading(false)
            handleError(payload.err, payload.href)
          }
      }
    
    const completeAccountSetup = async () => {
        setLoading(true)
        const payload = await putAccountSetupComplete()
            .catch(e => handleError(e))

        if (payload.success) {
            return handleNext()
        } else {
            setLoading(false)
            return handleError(payload.err, payload.href)
        }
    }

    const onSubmit = async (formData: FormData) => {
        setLoading(true)
        const {subscriptionTierId, numberOfAttorneys} = formData
        if (subscription && subscriptionTierId !== 0) {
            const variables = {
                subscriptionTier: subscriptionTierId, 
                subscriptionAttorneyCount: numberOfAttorneys
            }
            await updateSubscription({ variables })
        }
        if (subscription && subscriptionTierId === 0) {
            await deleteSubscription()
        }
        if (!subscription && subscriptionTierId !== 0) {
            await createSubscriptionFn(subscriptionTierId, numberOfAttorneys)
        }
        await completeAccountSetup()
    }

    const handleCloseSnackbar = () => setSnackbar(null)

    if (subscriptionLoading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }  

    const subscriptionHref: string = subscriptionError && subscriptionError.graphQLErrors[0] 
        ? subscriptionError.graphQLErrors[0].extensions.href as string 
        : ''

    return (
        <FormProvider {...methods} >
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <CenteredForm align="center">
                    <Typography variant="h2" component="h2" textAlign='left'>
                        Select Subscription Tier
                    </Typography>
                    <RadioInput
                        name="subscriptionTierId"
                        error={errors.subscriptionTierId !== undefined ? true : false}
                        errorMessage={errors.subscriptionTierId ? errors.subscriptionTierId.message : undefined}
                        options={subscriptionTierOptions}
                        defaultValue={(subscription && subscription.subscriptionTier) || 0}
                    />
                    {selectedPlan > 0 && (
                        <TextInput
                            name="numberOfAttorneys"
                            label="Number of Attorneys"
                            type="number"
                            required
                            error={errors.numberOfAttorneys !== undefined ? true : false }
                            errorMessage={errors.numberOfAttorneys ? errors.numberOfAttorneys.message : undefined}
                        />
                    )}
                    <Typography variant="body1" textAlign='left'>
                        <Link href="https://www.discoverygenie.com/pricing/" target="_blank">Click here to learn more.</Link>
                    </Typography>
                </CenteredForm>
                <Box sx={{ display: 'flex', justifyContent: setupType === 'upgrade' ? 'flex-end' : 'space-between' }}>
                    {setupType !== 'upgrade' && (
                        <Button onClick={handleBack} variant="contained">
                            Back
                        </Button>
                    )}
                    {loading || updateSubscriptionLoading || deleteSubscriptionLoading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ): (
                        <Button onClick={handleSubmit(onSubmit)} variant="contained">
                            Next
                        </Button>
                    )}
                </Box>
            </Box>
            {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <ErrorModal error={errorMessage || error || ''} href={href || subscriptionHref} resetError={resetError} />
        </FormProvider>
    )
}

export default Subscription
