import React from 'react'
import { currencyFormat } from 'shared-components/utils'
import { ExSet } from 'generated/graphql'
import { DialogContent, DialogActions, styled, Button, Box } from 'shared-components/material/core'

const CostTable = styled('table')`
    width: 100%;
    max-width: 600px;
    margin: 2em auto 1.5em auto;
    padding: 1em;
    border: 0;
    border-collapse: collapse;
`

const CostThead = styled('thead')`
    border-bottom: .5px solid #e0e0e0;
`

const CostTd = styled('td')`
    text-align: right;
    color: #999;
    padding: 2px 4px;
    border: 0;
`

const CostTh = styled('th')`
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #333;
    padding: 2px 4px;
    border: 0;
    text-align: left;
`

type ReviewProps = { 
  exSet: ExSet,
  handleBack: () => void,
  activeStep: number,
  handleNext: () => void,
}

export default function Review({ exSet, handleBack, handleNext, activeStep } : ReviewProps) {
  return (
    <>
      <DialogContent sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}>
        <CostTable>
          <CostThead>
            <tr>
              <CostTh>Category</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Docs</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Pages</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Cost</CostTh>
              <CostTh style={{ textAlign: 'right' }}>Total</CostTh>

            </tr>
          </CostThead>
          <tbody>
            {exSet.exSetSuppCount && exSet.exSetSuppCount > 0
              ? <tr>
                <CostTh>Suppressed</CostTh>
                <CostTd>{exSet.exSetSuppCount}</CostTd>
                <CostTd>{exSet.exSetSuppPages}</CostTd>
                <CostTd>{currencyFormat(exSet.exSetSuppCostPer as number)}</CostTd>
                <CostTd>{currencyFormat(exSet.exSetSuppCost as number)}</CostTd>
              </tr>
              : null
            }
            {exSet.exSetStmpCount && exSet.exSetStmpCount > 0
              ? <tr>
                <CostTh>Stamped</CostTh>
                <CostTd>{exSet.exSetStmpCount}</CostTd>
                <CostTd>{exSet.exSetStmpPages}</CostTd>
                <CostTd>{currencyFormat(exSet.exSetStmpCostPer as number)}</CostTd>
                <CostTd>{currencyFormat(exSet.exSetStmpCost as number)}</CostTd>
              </tr>
              : null
            }
          </tbody>
        </CostTable>
      </DialogContent>
      <DialogActions>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pt: 2,
            width: '100%'
        }}>
          <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
          >
              Back
          </Button>
          <Button 
            onClick={handleNext}
            variant="contained"
            >
              Next
            </Button>
        </Box>
      </DialogActions>
    </>
  )
}

