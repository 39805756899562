import React from 'react'
import { AdapterMoment, LocalizationProvider, DatePicker, TextField } from 'shared-components/material/core'
import { useFormContext, Controller } from "react-hook-form"
import { TextFieldProps } from '@mui/material'

type FormValues = {
    name: string, 
    label: string,
    error?: boolean,
    errorMessage?: string,
}

export default function DateInput({
    name,
    label,
    error=false,
    errorMessage, 
    }: FormValues) {
    const { control } = useFormContext()
    return <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label={label}
                    value={value}
                    onChange={onChange}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} error={error} helperText={errorMessage} />}
                />
            </LocalizationProvider>
        )}
        />
}
