import React, { useEffect, useState } from 'react'
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import Review from './Review'
import Success from './Success'
import Payment from './Payment'
import ErrorModal from '../ErrorModal'
import { calcUnpaidExSetCosts } from 'api/exSets'
import { Cost } from 'generated/graphql'
import { useNavigate } from 'react-router-dom'

type PayModalProps = { 
    open: boolean,
    closePayModal: (event?: object, reason?: string) => void,
    exSetId: number,
    disputeId: number,
}

const steps = ['Review', 'Payment', 'Success']

export default function ExhibitGenieSetupPayModal({
    open,
    closePayModal,
    exSetId,
    disputeId,
}: PayModalProps) {

    const navigate = useNavigate()
    const theme = useTheme()
    const [error, href, handleError, resetError ] = useError()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [activeStep, setActiveStep] = useState(0)
    const [payMethods, setPayMethods] = useState([])
    const [costs, setCosts] = useState<Cost[]>([])
    const [loading, setLoading] = useState(false)

    const getExSetCost = async () => {
        setLoading(true)
        const payload = await calcUnpaidExSetCosts(exSetId)
            .catch(e => handleError(e))
        
        if (payload.success) {
            setPayMethods(payload.data.payMethods)
            setCosts(payload.data.costs)
            setLoading(false)
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    useEffect(() => {
        getExSetCost()
    }, [])

    const handleBack = () => {
        if (activeStep === 0) {
            navigate(`/app/cases/${disputeId}/exhibit-set/${exSetId}`)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
    }

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

    if (loading) {
        return (
            <Dialog open={open} onClose={closePayModal} fullScreen={fullScreen}>
                <DialogTitle>Exhibit Set Setup Payment</DialogTitle>
                <DialogContent sx={{ textAlign: 'center'}}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={closePayModal} fullWidth maxWidth={'md'} fullScreen={fullScreen}>
                <DialogTitle>Exhibit Set Setup Payment</DialogTitle>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {}
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                {activeStep === 0 &&
                    <Review
                        costs={costs}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                }
                {activeStep === 1 &&
                    <Payment
                        costs={costs}
                        payMethods={payMethods}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        closePayModal={closePayModal}
                    />
                }
                {activeStep === 2 && <Success closePayModal={closePayModal} />}
                <ErrorModal error={error} href={href} resetError={resetError} />
        </Dialog>
    )
}
