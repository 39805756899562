import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { TextInput } from 'shared-components/inputs'
import { RightAlignedForm } from 'shared-components/layout'
import { 
  Typography,
  Button,
  Link,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  MuiAlert,
  AlertProps
} from 'shared-components/material/core'
import { getReferrals, postReferral } from 'api/accounts'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { Referral } from 'generated/graphql'
import moment from 'moment'

/* eslint-disable no-undef */
const { REACT_APP_CLIENT_URL } = process.env
const registerUrl = `${REACT_APP_CLIENT_URL}/register?partner=`

const schema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  firmName: yup.string().required(),
  email: yup.string().required(),
}).required()

type FormData = {
  firstname: string;
  lastname: string;
  firmName: string;
  email: string;
}

function ReferralTable({ referrals } : { referrals: Referral[] }) {
  return (
    <TableContainer component={Paper} >
      <Table aria-label="referrals">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Referral Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.map(({referralId, referralFirstname, referralLastname, referralEmail, referralCreatedAt } : Referral) => (
            <TableRow key={referralId}>
              <TableCell>{referralFirstname} {referralLastname}</TableCell>
              <TableCell>{referralEmail}</TableCell>
              <TableCell>{moment(referralCreatedAt).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default function Rewards() {
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const [error, href, handleError, resetError] = useError()
  const [referrals, setReferrals] = useState([])
  const [param, setParam] = useState('')

  const getReferralsFn = async () => {
    const payload = await getReferrals()
      .catch(e => handleError(e))
    if (payload.success) {
      setReferrals(payload.data.referrals)
      setParam(payload.data.partnerParam)
    } else {
      handleError(payload.err, payload.href)
    }
  }


  useEffect(() => {
    getReferralsFn()
  }, [])

  const methods = useForm({
    defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
        firstname: '',
        lastname: '',
        firmName: '',
        email: '',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState: { errors }} = methods

  const onSubmit = async (formData: FormData) => {
    const payload = await postReferral(formData)
      .catch(e => handleError(e))
    if (payload.success) {
      setSnackbar({ children: 'Save successful', severity: 'success' })
      reset()
      getReferralsFn()
    } else {
      handleError(payload.err, payload.href)
    }
  }

  const handleCloseSnackbar = () => setSnackbar(null)

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      }}
    >
      {referrals.length > 0 ? <ReferralTable referrals={referrals} /> : <div />}
      <div />
      <RightAlignedForm>
        <Typography variant='h3' gutterBottom>
          Referral Rewards
        </Typography>
        <Typography variant='body1' gutterBottom>
          Know someone that could benefit from Discovery Genie?
          Refer a new firm and when they run $1,500 worth of documents, 
          we&apos;ll reward you with a $300 gift card of your choice.
          We&apos;ll also give them a $200 credit for getting started. 
          Simply enter their information below and we&apos;ll send them 
          an invitation to sign up.
        </Typography>
        <FormProvider {...methods}>
          <TextInput
              name="firstname"
              label="First Name"
              required
              error={errors.firstname !== undefined ? true : false }
              errorMessage={errors.firstname ? errors.firstname.message : undefined}
          />
          <TextInput
              name="lastname"
              label="Last Name"
              required
              error={errors.lastname !== undefined ? true : false }
              errorMessage={errors.lastname ? errors.lastname.message : undefined}
          />
          <TextInput
              name="firmName"
              label="Firm Name"
              required
              error={errors.lastname !== undefined ? true : false }
              errorMessage={errors.lastname ? errors.lastname.message : undefined}
          />
          <TextInput
              name="email"
              label="E-Mail"
              required
              error={errors.email !== undefined ? true : false }
              errorMessage={errors.email ? errors.email.message : undefined}
          />
          <Button
              variant="contained"
              size="large"
              onClick={handleSubmit(onSubmit)}
          >
              Submit
          </Button>
        </FormProvider>
        <Typography variant='h3' gutterBottom sx={{ mt: 2 }}>
          Referral Link
        </Typography>
        <Typography variant='body1' gutterBottom>
          Use the following personalized link to spread the word about Discovery Genie. 
        </Typography>
        <Link href={`${registerUrl}${param}`} variant="body1" gutterBottom target="_blank">
          {`${registerUrl}${param}`}
        </Link>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {navigator.clipboard.writeText(`${registerUrl}${param}`)}}
        >
          Copy
        </Button>
        <Typography variant='body1'>
          When someone uses your link and runs a job, it will also count towards your rewards! 
          Referrals using this link will also get a $100 credit.
        </Typography>
      </RightAlignedForm>
      <ErrorModal error={error} href={href} resetError={resetError}/>
      {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
    </Box>
  )
}
