import { CircularProgress } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CenteredContent } from 'shared-components/layout'
import PreAuthSubscriptionPayModal from 'shared-components/modals/PreAuthSubscriptionPayModal/PreAuthSubscriptionPayModal'

export default function SubscriptionPaymentPreauth() {
    const { search } = useLocation()
    const preauthCode = new URLSearchParams(search).get('preauthCode')
    if (preauthCode) {
        return <PreAuthSubscriptionPayModal open={true} preauthCode={preauthCode} />
    }
    return <CenteredContent><CircularProgress /></CenteredContent>
}
