import React, { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { CreatePaymentMethodData, StripeCardElement, StripeElementChangeEvent } from '@stripe/stripe-js'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { 
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, 
  CircularProgress,
  Snackbar,
  MuiAlert,
  AlertProps,
  LoadingButton,
} from 'shared-components/material/core'

import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { PayMethod } from 'generated/graphql'
import { CheckboxInput } from 'shared-components/inputs'
import { CenteredContent } from 'shared-components/layout'

const schema = yup.object({
  payMethodType: yup.boolean().required(),
}).required()

export const GET_PAYMENT_METHODS = gql`
    query PayMethods {
      payMethods {
        payMethodId
        payMethodType
        payMethodExpDate
        payMethodUserPaymentAccess
        payMethodUiIdentifier
        payMethodFirmId
      }
    }
`

export const ADD_PAYMENT_METHOD = gql`
  mutation Mutation($paymentMethod: JSON, $payMethodType: Int) {
    addPayMethod(paymentMethod: $paymentMethod, payMethodType: $payMethodType) {
      payMethodId
    }
  }
`

export const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePayMethod($payMethodId: Int!) {
    deletePayMethod(payMethodId: $payMethodId)
  }
`

type FormData = {
  payMethodType: boolean,
}

function PaymentMethod() {
  const stripe = useStripe()
  const elements = useElements()
  const cardElement = elements && elements.getElement('card')
  const [isCardComplete, setIsCardComplete] = useState(false)

  const handleCardChange = (e: StripeElementChangeEvent) => {
    if (e.complete) {
      setIsCardComplete(true)
    } else {
      setIsCardComplete(false)
    }
  }
   
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const { data, loading: paymentMethodLoading, error: paymentMethodError, refetch } = useQuery(GET_PAYMENT_METHODS, {
    fetchPolicy: 'network-only'
})
  const [addPaymentMethod, { loading: addPaymentMethodloading, error: addPaymentMethodError }] = useMutation(ADD_PAYMENT_METHOD, {
    onCompleted: () => setSnackbar({ children: 'Card added successfully', severity: 'success' })
  })
  const [deletePaymentMethod, { loading: deletePaymentMethodloading, error: deletePaymentMethodError }] = useMutation(DELETE_PAYMENT_METHOD, {
    onCompleted: () => setSnackbar({ children: 'Card deleted successfully', severity: 'success' })
  })
  const [error, href, handleError, resetError ] = useError()

  


  let errorMessage = ''

  if (paymentMethodError) {
    errorMessage = paymentMethodError.message
  }
  if (addPaymentMethodError) {
    errorMessage = addPaymentMethodError.message
  }
  if (deletePaymentMethodError) {
    errorMessage = deletePaymentMethodError.message
  }
  if (error) {
    errorMessage = error
  }

  const methods = useForm({
    defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
        payMethodType: true,
    },
    resolver: yupResolver(schema),
  })

const { handleSubmit, formState: { errors } } = methods

  const handleDelete = async (payMethodId: number) => {
    const variables = { payMethodId }
    await deletePaymentMethod({ variables })
    return refetch()
  }

  const getPaymentMethod = async () => {
    const paymentMethodData: CreatePaymentMethodData = {
        type: 'card',
        card: cardElement as StripeCardElement,
    }

    if (stripe) {
      const { paymentMethod, error } = await stripe.createPaymentMethod(paymentMethodData)

      if (paymentMethod) {
        return paymentMethod
      }
  
      if (error) {
        return handleError(error.message)
      } 
    } 
  }

  const onSubmit = async (formData: FormData) => {
    const paymentMethod = await getPaymentMethod()
    const payMethodType = formData.payMethodType ? 1 : 0
    const variables = { payMethodType, paymentMethod }
    await addPaymentMethod({ variables })
    return refetch()
  }

  const handleCloseSnackbar = () => setSnackbar(null)

  if (paymentMethodLoading) {
    return (
        <CenteredContent>
            <CircularProgress sx={{ color: 'grey.50' }} />
        </CenteredContent>
    )
  }

  const { payMethods } = data

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      }}
    >
    {payMethods.length > 0 ? (
    <TableContainer component={Paper} sx={{ marginRight: '2em' }}>
      <Table aria-label="payment methods">
        <TableHead>
          <TableRow>
            <TableCell>Payment Method</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payMethods.map(({payMethodId, payMethodUiIdentifier, payMethodFirmId} : PayMethod) => (
            <TableRow key={payMethodId}>
              <TableCell>xxxxxxxxxxxx{payMethodUiIdentifier}</TableCell>
              <TableCell>{payMethodFirmId ? 'Firm' : 'User'}</TableCell>
              <TableCell>
              {deletePaymentMethodloading ? (
                  <LoadingButton loading variant="outlined">
                      Delete
                  </LoadingButton>
              ) : (
                <Button
                    variant="outlined"
                    size="large"
                    color="error"
                    onClick={() => handleDelete(payMethodId)}
                >
                    Delete
                </Button>
              )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    ) : <div /> }
    <div>
      <Typography style={{ width: '400px' }} variant="h3">Add Payment Method</Typography>
      <div style={{ width: '400px', margin: '1em 0', backgroundColor: '#f2f3f8', padding: '1em'}}>
        <CardElement onChange={(e) => handleCardChange(e)}/>
      </div>
      <FormProvider {...methods} >
        <CheckboxInput
            name='payMethodType'
            label="Add card for firm"
            required
            disabled={!isCardComplete}
            error={errors.payMethodType != undefined ? true : false }
            errorMessage={errors.payMethodType ? errors.payMethodType.message : undefined}
        />
        {addPaymentMethodloading ? (
            <LoadingButton loading variant="outlined">
                Add Card
            </LoadingButton>
        ) : (
            <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={!isCardComplete}>Add Card</Button>
        )}
      </FormProvider>
    </div>
    {!!snackbar && (
                <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                >
                    <MuiAlert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
      <ErrorModal error={errorMessage} href={href} resetError={resetError} />
    </Box>
  )
}

export default PaymentMethod
