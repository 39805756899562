import React, { useEffect } from 'react'

import { useError } from 'shared-components/hooks'
import { CenteredContent } from 'shared-components/layout'
import { CircularProgress } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'
import { generateId } from 'shared-components/utils'

import { getSSOLink } from 'api/clio'

const { REACT_APP_CLIENT_URL } = process.env
const clioLink = `https://app.clio.com/oauth/authorize?response_type=code&client_id=XAobjVcw5u9wSloD9uOLwHZobf9If6AMlI1ETy2C&redirect_uri=${REACT_APP_CLIENT_URL}/app/preferences/clio?redirect=clio-redirect`;

export default function AddToClioFlow() {
    const [error, href, handleError, resetError] = useError()

    const getSSOLinkFn = async () => {
        const {success, err, href, data} = await getSSOLink()
            .catch(e => handleError(e))

        if (success) {
            if (data.hasSession) {
                return window.location.assign(clioLink);
            } else {
                const identityState = generateId(10)
                localStorage.setItem('identityState', identityState);
                return window.location.assign(`${data.clioLink}&state=${identityState}`)
            }
        }

        return handleError(err, href)
    }

    useEffect(() => {
        getSSOLinkFn()
    }, [])

    return (
        <CenteredContent>
            <CircularProgress />
            <ErrorModal error={error} href={href} resetError={resetError} />
        </CenteredContent>
    )
}
