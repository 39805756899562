import React, { memo } from 'react'
import { Typography, Link } from 'shared-components/material/core'
import { Dispute, Client } from 'generated/graphql'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'

type DisputeMetaProps = {
  dispute: Dispute,
  client: Client,
  jobBatesRecId: number,
}

function DisputeMeta({ dispute, client, jobBatesRecId } : DisputeMetaProps) {
  const navigate = useNavigate()
  const batesRec = dispute.batesRecs && dispute.batesRecs.length !== 0 
    ? dispute.batesRecs.filter((batesRec) => batesRec && batesRec.batesRecId === jobBatesRecId)[0] 
    : null
  return (
    <>
      <Typography variant="body1" gutterBottom>
        <strong>Case Name: </strong>
        <Link component="button" variant="body1" onClick={() => navigate(`/app/cases/${dispute.disputeId}`)}>{dispute.disputeName && validator.unescape(dispute.disputeName)}</Link> <br />
        <strong>Client Name: </strong>
        <Link component="button" variant="body1" onClick={() => navigate(`/app/client/${client.clientId}`)}>{client.clientName}</Link> <br />
        {dispute.disputeBatesIntegrity ? (
          <>
            <strong>Bates Record:</strong> {batesRec 
              ? (
              <Link component="button" variant="body1" onClick={() => navigate(`/app/bates/${batesRec.batesRecId}`)}>
                {`Prefix: ${batesRec.batesRecPrefix} - Next bates: ${batesRec.batesRecNextBatesStr} - Next Priv: ${batesRec.batesRecNextPrivStr}`}
              </Link>
              ) : 'No Bates Recs'} <br />
          </>
        ) : null}
        
        <strong>Matter:</strong> {dispute.disputeMatter} <br />
      </Typography>
    </>
  )
}

export default memo(DisputeMeta)
