import React, { memo } from 'react'
import moment from 'moment'
import { Job, User } from 'generated/graphql'
import validator from 'validator'
import { Link, Typography } from 'shared-components/material/core'
import { useNavigate } from 'react-router-dom'
import ActionButton from './ActionButton'


function JobMeta({ job, users, refetchJob }: {job: Job, users: User[], refetchJob: () => void }) {
  const navigate = useNavigate()
  const jobOwner = users.find(user => user.userId === job.jobOwner)
  
  return (
    <>
      <Typography color="inherit" variant="h5" component="h2" gutterBottom>
          <Link component="button" variant="body1" onClick={() => navigate(`/app/document-set/${job.jobId}`)}>{job.jobName && validator.unescape(job.jobName)}</Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
          <strong>Owner:</strong> {jobOwner && jobOwner.userFirstname} {jobOwner && jobOwner.userLastname} <br />
          <strong>Created:</strong> {moment(job.jobCreatedAt).format('MMMM Do YYYY, h:mm:ss a')} <br />
          {job.jobCustomConfidential && <><strong>Stamp:</strong> {job.jobCustomConfidential} <br /></>}
          {job.finishUser ? (
            <span><strong>Finished By:</strong> {job.finishUser.userFirstname} {job.finishUser.userLastname}</span>
          ) : null}
          <strong>Status:</strong> {job.statusString && job.statusString.status} <br />
      </Typography>
      <ActionButton job={job} refetchJob={refetchJob} />
    </>
  )
}

export default memo(JobMeta)
