
import { DataGridPremium, DataGridPremiumProps, GridColDef, GridRenderCellParams, GridToolbar, GridValueFormatterParams } from '@mui/x-data-grid-premium'
import { getDisputePayments } from 'api/disputes';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useError } from 'shared-components/hooks';
import { ErrorModal } from 'shared-components/modals';
import { currencyFormat } from 'shared-components/utils';

interface PaymentAndCost {
    id: number;
    hierarchy: string[];
    description: string,
    cost: number,
    charge: number,
    paidDate?: Date,
    invoiceNumber?: number,
    exSetId?: number,
    jobId?: number,
  }

export default function Billing({ disputeId }:{ disputeId: number }) {
    const [paymentsAndCosts, setPaymentsAndCosts] = useState<PaymentAndCost[]>([])
    const [loading, setLoading] = useState(true)
    const [error, href, handleError, resetError] = useError()
    
    useEffect(() => {
        const getDisputePaymentsFn = async () => {
            const payload = await getDisputePayments(disputeId)
                .catch((e) => handleError(e))
            if (payload.success) {
                setPaymentsAndCosts(payload.data.paymentsAndCosts)
                setLoading(false)
            } else {
                setLoading(false)
                handleError(payload.err, payload.href)
            }
        }
        getDisputePaymentsFn()
    }, [])

    const columns: GridColDef[] = [
        {
          field: 'description',
          headerName: 'Description',
          renderCell: (params: GridRenderCellParams) => {
            if (params.row.exSetId) {
                return (
                    <a href={`/app/cases/${disputeId}/exhibit-set/${params.row.exSetId}`}>
                        {params.value}
                    </a>
                )
            }
            if (params.row.jobId) {
                return (
                    <a href={`/app/document-set/${params.row.jobId}`}>
                        {params.value}
                    </a>
                )
            }
            return params.value
          },
          type: 'string',
          width: 250,
        },
        {
          field: 'cost',
          headerName: 'Cost',
          valueFormatter: ({value}) => currencyFormat(value),
          type: 'string',
          width: 150,
        },
        {
          field: 'charge',
          headerName: 'Charged',
          valueFormatter: ({value}) => currencyFormat(value),
          type: 'string',
          width: 150,
        },
        {
          field: 'paidDate',
          headerName: 'Date Paid',
          width: 150,
          valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return ''
            }
            const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
            return valueFormatted
            }
        },
        {
          field: 'invoiceNumber',
          headerName: 'Invoice #',
          renderCell: (params: GridRenderCellParams) => <a href={`/cases/${disputeId}/payments/${params.row.id}`} target='_blank' rel='noreferrer'>{params.value}</a>,
          type: 'string',
          width: 150,
        },
      ];

    const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.hierarchy;

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPremium
                loading={loading}
                treeData
                rows={paymentsAndCosts}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                slots={{ toolbar: GridToolbar }}
                disableRowSelectionOnClick
                groupingColDef={{
                    width: 20,
                    headerName: '',
                    sortable: false,
                    filterable: false,
                    disableColumnMenu: true,
                    disableExport: true,
                    hideable: false,
                    resizable: false
                }}
            />
            <ErrorModal error={error} href={href} resetError={resetError} />
        </div>
    )
}
