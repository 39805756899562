import React, { useState, ChangeEvent } from 'react'
import { gql, useMutation } from '@apollo/client'
import { ProcUnit } from 'generated/graphql'

import { TableRow, TableCell, Link, Checkbox } from 'shared-components/material/core'
import { GetAppIcon } from 'shared-components/material/icons'
import { formatBytes } from 'shared-components/utils'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'

import { postS3DownloadUrl } from 'api/jobs'

export const UPDATE_PROC_UNIT = gql`
    mutation UpdateProcUnit(
        $procUnitId: Int!,
        $procUnitProcess: Boolean
    ) {
        updateProcUnit(
            procUnitId: $procUnitId,
            procUnitProcess: $procUnitProcess
        ) {
            procUnitId
        }
    }
`

type PSTProcUnitProps = { 
    jobId: number,
    procUnit: ProcUnit,
    status: number 
}

export default function PSTProcUnit({ jobId, procUnit, status } : PSTProcUnitProps) {
    const { 
        procUnitName, 
        procUnitSize, 
        procUnitRecords, 
        procUnitId, 
        procUnitProcessable, 
        procUnitProcess 
    } = procUnit

    const [processUnit, setProcessUnit] = useState(procUnitProcess)
    const [error, href, handleError, resetError ] = useError()
    const [updateProcUnit, { error: updateProcUnitError }] = useMutation(UPDATE_PROC_UNIT)

    const handleGetSignedUrl = async (resource: string) => {
        const payload = await postS3DownloadUrl(jobId, resource, null, procUnitId)
          .catch(e => handleError(e))

        if (payload.success) {
          window.open(payload.data.signedGetUrl)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const procUnitProcess = e.target.checked
        setProcessUnit(procUnitProcess)
        updateProcUnit({ variables: {procUnitId, procUnitProcess}})
    }

    return (
        <TableRow>
            <TableCell>{procUnitName}</TableCell>
            <TableCell>{procUnitSize && formatBytes(procUnitSize, 2)}</TableCell>
            <TableCell>{procUnitRecords}</TableCell>
            <TableCell>
            <Link sx={{ cursor: 'pointer' }} onClick={() => handleGetSignedUrl('procUnit')}>
                <GetAppIcon />
            </Link>
            </TableCell>
            <TableCell>
                {procUnitProcessable ? (
                    <Checkbox
                    checked={processUnit ?? false}
                    disabled={status !== 33}
                    onChange={(e) => handleChange(e)}
                />
                ) : null}
                
            </TableCell>
            <ErrorModal 
                error={error || (updateProcUnitError && updateProcUnitError.message) || ''}
                href={href}
                resetError={resetError}
            />
        </TableRow>
    )
}
