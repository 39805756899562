import React, { memo } from 'react'

import { Job } from 'generated/graphql'

import { Typography, Divider } from 'shared-components/material/core'


function Meta({ job } : { job: Job }) {
    const { jobBatesPrefix } = job
    const emls = job.emls || []
    const atts = job.atts || []
    const docs = job.docs || []

  const totalCatfinal = emls.filter(eml => eml && eml.emlCatFinal).length 
    + atts.filter(att => att && att.attCatFinal).length
    + docs.filter(doc => doc && doc.docCatFinal).length

  const recsTotal = (job.jobEmlsTotal || 0) + (job.jobAttsTotal || 0) + (job.jobDocsTotal || 0)

  const renderRange = (start: number, end: number, length: number) => {
    if (length === 0) {
      return 'n/a'
    }
    if (length === 1) {
      return `${start}`
    }
    return `${start} - ${end}`
  }

  return (
    <>
        <Typography variant="h5" component="h3">
          Records
        </Typography>
        <Divider />
        <Typography variant="body1" sx={{ mb: 4}}>
          {job.jobEmlsTotal ? (
            <span>
              <strong>Total Emails:</strong> {job.jobEmlsTotal}
              <br />
            </span>
          ) : null}
          {job.jobAttsTotal ? (
            <span>
              <strong>Total Attachments:</strong> {job.jobAttsTotal}
              <br />
            </span>
          ): null}
          {job.jobDocsTotal ? (
            <span>
              <strong>Total Documents:</strong> {job.jobDocsTotal}
              <br />
            </span>
          ) : null}
          <strong>Total Records:</strong> {recsTotal} <br />
          {totalCatfinal > 0 ? (
            <span>
              <strong>Total Categorized:</strong> {totalCatfinal}
              <br />
            </span>
          ): null}
        </Typography>

        <Typography variant="h5" component="h3">
          Job Settings
        </Typography>
        <Divider />
        <Typography variant="body1" gutterBottom>
          <strong>PDF Margin:</strong> {job.jobPdfMargin ? 'True' : 'False'} <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Category Bypass:</strong> {job.jobCatBypass ? 'True' : 'False '} <br />
          <strong>Category Type:</strong> {job.jobCatType} <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Custom Confidential:</strong> {job.jobCustomConfidential} <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>All Confidential:</strong> {job.jobAllConfidential ? 'True' : 'False'} <br />
          <strong>All Key:</strong> {job.jobAllKey ? 'True' : 'False'} <br />
        </Typography>
        <Typography variant="body1" sx={{ mb: 4}}>
          <strong>Client Email:</strong> {job.jobClientEmail !== null ? job.jobClientEmail : 'none'}
        </Typography>
            {jobBatesPrefix &&
                <>
                    <Typography variant="h5" component="h3">
                        Bates
                    </Typography>
                    <Divider />
                    <Typography variant="body1" gutterBottom>
                        <strong>Bates Prefix:</strong> {jobBatesPrefix} <br />
                        <strong>Bypass Bates: </strong> {job.jobBypassBates ? 'True' : 'False '} <br />
                        {job.statusString && job.statusString.status === 'ready to download' ? (
                          <>
                            <strong>Bates Range: </strong> {renderRange(job.jobBatesStart as number, job.jobBatesEnd as number, job.jobBatesLength as number)} <br />
                            <strong>Priv Range: </strong> {renderRange(job.jobPrivStart as number, job.jobPrivEnd as number, job.jobPrivLength as number)} <br />
                            <strong>Irrel Range: </strong> {renderRange(job.jobIrrelStart as number, job.jobIrrelEnd as number, job.jobIrrelLength as number)} <br />
                          </>
                        ) : null}
                        
                    </Typography>
                </>
            }
        </>
  )
}

export default memo(Meta)
