import React, { ReactElement } from "react"
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "../material/core"
import { SxProps, Theme } from '@mui/material/styles'
import { useFormContext, Controller } from "react-hook-form"

type FormValues = {
    name: string, 
    label: string,
    options: { label: string, value: string | number, component?: ReactElement }[]
    required?: boolean,
    error?: boolean,
    errorMessage?: string,
    disabled?: boolean,
    fullWidth?: boolean,
    sx?: SxProps<Theme>
}

const SelectInput = ({ 
    name,
    label,
    options,
    required=false, 
    error=false, 
    errorMessage,
    disabled=false,
    fullWidth=false,
    sx,
}: FormValues) => {
    const { control } = useFormContext()
    return <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
            <FormControl
                error={error}
                required={required}
                size="small"
                disabled={disabled}
                sx={sx}
            >
                <InputLabel id={name}>{label}</InputLabel>
                <Select
                    onChange={onChange}
                    value={value}
                    labelId={name}
                    id={name}
                    label={label}
                    fullWidth={fullWidth}
                >
                    {options.map((option) => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.component ? option.component : option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        )}
        />
};

export default SelectInput