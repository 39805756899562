import React, { Dispatch, SetStateAction } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import { RadioInput, TextInput } from 'shared-components/inputs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import { CenteredContent, CenteredForm } from 'shared-components/layout';

export const ADD_EX_SET = gql`
  mutation AddExSet($disputeId: Int!, $exSetPrefix: String!, $exSetName: String!, $exSetSequencingType: Int) {
  addExSet(disputeId: $disputeId, exSetPrefix: $exSetPrefix, exSetName: $exSetName, exSetSequencingType: $exSetSequencingType) {
    exSetId
  }
}
`

const schema = yup.object({
    exSetName: yup.string().required('Exhibit name is required'),
    exSetPrefix: yup.string().required('Exhibit prefix is required'),
    // exSetStart: yup.string().required('Exhibit start number is required'),
    exSetSequencingType: yup.number().required('Exhibit sequencing Type is required')
}).required()

type FormData = {
    exSetName: string,
    exSetPrefix: string,
    // exSetStart: string,
    exSetSequencingType: number,
}

const exSetSequencingTypeOptions = [
    { label: 'Numeric', value: 1 },
    { label: 'Alphabetic', value: 2 },
]

type AddExhibitSetModalProps = {
    openAddExhibitSet: boolean,
    setOpenAddExhibitSet: Dispatch<SetStateAction<boolean>>,
    refetchExhibitSets: () => void,
    disputeId: number
}

export default function AddExhibitSetModal({ openAddExhibitSet, setOpenAddExhibitSet, refetchExhibitSets, disputeId }: AddExhibitSetModalProps) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [addExSet, { loading: addExSetLoading, error: addExSetError }] = useMutation(ADD_EX_SET)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            exSetName: '',
            exSetPrefix: '',
            // exSetStart: '',
            exSetSequencingType: 1,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, reset, formState: { errors } } = methods

    const onSubmit = async (data: FormData) => {
        const variables = {...data, disputeId}
        await addExSet({variables})
        refetchExhibitSets()
        reset()
        setOpenAddExhibitSet(false)
    }

    if (addExSetLoading) {
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    }

    return (
        <Dialog open={openAddExhibitSet} onClose={() => setOpenAddExhibitSet(false)} maxWidth={'sm'} fullScreen={fullScreen}>
            <FormProvider {...methods}>
                <DialogTitle>Create Exhibit Set</DialogTitle>
                <DialogContent>
                    <CenteredForm>
                        <TextInput
                            name="exSetName"
                            label="Exhibit Set Name"
                            required
                            error={errors.exSetName !== undefined ? true : false }
                            errorMessage={errors.exSetName ? errors.exSetName.message : undefined}
                        />
                        <TextInput
                            name="exSetPrefix"
                            label="Exhibit Set Prefix"
                            required
                            error={errors.exSetPrefix !== undefined ? true : false }
                            errorMessage={errors.exSetPrefix ? errors.exSetPrefix.message : undefined}
                        />
                        <RadioInput 
                            label="Exhibit Set Sequencing Type"
                            name="exSetSequencingType"
                            options={exSetSequencingTypeOptions}
                            defaultValue={1}
                            required
                        />
                        {/* <TextInput
                            name="exSetStart"
                            label="Exhibit Set Start Number"
                            required
                            error={errors.exSetStart !== undefined ? true : false }
                            errorMessage={errors.exSetStart ? errors.exSetStart.message : undefined}
                        /> */}
                    </CenteredForm>
                </DialogContent>
                <DialogActions>
                    <Button 
                        sx={{ float: 'right' }}
                        onClick={handleSubmit(onSubmit)} 
                        variant="contained" color="primary">
                        Add Exhibit Set
                    </Button>
                </DialogActions>
            </FormProvider>
            <ErrorModal error={addExSetError && addExSetError.message || ''}  />
        </Dialog>
    )
}
