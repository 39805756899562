import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'

import { 
    DataGridPremium, 
    GridColumnMenuContainer, 
    GridColumnMenuProps, 
    GridColumnMenuPinningItem, 
    GridColumnMenuColumnsItem, 
    GridColumnMenuFilterItem, 
    GridRenderCellParams, 
    GridRowParams, 
    GridRowsProp, 
    GridToolbarColumnsButton, 
    GridToolbarContainer, 
    GridToolbarDensitySelector, 
    GridToolbarFilterButton, 
    GridValueFormatterParams, 
    GridColumnMenuHideItem, 
    GridColumnMenuSortItem 
} from '@mui/x-data-grid-premium'
import { lighten, styled } from '@mui/material/styles'

import { Box, CircularProgress } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'
import { CenteredContent } from 'shared-components/layout'
import { addLeadingZeros } from 'shared-components/utils'


const GET_DISPUTE = gql`
    query dispute($disputeId: Int) {
        dispute(disputeId: $disputeId) {
            disputeId
            jobs {
                jobId,
                jobOwner,
                jobCreatedAt,
                jobName,
                jobStatus,
                jobDisputeId,
                jobNeedsRefinish,
                jobBatesPrefix,
                jobBatesStart,
                jobBatesEnd,
                jobBatesNumDigits,
                jobBatesLength,
                jobFinishedTimestamp,
                jobPrivStart,
                jobPrivEnd,
                jobPrivNumDigits,
                jobPrivLength,
                jobIrrelStart,
                jobIrrelEnd,
                jobIrrelNumDigits,
                jobIrrelLength,
                statusString {
                    status
                    message
                }
            }
        }
    }
`

const getBackgroundColor = (color: string) => lighten(color, 0.3)

const getHoverBackgroundColor = (color: string) => lighten(color, 0.2)

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    '& .job-needs-refinish-true': {
      backgroundColor: getBackgroundColor(theme.palette.error.main),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
      },
    },
  }))

function CustomToolbar() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector sx={{ mr: 1}} />
            </GridToolbarContainer>
        </Box>
    )
  }

export const CustomGridColumnMenu = React.forwardRef<
HTMLUListElement,
GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
// eslint-disable-next-line react/prop-types
const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };

return (
    <GridColumnMenuContainer ref={ref} {...props}>
      <GridColumnMenuSortItem {...itemProps} />
      <GridColumnMenuFilterItem {...itemProps} />
      <GridColumnMenuHideItem {...itemProps} />
      <GridColumnMenuColumnsItem {...itemProps} />
      <GridColumnMenuPinningItem {...itemProps} />
    </GridColumnMenuContainer>
);
})

type JobsDataGridProps = { 
    disputeId: number,
}

export default function JobsDataGrid({ 
    disputeId,
} : JobsDataGridProps ) {
    const navigate = useNavigate()
    const { loading, error: disputeError, data } = useQuery(GET_DISPUTE, {
        fetchPolicy: 'network-only',
        variables: { disputeId },
    })

    const handleRowClick = (params: GridRowParams) => navigate(`/app/document-set/${params.row.jobId}`)

    const rows: GridRowsProp = data && data.dispute.jobs

    const columns = [
        {
            field: 'jobName', 
            headerName: 'Document Set',
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.jobNeedsRefinish && params.row.jobStatus === 71) {
                    return (
                        <span style={{ color: '#fff'}}>
                            {params.row.jobName}
                            <br />
                            Document Set reopened but not completed. Refinish document set.
                        </span>
                    )
                }
                if (params.row.jobNeedsRefinish && params.row.jobStatus === 70) {
                    return (
                        <span style={{ color: '#fff'}}>
                            {params.row.jobName}
                            <br />
                            Bates Discrepancy. Refinish Document Set.
                        </span>
                    )
                }
                return params.row.jobName
            }
        },
        {
            field: 'jobCreatedAt', 
            headerName: 'Date Created',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'jobFinishedTimestamp', 
            headerName: 'Date Finished',
            width: 175,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return ''
                }
                const valueFormatted = moment(params.value).format('MM/DD/YYYY hh:mm a')
                return valueFormatted
            }
        },
        {
            field: 'jobBatesPrefix',
            headerName: 'Bates Prefix',
            width: 100,
        },
        {
            field: 'batesRange', 
            headerName: 'Bates Range',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const { jobBatesStart, jobBatesEnd, jobBatesNumDigits, jobBatesLength } = params.row
                const start = jobBatesStart && jobBatesNumDigits 
                    ? addLeadingZeros(jobBatesStart, jobBatesNumDigits) 
                    : ''

                const end = jobBatesEnd && jobBatesNumDigits 
                    ? addLeadingZeros(jobBatesEnd, jobBatesNumDigits) 
                    : ''

                if (jobBatesLength && jobBatesLength > 1) {
                    return `${start} - ${end}`
                }
                if (jobBatesLength === 1) {
                    return start
                }
                    return ''
            }
        },
        {
            field: 'privRange', 
            headerName: 'Priv Range',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const { jobPrivStart, jobPrivEnd, jobPrivNumDigits, jobPrivLength } = params.row
                const start = jobPrivStart && jobPrivNumDigits 
                    ? addLeadingZeros(jobPrivStart, jobPrivNumDigits) 
                    : ''

                const end = jobPrivEnd && jobPrivNumDigits 
                    ? addLeadingZeros(jobPrivEnd, jobPrivNumDigits) 
                    : ''

                if (jobPrivLength && jobPrivLength > 1) {
                    return `${start} - ${end}`
                }
                if (jobPrivLength === 1) {
                    return start
                }
                    return ''
            }
        },
        {
            field: 'irrelRange', 
            headerName: 'Irrel Range',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const { jobIrrelStart, jobIrrelEnd, jobIrrelNumDigits, jobIrrelLength } = params.row
                const start = jobIrrelStart && jobIrrelNumDigits 
                    ? addLeadingZeros(jobIrrelStart, jobIrrelNumDigits) 
                    : ''

                const end = jobIrrelEnd && jobIrrelNumDigits 
                    ? addLeadingZeros(jobIrrelEnd, jobIrrelNumDigits) 
                    : ''

                if (jobIrrelLength && jobIrrelLength > 1) {
                    return `${start} - ${end}`
                }
                if (jobIrrelLength === 1) {
                    return start
                }
                    return ''
            }
        },
    ]

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )
    }

    return (
        <Box sx={{ height: 'calc(100vh - 400Px)', width: '100%' }}>
            <StyledDataGrid
                rows={rows} 
                columns={columns} 
                getRowId={(row) => row.jobId}
                disableRowSelectionOnClick
                onRowClick={(params) => handleRowClick(params)}
                pagination
                components={{
                    Toolbar: CustomToolbar,
                    ColumnMenu: CustomGridColumnMenu,
                }}
            />
            <ErrorModal error={(disputeError && disputeError.message) || ''} />
        </Box>
    )
}
