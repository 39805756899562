import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { CenteredContent, DetailWrapper, DetailContainer } from 'shared-components/layout'
import { CircularProgress, Grid } from 'shared-components/material/core'
import { ErrorModal } from 'shared-components/modals'

import DocumentSetsTable from './DocumentSetsGrid'
import BatesHeader from './BatesHeader'
import DisputeMeta from './DisputeMeta'

const GET_BATESREC = gql`
    query BatesRec($batesRecId: Int) {
        batesRec(batesRecId: $batesRecId) {
            batesRecId
            batesRecDisputeId
            batesRecPrefix
            batesRecNextBatesNum
            batesRecNextPrivNum
            batesRecNextIrrelNum
            batesRecIsDefault
            batesRecBatesLength
            batesRecPrivLength
            batesRecHasSpace
            batesRecParty
            batesRecLocked
            batesRecLockedUser
            batesRecLockedJob
            batesRecLockedTimestamp
            batesRecCreatedAt
            jobs {
                jobId
                jobCreatedAt
                jobName
                jobBatesRecId
                jobBatesPrefix
                jobBatesStart
                jobBatesEnd
                jobBatesLength
                jobPrivStart
                jobPrivEnd
                jobPrivLength
                jobIrrelStart
                jobIrrelEnd
                jobFinishedTimestamp
                jobBatesNumDigits
                jobIrrelNumDigits
                jobPrivNumDigits
            }
        }
    }
`

export default function Bates () {
    const { batesRecId } = useParams()

    const id = batesRecId ? parseInt(batesRecId) : 0
    
    const { loading, error, data } = useQuery(GET_BATESREC, {
        variables: { batesRecId: id },
    })

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <DetailWrapper>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={6}>
                    <DetailContainer>
                        <BatesHeader batesRec={data.batesRec} />
                    </DetailContainer>
                </Grid>
                <Grid item xs={6}>
                    <DetailContainer>
                        <DisputeMeta disputeId={data.batesRec.batesRecDisputeId} />
                    </DetailContainer>
                </Grid>
                <Grid item xs={12}>
                    <DetailContainer>
                        <DocumentSetsTable disputeId={data.batesRec.batesRecDisputeId} />
                    </DetailContainer>
                </Grid>
            </Grid>
            <ErrorModal error={error ? error.message : ''} />
        </DetailWrapper>
    )
}


