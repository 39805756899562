import React, { Dispatch, SetStateAction, memo } from 'react'

import { 
    GridToolbarColumnsButton, 
    GridToolbarContainer, 
    GridToolbarDensitySelector, 
    GridToolbarFilterButton, 
} from '@mui/x-data-grid-premium'

import { Box, Button } from 'shared-components/material/core'

function CustomToolbar({
    setOpenAddExhibitSet
} : { 
    setOpenAddExhibitSet: Dispatch<SetStateAction<boolean>>,
}) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector sx={{ mr: 1}} />
            </GridToolbarContainer>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenAddExhibitSet(true)}
                    size='small'
                >
                    Add Exhibit Set
                </Button>
            </div>
        </Box>
    )
  }

  export default memo(CustomToolbar)