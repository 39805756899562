import React, { useState, useEffect } from 'react'

import { CenteredContent } from 'shared-components/layout'
import { CircularProgress, Paper, Grid, Typography } from 'shared-components/material/core'
import { ErrorModal, AddCaseModal } from 'shared-components/modals'
import { useError } from 'shared-components/hooks'

import CasesDataGrid from './CasesDataGrid'

import { getDisputes } from 'api/disputes'
import VideoModal from 'shared-components/modals/VideoModal'
import ExhibitGenieGettingStarted from 'shared-components/videos/ExhibitGenieGettingStarted'
import { useUploadContext } from 'App/App'

function Cases () {
    const { user } = useUploadContext()
    const [error, href, handleError, resetError ] = useError()
    const [disputes, setDisputes] = useState([])
    const [loading, setLoading] = useState(true)
    const [openAddCase, setOpenAddCase] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(true)

    const getDisputesFc = async () => {
        setLoading(true)
        const payload = await getDisputes()
            .catch(e => handleError(e))
        if (payload.success) {
            setDisputes(payload.data.disputes)
            setLoading(false)
        } else {
            setLoading(false)
            handleError(payload.err, payload.href)
        }
    }

    useEffect(() => {
        getDisputesFc()
    }, [])

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Cases
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <CasesDataGrid disputes={disputes} setOpenAddCase={setOpenAddCase} refetch={getDisputesFc} />
                    </Paper>
                </Grid>
                <AddCaseModal 
                    openAddCase={openAddCase}
                    setOpenAddCase={setOpenAddCase}
                    getDisputesFc={getDisputesFc}
                />
                <ErrorModal error={error} href={href} resetError={resetError} />
                {user.userShowExGenVideo && (
                        <VideoModal 
                            openVideoModal={openVideoModal}
                            setOpenVideoModal={setOpenVideoModal}
                            title='Introducing Exhibit Genie'
                            video={<ExhibitGenieGettingStarted />}
                        />
                    )
                }
                
            </Grid>
        </>
    )
}

export default Cases