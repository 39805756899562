import React from 'react'
import { Job } from 'generated/graphql'

import { grey } from '@mui/material/colors'
import { Typography, CircularProgress, Box } from 'shared-components/material/core'

function CircularProgressWithLabel({ value }: { value: number }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
        <CircularProgress
          variant="determinate"
          sx={{ color: grey.A200 }}
          size={100}
          thickness={6}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            color: 'primary',
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            },
          }}
          size={100}
          thickness={6}
          value={value}
        />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2" component="p" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default function Progress({ job } : { job: Job }) {
  const emls = job.emls || []
  const atts = job.atts || []
  const docs = job.docs || []

  const totalCatfinal = emls.filter(eml => eml && eml.emlCatFinal).length 
    + atts.filter(att => att && att.attCatFinal).length
    + docs.filter(doc => doc && doc.docCatFinal).length
  
  const recsTotal = (job.jobEmlsTotal || 0) + (job.jobAttsTotal || 0) + (job.jobDocsTotal || 0)

  const progress = (totalCatfinal / recsTotal) * 100

  return (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column' 
      }}>
        <Typography
          variant="body1"
          component="h3"
          color="textSecondary"
          gutterBottom
        >
          <strong>Progress</strong>
        </Typography>
        <CircularProgressWithLabel value={progress || 0} />
      </div>
  )
}
