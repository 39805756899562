import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { CenteredForm, SplitScreen } from 'shared-components/layout'
import {
    AlertProps,
    Box,
    Button,
    Divider,
    Link,
    MuiAlert,
    Snackbar,
    Typography
} from 'shared-components/material/core'
import { PasswordInput, TextInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks'
import { ClioLogo } from 'shared-components/svg'
import { generateId } from 'shared-components/utils'

import { login } from '../../api/accounts'

import './clio.css'
import { getSSOLink } from 'api/clio'
import AccountInUseModal from 'shared-components/modals/AccountInUseModal'
import ExhibitGenieIntro from 'shared-components/videos/ExhibitGenieIntro'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

const schema = yup.object({
    email: yup.string().email("please enter a valid email").required('Email required'),
    password: yup
        .string()
        .required()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])((?=.*[A-Z])|(?=.*[0-9])|(?=.*[!@#\$%\^&\*]))(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )
  }).required()

type FormData = {
    email: string,
    password: string
}

interface StateType {
    redirect: string
 }
  
function Login () {
    const location = useLocation()
    const locationState = location.state as StateType
    const [openAccountInUseModal, setOpenAccountInUseModal] = useState(false)
    const [error, href, handleError, resetError] = useError()
    console.log(href)
    const navigate = useNavigate()

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            email: '',
            password: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, getValues, formState: { errors }} = methods


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return
        }
        resetError()
      }

    const onSubmit = async (data: FormData, killExistingSession: boolean) => {
        const { email, password } = data
        const payload = await login(email, password, killExistingSession)
            .catch(e => handleError(e))
        if (payload.success) {
            if (payload.needsToVerifyEmail) {
                return handleError("Please validate your email address by clicking the link in the email we sent you.")
            }
            if (payload.hasPendingSubscription) {
                return navigate('/subscription-payment')
            }
            if (payload.needsToCompleteAccountSetup) {
                return navigate('/account-setup')
            }
            if (locationState && locationState.redirect) {
                return navigate(locationState.redirect)
            }
            return navigate('/app/cases')
        } else if (!payload.success && payload.hasExistingSession) {
            return setOpenAccountInUseModal(true)
        }

        return handleError(payload.err, payload.href)
    }

    const handleClioSignIn = async () => {
        const payload = await getSSOLink()
            .catch(e => handleError(e))

        if (payload.success) {
            const identityState = generateId(10)
            localStorage.setItem('identityState', identityState);
            return window.location.assign(`${payload.data.clioLink}&state=${identityState}`)
        }

        return handleError(payload.err)
    }

    return (
        <FormProvider {...methods} >
            <SplitScreen video={<ExhibitGenieIntro />}> 
                <CenteredForm>
                    <img
                        width='336.73px'
                        src='/blueLogo.png'
                        style={{ alignSelf: 'center', marginBottom: '3em' }}
                    />
                    <Typography variant="h2" component="h2" textAlign='left'>
                        Login
                    </Typography>
                    <TextInput
                        name="email"
                        label="E-Mail"
                        required
                        error={errors.email !== undefined ? true : false }
                        errorMessage={errors.email ? errors.email.message : undefined}
                    />
                    <PasswordInput
                        name={"password"}
                        label={"Password"}
                        error={errors.password != undefined ? true : false }
                        errorMessage={errors.password ? errors.password.message : undefined}
                    />
                    <Typography variant="caption" component="p" textAlign='left'>
                        By logging in, you agree to the{' '}
                        <Link href='http://www.discoverygenie.com/wp-content/uploads/2023/10/2023-Terms-of-Use-v.7.pdf' target="_blank">User Agreement and Terms of Use</Link>.
                    </Typography>
                    <Button
                        type='submit'
                        variant="contained"
                        size="large"
                        onClick={handleSubmit(data => onSubmit(data, false))}
                        tabIndex={0}
                    >
                        Login
                    </Button>
                </CenteredForm>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '35ch',
                    maxWidth: '35ch',
                    '& .MuiButton-root': { m: 1 },
                    '& .MuiTypography-root': { m: 1 },
                    '& .MuiLink-root': { m: 0 },
                    '& .MuiFormControl-root': { m: 1 },
                    '& .MuiCheckbox-root': { m: 1 },
                }}>
                    <Button variant="text" size="small" href='/forgot-password'>Forgot Password</Button>
                    <Divider sx={{ m: '1rem' }} />
                    <Typography variant="body1" component="p" textAlign='center'>
                    Don&apos;t have an account?
                </Typography>
                <Button variant="text" size="small" href="/register">Register</Button>
                <Divider style={{ width: '100%', marginTop: '2em' }} />
                <button 
                    className="ClioButton"
                    onClick={handleClioSignIn}
                    tabIndex={-1}
                >
                        <ClioLogo width="16px" style={{ paddingRight: '8px' }} /> Sign in with Clio
                </button>
                </Box>
            </SplitScreen>
            <AccountInUseModal 
                openAccountInUseModal={openAccountInUseModal}
                login={onSubmit}
                formData={getValues()}
            />
            <Snackbar
                open={error.length > 0}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </FormProvider>
    )
}

export default Login