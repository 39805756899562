import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from 'shared-components/material/core'
import Templates from './Templates'

const options = [
  {
    slug: 'templates',
    component: <Templates />,
    title: 'Templates'
  },
]

function Help () {
  const { option } = useParams()
  const selectedOption = options.filter(navOption => navOption.slug === option)[0]

  return (
    <>
      <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
        Help
      </Typography>
        <Box sx={{ width: '100%' }}>
          {selectedOption.component}
        </Box>
    </>
  )
}

export default Help